import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import * as moment from "moment";
import {
	DateRangeUtilService,
	IMyDate,
	IMyDateRangeModel,
} from "mydaterangepicker";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { ApplyFilters } from "../app-assets/models/applyFilters";
import { Store } from "../app-assets/models/store";

@Injectable({
	providedIn: "root",
})
export class StateService {
	private currentStore = new BehaviorSubject<Store>(undefined);
	get getCurrentStore(): Observable<Store> {
		return this.currentStore.asObservable();
	}
	selectStore(value: Store) {
		this.currentStore.next(value);
	}

	private currentFilters = new BehaviorSubject<ApplyFilters>(new ApplyFilters());
	get getCurrentFilters(): Observable<ApplyFilters> {
		return this.currentFilters.asObservable();
	}
	selectFilters(value: ApplyFilters) {
		this.currentFilters.next(value);
	}

	get getStoreAndFilter(): Observable<[Store, ApplyFilters]> {
		return combineLatest(this.getCurrentStore, this.getCurrentFilters);
	}
	resetStoreAndFilters() {
		this.selectStore(undefined);
		this.selectFilters(new ApplyFilters());
	}

	private headerTitle = new BehaviorSubject<string>("");
	get getHeaderTitle(): Observable<string> {
		return this.headerTitle.asObservable();
	}
	setHeaderTitle(value: string) {
		this.headerTitle.next(value);
	}

	private dateRange = new BehaviorSubject<IMyDateRangeModel>(null);
	get getDateRange(): Observable<IMyDateRangeModel> {
		return this.dateRange.asObservable();
	}
	getCurrentDateRange(): IMyDateRangeModel {
		return this.dateRange.value;
	}
	public setDateRange(value: IMyDateRangeModel) {
		if (!this.fyCahngeInProgress) {
			this.selectedYear = undefined;
		}
		this.fyCahngeInProgress = false;
		this.dateRange.next(value);
	}
	public years: string[] = [];
	public selectedYear: string;
	fyCahngeInProgress = false;
	public onChangeSelectedYear($event, setDateRange: boolean = false) {
		if ($event) {
			let year = parseInt($event.substr(0, 4));
			let mStart = moment("01-07-" + year, "DD-MM-YYYY");
			let mEnd = moment("01-07-" + (year + 1), "DD-MM-YYYY");
			mEnd = mEnd.subtract(1, "d");
			let beginDate = mStart.toDate();
			let endDate = mEnd.toDate();
			this.fyCahngeInProgress = true;
			this.updateDateRange("set", beginDate, endDate, setDateRange);
		}
	}

	private dateRangeUpdateId = new BehaviorSubject<string>("");
	public dateRangeUpdateOperation: string = "";
	public dateRangeUpdateBeginDate: Date | null = null;
	public dateRangeUpdateEndDate: Date | null = null;
	get getDateRangeUpdateId(): Observable<string> {
		return this.dateRangeUpdateId.asObservable();
	}
	updateDateRange(
		operation: string,
		beginDate: Date | null,
		endDate: Date | null,
		setDateRange: boolean = false
	) {
		if (setDateRange) {
			if (operation === "null") {
				this.setDateRange(null);
			} else {
				let drm = this.getDateRangeModel(
					{
						year: beginDate.getFullYear(),
						month: beginDate.getMonth() + 1,
						day: beginDate.getDate(),
					},
					{
						year: endDate.getFullYear(),
						month: endDate.getMonth() + 1,
						day: endDate.getDate(),
					}
				);
				this.setDateRange(drm);
			}
		}
		this.dateRangeUpdateOperation = operation;
		this.dateRangeUpdateBeginDate = beginDate;
		this.dateRangeUpdateEndDate = endDate;
		let guid = Guid.create().toString();
		this.dateRangeUpdateId.next(guid);
	}
	getDateRangeModel(beginDate: IMyDate, endDate: IMyDate): IMyDateRangeModel {
		// Creates a date range model object from the given parameters
		let bEpoc: number = this.drus.getTimeInMilliseconds(beginDate) / 1000.0;
		let eEpoc: number = this.drus.getTimeInMilliseconds(endDate) / 1000.0;
		return {
			beginDate: beginDate,
			beginJsDate: this.getDate(beginDate),
			endDate: endDate,
			endJsDate: this.getDate(endDate),
			formatted: "",
			beginEpoc: bEpoc,
			endEpoc: eEpoc,
		};
	}
	getDate(date: IMyDate): Date {
		return new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0);
	}

	getCurrentFY(): number {
		let m = moment.utc();
		let currentYear = m.year();
		let currentMonth = m.month(); // 0 - 11
		// < July
		if (currentMonth < 6) {
			currentYear -= 1;
		}
		return currentYear;
	}

	getMaxFYToShow(): number {
		let currentFY = this.getCurrentFY();
		let showFYFrom = moment("15-06-" + (currentFY + 1), "DD-MM-YYYY").toDate();
		let now = moment().toDate();
		if (showFYFrom <= now) {
			currentFY += 1;
		}
		return currentFY;
	}

	yearToFY(year: number): string {
		return year.toString() + "/" + (year + 1).toString().substr(-2);
	}

	isCurrentFY(): boolean {
		let currentFY = this.getCurrentFY();
		let beginFY = moment("01-07-" + currentFY, "DD-MM-YYYY").toDate();
		let endFY = moment("01-07-" + (currentFY + 1), "DD-MM-YYYY").toDate();
		let dateRange = this.getCurrentDateRange();
		let result = false;
		if (dateRange && dateRange.beginDate && dateRange.endDate) {
			let beginDate = this.getDate(dateRange.beginDate);
			let endDate = this.getDate(dateRange.endDate);
			result =
				beginDate >= beginFY &&
				beginDate < endFY &&
				endDate >= beginFY &&
				endDate < endFY;
		}
		return result;
	}

	constructor(private drus: DateRangeUtilService) {
		let years: string[] = [];
		let currentYear = this.getMaxFYToShow();
		for (let i = 2018; i <= currentYear; i++) {
			years.push(this.yearToFY(i));
		}
		this.years = years;
	}
}
