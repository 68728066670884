<main>
	<div class="container">
		<div class="row">
			<div class="col">
				<form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
					<div class="card mx-auto login">
						<div class="card-header">
							<h2 class="mb-0 h5"><fa-icon [icon]="faLogin"></fa-icon> Login</h2>
						</div>
						<div class="card-body">
							<div
								*ngIf="isInvalidLogin$ | async"
								class="alert alert-danger half-strong"
							>
								Invalid username or password.
							</div>
							<div class="form-group mb-3">
								<label for="username" class="mb-3">Username</label>
								<input
									ngModel
									[disabled]="isLoginInProgress$ | async"
									type="text"
									name="username"
									id="username"
									class="form-control"
									placeholder="Enter username"
									required
									autofocus
									[ngClass]="{ 'is-invalid': isInvalidLogin$ | async }"
								/>
							</div>
							<div class="form-group mb-0">
								<label for="password" class="mb-3">Password</label>
								<input
									ngModel
									[disabled]="isLoginInProgress$ | async"
									type="password"
									name="password"
									id="password"
									class="form-control"
									placeholder="Enter password"
									required
									[ngClass]="{ 'is-invalid': isInvalidLogin$ | async }"
								/>
							</div>
						</div>
						<div class="card-footer d-flex justify-content-end">
							<button
								[hidden]="!isAzureADEnabled"
								[disabled]="isLoginInProgress$ | async"
								type="button"
								class="btn btn-microsoft me-3"
								(click)="loginAzure()"
							>
								<span class="me-2"><fa-icon [icon]="faMicrosoft"></fa-icon></span>Azure
								Log in
							</button>

							<button
								[hidden]="!isSamlEnabled"
								[disabled]="isLoginInProgress$ | async"
								type="button"
								class="btn btn-microsoft me-3"
								(click)="loginSaml()"
							>
								Okta Log in
							</button>

							<button
								[disabled]="isLoginInProgress$ | async"
								type="submit"
								class="btn btn-primary"
							>
								Log in<span class="loader loader-small loader-reverse"
									><span class="loader-inner"
										><span class="left">Loading...</span><span class="right"></span></span
								></span>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</main>
