<header class="navbar navbar-light without-filter">
  <div class="container d-block">
    <div class="row align-items-center w-100">
      <div class="col">
        <div class="row align-items-md-center credentials">
          <div class="col-12 col-md-auto logo">
            <a routerLink="/">
              <img *ngIf="enableLogo" [src]="companyLogo" alt="Home" class="logo-company" />
            </a>
          </div>
          <div class="col title">
            <h1 class="main-title mb-0 mt-2 mt-md-0">
              {{ headerTitle$ | async }}
            </h1>
          </div>
          <div class="col-12 col-md-auto logo">
            <a routerLink="/">
              <img src="../../assets/img/platinumfm.png" alt="platinum.fm" class="logo-pf" />
            </a>
          </div>
        </div>
      </div>
      <!-- Hide on login-->
      <div *ngIf="isLoggedIn$ | async" class="col-12 col-xl-auto mt-3 mt-md-2 mt-xl-0">
        <div class="row align-items-center filters">
          <div class="col-auto main-menu">
            <div ngbDropdown class="dropdown">
              <button ngbDropdownToggle id="menu-link" class="menu-link navbar-toggler" type="button">
                <span class="navbar-toggler-icon"></span>
              </button>
              <nav ngbDropdownMenu class="dropdown-menu" aria-labelledby="menu-link" style="top: 40px;left: -184px;">
                <p class="user-name m-0">
                  <span class="user-icon">
                    <fa-icon [icon]="faUser"></fa-icon>
                  </span>
                  <span>{{ currentUsername$ | async }}</span>
                </p>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/work-orders-open">Open Work Orders</a>
                <a class="dropdown-item" routerLink="/work-orders-completed">Completed Work Orders</a>
                <a *ngIf="
                    whiteLabelService.getSettings.assetConditionEnable &&
                    ((roles$ | async).indexOf('admin') > -1 ||
                      (roles$ | async).indexOf('fm') > -1)
                  " class="dropdown-item" routerLink="/asset-condition">{{
                  whiteLabelService.getSettings.assetConditionName }}</a>
                <span class="dropdown-item" (click)="logOut()">Logout</span>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!-- / Hide on login-->
    </div>
  </div>
</header>