import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faChartColumn,
} from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-open-state",
	templateUrl: "./work-orders-open-state.component.html",
})
export class WorkOrdersOpenStateComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loadedByState: boolean;
	chartConfigByState = {
		type: "bar",
		data: {
			labels: [],
			datasets: [],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: true,
			},
			scales: {
				xAxes: [
					{
						ticks: {
							autoSkip: false,
						},
					},
				],
				yAxes: [
					{
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "left",
						id: "y-axis-1",
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `${label.toLocaleString()}`;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data["datasets"][tooltipItem[0]["datasetIndex"]].label;
						return title === null ? "null" : title;
					},
					label: function (tooltipItem, data) {
						return `${tooltipItem.yLabel.toLocaleString()}`;
					},
				},
			},
			onClick: (event) => {
				var activePoints = this.chartByState.chart.getElementsAtEvent(event);
				if (activePoints && activePoints.length > 0) {
					for (var i = 0; i < activePoints.length; i++) {
						if (activePoints[i]._model && activePoints[i]._model.label) {
							this.woFiltersService.isCollapsed = false;
							var value = activePoints[i]._model.label;
							if (this.facilityTypeMode) {
								this.woFiltersService.selectedStoreType = [value];
							} else {
								this.woFiltersService.selectedState = [value];
							}
							break;
						}
					}
				}
			},
		},
	};
	@ViewChild("stateChart") chartByState: ChartComponent;

	public hasBack(): boolean {
		return (
			(this.facilityTypeMode &&
				this.woFiltersService.selectedStoreType &&
				this.woFiltersService.selectedStoreType.length > 0) ||
			(!this.facilityTypeMode &&
				this.woFiltersService.selectedState &&
				this.woFiltersService.selectedState.length > 0)
		);
	}

	backgroundColor = [];
	hoverBackgroundColor = [];
	public facilityTypeMode: boolean;
	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;
		this.facilityTypeMode = this.whiteLabelService.getFacilityTypeMode;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByState = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Open"];
		body = this.woFiltersService.fillBodyEndDateThisMonth(body);
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByState = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		if (this.facilityTypeMode) {
			this.subs.add(
				this.workOrderReportService
					.getRequestSummaryStoreType(body)
					.subscribe((data) => {
						if (this.woFiltersService.getCurrentUpdateId() === updateId) {
							this.loadedByState = true;
							let newLabels = [];
							let newData = [];
							let states = [];
							let types = [];
							data.forEach((element) => {
								if (!states.includes(element.storeType)) {
									states.push(element.storeType);
								}
								if (!types.includes(element.requestType)) {
									types.push(element.requestType);
								}
							});
							newLabels = states;
							let array = [];
							states.forEach((element2) => {
								array.push(0);
							});
							let colors2 = this.whiteLabelService.getIncidentPlannedReactiveColors();
							let i = 0;
							types.sort();
							types.forEach((element) => {
								newData.push({
									label: element,
									backgroundColor: colors2[element].backgroundColor,
									hoverBackgroundColor: colors2[element].hoverBackgroundColor,
									yAxisID: "y-axis-1",
									data: array.slice(),
								});
								i++;
							});
							data.forEach((element) => {
								var indexType = types.indexOf(element.requestType);
								var indexState = states.indexOf(element.storeType);
								newData[indexType].data[indexState] += element.count;
							});
							this.chartConfigByState.data.labels = newLabels;
							this.chartConfigByState.data.datasets = newData;
							if (this.chartByState) {
								this.chartByState.chart.update();
							}
							this.ref.detectChanges();
						}
					})
			);
		} else {
			this.subs.add(
				this.workOrderReportService
					.getRequestSummaryState(body)
					.subscribe((data) => {
						if (this.woFiltersService.getCurrentUpdateId() === updateId) {
							this.loadedByState = true;
							let newLabels = [];
							let newData = [];
							let states = [];
							let types = [];
							data.forEach((element) => {
								if (!states.includes(element.state)) {
									states.push(element.state);
								}
								if (!types.includes(element.requestType)) {
									types.push(element.requestType);
								}
							});
							newLabels = states;
							let array = [];
							states.forEach((element2) => {
								array.push(0);
							});
							let colors2 = this.whiteLabelService.getIncidentPlannedReactiveColors();
							let i = 0;
							types.sort();
							types.forEach((element) => {
								newData.push({
									label: element,
									backgroundColor: colors2[element].backgroundColor,
									hoverBackgroundColor: colors2[element].hoverBackgroundColor,
									yAxisID: "y-axis-1",
									data: array.slice(),
								});
								i++;
							});
							data.forEach((element) => {
								var indexType = types.indexOf(element.requestType);
								var indexState = states.indexOf(element.state);
								newData[indexType].data[indexState] += element.count;
							});
							this.chartConfigByState.data.labels = newLabels;
							this.chartConfigByState.data.datasets = newData;
							if (this.chartByState) {
								this.chartByState.chart.update();
							}
							this.ref.detectChanges();
						}
					})
			);
		}
	}
	//#endregion

	// Icons
	faChartColumn = faChartColumn;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
