import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	faFileExport,
	faTable
} from '@fortawesome/free-solid-svg-icons';
import {
	DatatableComponent
} from '@swimlane/ngx-datatable';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import * as moment from 'moment';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { MessageService } from '../../services/message.service';
import {
	StateService
} from '../../services/state.service';
import {
	WhiteLabelService
} from '../../services/white-label.service';
import { AssetRequestListResponseModel } from '../models/assetRequestListResponseModel';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	QueryOptionsPagingModel
} from '../models/queryOptionsPagingModel';
import {
	AssetService
} from '../services/asset.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';

@Component({
	selector: 'app-asset-affecting-st-table',
	templateUrl: './asset-affecting-st-table.component.html'
})
export class AssetAffectingSTTableComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		public whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private assetService: AssetService,
		private messageService: MessageService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(value);
		}));
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ['Open'];
		body.filter.assetTypeTags = ['Offline', 'Key'];
		return body;
	}

	private currentUpdateId: string = '';
	update(updateId: string) {
		this.currentUpdateId = updateId;
		setTimeout(() => {
			this.loadData(true);
		});
	}

	@ViewChild('assetAffectingSTTable') table: DatatableComponent;
	loading: boolean = false;

	tableData = {
		page: {
			size: 20,
			totalElements: 0
		},
		sorts: [{
			prop: 'assetOfflineStart',
			dir: 'asc'
		}],
		rows: [],
		columns: [{
			prop: 'storeNo',
			name: 'Store No'
		}, {
			prop: 'storeName',
			name: 'Store Name'
		}, {
			prop: 'storeState',
			name: 'Store State'
		}, {
			prop: 'assetName',
			name: 'Asset Name'
		}, {
			prop: 'assetId',
			name: 'Asset Id'
		}, {
			prop: 'assetId',
			name: 'Asset Id'
		}, {
			prop: 'assetBarcode',
			name: 'Barcode'
		}, {
			prop: 'priority',
			name: 'Priority'
		}, {
			prop: 'statusName',
			name: 'Status'
		}, {
			prop: 'assetOfflineStart',
			name: 'Offline Time (hrs)'
		}, {
			prop: 'vendorEstimateAttended',
			name: 'Vendor ETA'
		}, {
			prop: 'remainingTime',
			name: 'Remaining Time'
		}, {
			prop: 'vendorName',
			name: 'Vendor'
		}, {
			prop: 'requestNumber',
			name: 'Request Number'
		}],
		onSort: (event) => {
			this.loadData(true);
		},
		onScroll: (event) => {
			if (event && event.currentTarget) {
				const offsetY = event.currentTarget.scrollTop;
				// total height of all rows in the viewport
				const viewHeight = event.currentTarget.clientHeight;

				// check if we scrolled to the end of the viewport
				if (!this.loading && offsetY + viewHeight >= (this.tableData.rows.length - 4) * 38/*rowHeight*/) {
					this.loadData(false);
				}
			}
		},
		onAssetIdClick: (row) => {
			if (event.type == 'click') {
				window.open(this.whiteLabelService.getOldDashboardUrl() + '/Asset/AssetDetails?id=' + row.assetId, '_blank');
			}
		},
		onRequestNumberClick: (row) => {
			if (event.type == 'click') {
				window.open(this.whiteLabelService.getOldDashboardUrl() + '/FM/RequestDetails?requestid=' + row.id, '_blank');
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.table.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		}
	};

	loadData(cleanup: boolean) {
		if (!cleanup && this.tableData.rows.length >= this.tableData.page.totalElements) {
			return;
		}
		this.loading = true;
		let body = new QueryOptionsPagingModel();
		body.paging = {
			limit: this.tableData.page.size,
			currentPage: cleanup ? 1 : Math.round(this.tableData.rows.length / this.tableData.page.size) + 1
		};
		let sorts = [];
		this.table.sorts.forEach(element => {
			sorts.push({
				field: element.prop,
				order: this.workOrderReportService.capitalizeFirstLetter(element.dir)
			});
			if (element.prop === "priority") {
				sorts.push({
					field: "assetOfflineStart",
					order: "ASC"
				});
			}
		});
		body.sortBy = sorts;
		let body2 = this.fillBody(body, this.stateService.getCurrentDateRange());
		this.subs.add(this.assetService.getAssetRequestList(body2)
			.subscribe(data => this.subscribeTableData(data, cleanup)));
	}

	subscribeTableData(data: AssetRequestListResponseModel[], cleanup: boolean) {
		if (this.woFiltersService.getCurrentUpdateId() === this.currentUpdateId) {
			let newData = [];
			let m = moment();
			data.forEach(element => {
				let assetOfflineStart = moment(element.assetOfflineStart);
				let timeLapsed1 = moment.duration(m.diff(assetOfflineStart));
				let timeLapsed2 = Math.floor(timeLapsed1.as('h')) + moment.utc(timeLapsed1.as('ms')).format(':mm');

				let vendorEstimateAttended = element.vendorEstimateAttended ? moment(element.vendorEstimateAttended) : null;
				let remainingTime1 = vendorEstimateAttended ? moment.duration(vendorEstimateAttended.diff(m)) : null;
				let remainingTime2 = remainingTime1 ? (Math.floor(remainingTime1.as('h')) + moment.utc(remainingTime1.as('ms')).format(':mm')) : "";

				let newRow = {
					id: element.id,
					storeNo: element.storeNo,
					storeName: element.storeName,
					storeState: element.storeState,
					assetName: element.assetName,
					assetId: element.assetId,
					assetBarcode: element.assetBarcode,
					priority: element.priority,
					statusName: element.statusName,
					assetOfflineStart: timeLapsed2,
					vendorEstimateAttended: vendorEstimateAttended ? vendorEstimateAttended.format('DD-MM-YYYY HH:mm') : '',
					remainingTime: remainingTime2,
					vendorName: element.vendorName,
					requestNumber: element.requestNumber,

					resolveKPIFail: element.resolveKPIFail,
					respondKPIFail: element.respondKPIFail,
					requestDate: element.requestDate,
					completedDate: element.completed
				};
				newData.push(newRow);
			});
			if (data.length > 0 && this.tableData.page.totalElements !== data[0].totalCount) {
				this.tableData.page.totalElements = data[0].totalCount;
			}
			let dataArray;
			if (cleanup) {
				dataArray = [];
				setTimeout(() => {
					this.table.offset = 0;
					this.table.bodyComponent.updateOffsetY(0);
				});
			} else {
				dataArray = this.tableData.rows;
			}
			dataArray = dataArray.concat(newData);
			this.tableData.rows = [...dataArray];
			this.loading = false;
			this.ref.detectChanges();
		}
	}

	timeLapsedColor(data) {
		// warning: hours(current date - request date) > (hours(resolve date - request date) *0.7) or when ResponseKPI failed
		let isWarning = data.row.respondKPIFail === 1;
		if (!isWarning) {
			const requestDate = data.row.requestDate;
			const resolveDate = data.row.completedDate;
			if (requestDate && resolveDate) {
				const curretDateM = moment();
				const requestDateM = moment(requestDate);
				const resolveDateM = moment(resolveDate);
				isWarning = curretDateM.diff(requestDateM, "hours") > (resolveDateM.diff(requestDateM, "hours") * 0.7);
			}
		}

		let isRed = data.row.resolveKPIFail === 1;
		return { col: true, "bg-danger": isRed, "bg-warning": isWarning && !isRed, "bg-success": !isRed && !isWarning, "text-white": true };
	}

	remainingTimeColor(data) {
		let hasColor = !!data.row.remainingTime;
		let isRed = data.row.remainingTime && data.row.remainingTime.startsWith("-") ? true : false;
		return { col: true, "bg-danger": hasColor && isRed, "bg-success": hasColor && !isRed, "text-white": hasColor };
	}

	public inProgressExportCSV: boolean = false;
	private totalExport: number = -1;
	private dataExport: AssetRequestListResponseModel[];
	exportCsv() {
		this.inProgressExportCSV = true;
		this.dataExport = [];
		this.totalExport = -1;
		let body = this.fillBody(new QueryOptionsPagingModel(), this.stateService.getCurrentDateRange());
		this.exportCsvRequest(body);
	}

	exportCsvRequest(body: any) {
		const limit = 10000;
		body.paging = {
			limit: limit,
			currentPage: this.totalExport < 0 ? 1 : Math.round(this.dataExport.length / limit) + 1
		};
		this.subs.add(this.assetService.getAssetRequestList(body)
			.subscribe(data => {
				if (data.length > 0) {
					this.totalExport = data[0].totalCount;
					this.dataExport.push(...data);
				}
				if (this.dataExport.length >= this.totalExport) {
					this.inProgressExportCSV = false;
					if (this.dataExport.length > 0) {
						let headers = Object.getOwnPropertyNames(data[0]);
						if (this.whiteLabelService.getSettings.filterStoreLabel != "store") {
							// replace store with whitelabel setting in all headers
							headers = headers.map((header) =>
								header.replace("store", this.whiteLabelService.getSettings.filterStoreLabel)
							);
							headers = headers.map((header) =>
								header.replace("Store", this.whiteLabelService.capitalizeFirstLetter(this.whiteLabelService.getSettings.filterStoreLabel))
							);
						}
						if (this.whiteLabelService.getSettings.filterVendorLabel != "vendor") {
							// replace vendor with whitelabel setting in all headers
							headers = headers.map((header) =>
								header.replace("vendor", this.whiteLabelService.getSettings.filterVendorLabel)
							);
							headers = headers.map((header) =>
								header.replace("Vendor", this.whiteLabelService.capitalizeFirstLetter(this.whiteLabelService.getSettings.filterVendorLabel))
							);
						}
						let options = {
							fieldSeparator: ',',
							quoteStrings: '"',
							decimalseparator: '.',
							showLabels: true,
							headers: headers,
							showTitle: false,
							title: "export",
							useBom: true,
						};
						new AngularCsv(this.dataExport, 'export', options);
					} else {
						this.messageService.info("CSV Export", "No data");
					}
				} else {
					this.exportCsvRequest(body);
				}
			})
		);
	}

	// Icons
	faTable = faTable;
	faFileExport = faFileExport;
}