import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/message.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-weekly-status-volume-priority",
	templateUrl: "./weekly-status-volume-priority.component.html",
})
export class WeeklyStatusVolumePriorityComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByPriority: boolean;
	chartConfigByPriority = {
		type: "bar",
		data: {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [],
					hoverBackgroundColor: [],
				},
			],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: false,
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data["labels"][tooltipItem[0]["index"]];
						return title === null ? "null" : title;
					},
					label: function (tooltipItem, data) {
						var total = data["datasets"][0]["data"].reduce((a, b) => a + b, 0);
						//var total = this._chart.getDatasetMeta(0).total;
						var value = data["datasets"][0]["data"][tooltipItem["index"]];
						var percent = total == 0 ? 0 : (100 * value) / total;
						return `${value.toLocaleString()} (${percent.toLocaleString(undefined, {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						})}%)`;
					},
				},
			},
			onClick: (event) => {
				var activePoints = this.chartByPriority.chart.getElementsAtEvent(event);
				if (
					activePoints &&
					activePoints.length > 0 &&
					activePoints[0]._model &&
					activePoints[0]._model.label
				) {
					var value = activePoints[0]._model.label;
					if (value && value !== "null") {
						this.woFiltersService.selectedPriority = [value];
					}
				}
			},
		},
	};
	@ViewChild("priorityChart") chartByPriority: ChartComponent;

	backgroundColor = [];
	hoverBackgroundColor = [];

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private messageService: MessageService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;

		this.chartConfigByPriority.data.datasets[0].backgroundColor =
			this.backgroundColor;
		this.chartConfigByPriority.data.datasets[0].hoverBackgroundColor =
			this.hoverBackgroundColor;
	}

	ngOnInit() {
		this.loadedByPriority = false;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(value);
			})
		);
	}

	update(updateId: string) {
		this.loadedByPriority = false;
		let body = this.woFiltersService.fillBodyWeeklyStatus(
			new QueryOptionsModel()
		);
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryWeekPriority(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.loadedByPriority = true;
						let newLabels = [];
						let newData = [];
						data.forEach((element) => {
							if (newLabels.indexOf(element.priority) < 0) {
								newLabels.push(element.priority);
								newData.push(0);
							}
						});
						newLabels.sort();
						data.forEach((element) => {
							var index = newLabels.indexOf(element.priority);
							newData[index] += element.count;
						});
						this.chartConfigByPriority.data.labels = newLabels;
						this.chartConfigByPriority.data.datasets[0].data = newData;
						if (this.chartByPriority) {
							this.chartByPriority.chart.update();
						}
						this.ref.detectChanges();
					}
				})
		);
	}

	// Icons
	faChartColumn = faChartColumn;
}
