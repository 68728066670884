import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { DecimalPipeZeroDigits } from "../../decimalPipeZeroDigits";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-financial-asset-count",
	templateUrl: "./financial-asset-count.component.html",
})
export class FinancialAssetCountComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	resetFilters() {
		this.woFiltersService.resetFilters();
		this.stateService.updateDateRange("null", null, null);
		this.stateService.setDateRange(null); // call update automatically
	}

	loadedByAsset: boolean;
	tableDataByAsset = {
		rows: [],
		columnsStart: [
			{
				prop: "name",
				name: "Asset Category",
				headerClass: "col-3",
				cellClass: "col-3 cursor-pointer",
				resizeable: false,
				draggable: false,
			},
		],
		columns: [],
		columnsEnd: [
			{
				prop: "total",
				name: "Total",
				headerClass: "col text-end",
				cellClass: "col text-end",
				resizeable: false,
				draggable: false,
				pipe: new DecimalPipeZeroDigits("en-US"),
			},
		],
		total: [],
		onNameClick: (event) => {
			if (event && event.type == "click" && event.row) {
				let row = event.row;
				for (let i = 0; i < this.woFiltersService.assetNames.length; i++) {
					if (this.woFiltersService.assetNames[i] === row.assetName) {
						this.woFiltersService.isCollapsed = false;
						this.woFiltersService.selectedAssetName = [
							this.woFiltersService.assetNames[i],
						];
						break;
					}
				}
			}
		},
	};
	@ViewChild("assetTableCount") tableByAsset: DatatableComponent;

	public facilityTypeMode: boolean;
	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		public whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {
		this.facilityTypeMode = this.whiteLabelService.getFacilityTypeMode;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByAsset = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Open", "Completed"];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByAsset = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		if (this.facilityTypeMode) {
			this.subs.add(
				this.workOrderReportService
					.getRequestSummaryAssetStoreType(body)
					.subscribe((data) => {
						if (this.woFiltersService.getCurrentUpdateId() === updateId) {
							let headers = [];
							let newData: {
								[id: string]: any;
							} = {};
							let newTotal = [
								{
									class: "col text-end",
									value: 0,
								},
							];
							data.forEach((element) => {
								if (!headers.includes(element.storeType)) {
									headers.push(element.storeType);
								}
							});
							headers.sort();
							headers.forEach((element) => {
								newTotal.push({
									class: "col text-end",
									value: 0,
								});
							});
							newTotal[newTotal.length - 1].class =
								this.tableDataByAsset.columnsEnd[0].cellClass;
							data.forEach((element) => {
								let rowId = `${element.name} - ${element.category}`;
								if (typeof newData[rowId] === "undefined") {
									let newRow = {
										name: `${element.name} - ${element.category}`,
										assetName: element.name,
										assetCategory: element.category,
										total: 0,
									};
									headers.forEach((state) => {
										newRow[state] = 0;
									});
									newData[rowId] = newRow;
								}
								newData[rowId].total += element.count;
								newData[rowId][element.storeType] += element.count;
								newTotal[headers.indexOf(element.storeType)].value += element.count;
								newTotal[newTotal.length - 1].value += element.count;
							});

							let newDataArray = [];
							for (let key in newData) {
								newDataArray.push(newData[key]);
							}
							let headersObjects = [];
							headers.forEach((element) => {
								headersObjects.push({
									prop: element,
									name: element,
									headerClass: "col text-end",
									cellClass: "col text-end",
									resizeable: false,
									draggable: false,
									pipe: new DecimalPipeZeroDigits("en-US"),
								});
							});

							this.tableDataByAsset.total = newTotal;
							this.tableDataByAsset.rows = newDataArray;
							this.tableDataByAsset.columns = this.tableDataByAsset.columnsStart
								.concat(headersObjects)
								.concat(this.tableDataByAsset.columnsEnd);
							this.loadedByAsset = true;
							this.ref.detectChanges();
						}
					})
			);
		} else {
			this.subs.add(
				this.workOrderReportService
					.getRequestSummaryAssetState(body)
					.subscribe((data) => {
						if (this.woFiltersService.getCurrentUpdateId() === updateId) {
							let headers = [];
							let newData: {
								[id: string]: any;
							} = {};
							let newTotal = [
								{
									class: "col text-end",
									value: 0,
								},
							];
							data.forEach((element) => {
								if (!headers.includes(element.state)) {
									headers.push(element.state);
								}
							});
							headers.sort();
							headers.forEach((element) => {
								newTotal.push({
									class: "col text-end",
									value: 0,
								});
							});
							newTotal[newTotal.length - 1].class =
								this.tableDataByAsset.columnsEnd[0].cellClass;
							data.forEach((element) => {
								let rowId = `${element.name} - ${element.category}`;
								if (typeof newData[rowId] === "undefined") {
									let newRow = {
										name: `${element.name} - ${element.category}`,
										assetName: element.name,
										assetCategory: element.category,
										total: 0,
									};
									headers.forEach((state) => {
										newRow[state] = 0;
									});
									newData[rowId] = newRow;
								}
								newData[rowId].total += element.count;
								newData[rowId][element.state] += element.count;
								newTotal[headers.indexOf(element.state)].value += element.count;
								newTotal[newTotal.length - 1].value += element.count;
							});

							let newDataArray = [];
							for (let key in newData) {
								newDataArray.push(newData[key]);
							}
							let headersObjects = [];
							headers.forEach((element) => {
								headersObjects.push({
									prop: element,
									name: element,
									headerClass: "col text-end",
									cellClass: "col text-end",
									resizeable: false,
									draggable: false,
									pipe: new DecimalPipeZeroDigits("en-US"),
								});
							});

							this.tableDataByAsset.total = newTotal;
							this.tableDataByAsset.rows = newDataArray;
							this.tableDataByAsset.columns = this.tableDataByAsset.columnsStart
								.concat(headersObjects)
								.concat(this.tableDataByAsset.columnsEnd);
							this.loadedByAsset = true;
							this.ref.detectChanges();
						}
					})
			);
		}
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
