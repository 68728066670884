import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '../models/store';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../services/message.service';
import { AuthService } from '../../services/auth.service';
import { WhiteLabelService } from '../../services/white-label.service';

@Injectable()
export class StoreService {
  private apiUrl = this.whiteLabelService.getSettings.apiUrl + 'api/Stores';

  constructor(
    private http: HttpClient,
    private messages: MessageService,
    private authService: AuthService,
    private whiteLabelService: WhiteLabelService) { }

  getStores(): Observable<Store[]> {
    return this.http.get<Store[]>(this.apiUrl, this.authService.getHttpOptions()).pipe(
      catchError(this.messages.handleError(this.authService, 'getStores', []))
    );
  }
}
