<div class="card has-export">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon><span> Work Orders</span>
		</h2>
		<div class="export-btns">
			<button type="button" class="btn btn-secondary" (click)="exportCsv()" [disabled]="inProgressExportCSV">
				<fa-icon [icon]="faFileExport"></fa-icon><span class="d-none d-md-inline ms-1"> CSV</span>
				<span class="loader loader-small loader-reverse"><span class="loader-inner"><span
							class="left">Loading...</span><span class="right"></span></span></span>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div class="datatable-responsive data-responsive-exl">
			<ngx-datatable #financialWorkOrders class="bootstrap overflow-header-name"
				[rows]="tableDataOpenRMWorkOrders.rows" [columns]="tableDataOpenRMWorkOrders.columns"
				[headerHeight]="50" [rowHeight]="38" [footerHeight]="50" [columnMode]="'flex'"
				[loadingIndicator]="loadingOpenRMWorkOrders" [scrollbarV]="true"
				(scroll)="tableDataOpenRMWorkOrders.onScroll($event)" [sorts]="[{prop: 'requestNumber', dir: 'asc'}]"
				(sort)="tableDataOpenRMWorkOrders.onSort($event)" [externalSorting]="true">
				<!-- Row Detail Template -->
				<ngx-datatable-row-detail [rowHeight]="280" (toggle)="tableDataOpenRMWorkOrders.onDetailToggle($event)">
					<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
						<div class="row-detail-inner d-flex flex-row flex-wrap text-wrap">
							<div class="mb-3 me-4">
								<span
									class="text-uppercase half-strong">{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}}:
								</span>{{row.storeNo}} - {{row.storeName}}
							</div>

							<div class="mb-3 me-4">
								<span
									class="text-uppercase half-strong">{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}}:
								</span>{{row.vendorName}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Request Date: </span>{{row.requestDate}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Status: </span>{{row.statusName}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Asset: </span>{{row.assetName}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">GL Code: </span>{{row.glCode}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">GL Status: </span>{{row.glStatus}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Cost Type: </span>{{row.glCostType}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Cost: </span>${{row.cost | number:'1.0-0'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Cost Est: </span>${{row.costEst |
								number:'1.0-0'}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-row-detail>
				<!-- Column Templates -->
				<ngx-datatable-column prop="requestNumber" name="Request"
					headerClass="col-11-float col-request has-expand-icon"
					cellClass="col-11-float col-request has-expand-icon" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-row="row" let-value="value" let-expanded="expanded" ngx-datatable-cell-template>
						<span title="{{value}}">
							<span class="position-relative d-block">
								<a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
									[class.datatable-icon-down]="expanded" class="datatable-icon-action"
									title="Expand/Collapse Row"
									(click)="tableDataOpenRMWorkOrders.toggleExpandRow(row)">
								</a>
								<span class="link-style link-style-underline text-truncate-custom"
									(click)="tableDataOpenRMWorkOrders.onNameClick(row)">{{value}}</span>
							</span>
						</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="storeNo"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}} No"
					headerClass="col-9-float col-store-no" cellClass="col-9-float col-store-no" [resizeable]="false"
					[draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="storeName"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}} Name"
					headerClass="col-1 col-store-name" cellClass="col-1 col-store-name" [resizeable]="false"
					[draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="vendorName"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}} Name"
					headerClass="col-17-float col-vendor-name" cellClass="col-17-float col-vendor-name"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="requestDate" name="Request Date" headerClass="col-2 col-request-date"
					cellClass="col-2 col-request-date" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="statusName" name="Status" headerClass="col-9-float col-status-name"
					cellClass="col-9-float col-status-name" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="assetName" name="AssetName – AssetCategory" headerClass="col col-asset-name"
					cellClass="col col-asset-name" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="glCode" name="GL Code" headerClass="col-9-float col-code-no"
					cellClass="col-9-float col-code-no" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="glStatus" name="GL Status" headerClass="col-9-float col-status-name"
					cellClass="col-9-float col-status-name" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="glCostType" name="Cost Type" headerClass="col-9-float col-cost-type"
					cellClass="col-9-float col-cost-type" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="cost" name="Cost" headerClass="col-9-float col-cost text-end"
					cellClass="col-9-float col-cost text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="${{value}}">${{value | number:'1.0-0'}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="costEst" name="Cost Est" headerClass="col-9-float col-cost text-end"
					cellClass="col-9-float col-cost text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="${{value}}">${{value | number:'1.0-0'}}</span>
					</ng-template>
				</ngx-datatable-column>
				<!-- Footer Template -->
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template>
						<div class="datatable-footer-row">
							<div class="datatable-footer-cell col-12">
								{{tableDataOpenRMWorkOrders.page.totalElements | number}} total
							</div>
						</div>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
	</div>
</div>