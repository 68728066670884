<ng-template #offlineTimeTipContent>
	Time in hours that asset has been offline
</ng-template>
<ng-template #vendorEtaTipContent>
	Estimated Time of Arrival put in by vendor
</ng-template>
<ng-template #remainingTimeTipContent>
	Estimated remaining time in hours for Vendor to attend
</ng-template>

<div class="card has-export">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon><span>Key Assets Offline by
				{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}} and RM Work
				Order</span>
		</h2>
		<div class="export-btns">
			<button type="button" class="btn btn-secondary" (click)="exportCsv()" [disabled]="inProgressExportCSV">
				<fa-icon [icon]="faFileExport"></fa-icon><span class="d-none d-md-inline ml-1"> CSV</span>
				<span class="loader loader-small loader-reverse"><span class="loader-inner"><span
							class="left">Loading...</span><span class="right"></span></span></span>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div class="datatable-responsive data-responsive-exl">
			<ngx-datatable #assetAffectingSTTable class="bootstrap wrap-header-name wrap-body-cell"
				[rows]="tableData.rows" [columns]="tableData.columns" [headerHeight]="76" [rowHeight]="56"
				[footerHeight]="50" [columnMode]="'flex'" [sorts]="[{prop: 'assetOfflineStart', dir: 'asc'}]"
				[loadingIndicator]="loading" [scrollbarV]="true" (scroll)="tableData.onScroll($event)"
				[sorts]="[{prop: 'requestNumber', dir: 'asc'}]" (sort)="tableData.onSort($event)"
				[externalSorting]="true">
				<!-- Row Detail Template -->
				<ngx-datatable-row-detail [rowHeight]="280" (toggle)="tableData.onDetailToggle($event)">
					<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
						<div class="row-detail-inner d-flex flex-row flex-wrap text-wrap">
							<div class="mb-3 me-4">
								<span
									class="text-uppercase half-strong">{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}}:
								</span>{{row.storeNo}} - {{row.storeName}}
							</div>

							<div class="mb-3 me-4">
								<span
									class="text-uppercase half-strong">{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}}
									State: </span>{{row.storeState}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Asset Name: </span>{{row.assetName}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Asset Id: </span><span
									class="link-style link-style-underline"
									(click)="tableData.onAssetIdClick(row)">{{row.assetId}}</span>
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Barcode: </span>{{row.assetBarcode}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Priority: </span>{{row.priority}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Status: </span>{{row.statusName}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Offline Time (hrs):
								</span>{{row.assetOfflineStart}}
							</div>

							<div class="mb-3 me-4">
								<span
									class="text-uppercase half-strong">{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}}
									ETA:
								</span>{{row.vendorEstimateAttended}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Remaining Hrs till Attendance:
								</span>{{row.remainingTime}}
							</div>

							<div class="mb-3 me-4">
								<span
									class="text-uppercase half-strong">{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}}:
								</span>{{row.vendorName}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Request Number: </span><span
									class="link-style link-style-underline"
									(click)="tableData.onRequestNumberClick(row)">{{row.requestNumber}}</span>
							</div>
						</div>
					</ng-template>
				</ngx-datatable-row-detail>
				<!-- Column Templates -->
				<ngx-datatable-column prop="storeNo"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}}"
					headerClass="col-2 col-id has-expand-icon" cellClass="col-2 col-id has-expand-icon"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-row="row" let-value="value" let-expanded="expanded" ngx-datatable-cell-template>
						<span title="{{value}}">
							<span class="position-relative d-block">
								<a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
									[class.datatable-icon-down]="expanded" class="datatable-icon-action"
									title="Expand/Collapse Row" (click)="tableData.toggleExpandRow(row)">
								</a>
								<span title="{{value}}">{{value}}</span>
							</span>
						</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="storeName"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}} Name"
					headerClass="col" cellClass="col" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="storeState"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}} State"
					headerClass="col" cellClass="col" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="assetName" name="Asset Name" headerClass="col-1" cellClass="col-1"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="assetId" name="Asset Id" headerClass="col" cellClass="col"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span class="link-style link-style-underline text-truncate-custom"
							(click)="tableData.onAssetIdClick(row)">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="assetBarcode" name="Barcode" headerClass="col" cellClass="col"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="priority" name="Priority" headerClass="col" cellClass="col"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="statusName" name="Status" headerClass="col" cellClass="col"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="assetOfflineStart" name="Offline Time (hrs)" headerClass="col"
					[cellClass]="timeLapsedColor" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper">
							<span class="datatable-header-cell-label" (click)="sort()">
								<span [ngbTooltip]="offlineTimeTipContent" placement="bottom" container="body"
									tooltipClass="summary-tooltip">{{column.name}}</span>
							</span>
						</span>
					</ng-template>
					<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="vendorEstimateAttended"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}} ETA"
					headerClass="col" cellClass="col" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper">
							<span class="datatable-header-cell-label" (click)="sort()">
								<span [ngbTooltip]="vendorEtaTipContent" placement="bottom" container="body"
									tooltipClass="summary-tooltip">{{column.name}}</span>
							</span>
						</span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="vendorEstimateAttended" name="Remaining Hrs till Attendance"
					headerClass="col" [cellClass]="remainingTimeColor" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper">
							<span class="datatable-header-cell-label" (click)="sort()">
								<span [ngbTooltip]="remainingTimeTipContent" placement="bottom" container="body"
									tooltipClass="summary-tooltip">{{column.name}}</span>
							</span>
						</span>
					</ng-template>
					<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
						<span title="{{row.remainingTime}}">{{row.remainingTime}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="vendorName"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}}"
					headerClass="col" cellClass="col" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="requestNumber" name="Request Number" headerClass="col" cellClass="col"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
						<span class="link-style link-style-underline text-truncate-custom"
							(click)="tableData.onRequestNumberClick(row)">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<!-- Footer Template -->
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template>
						<div class="datatable-footer-row">
							<div class="datatable-footer-cell col">
								{{tableData.page.totalElements | number}} total
							</div>
						</div>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
	</div>
</div>