class ConverterHelper {
    floatToString(value: number) {
        return value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }

    intToString(value: number) {
        return value.toLocaleString('en-US', {
            maximumFractionDigits: 0
        })
    }
}

export default new ConverterHelper();