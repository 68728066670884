import { Component, OnInit } from "@angular/core";
import {
	faCheck,
	faClock,
	faExclamationTriangle,
	faFile,
	faFileExcel,
	faFilePdf,
	faFileUpload,
	faFileWord,
	faMapMarkerAlt,
	faTrashAlt,
	faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { MessageService } from "../../services/message.service";
import { StateService } from "../../services/state.service";
import { Entity } from "../models/entity";
import { Store } from "../models/store";
import { EntitiesService } from "../services/entities.service";
import { FilesService } from "../services/files.service";

@Component({
	selector: "app-store-dashboard",
	templateUrl: "./store-dashboard.component.html",
})
export class StoreDashboardComponent implements OnInit {
	// Icons
	faClock = faClock;
	faMapMarkerAlt = faMapMarkerAlt;
	faFile = faFile;
	faTrashAlt = faTrashAlt;
	faFileUpload = faFileUpload;
	faCheck = faCheck;
	faExclamationTriangle = faExclamationTriangle;

	// uploading
	public inProgress: boolean;
	public errorMessage: string;
	public buttonText = "Upload file";
	public isOk: boolean;
	store: Store;

	// table
	entity: Entity;
	tableData = {
		rows: [],
		columns: [
			{ prop: "fileName", name: "Name" },
			{ prop: "fileType", name: "Type" },
			{ prop: "uploadTime", name: "Upload date" },
		],
	};

	// google map
	zoom = 16;
	center: google.maps.LatLngLiteral = { lat: 51.678418, lng: 7.809007 };

	constructor(
		private entitiesService: EntitiesService,
		private stateService: StateService,
		private filesService: FilesService,
		private messageService: MessageService
	) {}

	ngOnInit() {
		this.inProgress = false;
		this.errorMessage = "";
		this.isOk = false;
		this.stateService.getCurrentStore.subscribe((value) => {
			this.store = value;
		});

		this.tableData.rows = [];
		this.entitiesService.getCurrentEntity.subscribe((value) => {
			this.entity = value;
			if (this.entity) {
				this.center = {
					lat: Number(this.entity.latitude),
					lng: Number(this.entity.longitude)
				}
				this.filesService.getFiles(this.entity.storeNo).subscribe((response) => {
					this.tableData.rows = response;
				});
			}
		});
	}

	upload(files) {
		this.errorMessage = "";
		this.isOk = false;
		if (files.length === 0) return;

		const formData = new FormData();

		for (let file of files) {
			formData.append(file.name, file);
			this.buttonText = file.name;
		}
		this.inProgress = true;

		this.filesService.uploadFile(formData, this.store.storeNo).subscribe(
			(response) => {
				this.inProgress = false;
				this.messageService.success("Done.", "Upload Successful.");
				this.isOk = true;
				this.tableData.rows = [...this.tableData.rows, response];
			},
			(event) => {
				this.inProgress = false;
				if (event.error.error) {
					this.messageService.error("Error.", event.error.error);
					this.errorMessage = event.error.error;
				} else {
					this.messageService.error("Error.", event.message);
					this.errorMessage = event.message;
				}
				this.isOk = false;
			}
		);
	}

	deleteFile(event, id: number) {
		event.stopPropagation();
		if (confirm("Are you sure to delete file?")) {
			this.filesService.deleteFile(id).subscribe((x) => {
				var index = this.tableData.rows.findIndex(function (o) {
					return o.id === id;
				});
				if (index !== -1) {
					var newArray = [...this.tableData.rows];
					newArray.splice(index, 1);
					this.tableData.rows = newArray;
				}
				this.messageService.success("Done", "File deleted");
			});
		}
	}

	getIcon(fileName) {
		let type = fileName.split(".").pop().toLowerCase();
		switch (type) {
			case "mp4":
			case "avi":
			case "mkv":
			case "mov":
			case "wmv":
				return faVideo;
			case "xlsx":
			case "xls":
			case "csv":
			case "ods":
				return faFileExcel;
			case "docx":
			case "doc":
			case "rtf":
			case "txt":
			case "odt":
				return faFileWord;
			case "pdf":
				return faFilePdf;
			default:
				return faFile;
		}
	}

	getLink(row) {
		return this.filesService.getFileName(row.storeNo, row.blobName);
	}
}
