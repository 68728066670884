import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdalService } from 'adal-angular4';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class AdalOptionalInterceptor implements HttpInterceptor {
    constructor(private adal: AdalService) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if the endpoint is not registered then pass
        // the request as it is to the next handler
        try {
            var resource = this.adal.getResourceForEndpoint(request.url);
            if (!resource || !this.adal.userInfo.authenticated) {
                return next.handle(request);
            }
        } catch {
            return next.handle(request);
        }
        return this.adal.acquireToken(resource)
            .pipe(mergeMap(function (token) {
                // clone the request and replace the original headers with
                // cloned headers, updated with the authorization
                var authorizedRequest = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + token),
                });
                return next.handle(authorizedRequest);
            }));
    }
}