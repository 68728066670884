import { Component, OnInit } from '@angular/core';
import { EntitiesService } from '../services/entities.service';
import { Entity } from '../models/entity';
import { WhiteLabelService } from '../../services/white-label.service';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html'
})
export class StoreInfoComponent implements OnInit {
  entity: Entity;

  constructor(private entitiesService: EntitiesService,
    public whiteLabelService: WhiteLabelService) { }

  ngOnInit() {
    this.entitiesService.getCurrentEntity.subscribe(value => {
      this.entity = value;
    });
  }
}
