import { DecimalPipe } from '@angular/common';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'decimalPipeZeroDigits'
})
export class DecimalPipeZeroDigits extends DecimalPipe {
    public transform(value): any {
      return super.transform(value, '1.0-0');
    }
  }