<div *ngIf="entity">
  <dl class="store-info row">
      <dt class="col-sm-6 col-md-5 col-lg-6 text-large">{{whiteLabelService.getSettings.assetConditionStoreNameText}}:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6 text-large">{{entity.storeName}}</dd>
        
      <dt class="col-sm-6 col-md-5 col-lg-6">Address:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6">{{entity.storeAddress}}</dd>
      
      <dt class="col-sm-6 col-md-5 col-lg-6">Suburb:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6">{{entity.storeSuburb}}</dd>
        
      <dt class="col-sm-6 col-md-5 col-lg-6">Postcode:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6">{{entity.postCode}}</dd>


      <dt class="col-sm-6 col-md-5 col-lg-6" *ngIf="whiteLabelService.is7Eleven() || whiteLabelService.isWhittlesea()">Platinum FM - SDM:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6" *ngIf="whiteLabelService.is7Eleven() || whiteLabelService.isWhittlesea()">{{entity.serviceDeliveryManager}}</dd>

      <dt class="col-sm-6 col-md-5 col-lg-6" *ngIf="whiteLabelService.is7Eleven() || whiteLabelService.isWhittlesea()">SDM Contact Number:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6" *ngIf="whiteLabelService.is7Eleven() || whiteLabelService.isWhittlesea()">{{entity.serviceDeliveryManagerPhone}}</dd>

      <dt class="col-sm-6 col-md-5 col-lg-6" *ngIf="whiteLabelService.is7Eleven()">7-Eleven-RBM:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6" *ngIf="whiteLabelService.is7Eleven()">{{entity.retailBusinessMgr}}</dd>

      <dt class="col-sm-6 col-md-5 col-lg-6 last" *ngIf="whiteLabelService.is7Eleven()">RBM Contact Number:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6 last" *ngIf="whiteLabelService.is7Eleven()">{{entity.retailBusinessMgrPhone}}</dd>

      <dt class="col-sm-6 col-md-5 col-lg-6" *ngIf="whiteLabelService.isStarbucks()">Starbucks DM:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6" *ngIf="whiteLabelService.isStarbucks()">{{entity.dm}}</dd>

      <dt class="col-sm-6 col-md-5 col-lg-6 last" *ngIf="whiteLabelService.isStarbucks()">DM Contact Number:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6 last" *ngIf="whiteLabelService.isStarbucks()">{{entity.dmmobile}}</dd>

      <!-- Functional Site Representative -->
      <dt class="col-sm-6 col-md-5 col-lg-6" *ngIf="whiteLabelService.isWhittlesea()">Functional Site Representative:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6" *ngIf="whiteLabelService.isWhittlesea()">{{entity.functionalRepresentative}}</dd>

      <dt class="col-sm-6 col-md-5 col-lg-6 last" *ngIf="whiteLabelService.isWhittlesea()">FSR Contact Number:</dt>
      <dd class="col-sm-6 col-md-7 col-lg-6 last" *ngIf="whiteLabelService.isWhittlesea()">{{entity.functionalRepresentativePhone}}</dd>
  </dl>
</div>

<div *ngIf="!entity">Please select {{whiteLabelService.getSettings.assetConditionStoreText}}
  <!--<span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span>-->
</div>
