import {
	Injectable
} from '@angular/core';
import {
	HttpClient
} from '@angular/common/http';
import {
	Observable
} from 'rxjs';
import {
	catchError
} from 'rxjs/operators';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	MessageService
} from '../../services/message.service';
import {
	AuthService
} from '../../services/auth.service';
import {
	ReportTypeVendorHistoryResponseModel
} from '../models/reportTypeVendorHistoryResponseModel';
import { WhiteLabelService } from '../../services/white-label.service';
import { ReportTypeVendorResponseModel } from '../models/reportTypeVendorResponseModel';
import { IsReportReadyQueryOptionsModel } from '../models/isReportReadyQueryOptionsModel';
import * as moment from 'moment';

@Injectable()
export class VendorKpiService {
	private apiUrl = this.whiteLabelService.getSettings.informApiUrl + 'api/';

	constructor(
		private http: HttpClient,
		private messages: MessageService,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService) { }

	getReportTypeVendors(model: QueryOptionsModel | null): Observable<ReportTypeVendorResponseModel[]> {
		let url = this.apiUrl + 'VendorKpi/GetReportTypeVendors';
		return this.http.post<ReportTypeVendorResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getReportTypeVendors(model)', []))
		);
	}

	getReportTypeVendorHistory(model: QueryOptionsModel | null): Observable<ReportTypeVendorHistoryResponseModel[]> {
		let url = this.apiUrl + 'VendorKpi/GetReportTypeVendorHistory';
		return this.http.post<ReportTypeVendorHistoryResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getReportTypeVendorHistory(model)', []))
		);
	}

	isReportReady(model: IsReportReadyQueryOptionsModel | null): Observable<ReportTypeVendorHistoryResponseModel> {
		let url = this.apiUrl + 'VendorKpi/IsReportReady';
		return this.http.post<ReportTypeVendorHistoryResponseModel>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'IsReportReady(model)', null))
		);
	}

	getReportLink(report: ReportTypeVendorHistoryResponseModel): string {
		let m1 = moment(report.startDate);
		let m2 = moment(report.endDate);
		return `${this.apiUrl}VendorKpi/GetReport?filename=${report.reportPath}&downloadAs=Vendor - ${report.vendorName.replace(/&/g, '_')} - KPI Report - ${m1.format('YYMMDD')} - ${m2.format('YYMMDD')}.xlsx`;
	}

	scheduleReport(model: ReportTypeVendorResponseModel | null): Observable<boolean | any> {
		let url = this.apiUrl + 'VendorKpi/ScheduleReport';
		return this.http.post<boolean>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'ScheduleReport(model)', []))
		);
	}
}