import {
	Component,
	OnInit,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import {
	StateService
} from '../../services/state.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	faInfo
} from '@fortawesome/free-solid-svg-icons';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	QueryOptionsPagingModel
} from '../models/queryOptionsPagingModel';
import { AuthService } from '../../services/auth.service';
import { Observable, Subscription } from 'rxjs';

@Component({
	selector: 'app-work-orders-open-summary',
	templateUrl: './work-orders-open-summary.component.html'
})
export class WorkOrdersOpenSummaryComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loadedOpenSummary: boolean;
	openRM: number = 0;
	openPM: number = 0;

	loadedKPISummary: boolean;
	p1KPI: number = 0;
	p2KPI: number = 0;

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedOpenSummary = false;
		this.loadedKPISummary = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(this.stateService.getCurrentDateRange(), value);
		}));
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ['Open'];
		body = this.woFiltersService.fillBodyEndDateThisMonth(body);
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedOpenSummary = false;
		this.loadedKPISummary = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		//#region Item 1, 2
		this.subs.add(this.workOrderReportService.getRequestSummary(body)
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.openRM = 0;
					this.openPM = 0;
					data.forEach(element => {
						if (element.requestType == 'Reactive' || element.requestType == 'Incident') {
							this.openRM += element.count;
						}
						else if (element.requestType == 'Planned') {
							this.openPM += element.count;
						}
					});
					this.loadedOpenSummary = true;
					this.ref.detectChanges();
				}
			}));
		//#endregion

		//#region Item 3, 4
		this.subs.add(this.workOrderReportService.getRequestSummaryPriority(body)
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.p1KPI = 0;
					this.p2KPI = 0;
					data.forEach(element => {
						if (element.priority) {
							if (element.priority.startsWith('P1')) {
								this.p1KPI += element.respondKPIFail;
							}
							if (element.priority.startsWith('P2')) {
								this.p2KPI += element.resolveKPIFail;
							}
						}
					});
					this.loadedKPISummary = true;
					this.ref.detectChanges();
				}
			}));
		//#endregion
	}

	// Icons
	faInfo = faInfo;

}