import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { StateService } from "../services/state.service";
import { AuthService } from "../services/auth.service";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { AdalService } from "adal-angular4";
import { WhiteLabelService } from "../services/white-label.service";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
	isLoginInProgress$: Observable<boolean>;
	isInvalidLogin$: Observable<boolean>;
	isAzureADEnabled: boolean;
	isSamlEnabled: boolean;

	// Icons
	faLogin = faSignInAlt;
	faMicrosoft = faMicrosoft;

	constructor(
		private authService: AuthService,
		private stateService: StateService,
		private adal: AdalService,
		private whiteLabelService: WhiteLabelService
	) {}

	ngOnInit() {
		this.stateService.setHeaderTitle("Login");
		this.isLoginInProgress$ = this.authService.isLoginInProgress;
		this.isInvalidLogin$ = this.authService.isInvalidLogin;
		this.isAzureADEnabled = this.whiteLabelService.isAzureADEnabled();
		this.isSamlEnabled = this.whiteLabelService.isSamlEnabled();
	}

	login(form: NgForm) {
		this.authService.login(form.value);
	}

	loginAzure() {
		this.adal.login();
	}

	loginSaml() {
		this.authService.loginSamlStep1();
	}
}
