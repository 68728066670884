import {
	Injectable
} from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class HelperService {
	get lastDayInMonth(): Date {
		const now = new Date();
		const result = new Date(now.getFullYear(), now.getMonth() + 1, 0);
		return result;
	}

	public numberToTwoDigitString(value: number): string {
		return value.toLocaleString('en-US', {
			minimumIntegerDigits: 2,
			useGrouping: false
		});
	}

	public convertDecimalDaysToDaysAndHours(decimalDays) {
		// Extract the whole number of days
		const wholeDays = Math.floor(decimalDays);
		
		// Calculate the remaining hours
		const remainingHours = (decimalDays - wholeDays) * 24;
		const wholeHours = Math.floor(remainingHours);
		
		return {
			days: wholeDays,
			hours: wholeHours
		};
	}
}