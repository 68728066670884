<div
	class="work-filters d-flex align-items-end flex-wrap flex-md-nowrap justify-content-sm-end justify-content-md-start">
	<div>
		<label class="half-strong d-block">Date Range</label>
		<my-date-range-picker #mydrp name="mydaterange" placeholder="Select date range" id="date-range-input"
			class="data-range" [options]="myDateRangePickerOptions" (dateRangeChanged)="onDateRangeChanged($event)"
			(inputFocusBlur)="onInputFocusBlur($event)" [(ngModel)]="model" required></my-date-range-picker>
	</div>
	<div class="divider-or mt-3 mb-1 px-3 mt-sm-0 mb-sm-0">or</div>
	<div>
		<label class="half-strong d-block">Financial Year</label>
		<ng-select [dropdownPosition]="'bottom'" [items]="stateService.years" [(ngModel)]="stateService.selectedYear"
			(change)="stateService.onChangeSelectedYear($event)" placeholder="Select financial year"
			class="dropdown-select">
		</ng-select>
	</div>
	<button type="button" class="btn btn-primary btn-filter text-nowrap ms-3 mt-sm-3 mt-md-0"
		(click)="woFiltersService.isCollapsed = !woFiltersService.isCollapsed"
		[attr.aria-expanded]="!woFiltersService.isCollapsed" aria-controls="collapseFilters">
		<fa-icon [icon]="faFilter"></fa-icon><span class="d-none d-sm-inline ms-2">Filters</span>
		<fa-icon [icon]="faCaretDown" class="ms-2 d-none d-sm-inline"></fa-icon>
	</button>
</div>
<div id="collapseFilters" [ngbCollapse]="woFiltersService.isCollapsed">
	<div class="dropdown-filters">
		<div class="dropdown-filters-inner">
			<div class="filters-wrapper">
				<div class="mb-3" *ngIf="whiteLabelService.getSettings.enableFilterBrand">
					<label class="half-strong">Select brand</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.brands"
						[(ngModel)]="woFiltersService.selectedBrand" placeholder="Select brand" class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3" *ngIf="whiteLabelService.getSettings.enableFilterDistrict">
					<label class="half-strong">Select {{whiteLabelService.getSettings.filterDistrictLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.districts"
						[(ngModel)]="woFiltersService.selectedDistrict"
						placeholder="Select {{whiteLabelService.getSettings.filterDistrictLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3" *ngIf="whiteLabelService.getSettings.enableFilterCategoryManager">
					<label class="half-strong">Select category manager</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'"
						[items]="woFiltersService.categoryManagers"
						[(ngModel)]="woFiltersService.selectedCategoryManager" placeholder="Select category manager"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3" *ngIf="!whiteLabelService.getSettings.enableNewVendorFilter">
					<label class="half-strong">Select {{whiteLabelService.getSettings.filterVendorLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.vendors"
						bindLabel="name" [(ngModel)]="woFiltersService.selectedVendor"
						placeholder="Select {{whiteLabelService.getSettings.filterVendorLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3" *ngIf="whiteLabelService.getSettings.enableNewVendorFilter">
					<label class="half-strong">Select {{whiteLabelService.getSettings.filterVendorLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.vendorNames"
						bindLabel="name" [(ngModel)]="woFiltersService.selectedVendorNames"
						placeholder="Select {{whiteLabelService.getSettings.filterVendorLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3" *ngIf="whiteLabelService.getSettings.enableFilterState">
					<label class="half-strong">Select state</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.states"
						[(ngModel)]="woFiltersService.selectedState" placeholder="Select state" class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3">
					<label class="half-strong">Select {{whiteLabelService.getSettings.filterStoreLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.stores"
						bindLabel="name" [(ngModel)]="woFiltersService.selectedStore"
						placeholder="Select {{whiteLabelService.getSettings.filterStoreLabel}}" class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3" *ngIf="woFiltersService.assetNamesEnabled">
					<label class="half-strong">Select asset name</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.assetNames"
						[(ngModel)]="woFiltersService.selectedAssetName" placeholder="Select asset name"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3">
					<label class="half-strong">Select request type</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.requestTypes"
						[(ngModel)]="woFiltersService.selectedRequestType" placeholder="Select request type"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3">
					<label class="half-strong">Select priority</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.priorities"
						[(ngModel)]="woFiltersService.selectedPriority" placeholder="Select priority"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3">
					<label class="half-strong">Select GL cost type</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.glCostTypes"
						[(ngModel)]="woFiltersService.selectedGLCostTypes" placeholder="Select GL cost type"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3"
					*ngIf="whiteLabelService.getSettings.enableFilterGLDescription && woFiltersService.glDescriptions.length > 0">
					<label class="half-strong">Select {{whiteLabelService.getSettings.filterGLDescriptionLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.glDescriptions"
						[(ngModel)]="woFiltersService.selectedGLDescriptions"
						placeholder="Select {{whiteLabelService.getSettings.filterGLDescriptionLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>

				<!-- <div class="mb-3">
					<label class="half-strong">Select GL status</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.glStatuses" [(ngModel)]="woFiltersService.selectedGLStatuses" placeholder="Select GL status" class="dropdown-select">
					</ng-select>
				</div> -->

				<div class="mb-3"
					*ngIf="whiteLabelService.getSettings.enableFilterDistrictManager && woFiltersService.districtManagers.length > 0">
					<label class="half-strong">Select
						{{whiteLabelService.getSettings.filterDistrictManagerLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'"
						[items]="woFiltersService.districtManagers"
						[(ngModel)]="woFiltersService.selectedDistrictManagers"
						placeholder="Select {{whiteLabelService.getSettings.filterDistrictManagerLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3"
					*ngIf="whiteLabelService.getSettings.enableFilterRetailBusinessManagers && woFiltersService.retailBusinessManagers.length > 0">
					<label class="half-strong">Select
						{{whiteLabelService.getSettings.filterRetailBusinessManagerLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'"
						[items]="woFiltersService.retailBusinessManagers"
						[(ngModel)]="woFiltersService.selectedRetailBusinessManager"
						placeholder="Select {{whiteLabelService.getSettings.filterRetailBusinessManagerLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3"
					*ngIf="whiteLabelService.getSettings.enableFilterRegionalManagers && woFiltersService.regionalManagers.length > 0">
					<label class="half-strong">Select
						{{whiteLabelService.getSettings.filterRegionalManagerLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'"
						[items]="woFiltersService.regionalManagers"
						[(ngModel)]="woFiltersService.selectedRegionalManagers"
						placeholder="Select {{whiteLabelService.getSettings.filterRegionalManagerLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3"
					*ngIf="whiteLabelService.getSettings.enableFilterStoreType && woFiltersService.storeType.length > 0">
					<label class="half-strong">Select {{whiteLabelService.getSettings.filterStoreTypeLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.storeType"
						[(ngModel)]="woFiltersService.selectedStoreType"
						placeholder="Select {{whiteLabelService.getSettings.filterStoreTypeLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3"
					*ngIf="whiteLabelService.getSettings.enableFilterStoreProfile && woFiltersService.storeProfile.length > 0">
					<label class="half-strong">Select {{whiteLabelService.getSettings.filterStoreProfileLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.storeProfile"
						[(ngModel)]="woFiltersService.selectedStoreProfile"
						placeholder="Select {{whiteLabelService.getSettings.filterStoreProfileLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3"
					*ngIf="whiteLabelService.getSettings.enableFilterServiceDeliveryManager && woFiltersService.storeProfile.length > 0">
					<label class="half-strong">Select
						{{whiteLabelService.getSettings.filterServiceDeliveryManagerLabel}}</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'"
						[items]="woFiltersService.serviceDeliveryManager"
						[(ngModel)]="woFiltersService.selectedServiceDeliveryManager"
						placeholder="Select {{whiteLabelService.getSettings.filterServiceDeliveryManagerLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3" *ngIf="woFiltersService.deliveryIndicators.length > 0">
					<label class="half-strong">Select response\resolution status</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'"
						[items]="woFiltersService.deliveryIndicators"
						[(ngModel)]="woFiltersService.selectedDeliveryIndicators"
						placeholder="Select response\resolution status" class="dropdown-select">
					</ng-select>
				</div>

				<div class="mb-3">
					<label class="half-strong">Est. completed date range</label>
					<my-date-range-picker #mydrpEst name="mydaterange-est"
						placeholder="Select est. completed date range" id="est-date-range-input" class="data-range"
						[options]="myDateRangePickerEstOptions" (dateRangeChanged)="onDateRangeEstChanged($event)"
						(inputFocusBlur)="onInputFocusBlurEst($event)" [(ngModel)]="modelEst"
						required></my-date-range-picker>
				</div>

				<div class="mb-3">
					<label class="half-strong">Select status</label>
					<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.statuses"
						[(ngModel)]="woFiltersService.selectedStatuses" placeholder="Select status"
						class="dropdown-select">
					</ng-select>
				</div>
			</div>
		</div>
	</div>
</div>