import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-open-overdue",
	templateUrl: "./work-orders-open-overdue.component.html",
})
export class WorkOrdersOpenOverdueComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loaded: boolean;
	tableData = {
		rows: [],
		columns: [],
	};

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loaded = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Open"];
		body.filter.priorities = null;
		body.filter.deliveryIndicators = ["Completion Overdue"];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryPriority(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						let newPriorities = [];
						data.forEach((element) => {
							if (element.priority.startsWith("P1")) {
								element.priority = "P1";
							} else if (element.priority.startsWith("P2")) {
								element.priority = "P2";
							}
							if (!newPriorities.includes(element.priority)) {
								newPriorities.push(element.priority);
							}
						});
						newPriorities.sort();
						var prioritiesCustomOrder = ["FM", "HNDY", "PM"];
						prioritiesCustomOrder.forEach((priority) => {
							if (newPriorities.includes(priority)) {
								newPriorities = newPriorities.filter((e) => e !== priority);
								newPriorities.push(priority);
							}
						});
						let rowOpen = { Type: "Open", Total: 0 };
						let rowRespond = { Type: "Respond Overdue", Total: 0 };
						let rowResolve = { Type: "Resolve Overdue", Total: 0 };
						let newColumns = [{ prop: "Type", name: "" }];
						newPriorities.forEach((element) => {
							rowOpen[element] = 0;
							rowRespond[element] = 0;
							rowResolve[element] = 0;
							newColumns.push({ prop: element, name: element });
						});
						newColumns.push({ prop: "Total", name: "Total" });
						data.forEach((element) => {
							rowOpen[element.priority] += element.count;
							rowOpen["Total"] += element.count;

							rowRespond[element.priority] += element.respondKPIFail;
							rowRespond["Total"] += element.respondKPIFail;

							rowResolve[element.priority] += element.resolveKPIFail;
							rowResolve["Total"] += element.resolveKPIFail;
						});
						this.tableData.columns = newColumns;
						this.tableData.rows = [rowOpen, rowRespond, rowResolve];
						this.loaded = true;
						this.ref.detectChanges();
					}
				})
		);
	}

	// Icons
	faChartColumn = faChartColumn;
}
