import { Component, OnDestroy, OnInit } from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { Guid } from "guid-typescript";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { WOFiltersService } from "../services/wo-filters.service";

@Component({
	selector: "app-financial",
	templateUrl: "./financial.component.html",
})
export class FinancialComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	resetFilters() {
		this.woFiltersService.resetFilters();
		this.stateService.updateDateRange("null", null, null);
		this.stateService.setDateRange(null); // call update automatically
	}

	constructor(
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		public whiteLabelService: WhiteLabelService
	) {
		if (this.stateService.years.length) {
			if (!this.stateService.selectedYear) {
				let fy = this.stateService.years[this.stateService.years.length - 1];
				this.stateService.selectedYear = fy;
				this.stateService.onChangeSelectedYear(fy, true);
			}
		}
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.stateService.setHeaderTitle("Financial Report");
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.roles$.subscribe((data) => {
				if (data.indexOf("admin") > -1 || data.indexOf("fm") > -1) {
					this.woFiltersService.loadData();
					this.subs.add(
						this.stateService.getDateRange.subscribe((value) => {
							this.update();
						})
					);
				}
			})
		);
	}

	ngDoCheck() {
		if (this.woFiltersService.isChanged()) {
			this.update();
		}
	}

	update() {
		let currentUpdateId = Guid.create().toString();
		this.woFiltersService.setUpdateId(currentUpdateId);
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
