import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	faArrowAltCircleLeft,
	faTable,
} from '@fortawesome/free-solid-svg-icons';
import {
	DatatableComponent
} from '@swimlane/ngx-datatable';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import {
	StateService
} from '../../services/state.service';
import { WhiteLabelService } from '../../services/white-label.service';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	QueryOptionsPagingModel
} from '../models/queryOptionsPagingModel';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';

@Component({
	selector: 'app-work-orders-open-vendor',
	templateUrl: './work-orders-open-vendor.component.html'
})
export class WorkOrdersOpenVendorComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loadedByVendor: boolean;
	tableDataByVendor = {
		rows: [],
		columns: [{
			prop: 'name',
			name: 'Vendor Name'
		}, {
			prop: 'Incident',
			name: 'Incident'
		}, {
			prop: 'Planned',
			name: 'Planned'
		}, {
			prop: 'Reactive',
			name: 'Reactive'
		}, {
			prop: 'Total',
			name: 'Total'
		}],
		total: [],
		onNameClick: (row) => {
			for (var i = 0; i < this.woFiltersService.vendors.length; i++) {
				if (this.whiteLabelService.getSettings.enableNewVendorFilter) {
					if (this.woFiltersService.vendorNames[i].name === row.name) {
						this.woFiltersService.isCollapsed = false;
						this.woFiltersService.selectedVendorNames = [this.woFiltersService.vendorNames[i]];
						break;
					}
				} else {
					if (this.woFiltersService.vendors[i].id === row.id) {
						this.woFiltersService.isCollapsed = false;
						this.woFiltersService.selectedVendor = [this.woFiltersService.vendors[i]];
						break;
					}
				}
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.openWOVendorTable.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		}
	};
	@ViewChild('openWOVendorTable') openWOVendorTable: DatatableComponent;

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		public whiteLabelService: WhiteLabelService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByVendor = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(this.stateService.getCurrentDateRange(), value);
		}));
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ['Open'];
		body = this.woFiltersService.fillBodyEndDateThisMonth(body);
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByVendor = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		//#region Table 1
		this.subs.add(this.workOrderReportService.getRequestSummaryVendor(body)
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					let headers = ["Incident", "Planned", "Reactive", "Total"];
					let newData: {
						[id: string]: any;
					} = {};
					let newTotal = [];
					headers.forEach(element => {
						newTotal.push(0);
					});
					data.forEach(element => {
						let rowId = `${element.name}--${element.vendorNo}`;
						//console.log("ignore vendorNo: " + this.whiteLabelService.getSettings.ignoreVendorNoGrouping)
						if (this.whiteLabelService.getSettings.ignoreVendorNoGrouping) {
							rowId = `${element.name}`;
						}
						//console.log("rowId: " + rowId);
						if (typeof newData[rowId] === "undefined") {
							let newRow = {
								id: element.id,
								name: element.name,
								count: 0
							};
							headers.forEach(element => {
								newRow[element] = 0;
							});
							newData[rowId] = newRow;
						}
						newData[rowId][element.requestType] += element.count;
						newData[rowId]["Total"] += element.count;
						let index = headers.indexOf(element.requestType);
						newTotal[index] += element.count;
						let indexTotal = headers.indexOf("Total");
						newTotal[indexTotal] += element.count;
					});

					let newDataArray = [];
					for (let key in newData) {
						newDataArray.push(newData[key]);
					}

					this.tableDataByVendor.total = newTotal;
					this.tableDataByVendor.rows = newDataArray;
					this.loadedByVendor = true;
					this.ref.detectChanges();
				}
			}));
		//#endregion
	}

	// Icons
	faTable = faTable;
	faArrowAltCircleLeft = faArrowAltCircleLeft;

}