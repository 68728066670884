<div class="card has-invisible-content has-table-min-height" [ngClass]="{'has-back': woFiltersService.selectedAssetName && woFiltersService.selectedAssetName.length > 0}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon> Asset Category (WO $ Value{{facilityTypeMode ? " by facility type" : " by state"}} by asset category)
		</h2>
		<div class="back-btn" *ngIf="woFiltersService.selectedAssetName && woFiltersService.selectedAssetName.length > 0">
			<button type="button" class="btn btn-secondary" (click)="woFiltersService.drilldownBackByAssetName()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div *ngIf="loadedByAsset" class="datatable-responsive data-responsive-xl">
			<ngx-datatable #assetTableCost class="bootstrap overflow-header-name" [rows]="tableDataByAsset.rows" [columns]="tableDataByAsset.columns" [headerHeight]="50" [scrollbarV]="true" [rowHeight]="38" [footerHeight]="50" [columnMode]="'flex'" [sorts]="[{prop: 'name', dir: 'asc'}]" [loadingIndicator]="!loadedByAsset" (activate)="tableDataByAsset.onNameClick($event)">
				<!-- Footer Template -->
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template>
						<div class="datatable-footer-row">
							<div class="datatable-footer-cell" [ngClass]="tableDataByAsset.columnsStart[0].headerClass">Total</div>
							<div *ngFor="let column of tableDataByAsset.total; let i = index" [ngClass]="column.class" class="datatable-footer-cell">
								${{column.value | number:'1.0-0'}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>

		<div *ngIf="!loadedByAsset" class="card-padding"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
