<div class="card has-export" [ngClass]="{'has-back': woFiltersService.isDrilldownBackByVendorAvailable()}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon> Open Work Orders by
			{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}} (Planned)
		</h2>
		<div class="back-btn" *ngIf="woFiltersService.isDrilldownBackByVendorAvailable()">
			<button type="button" class="btn btn-secondary" (click)="woFiltersService.drilldownBackByVendor()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
		<div class="export-btns">
			<button type="button" class="btn btn-secondary" (click)="exportCsv()" [disabled]="inProgressExportCSV">
				<fa-icon [icon]="faFileExport"></fa-icon><span class="d-none d-md-inline ms-1"> CSV</span>
				<span class="loader loader-small loader-reverse"><span class="loader-inner"><span
							class="left">Loading...</span><span class="right"></span></span></span>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div class="datatable-responsive data-responsive-md">
			<ngx-datatable #woVendorTablePlanned class="bootstrap wrap-header-name"
				[rows]="tableDataByVendorPlanned.rows" [columns]="tableDataByVendorPlanned.columns" [headerHeight]="76"
				[scrollbarV]="true" [rowHeight]="38" [footerHeight]="50" [columnMode]="'flex'"
				[sorts]="[{prop: 'name', dir: 'asc'}]" [loadingIndicator]="!loadedByVendorPlanned">
				<!-- Row Detail Template -->
				<ngx-datatable-row-detail [rowHeight]="120" (toggle)="tableDataByVendorPlanned.onDetailToggle($event)">
					<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
						<div class="row-detail-inner d-flex flex-row flex-wrap">
							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Planned Count: </span>{{row.plannedCount |
								number : '1.0-0'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Planned Respond KPI Fail:
								</span>{{row.plannedRespondKPIFail | number : '1.0-0'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Planned Resolve KPI Fail:
								</span>{{row.plannedResolveKPIFail | number : '1.0-0'}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-row-detail>
				<!-- Column Templates -->
				<ngx-datatable-column prop="name"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}}"
					headerClass="col-5 col-xl-4 has-expand-icon" cellClass="col-5 col-xl-4 has-expand-icon"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-row="row" let-value="value" let-expanded="expanded" ngx-datatable-cell-template>
						<span title="{{value}}">
							<span class="position-relative d-block">
								<a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
									[class.datatable-icon-down]="expanded" class="datatable-icon-action"
									title="Expand/Collapse Row" (click)="tableDataByVendorPlanned.toggleExpandRow(row)">
								</a>
								<span class="cursor-pointer text-truncate-custom"
									(click)="tableDataByVendorPlanned.onNameClick(row)">{{value}}</span>
							</span>
						</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="plannedCount" name="PM Count" headerClass="col text-end"
					cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="plannedRespondKPIFail" name="PM Respond KPI Fail" headerClass="col text-end"
					cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="plannedResolveKPIFail" name="PM Resolve KPI Fail" headerClass="col text-end"
					cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column *ngIf="whiteLabelService.getSettings.enableResolveFailPercentage"
					prop="plannedResolveFailPercentage" name="Resolve Fail %" headerClass="col text-end"
					cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<!-- Footer Template -->
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template>
						<div class="datatable-footer-row">
							<div class="datatable-footer-cell col-5 col-xl-4">Total</div>
							<div *ngFor="let column of tableDataByVendorPlanned.total; let i = index"
								[ngClass]="column.class" class="datatable-footer-cell">
								{{column.value | number : '1.0-0'}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
	</div>
</div>