import {
	Injectable
} from '@angular/core';
import {
	HttpClient
} from '@angular/common/http';
import {
	Observable
} from 'rxjs';
import {
	catchError
} from 'rxjs/operators';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	MessageService
} from '../../services/message.service';
import {
	AuthService
} from '../../services/auth.service';
import { WhiteLabelService } from '../../services/white-label.service';
import { AssetOfflineSummaryResponseModel } from '../models/assetOfflineSummaryResponseModel';
import { AssetTypeTagModel } from '../models/assetTypeTagModel';
import { AssetRequestListResponseModel } from '../models/assetRequestListResponseModel';
import { QueryOptionsPagingModel } from '../models/queryOptionsPagingModel';
import { CriticalAssetNameModel } from '../models/criticalAssetNameModel';
import { AssetBenchmarkByAgeResponseModel } from '../models/assetBenchmarkByAgeResponseModel';
import { AssetBenchmarkByMonthNameResponseModel } from '../models/assetBenchmarkByMonthNameResponseModel';
import { AssetBenchmarkByAssetResponseModel } from '../models/assetBenchmarkByAssetResponseModel';
import { AssetBenchmarkListResponseModel } from '../models/assetBenchmarkListResponseModel';

@Injectable()
export class AssetService {
	private apiUrl = this.whiteLabelService.getSettings.informApiUrl + 'api/';

	constructor(
		private http: HttpClient,
		private messages: MessageService,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService) { }

	getAssetOfflineSummary(model: QueryOptionsModel | null): Observable<AssetOfflineSummaryResponseModel[]> {
		let url = this.apiUrl + 'Asset/AssetOfflineSummary';
		return this.http.post<AssetOfflineSummaryResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getAssetOfflineSummary(model)', []))
		);
	}

	getAssetRequestList(model: QueryOptionsModel | QueryOptionsPagingModel | null): Observable<AssetRequestListResponseModel[]> {
		let url = this.apiUrl + 'Asset/AssetRequestList';
		return this.http.post<AssetRequestListResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getAssetRequestList(model)', []))
		);
	}

	getAssetTypeTagList(model: QueryOptionsModel | null): Observable<AssetTypeTagModel[]> {
		let url = this.apiUrl + 'Asset/AssetTypeTagList';
		return this.http.post<AssetTypeTagModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getAssetTypeTagList(model)', []))
		);
	}

	getCriticalAssetNames(): Observable<CriticalAssetNameModel[]> {
		let url = this.apiUrl + 'Asset/CriticalAssetNames';
		return this.http.get<CriticalAssetNameModel[]>(url, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getCriticalAssetNames()', []))
		);
	}

	getAssetBenchmarkByAge(model: QueryOptionsModel | null): Observable<AssetBenchmarkByAgeResponseModel[]> {
		let url = this.apiUrl + 'Asset/AssetBenchmarkByAge';
		return this.http.post<AssetBenchmarkByAgeResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getAssetBenchmarkByAge(model)', []))
		);
	}

	getAssetBenchmarkByMonthName(model: QueryOptionsModel | null): Observable<AssetBenchmarkByMonthNameResponseModel[]> {
		let url = this.apiUrl + 'Asset/AssetBenchmarkByMonthName';
		return this.http.post<AssetBenchmarkByMonthNameResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getAssetBenchmarkByMonthName(model)', []))
		);
	}

	getAssetBenchmarkByAsset(model: QueryOptionsModel | null): Observable<AssetBenchmarkByAssetResponseModel[]> {
		let url = this.apiUrl + 'Asset/AssetBenchmarkByAsset';
		return this.http.post<AssetBenchmarkByAssetResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getAssetBenchmarkByAsset(model)', []))
		);
	}

	getAssetBenchmarkList(model: QueryOptionsModel | null): Observable<AssetBenchmarkListResponseModel[]> {
		let url = this.apiUrl + 'Asset/AssetBenchmarkList';
		return this.http.post<AssetBenchmarkListResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'AssetBenchmarkList(model)', []))
		);
	}
}
