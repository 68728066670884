// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	adalConfig: {
		"7eleven": {
			tenant: "ac704964-7e1d-435a-b064-20c7a110ce19",
			clientId: "eb56ef5b-63a9-48ce-82fc-ef16590a35ed",
			redirectUri: "http://localhost:4200/7eleven/ssologin",
			postLogoutRedirectUri: "http://localhost:4200/",
			endpoints: {
				"https://localhost:5001": "eb56ef5b-63a9-48ce-82fc-ef16590a35ed",
				"https://localhost:5003": "eb56ef5b-63a9-48ce-82fc-ef16590a35ed",
			},
		},
		"racv": {
			tenant: "53aaf5b4-420c-4edc-a867-e6fab4d41e70",
			clientId: "9c22d091-30af-42f6-a990-89a2ffc4207c",
			redirectUri:
				"https://reporting.property.racv.com.au/racv/ssologin",
			postLogoutRedirectUri: "https://reporting.property.racv.com.au/",
			endpoints: {
				"https://platinumfmapi-test.azurewebsites.net":
					"9c22d091-30af-42f6-a990-89a2ffc4207c",
				"https://api-test-racv.platinumfm.com.au":
					"9c22d091-30af-42f6-a990-89a2ffc4207c",
			},
		}
	},
	samlConfig: {
		visionaustralia: {
			samlSsoUrl:
				"https://api-visionaustralia.local:5001/Saml/InitiateSingleSignOn?returnurl=https://report-visionaustralia.local:5005/visionaustralia/ssologin",
			samlSloUrl:
				"https://api-visionaustralia.local:5001/Saml/InitiateSingleLogout?returnurl=https://report-visionaustralia.local:5005",
		},
	},
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
