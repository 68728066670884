export class FilterLight {
    overallStatuses: string[] = null;
    states: string[] = null;
    vendorIds: number[] = null;
    vendorNames: string[] = null;
    vendorExclusions: string[] = null;
    storeIds: number[] = null;
    assetCategories: string[] = null;
    assetNames: string[] = null;
    statuses: string[] = null;
    statusIds: number[] = null;
    requestTypes: string[] = null;
    priorities: string[] = null;
    glCostTypes: string[] = null;
    glDescriptions: string[] = null;
    glStatuses: string[];
    serviceDeliveryManagers: string[] = null;
    retailBusinessManagers: string[] = null;
    regionalManagers: string[] = null;
    storeTypes: string[] = null;
    storeProfiles: string[] = null;
    storeClusters: string[] = null;
    districtManagers: string[] = null;
    deliveryIndicators: string[] = null;
}