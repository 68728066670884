<main>
	<div class="container">
		<div class="row mb-3">
			<nav aria-label="breadcrumb" class="w-100 half-strong">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">Home</li>
					<li class="breadcrumb-item"><span (click)="resetFilters()">Open Work Orders</span></li>
				</ol>
			</nav>
		</div>

		<div class="row">
			<div class="col-12 mb-4">
				<app-work-orders-nav></app-work-orders-nav>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-filters></app-work-orders-filters>
			</div>
		</div>
		<div class="row">
			<div class="col-12 mb-3 summary-tabs">
				<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-tabs" (navChange)="beforeTabChange($event)">
					<li [ngbNavItem]="1" id="tab-summary" class="nav-item">
						<a ngbNavLink>
							<h2 class="mb-0 h5">
								<fa-icon [icon]="faInfo"></fa-icon> Summary
							</h2>
						</a>
						<ng-template ngbNavContent>
							<app-work-orders-open-summary></app-work-orders-open-summary>
						</ng-template>
					</li>

					<li [ngbNavItem]="2" id="tab-overdue" class="nav-item">
						<a ngbNavLink>
							<h2 class="mb-0 h5">
								<fa-icon [icon]="faHourglassEnd"></fa-icon> Overdue
							</h2>
						</a>
						<ng-template ngbNavContent class="p-0">
							<div>
								<app-work-orders-open-overdue></app-work-orders-open-overdue>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="nav" class=""></div>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-lg-4 mb-3">
				<app-work-orders-open-priority></app-work-orders-open-priority>
			</div>
			<div class="col-12 col-lg-8 mb-3">
				<app-work-orders-open-state></app-work-orders-open-state>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-open-overdue-date></app-work-orders-open-overdue-date>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-xl-6 mb-3">
				<app-work-orders-open-vendor></app-work-orders-open-vendor>
			</div>

			<div class="col-12 col-xl-6 mb-3">
				<app-work-orders-open-asset></app-work-orders-open-asset>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-open-store></app-work-orders-open-store>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-open-state-rm></app-work-orders-open-state-rm>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-open-rm></app-work-orders-open-rm>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-open-state-pm></app-work-orders-open-state-pm>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-0">
				<app-work-orders-open-pm></app-work-orders-open-pm>
			</div>
		</div>
	</div>
</main>
