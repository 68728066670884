import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faChartColumn,
} from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import * as moment from "moment";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/message.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { RequestSummaryDayResolveResponseModel } from "../models/requestSummaryDayResolveResponseModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-open-overdue-date",
	templateUrl: "./work-orders-open-overdue-date.component.html",
})
export class WorkOrdersOpenOverdueDateComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loaded: boolean;
	chartOldRecordsStart: moment.Moment = null;
	chartOldRecordsEnd: moment.Moment = null;
	dateFormatInternal: string = "YYYY-MM-DD";
	dateFormatChart: string = "DD-MM-YYYY";
	overText: string = "Over 12 weeks";
	chartConfig = {
		type: "bar",
		data: {
			labels: [],
			datasets: [
				{
					label: "Overdue",
					data: [],
					backgroundColor: [],
					hoverBackgroundColor: [],
				},
				{
					label: "Open",
					data: [],
					backgroundColor: [],
					hoverBackgroundColor: [],
				},
			],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: true,
			},
			scales: {
				xAxes: [
					{
						stacked: true,
						ticks: {
							autoSkip: true,
						},
					},
				],
				yAxes: [
					{
						stacked: true,
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "left",
						id: "y-axis-1",
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `${label.toLocaleString()}`;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					title: (tooltipItem, data) => {
						var title = data["labels"][tooltipItem[0]["index"]];
						return title === null
							? "null"
							: title === this.overText
							? `All records between ${
									this.chartOldRecordsStart
										? this.chartOldRecordsStart.format("DD-MMM-YYYY")
										: ""
							  } and ${
									this.chartOldRecordsEnd
										? this.chartOldRecordsEnd.format("DD-MMM-YYYY")
										: ""
							  }`
							: "Week starting Monday " + title;
					},
					label: function (tooltipItem, data) {
						var value =
							data["datasets"][tooltipItem.datasetIndex]["data"][tooltipItem["index"]];
						return `${value.toLocaleString()}`;
					},
				},
			},
			onClick: (event) => {
				var activePoints = this.chart.chart.getElementsAtEvent(event);
				if (activePoints && activePoints.length > 0) {
					for (var i = 0; i < activePoints.length; i++) {
						if (activePoints[i]._model && activePoints[i]._model.label) {
							var value = activePoints[i]._model.label;
							if (value === this.overText) {
								if (this.chartOldRecordsStart && this.chartOldRecordsEnd) {
									this.woFiltersService.isCollapsed = false;
									let beginDate = this.chartOldRecordsStart.toDate();
									let endDate = this.chartOldRecordsEnd.toDate();
									this.woFiltersService.updateDateRangeEst("set", beginDate, endDate);
								}
							} else {
								let m = moment(value, this.dateFormatChart);
								if (m.isValid()) {
									this.woFiltersService.isCollapsed = false;
									let beginDate = m.toDate();
									let endDate = m.add(6, "days").toDate();
									this.woFiltersService.updateDateRangeEst("set", beginDate, endDate);
								}
							}
							break;
						}
					}
				}
			},
		},
	};
	@ViewChild("overdueChart") chart: ChartComponent;

	colors: any = null;

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private messageService: MessageService
	) {
		this.colors = whiteLabelService.getOverdueChartColors();
	}

	ngOnInit() {
		this.loaded = false;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Open"];
		body = this.woFiltersService.fillBodyEndDateThisMonth(body, true);
		body.filter.deliveryIndicators = null;
		return body;
	}

	buildWeek(
		element: RequestSummaryDayResolveResponseModel,
		oldestSince: moment.Moment
	): string {
		let dateStr = `${element.year}-${element.month.toLocaleString(undefined, {
			minimumIntegerDigits: 2,
		})}-${element.day.toLocaleString(undefined, {
			minimumIntegerDigits: 2,
		})}T00:00:00Z`;
		var date = moment.utc(dateStr);
		let dateWeek = moment.utc(dateStr).isoWeekday(1);
		if (oldestSince > date) {
			if (!this.chartOldRecordsEnd || date > this.chartOldRecordsEnd) {
				this.chartOldRecordsEnd = date;
			}
			if (!this.chartOldRecordsStart || date < this.chartOldRecordsStart) {
				this.chartOldRecordsStart = date;
			}
		}
		return oldestSince > dateWeek
			? "0"
			: dateWeek.format(this.dateFormatInternal);
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;
		this.chartOldRecordsStart = null;
		this.chartOldRecordsEnd = null;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryDayResolve(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.loaded = true;
						let dates = [];
						let newLabels = [];
						let newData = [];
						let newDataNotOverdue = [];
						let oldestSince = moment
							.utc()
							.isoWeekday(1)
							.hours(0)
							.minutes(0)
							.seconds(0)
							.milliseconds(0);
						oldestSince.subtract(12, "weeks");
						data.forEach((element) => {
							if (element.year > 0) {
								var key = this.buildWeek(element, oldestSince);
								if (dates.indexOf(key) < 0) {
									dates.push(key);
									newData.push(0);
									newDataNotOverdue.push(0);
								}
							}
						});
						dates.sort();
						let today = moment();
						let todayStr = today.format(this.dateFormatInternal);
						data.forEach((element) => {
							if (element.year > 0) {
								var key = this.buildWeek(element, oldestSince);
								var index = dates.indexOf(key);
								newData[index] += element.resolveKPIFail;
								let dateStr = `${element.year}-${element.month.toLocaleString(
									undefined,
									{ minimumIntegerDigits: 2 }
								)}-${element.day.toLocaleString(undefined, {
									minimumIntegerDigits: 2,
								})}`;
								if (todayStr <= dateStr) {
									newDataNotOverdue[index] += element.count - element.resolveKPIFail;
								}
							}
						});
						dates.forEach((element) => {
							if (element === "0") {
								newLabels.push(this.overText);
							} else {
								var m = moment.utc(element);
								newLabels.push(m.format(this.dateFormatChart));
							}
						});
						this.chartConfig.data.labels = newLabels;
						this.chartConfig.data.datasets[0].data = newData;
						this.chartConfig.data.datasets[0].backgroundColor =
							this.colors.colorsPast[0];
						this.chartConfig.data.datasets[0].hoverBackgroundColor =
							this.colors.colorsPast[1];
						this.chartConfig.data.datasets[1].data = newDataNotOverdue;
						this.chartConfig.data.datasets[1].backgroundColor =
							this.colors.colorsFuture[0];
						this.chartConfig.data.datasets[1].hoverBackgroundColor =
							this.colors.colorsFuture[1];
						if (this.chart) {
							this.chart.chart.update();
						}
						this.ref.detectChanges();
					}
				})
		);
	}

	// Icons
	faChartColumn = faChartColumn;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
