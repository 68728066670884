<div class="card has-invisible-content" [ngClass]="{'has-back': hasBack()}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon><span>Open RM KPI Status<span *ngIf="(!hasBack())"> by {{facilityTypeMode ? "Facility Type" : "State"}}</span><span *ngIf="hasBack()"> by Priority ({{facilityTypeMode ? woFiltersService.selectedStoreType : woFiltersService.selectedState}}<span *ngIf="woFiltersService.selectedPriority && woFiltersService.selectedPriority.length > 0"> - {{woFiltersService.selectedPriority}}</span>)</span></span>
		</h2>
		<div class="back-btn" *ngIf="hasBack()">
			<button type="button" class="btn btn-secondary" (click)="facilityTypeMode && woFiltersService.drilldownBackRMByStoreType() || !facilityTypeMode && woFiltersService.drilldownBackRM()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !((!hasBack() && loadedByState) || (hasBack() && loadedByStateRMDrilldown))}" class="chart-container long-height-sm">
			<chart class="chart-container long-height-sm" #stateRMChart [type]="chartConfigByStateRM.type" [data]="chartConfigByStateRM.data" [options]="chartConfigByStateRM.options"></chart>
		</div>

		<div *ngIf="!((!hasBack() && loadedByState) || (hasBack() && loadedByStateRMDrilldown))"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span
			class="right"></span></span></span></div>
	</div>
</div>
