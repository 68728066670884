import { DatePipe } from '@angular/common';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'datePipeFull'
})
export class DatePipeFull extends DatePipe {
    public transform(value): any {
      return super.transform(value, 'yyyy-MM-dd HH:mm:ss');
    }
  }