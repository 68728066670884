export class Settings {
	name: string;
	whiteLabel: string;
	apiUrl: string;
	informApiUrl: string;
	informApplicationUrl: string;
	enableLogo: boolean;
	enableNewVendorFilter: boolean;
	enableWeeklyStatusTab: boolean;
	enableAssetAvailabilityTab: boolean;
	enableAssetPerformanceTab: boolean;
	enableFilterState: boolean;
	enableFilterDistrictManager: boolean;
	enableFilterGLDescription: boolean;
	enableFilterRetailBusinessManagers: boolean;
	enableFilterRegionalManagers: boolean;
	enableFilterStoreType: boolean;
	enableFilterStoreProfile: boolean;
	enableFilterServiceDeliveryManager: boolean;
	enableFilterBrand: boolean;
	enableFilterDistrict: boolean;
	enableFilterCategory: boolean;
	enableFilterCategoryManager: boolean;
	enableResolveFailPercentage: boolean;
	enableDaysOverdue: boolean;
	hideWidgetVendorPerformanceStatusCompletedWorkOrdersByState: boolean;
	hideWidgetVendorPerformanceStatusVendorKPIReports: boolean;
	hideWidgetFinancialBudgetTotalSpendStoreTypeByState: boolean;
	hideWidgetFinancialBudgetTotalSpendFuelvsNonFuelByState: boolean;
	resolveKPIPriorities: string;
	respondKPIPriorities: string;
	filterServiceDeliveryManagerLabel: string;
	filterRetailBusinessManagerLabel: string;
	filterRegionalManagerLabel: string;
	filterDistrictManagerLabel: string;
	filterGLDescriptionLabel: string;
	filterStoreTypeLabel: string;
	filterStoreProfileLabel: string;
	filterStoreLabel: string;
	filterVendorLabel: string;
	assetConditionEnable: boolean;
	assetConditionName: string;
	assetConditionShowInTopNav: boolean;
	assetConditionStoreText: string;
	assetConditionStoreNameText: string;
	assetPerformanceBenchmarkHideRetailBusinessManager: boolean;
	assetPerformanceBenchmarkHideStoreProfile: boolean;
	assetPerformanceBenchmarkHideStoreType: boolean;
	assetPerformanceBenchmarkHideCategory: boolean;
	assetAvailabilityKeyAssetsOffline: string;
	ignoreVendorNoGrouping: boolean;
}
