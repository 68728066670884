<div class="card has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> Planned Work Orders by Month
		</h2>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loadedByMonth}" class="chart-container long-height-sm">
			<chart class="chart-container long-height-sm" #monthChart [type]="chartConfigByMonth.type" [data]="chartConfigByMonth.data" [options]="chartConfigByMonth.options"></chart>
		</div>

		<div *ngIf="!loadedByMonth"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
