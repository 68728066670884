<div class="datatable-responsive" [ngClass]="{'data-responsive-exl': whiteLabelService.isWhittlesea(), 'data-responsive-lg': !whiteLabelService.isWhittlesea()}">
  <ngx-datatable
      class="bootstrap"
      [rows]="tableByDescription.rows"
      [columns]="tableByDescription.columns"
      [headerHeight]="50"
      [scrollbarV]="true"
      [rowHeight]="38"
      [footerHeight]="50"
      [columnMode]="'flex'"
      [sorts]="[{prop: 'description', dir: 'asc'}]">
      <ngx-datatable-column headerClass="col-3 col-xl-4" cellClass="col-3 col-xl-4" name="Description" prop='description' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.description}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column headerClass="col-1 text-center" cellClass="col-1 text-center" name="Condition" prop='condition' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.condition | number : '1.1-1'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column headerClass="col text-end" cellClass="col text-end" name="Year 1" prop='year1' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.year1 | number : '1.0-0'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column headerClass="col text-end" cellClass="col text-end" name="Year 2" prop='year2' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.year2 | number : '1.0-0'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column headerClass="col text-end" cellClass="col text-end" name="Year 3" prop='year3' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.year3 | number : '1.0-0'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column headerClass="col text-end" cellClass="col text-end" name="Year 4" prop='year4' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.year4 | number : '1.0-0'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column headerClass="col text-end" cellClass="col text-end" name="Year 5" prop='year5' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.year5 | number : '1.0-0'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="whiteLabelService.isWhittlesea()" headerClass="col text-end" cellClass="col text-end" name="Year 6" prop='year6' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.year6 | number : '1.0-0'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="whiteLabelService.isWhittlesea()" headerClass="col text-end" cellClass="col text-end" name="Year 7" prop='year7' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.year7 | number : '1.0-0'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="whiteLabelService.isWhittlesea()" headerClass="col text-end" cellClass="col text-end" name="Year 8" prop='year8' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.year8 | number : '1.0-0'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="whiteLabelService.isWhittlesea()" headerClass="col text-end" cellClass="col text-end" name="Year 9" prop='year9' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.year9 | number : '1.0-0'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="whiteLabelService.isWhittlesea()" headerClass="col text-end" cellClass="col text-end" name="Year 10" prop='year10' [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.year10 | number : '1.0-0'}}
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template >
              <div class="datatable-footer-row">
                  <div class="datatable-footer-cell col-3 col-xl-4">Total</div>
                  <div class="datatable-footer-cell col-1 text-center">{{tableByDescription.conditionTotal | number : '1.1-1'}}</div>
                  <div class="datatable-footer-cell col text-end">${{tableByDescription.year1Total | number : '1.0-0'}}</div>
                  <div class="datatable-footer-cell col text-end">${{tableByDescription.year2Total | number : '1.0-0'}}</div>
                  <div class="datatable-footer-cell col text-end">${{tableByDescription.year3Total | number : '1.0-0'}}</div>
                  <div class="datatable-footer-cell col text-end">${{tableByDescription.year4Total | number : '1.0-0'}}</div>
                  <div class="datatable-footer-cell col text-end">${{tableByDescription.year5Total | number : '1.0-0'}}</div>
                  <div class="datatable-footer-cell col text-end" *ngIf="whiteLabelService.isWhittlesea()">${{tableByDescription.year6Total | number : '1.0-0'}}</div>
                  <div class="datatable-footer-cell col text-end" *ngIf="whiteLabelService.isWhittlesea()">${{tableByDescription.year7Total | number : '1.0-0'}}</div>
                  <div class="datatable-footer-cell col text-end" *ngIf="whiteLabelService.isWhittlesea()">${{tableByDescription.year8Total | number : '1.0-0'}}</div>
                  <div class="datatable-footer-cell col text-end" *ngIf="whiteLabelService.isWhittlesea()">${{tableByDescription.year9Total | number : '1.0-0'}}</div>
                  <div class="datatable-footer-cell col text-end" *ngIf="whiteLabelService.isWhittlesea()">${{tableByDescription.year10Total | number : '1.0-0'}}</div>
              </div>
          </ng-template>
      </ngx-datatable-footer>
  </ngx-datatable>
</div>