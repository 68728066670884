import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	faArrowAltCircleLeft,
	faInfo
} from '@fortawesome/free-solid-svg-icons';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import {
	StateService
} from '../../services/state.service';
import {
	WhiteLabelService
} from '../../services/white-label.service';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import { QueryOptionsPagingModel } from '../models/queryOptionsPagingModel';
import { AssetService } from '../services/asset.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';

@Component({
	selector: 'app-asset-availability-critical-offline',
	templateUrl: './asset-availability-critical-offline.component.html'
})
export class AssetAvailabilityCriticalOfflineComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loaded: boolean;
	data: any[];

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private assetService: AssetService,
		private workOrderReportService: WorkOrderReportService) {

	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loaded = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.woFiltersService.loadData();
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(this.stateService.getCurrentDateRange(), value);
		}));
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.assetTypeTags = ['Critical'];
		body.filter.assetNames = undefined;
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		this.subs.add(this.assetService.getCriticalAssetNames()
			.subscribe(assets => {
				this.subs.add(this.assetService.getAssetOfflineSummary(body)
					.subscribe(data => {
						if (this.woFiltersService.getCurrentUpdateId() === updateId) {
							const newData = [];
							assets.forEach((asset) => {
								if (newData.find((item) => item.name === asset.groupName)) {
									newData.find((item) => item.name === asset.groupName).assets.push(asset.inform_Submenu2 ?? asset.inform_System);
								}
								else {
									newData.push({ name: asset.groupName, assets: [asset.inform_Submenu2 ?? asset.inform_System], count: 0, icon: asset.icon });
								}
							})
							//console.log(newData);
							//console.log(data);
							data.forEach((item) => {								
								for (let i = 0; i < newData.length; i++) {
									if (newData[i].assets.includes(item.name) || newData[i].assets.includes(item.system)) {
										newData[i].count += item.offlineCount;
									}
								}
							});
							this.data = newData;
							this.loaded = true;
							this.ref.detectChanges();
						}
					}));

			}));
	}

	onClick(item) {
		for (var i = 0; i < this.woFiltersService.assetNames.length; i++) {
			if (item.assets.includes(this.woFiltersService.assetNames[i])) {
				this.woFiltersService.isCollapsed = false;
				this.woFiltersService.selectedAssetName = item.assets;
				break;
			}
		}
	}

	// Icons
	faInfo = faInfo;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}