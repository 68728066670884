import {
	Component,
	OnInit,
	OnDestroy,
	Input,
	ViewChild
} from '@angular/core';
import {
	faChartLine
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { DashboardAssetPerformanceSeriesModel } from '../models/dashboardAssetPerformanceSeriesModel';
import { WOFiltersService } from '../services/wo-filters.service';
import { WhiteLabelService } from '../../services/white-label.service';
import { DropdownItem } from '../../models/dropdownItem';
import * as moment from 'moment';

@Component({
	selector: 'app-asset-performance-series',
	templateUrl: './asset-performance-series.component.html'
})
export class AssetPerformanceSeriesComponent implements OnInit, OnDestroy {
	@Input() model: DashboardAssetPerformanceSeriesModel;

	month1: moment.Moment | null = null;
	month2: moment.Moment | null = null;
	datePicker1Config = {
		appendTo: 'body',
		max: moment().endOf('month'),
		opens: "right"
	}
	datePicker2Config = {
		appendTo: 'body',
		max: moment().endOf('month'),
		opens: "left"
	}

	onMonth1Changed() {
		if (this.month1) {
			const newValue = this.month1.format('YYYY-MM-DD');
			if (newValue !== this.model.fromDt) {
				this.model.fromDt = newValue;
			}
			if (this.month2 && this.month2 < this.month1) {
				this.month2 = this.month1;
				this.onMonth2Changed();
			}
		}
	}

	onMonth2Changed() {
		if (this.month2) {
			const newValue = this.month2.endOf('month').format('YYYY-MM-DD');
			if (newValue !== this.model.toDt) {
				this.model.toDt = newValue;
			}
			if (this.month1 && this.month2 < this.month1) {
				this.month1 = this.month2;
				this.onMonth1Changed();
			}
		}
	}

	singleValue: string | DropdownItem = undefined;

	constructor(public woFiltersService: WOFiltersService,
		public whiteLabelService: WhiteLabelService) {
	}

	ngOnInit() {
		this.month1 = moment(this.model.fromDt);
		this.month2 = moment(this.model.toDt);

		if (this.model.filterValues && this.model.filterValues.length) {
			this.singleValue = this.model.filterValues[0];
		}
	}

	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	onChangeSingleValue() {
		if (this.singleValue) {
			if (this.model.filterValues.length) {
				this.model.filterValues[0] = this.singleValue;
			} else {
				this.model.filterValues.push(this.singleValue);
			}
		} else {
			this.model.filterValues = [];
		}
	}

	onChangeFilterType() {
		this.model.filterValues = [];
	}

	// Icons
	faChartLine = faChartLine;
}