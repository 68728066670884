import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-vendor-performance-status-state",
	templateUrl: "./vendor-performance-status-state.component.html",
})
export class VendorPerformanceStatusStateComponent
	implements OnInit, OnDestroy
{
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedInformByState: boolean;
	tableDataInformByState = {
		rows: [],
		columns: [
			{
				prop: "state",
				name: "State",
			},
			{
				prop: "no",
				name: "No",
			},
			{
				prop: "yes",
				name: "Yes",
			},
			{
				prop: "total",
				name: "Total",
			},
		],
		total: [],
		onNameClick: (row) => {
			if (this.whiteLabelService.getSettings.enableFilterState) {
				for (var i = 0; i < this.woFiltersService.states.length; i++) {
					if (this.woFiltersService.states[i] === row.state) {
						this.woFiltersService.isCollapsed = false;
						this.woFiltersService.selectedState = [this.woFiltersService.states[i]];
						break;
					}
				}
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.tableByInformByState.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		},
	};
	@ViewChild("woInformTable") tableByInformByState: DatatableComponent;

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		private whiteLabelService: WhiteLabelService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedInformByState = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.roles$.subscribe((data) => {
				if (
					data.length > 0 &&
					data.indexOf("store") < 0 &&
					data.indexOf("rm") < 0
				) {
					this.subs.add(
						this.woFiltersService.getUpdateId.subscribe((value) => {
							this.update(this.stateService.getCurrentDateRange(), value);
						})
					);
				}
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		return this.woFiltersService.fillBody(body, dateRange);
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedInformByState = false;

		//#region Table 4
		let bodyTable4 = this.fillBody(new QueryOptionsModel(), dateRange);
		bodyTable4.filter.overallStatuses = ["Completed"];
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryState(bodyTable4)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						let newData: {
							[id: string]: any;
						} = {};
						let newTotal = [
							{
								class: "col text-right",
								value: 0,
							},
							{
								class: "col text-right",
								value: 0,
							},
							{
								class: "col text-right",
								value: 0,
							},
						];
						data.forEach((element) => {
							let rowId = `${element.state}`;
							if (typeof newData[rowId] === "undefined") {
								let newRow = {
									state: element.state,
									no: 0,
									yes: 0,
									total: 0,
								};
								newData[rowId] = newRow;
							}
							newData[rowId].no += element.count - element.informUseByVendor;
							newTotal[0].value += element.count - element.informUseByVendor;
							newData[rowId].yes += element.informUseByVendor;
							newTotal[1].value += element.informUseByVendor;
							newData[rowId].total += element.count;
							newTotal[2].value += element.count;
						});

						let newDataArray = [];
						for (let key in newData) {
							newDataArray.push(newData[key]);
						}
						this.tableDataInformByState.total = newTotal;
						this.tableDataInformByState.rows = newDataArray;
						this.loadedInformByState = true;
						this.ref.detectChanges();
					}
				})
		);
		//#endregion
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
