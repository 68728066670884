import {
	Injectable, EventEmitter
} from '@angular/core';
import {
	Observable,
	of
} from 'rxjs';
import {
	NotificationsService, NotificationType
} from 'angular2-notifications';
import { AuthService } from './auth.service';

@Injectable()
export class MessageService {

	constructor(private notifications: NotificationsService) { }

	public removeNotification(notification: any) {
		this.notifications.remove(notification.id);
	}

	public success(title: string, message: string) {
		this.notifications.success(title, message);
	}

	public info(title: string, message: string) {
		this.notifications.info(title, message);
	}

	public warn(title: string, message: string) {
		this.notifications.warn(title, message);
	}

	public error(title: string, message: string) {
		this.notifications.error(title, message);
	}

	public html(html: any, type?: NotificationType, override?: any, icon?: string, context?: any, onClick?: any) {
		let notification = this.notifications.html(html, type, override, icon, context);
		if (onClick) {
			notification.click.subscribe((event) => {
				onClick(event, notification)
			});
		}
	}

	private blobToString(b: Blob): string {
		var u, x;
		u = URL.createObjectURL(b);
		x = new XMLHttpRequest();
		x.open('GET', u, false); // although sync, you're not fetching over internet
		x.send();
		URL.revokeObjectURL(u);
		return x.responseText;
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param authService - AuthService
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	public handleError<T>(authService: AuthService, operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.error(`${operation} failed: ${JSON.stringify(error)}`);
			if (error.status === 401) {
				authService.logout();
			} else {
				let errorMessage = "";
				if (error.error && error.error instanceof Blob) {
					let errorObj = JSON.parse(this.blobToString(error.error));
					errorMessage = errorObj.error ? errorObj.error : errorObj;
					console.error(`${operation} failed(addtional info): ${errorMessage}`);
				} else {
					errorMessage = error.error && error.error.error ? error.error.error : error.message;
				}
				this.error("Error", errorMessage);
			}

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * Without notification.
	 * @param authService - AuthService
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	public handleErrorSilent<T>(authService: AuthService, operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.error(`${operation} failed: ${JSON.stringify(error)}`);
			if (error.status === 401) {
				authService.logout();
			}

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}