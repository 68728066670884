<div class="card info-card has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faInfo"></fa-icon> Summary
		</h2>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loadedSummary}">
			<div class="info-wrapper place-row-md">
				<div class="display-1 long-data">{{countRM | number}}<small>Completed RM</small></div>
				<div class="display-1 long-data">{{countPM | number}}<small>Completed PM</small></div>
				<div class="display-1 long-data pb-sm-1 pb-md-0">${{costRM | number:'1.0-0'}}<small>RM Cost</small></div>
				<div class="display-1 long-data pb-sm-1 pb-md-0">${{costPM | number:'1.0-0'}}<small>PM Cost</small></div>
			</div>
		</div>

		<div *ngIf="!loadedSummary"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
