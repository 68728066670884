import { DecimalPipe } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/message.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-weekly-status-asset",
	templateUrl: "./weekly-status-asset.component.html",
})
export class WeeklyStatusAssetComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByAsset: boolean;
	tableDataByAsset = {
		rows: [],
		columns: [
			{
				prop: "name",
				name: "Asset Name",
				headerClass: "col-9 col-xl-10",
				cellClass: "col-9 col-xl-10",
				resizeable: false,
				draggable: false,
			},
			{
				prop: "count",
				name: "Count",
				headerClass: "col text-right",
				cellClass: "col text-right",
				resizeable: false,
				draggable: false,
				pipe: new DecimalPipe("en-US"),
			},
		],
		total: [],
	};
	@ViewChild("woAssetTable") openWOAssetTable: DatatableComponent;

	backgroundColor = [];
	hoverBackgroundColor = [];

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private messageService: MessageService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;
	}

	ngOnInit() {
		this.loadedByAsset = false;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(value);
			})
		);
	}

	update(updateId: string) {
		this.loadedByAsset = false;
		let body = this.woFiltersService.fillBodyWeeklyStatus(
			new QueryOptionsModel()
		);
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryAsset(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						let newData: {
							[id: string]: any;
						} = {};
						let newTotal = [0];
						data.forEach((element) => {
							var rowId = element.name;
							if (typeof newData[rowId] === "undefined") {
								let newRow = {
									name: element.name,
									count: 0,
								};
								newData[rowId] = newRow;
							}
							newData[rowId]["count"] += element.count;
							newTotal[0] += element.count;
						});

						let newDataArray = [];
						for (var key in newData) {
							newDataArray.push(newData[key]);
						}

						this.tableDataByAsset.total = newTotal;
						this.tableDataByAsset.rows = newDataArray;
						this.loadedByAsset = true;
						this.ref.detectChanges();
					}
				})
		);
	}

	// Icons
	faTable = faTable;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
