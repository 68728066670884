import { NameCountModel } from "./nameCountModel";

export class ReportWeeklyStatusModel {
    BodyClass: string;
    Year: string;
    Week: string;
    State: string[];
    Comment: string;
    Priority: string;
    ChartConfigByPriorityLabels: string[];
    ChartConfigByPriorityValues: number[];
    TableAssetData: NameCountModel[];
    ChartsConfigKPIPerformance: any[];
    ChartConfigByVolumeLabels: string[];
    ChartConfigByVolumeValues: number[];
    TableVendorsData: NameCountModel[];
    TableStoresData: NameCountModel[];
}