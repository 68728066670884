import {
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faChartColumn,
	faExpandArrowsAlt,
	faFileExport,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { AngularCsv } from "angular-csv-ext";
import { ChartComponent } from "angular2-chartjs";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import ConverterHelper from "../../converterHelper";
import { DropdownItem } from "../../models/dropdownItem";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { AssetBenchmarkByMonthNameResponseModel } from "../models/assetBenchmarkByMonthNameResponseModel";
import { DashboardAssetPerformanceDataSetModel } from "../models/dashboardAssetPerformanceDataSetModel";
import { DashboardAssetPerformanceModel } from "../models/dashboardAssetPerformanceModel";
import { DashboardAssetPerformanceSeriesModel } from "../models/dashboardAssetPerformanceSeriesModel";
import { Filter } from "../models/filter";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { AssetService } from "../services/asset.service";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-asset-performance-month",
	templateUrl: "./asset-performance-month.component.html",
})
export class AssetPerformanceMonthComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loaded: boolean;
	title: string;
	// data, series, dataSet, datasetIndex, seriesIndex
	chartData: [
		AssetBenchmarkByMonthNameResponseModel[],
		DashboardAssetPerformanceSeriesModel,
		DashboardAssetPerformanceDataSetModel,
		number,
		number
	][] = [];
	// dataSetName, timeRange, seriesName, color
	legendArray: [string, string, [string, string][]][] = [];
	chartConfig = {
		type: "line",
		data: {
			datasets: [],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: false,
			},
			hover: {
				mode: "x",
			},
			scales: {
				xAxes: [
					{
						display: true,
						type: "category",
						labels: [
							"Jan",
							"Feb",
							"Mar",
							"Apr",
							"May",
							"Jun",
							"Jul",
							"Aug",
							"Sep",
							"Oct",
							"Nov",
							"Dec",
						],
						ticks: {
							autoSkip: false,
						},
					},
				],
				yAxes: [
					{
						display: true,
						position: "left",
						id: "y-axis-1",
						scaleLabel: {
							display: false,
							labelString: "",
						},
						ticks: {
							beginAtZero: true,
						},
					},
				],
			},
			parsing: {
				xAxisKey: "x",
				yAxisKey: "y",
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data.datasets[tooltipItem[0].datasetIndex].label;
						return title === null ? "null" : title;
					},
					label: (tooltipItem, data) => {
						let result = "";
						for (
							let i = 0;
							i < this.chartData[tooltipItem.datasetIndex][0].length;
							i++
						) {
							const element = this.chartData[tooltipItem.datasetIndex][0][i];
							const x = tooltipItem["xLabel"];
							if (element.monthName === x) {
								result = `Month: ${element.monthName}`;
								break;
							}
						}
						return result;
					},
					afterLabel: (tooltipItem, data) => {
						let result = [];
						for (
							let i = 0;
							i < this.chartData[tooltipItem.datasetIndex][0].length;
							i++
						) {
							const element = this.chartData[tooltipItem.datasetIndex][0][i];
							const x = tooltipItem["xLabel"];
							if (element.monthName === x) {
								const series = this.chartData[tooltipItem.datasetIndex][1];
								const dataSet = this.chartData[tooltipItem.datasetIndex][2];
								const timeStr =
									dataSet.series[0].fromDt && dataSet.series[0].toDt
										? `${dataSet.series[0].fromDt} - ${dataSet.series[0].toDt}`
										: "";
								result.push(`Date Range: ${timeStr}`);
								result.push(
									`Asset Count: ${ConverterHelper.intToString(element.assetCount)}`
								);
								switch (this.selectedDisplayType) {
									case "1": {
										result.push(
											`Maintain Cost: ${ConverterHelper.floatToString(
												element.benchmarkMaintCost
											)}`
										);
										break;
									}
									case "2": {
										result.push(
											`Maintain Count: ${ConverterHelper.floatToString(
												element.benchmarkMaintCount
											)}`
										);
										break;
									}
									case "3": {
										result.push(
											`Time Between Failure: ${ConverterHelper.intToString(
												element.timeBetweenFailure
											)}`
										);
										break;
									}
								}
								switch (series.filterType) {
									case "assetNames": {
										result.push(
											`Asset Names: ${this.chartData[
												tooltipItem.datasetIndex
											][1].filterValues.join(", ")}`
										);
										break;
									}
									case "retailBusinessManagers": {
										// squad
										result.push(
											`Asset Names: ${dataSet.series[0].filterValues.join(", ")}`
										);
										result.push(
											`${this.titleCase(
												this.whiteLabelService.getSettings.filterRetailBusinessManagerLabel
											)}: ${this.chartData[tooltipItem.datasetIndex][1].filterValues.join(
												", "
											)}`
										);
										break;
									}
									case "storeProfiles": {
										// fuel/non fuel
										result.push(
											`Asset Names: ${dataSet.series[0].filterValues.join(", ")}`
										);
										result.push(
											`Store Profile: ${this.chartData[
												tooltipItem.datasetIndex
											][1].filterValues.join(", ")}`
										);
										break;
									}
									case "storeTypes": {
										result.push(
											`Asset Names: ${dataSet.series[0].filterValues.join(", ")}`
										);
										result.push(
											`${this.titleCase(
												this.whiteLabelService.getSettings.filterStoreTypeLabel
											)}: ${this.chartData[tooltipItem.datasetIndex][1].filterValues.join(
												", "
											)}`
										);
										break;
									}
									case "stores": {
										result.push(
											`Asset Names: ${dataSet.series[0].filterValues.join(", ")}`
										);
										result.push(
											`Stores: ${(
												this.chartData[tooltipItem.datasetIndex][1]
													.filterValues as DropdownItem[]
											)
												.map((x) => x.name)
												.join(", ")}`
										);
										break;
									}
								}
								break;
							}
						}
						return result.length ? result : "";
					},
				},
			},
		},
	};
	@ViewChild("apaChart") chart: ChartComponent;

	titleCase(str) {
		var splitStr = str.toLowerCase().split(" ");
		for (var i = 0; i < splitStr.length; i++) {
			// You do not need to check if i is larger than splitStr length, as your for does that for you
			// Assign it back to the array
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		}
		// Directly return the joined string
		return splitStr.join(" ");
	}

	tableData = {
		rows: [],
		columns: [],
		headersGroup: [],
		headersTop: [],
		rowsForExport: [],
		headersForExport: [],
	};

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private assetService: AssetService
	) {}

	@Input() selectedDisplayType = "1";
	@Input() model: DashboardAssetPerformanceModel | null = null;
	roles$: Observable<string[]>;
	ngOnInit() {
		switch (this.selectedDisplayType) {
			default: {
				this.title = "Average Maintenance Cost by Calendar Month";
				break;
			}
			case "2": {
				this.title = "Average Maintenance Count by Calendar Month";
				break;
			}
			case "3": {
				this.title = "Mean Time Between Failures (MTBF)";
				break;
			}
		}
		this.loaded = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel,
		dataSet: DashboardAssetPerformanceDataSetModel,
		series: DashboardAssetPerformanceSeriesModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body.filter = new Filter();
		body.filter.assetTypeTags = ["Key"];
		switch (series.filterType) {
			case "assetNames": {
				body.filter.assetNames = series.filterValues as string[];
				break;
			}
			case "retailBusinessManagers": {
				// squad
				body.filter.retailBusinessManagers = series.filterValues as string[];
				body.filter.assetNames = dataSet.series[0].filterValues as string[];
				break;
			}
			case "storeProfiles": {
				// fuel/non fuel
				body.filter.storeProfiles = series.filterValues as string[];
				body.filter.assetNames = dataSet.series[0].filterValues as string[];
				break;
			}
			case "storeTypes": {
				if (
					this.whiteLabelService.getSettings.filterStoreTypeLabel === "facility type"
				) {
					body.filter.storeTypes = series.filterValues as string[];
				} else {
					body.filter.storeClusters = series.filterValues as string[];
				}
				body.filter.assetNames = dataSet.series[0].filterValues as string[];
				break;
			}
			case "stores": {
				body.filter.storeIds = (series.filterValues as DropdownItem[]).map(
					(item) => item.id
				);
				body.filter.assetNames = dataSet.series[0].filterValues as string[];
				break;
			}
		}
		if (dataSet.series[0].fromDt && dataSet.series[0].toDt) {
			body.filter.fromDt = dataSet.series[0].fromDt + "T00:00:00.000Z";
			body.filter.toDt = dataSet.series[0].toDt + "T00:00:00.000Z";
		}
		return body;
	}

	updateCounter = 0;
	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;
		this.tableData.columns = [];
		this.tableData.rows = [];
		this.tableData.headersGroup = [];
		this.tableData.headersTop = [];
		this.tableData.headersForExport = [];
		this.tableData.rowsForExport = [];
		this.chartData = [];
		this.chartConfig.data.datasets = [];
		this.legendArray = [];
		let updateCounter = 0;
		this.model.dataSets.forEach((dataSet) => {
			updateCounter += dataSet.series.length;
		});
		this.updateCounter = updateCounter;
		let i = 1;
		const disableSeries3 = this.model.dataSets.length > 2;
		this.model.dataSets.forEach((dataSet) => {
			let j = 0;
			dataSet.series.forEach((series) => {
				if (!disableSeries3 || j < 2) {
					this.sendRequest(
						i,
						j,
						dataSet,
						series,
						this.fillBody(new QueryOptionsModel(), dateRange, dataSet, series),
						updateId
					);
				} else {
					this.updateCounter--;
				}
				j++;
			});
			i++;
		});

		if (!updateCounter) {
			this.loaded = true;
		}
	}

	sendRequest(
		dataSetIndex: number,
		seriesIndex: number,
		dataSet: DashboardAssetPerformanceDataSetModel,
		series: DashboardAssetPerformanceSeriesModel,
		body: QueryOptionsModel | QueryOptionsPagingModel,
		updateId: string
	) {
		this.subs.add(
			this.assetService.getAssetBenchmarkByMonthName(body).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.updateCounter--;
					this.chartData.push([data, series, dataSet, dataSetIndex, seriesIndex]);

					if (this.updateCounter <= 0) {
						// fill chart
						for (let i = 0; i < this.chartData.length; i++) {
							const currentData = this.chartData[i][0];
							const currentSeries = this.chartData[i][1];
							const currentDataSetIndex = this.chartData[i][3];
							const currentSeriesIndex = this.chartData[i][4];
							if (currentSeries.showInGraph) {
								const values = [];
								currentData.forEach((element) => {
									switch (this.selectedDisplayType) {
										case "1": {
											values.push({ y: element.benchmarkMaintCost, x: element.monthName });
											break;
										}
										case "2": {
											values.push({
												y: element.benchmarkMaintCount,
												x: element.monthName,
											});
											break;
										}
										case "3": {
											values.push({ y: element.timeBetweenFailure, x: element.monthName });
											break;
										}
									}
								});
								this.chartConfig.data.datasets.push({
									label: `Data Set ${currentDataSetIndex} - Series ${
										currentSeriesIndex + 1
									}`,
									borderColor: currentSeries.color,
									backgroundColor: currentSeries.color,
									hoverBackgroundColor: currentSeries.color,
									yAxisID: "y-axis-1",
									data: values,
									fill: false,
								});
							}
						}
						const options = Object.assign({}, this.chartConfig.options);
						switch (this.selectedDisplayType) {
							case "1": {
								options.scales.yAxes[0].scaleLabel.display = true;
								options.scales.yAxes[0].scaleLabel.labelString = "$";
								break;
							}
							case "2": {
								options.scales.yAxes[0].scaleLabel.display = false;
								options.scales.yAxes[0].scaleLabel.labelString = "";
								break;
							}
							case "3": {
								options.scales.yAxes[0].scaleLabel.display = true;
								options.scales.yAxes[0].scaleLabel.labelString = "Hours";
								break;
							}
						}
						this.chartConfig.options = options;

						if (this.chart) {
							this.chart.chart.update();
						}

						// fill legend
						// dataSetName, dateRange, seriesName, color, filter
						const newLegendMap: {
							[key: string]: [string, [string, string, string][]];
						} = {};
						for (let i = 0; i < this.chartData.length; i++) {
							const currentSeries = this.chartData[i][1];
							if (currentSeries.showInGraph) {
								const currentDataSet = this.chartData[i][2];
								const currentDataSetIndex = this.chartData[i][3];
								const currentSeriesIndex = this.chartData[i][4];
								const timeStr =
									currentDataSet.series[0].fromDt && currentDataSet.series[0].toDt
										? `\r\n${currentDataSet.series[0].fromDt} - ${currentDataSet.series[0].toDt}`
										: "";
								const dataSetName = `Data Set ${currentDataSetIndex}`;
								if (!newLegendMap[dataSetName]) {
									newLegendMap[dataSetName] = [timeStr, []];
								}
								let filter = "";
								switch (currentSeries.filterType) {
									case "assetNames": {
										filter = `Asset Names: ${currentSeries.filterValues.join(", ")}`;
										break;
									}
									case "retailBusinessManagers": {
										// squad
										filter = `${this.titleCase(
											this.whiteLabelService.getSettings.filterRetailBusinessManagerLabel
										)}: ${currentSeries.filterValues.join(", ")}`;
										break;
									}
									case "storeProfiles": {
										// fuel/non fuel
										filter = `Store Profile: ${currentSeries.filterValues.join(", ")}`;
										break;
									}
									case "storeTypes": {
										filter = `${this.titleCase(
											this.whiteLabelService.getSettings.filterStoreTypeLabel
										)}: ${currentSeries.filterValues.join(", ")}`;
										break;
									}
									case "stores": {
										filter = `Stores: ${(currentSeries.filterValues as DropdownItem[])
											.map((x) => x.name)
											.join(", ")}`;
										break;
									}
								}
								newLegendMap[dataSetName][1].push([
									`Series ${currentSeriesIndex + 1}`,
									currentSeries.color,
									filter,
								]);
							}
						}
						let newLegendArray = [];
						for (let key in newLegendMap) {
							newLegendArray.push([
								key,
								newLegendMap[key][0],
								newLegendMap[key][1].sort((a, b) => a[0].localeCompare(b[0])),
							]);
						}
						newLegendArray.sort((a, b) => a[0].localeCompare(b[0]));
						this.legendArray = newLegendArray;

						// fill table from this.chartData
						interface GroupedMonthDataSets {
							dataSetName: string;
							seriesNames: string[];
							// series, monthName, value
							seriesData: { [key: string]: { [key: string]: number } };
						}
						const groupedData: GroupedMonthDataSets[] = [];
						const disableSeries3 = this.model.dataSets.length > 2;
						for (let i = 0; i < this.model.dataSets.length; i++) {
							const currentDataSet = this.model.dataSets[i];
							if (currentDataSet.series.length) {
								const timeStr =
									currentDataSet.series[0].fromDt && currentDataSet.series[0].toDt
										? `${currentDataSet.series[0].fromDt} - ${currentDataSet.series[0].toDt}`
										: "";
								const filterStr = (
									currentDataSet.series[0].filterValues as string[]
								).join(",");
								const gd = {
									dataSetName: `Data Set ${i + 1} - ${filterStr} - ${timeStr}`,
									seriesNames: [],
									seriesData: {},
								};
								let j = 0;
								this.model.dataSets[i].series.forEach((series) => {
									if (!disableSeries3 || j < 2) {
										gd.seriesNames.push(this.buildSeriesName(j, series));
										gd.seriesData[this.buildSeriesName(j, series)] = {};
									}
									j++;
								});
								groupedData.push(gd);
							}
						}
						let monthNames: string[] = [
							"Jan",
							"Feb",
							"Mar",
							"Apr",
							"May",
							"Jun",
							"Jul",
							"Aug",
							"Sep",
							"Oct",
							"Nov",
							"Dec",
						];
						monthNames.forEach((monthName) => {
							groupedData.forEach((gd) => {
								gd.seriesNames.forEach((sName) => {
									gd.seriesData[sName][monthName] = 0;
								});
							});
						});

						for (let i = 0; i < this.chartData.length; i++) {
							const currentData = this.chartData[i][0];
							const currentSeries = this.chartData[i][1];
							//const currentDataSet = this.chartData[i][2];
							const currentDataSetIndex = this.chartData[i][3];
							const currentSeriesIndex = this.chartData[i][4];
							currentData.forEach((element) => {
								const key = element.monthName;
								let value = 0;
								switch (this.selectedDisplayType) {
									case "1": {
										value = element.benchmarkMaintCost;
										break;
									}
									case "2": {
										value = element.benchmarkMaintCount;
										break;
									}
									case "3": {
										value = element.timeBetweenFailure;
										break;
									}
								}
								groupedData[currentDataSetIndex - 1].seriesData[
									this.buildSeriesName(currentSeriesIndex, currentSeries)
								][key] = value;
							});
						}

						const columns = ["Month"];
						const headersForExport = ["Month"];
						groupedData.forEach((gd) => {
							gd.seriesNames.forEach((sName) => {
								columns.push(sName);
								headersForExport.push(`${gd.dataSetName} - ${sName}`);
							});
							if (gd.seriesNames.length > 1) {
								const title = `${gd.seriesNames[gd.seriesNames.length - 1]} % against ${
									gd.seriesNames[0]
								}`;
								columns.push(title);
								headersForExport.push(`${gd.dataSetName} - ${title}`);
							}
						});

						const rows = [];
						const rowsForExport = [];
						monthNames.forEach((monthName) => {
							let row = [[monthName, ""]];
							let rowForExport = [monthName];
							groupedData.forEach((gd) => {
								gd.seriesNames.forEach((sName) => {
									row.push([
										`${
											this.selectedDisplayType === "1" ? "$" : ""
										}${ConverterHelper.floatToString(gd.seriesData[sName][monthName])}`,
										"text-end",
									]);
									rowForExport.push(
										ConverterHelper.floatToString(gd.seriesData[sName][monthName])
									);
								});
								if (gd.seriesNames.length > 1) {
									const lastSValue =
										gd.seriesData[gd.seriesNames[gd.seriesNames.length - 1]][monthName];
									const firstSValue = gd.seriesData[gd.seriesNames[0]][monthName];
									const value = firstSValue ? (lastSValue * 100) / firstSValue : 0;
									let classes = "";
									if (this.selectedDisplayType === "3") {
										classes =
											value < 50
												? "bg-danger text-white text-end"
												: value >= 50 && value < 75
												? "bg-warning text-white text-end"
												: "text-end";
									} else {
										classes =
											value > 150
												? "bg-danger text-white text-end"
												: value > 125
												? "bg-warning text-white text-end"
												: "text-end";
									}
									row.push([`${ConverterHelper.floatToString(value)}%`, classes]);
									rowForExport.push(ConverterHelper.floatToString(value));
								}
							});
							rows.push(row);
							rowsForExport.push(rowForExport);
						});

						const headersGroup = [];
						headersGroup.push(["", 1]);
						groupedData.forEach((gd) => {
							headersGroup.push([
								gd.dataSetName,
								gd.seriesNames.length > 1
									? gd.seriesNames.length + 1
									: gd.seriesNames.length,
							]);
						});
						let headersTop;
						switch (this.selectedDisplayType) {
							default: {
								headersTop = [
									"Average Maintenance Cost by Calendar Month",
									columns.length,
								];
								break;
							}
							case "2": {
								headersTop = [
									"Average Maintenance Counts by Calendar Month",
									columns.length,
								];
								break;
							}
							case "3": {
								headersTop = [
									"Mean Time Between Failures (MTBF) - Hrs",
									columns.length,
								];
								break;
							}
						}

						this.tableData.columns = columns;
						this.tableData.rows = rows;
						this.tableData.headersGroup = headersGroup;
						this.tableData.headersTop = [headersTop];
						this.tableData.rowsForExport = rowsForExport;
						this.tableData.headersForExport = headersForExport;

						// all done
						this.loaded = true;
						this.ref.detectChanges();
					}
				}
			})
		);
	}

	buildSeriesName(
		seriesIndex: number,
		series: DashboardAssetPerformanceSeriesModel
	): string {
		switch (seriesIndex) {
			case 0: {
				return "All Assets";
			}
			default: {
				switch (series.filterType) {
					case "retailBusinessManagers": {
						// squad
						return series.filterValues.join(", ");
					}
					case "storeProfiles": {
						// fuel/non fuel
						return series.filterValues.join(", ");
					}
					case "storeTypes": {
						return series.filterValues.join(", ");
					}
					case "stores": {
						return (series.filterValues as DropdownItem[])
							.map((x) => x.name)
							.join(", ");
					}
				}
				return `Series ${seriesIndex + 1}`;
			}
		}
	}

	isChartFullscreen = false;
	public goFullscreenChart(value: boolean) {
		this.isChartFullscreen = value;
	}

	public exportCsv() {
		const options = {
			fieldSeparator: ",",
			quoteStrings: '"',
			decimalseparator: ".",
			showLabels: true,
			headers: this.tableData.headersForExport.map((x) => {
				return `\"${x}\"`;
			}),
			showTitle: false,
			title: "export",
			useBom: true,
		};
		return new AngularCsv(this.tableData.rowsForExport, "export", options);
	}

	// Icons
	faChartColumn = faChartColumn;
	faFileExport = faFileExport;
	faExpandArrowsAlt = faExpandArrowsAlt;
	faTable = faTable;
}
