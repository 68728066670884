import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-completed-month",
	templateUrl: "./work-orders-completed-month.component.html",
})
export class WorkOrdersCompletedMonthComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByMonth: boolean;
	chartConfigByMonth = {
		type: "bar",
		data: {
			labels: [],
			datasets: [],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: true,
			},
			scales: {
				xAxes: [
					{
						stacked: true,
						ticks: {
							autoSkip: false,
						},
					},
				],
				yAxes: [
					{
						stacked: true,
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "right",
						id: "y-axis-1",
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `${label.toLocaleString()}`;
							},
						},
					},
					{
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "left",
						id: "y-axis-2",
						// grid line settings
						gridLines: {
							drawOnChartArea: false, // only want the grid lines for one axis to show up
						},
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `\$${label.toLocaleString()}`;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data["datasets"][tooltipItem[0]["datasetIndex"]].label;
						return title === null ? "null" : title;
					},
					label: function (tooltipItem, data) {
						if (data.datasets[tooltipItem.datasetIndex].label.endsWith("Cost")) {
							return `\$${tooltipItem.yLabel.toLocaleString()}`;
						} else {
							return `${tooltipItem.yLabel.toLocaleString()}`;
						}
					},
				},
			},
		},
	};
	@ViewChild("monthChart") chartByMonth: ChartComponent;

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByMonth = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Completed"];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByMonth = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		//#region Chart 3
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryStateMonth(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.loadedByMonth = true;
						let newLabels = [];
						let newData = [];
						let types = [];
						let monthes = [];
						data.forEach((element) => {
							let month =
								element.year +
								"/" +
								element.month.toLocaleString("en-US", {
									minimumIntegerDigits: 2,
									useGrouping: false,
								});
							if (!monthes.includes(month)) {
								monthes.push(month);
							}
							if (!types.includes(element.requestType)) {
								types.push(element.requestType);
							}
						});
						monthes.sort();
						newLabels = monthes;
						let array = [];
						monthes.forEach((element2) => {
							array.push(0);
						});
						let colors = this.whiteLabelService.getIncidentPlannedReactiveColors();
						types.sort();
						types.forEach((element) => {
							newData.push({
								label: element + " Cost",
								backgroundColor: colors[element].backgroundColorCost,
								hoverBackgroundColor: colors[element].hoverBackgroundColorCost,
								yAxisID: "y-axis-2",
								data: array.slice(),
								type: "line",
								fill: false,
								borderColor: colors[element].backgroundColorCost,
								borderWidth: 2,
							});
						});
						types.forEach((element) => {
							newData.push({
								label: element + " Count",
								backgroundColor: colors[element].backgroundColor,
								hoverBackgroundColor: colors[element].hoverBackgroundColor,
								yAxisID: "y-axis-1",
								data: array.slice(),
								type: "bar",
							});
						});
						data.forEach((element) => {
							let month =
								element.year +
								"/" +
								element.month.toLocaleString("en-US", {
									minimumIntegerDigits: 2,
									useGrouping: false,
								});
							let indexMonth = monthes.indexOf(month);
							let indexTypeCount = types.indexOf(element.requestType) + types.length;
							let indexTypeCost = types.indexOf(element.requestType);
							newData[indexTypeCount].data[indexMonth] += element.count;
							newData[indexTypeCost].data[indexMonth] += element.cost;
						});
						this.chartConfigByMonth.data.labels = newLabels;
						this.chartConfigByMonth.data.datasets = newData;
						if (this.chartByMonth) {
							this.chartByMonth.chart.update();
						}
						this.ref.detectChanges();
					}
				})
		);
		//#endregion
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
