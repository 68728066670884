import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgSelectModule } from "@ng-select/ng-select";
import { ChartModule } from "angular2-chartjs";
// import ('../../node_modules/chartjs-plugin-funnel/dist/chart.funnel.min.js');
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { DragDropModule } from "@angular/cdk/drag-drop";
import { GoogleMapsModule } from "@angular/google-maps";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngJsoneditorModule } from '@maaxgr/ang-jsoneditor';
import { AdalService } from "adal-angular4";
import { SimpleNotificationsModule } from "angular2-notifications";
import {
	DateRangeUtilService,
	MyDateRangePickerModule,
} from "mydaterangepicker";
import { DpDatePickerModule } from "ng2-date-picker";
import { NgxColorsModule } from "ngx-colors";
import { AdalOptionalInterceptor } from "./adal-optional.interceptor";
import { AssetConditionsInfoByDescriptionService } from "./app-assets/services/ac-by-description.service";
import { AssetConditionsInfoByStoreService } from "./app-assets/services/ac-by-store.service";
import { AssetConditionsRatingsService } from "./app-assets/services/ac-ratings.service";
import { AssetConditionsStatsService } from "./app-assets/services/ac-sats.service";
import { EntitiesService } from "./app-assets/services/entities.service";
import { FilesService } from "./app-assets/services/files.service";
import { LifeCycleForecastService } from "./app-assets/services/life-cycle-forecast.service";
import { StoreFiltersService } from "./app-assets/services/store-filters.service";
import { StoreService } from "./app-assets/services/store.service";
import { AssetService } from "./app-work-orders/services/asset.service";
import { DashboardService } from "./app-work-orders/services/dashboard.service";
import { FinancialService } from "./app-work-orders/services/financial.service";
import { VendorKpiService } from "./app-work-orders/services/vendor-kpi.service";
import { WOFiltersService } from "./app-work-orders/services/wo-filters.service";
import { WorkOrderReportService } from "./app-work-orders/services/work-order-report.service";
import { DatePipeFull } from "./datePipeFull";
import { DecimalPipeCostTwoDigits } from "./decimalPipeCostTwoDigits";
import { DecimalPipeCostZeroDigits } from "./decimalPipeCostZeroDigits";
import { DecimalPipeTwoDigits } from "./decimalPipeTwoDigits";
import { DecimalPipeZeroDigits } from "./decimalPipeZeroDigits";
import { AuthGuard } from "./services/auth-guard.service";
import { AuthService } from "./services/auth.service";
import { MessageService } from "./services/message.service";
import { SettingsHttpService } from "./services/settings.http.service";
import { SettingsService } from "./services/settings.service";
import { StateService } from "./services/state.service";
import { WhiteLabelService } from "./services/white-label.service";

import { AssetConditionByDescriptionComponent } from "./app-assets/asset-condition-by-description/asset-condition-by-description.component";
import { AssetConditionByStoreComponent } from "./app-assets/asset-condition-by-store/asset-condition-by-store.component";
import { AssetConditionRatingComponent } from "./app-assets/asset-condition-rating/asset-condition-rating.component";
import { AssetConditionComponent } from "./app-assets/asset-condition/asset-condition.component";
import { AssetsComponent } from "./app-assets/assets/assets.component";
import { LifeCycleForecastComponent } from "./app-assets/life-cycle-forecast/life-cycle-forecast.component";
import { StoreDashboardComponent } from "./app-assets/store-dashboard/store-dashboard.component";
import { StoreInfoComponent } from "./app-assets/store-info/store-info.component";
import { AssetAffectingSTTableComponent } from "./app-work-orders/asset-affecting-st-table/asset-affecting-st-table.component";
import { AssetAvailabilityChartComponent } from "./app-work-orders/asset-availability-chart/asset-availability-chart.component";
import { AssetAvailabilityCriticalOfflineComponent } from "./app-work-orders/asset-availability-critical-offline/asset-availability-critical-offline.component";
import { AssetAvailabilitySummaryComponent } from "./app-work-orders/asset-availability-summary/asset-availability-summary.component";
import { AssetAvailabilityTableComponent } from "./app-work-orders/asset-availability-table/asset-availability-table.component";
import { AssetAvailabilityComponent } from "./app-work-orders/asset-availability/asset-availability.component";
import { AssetPerformanceAgeComponent } from "./app-work-orders/asset-performance-age/asset-performance-age.component";
import { AssetPerformanceMonthComponent } from "./app-work-orders/asset-performance-month/asset-performance-month.component";
import { AssetPerformanceSeriesComponent } from "./app-work-orders/asset-performance-series/asset-performance-series.component";
import { AssetPerformanceSummaryAssetComponent } from "./app-work-orders/asset-performance-summary-asset/asset-performance-summary-asset.component";
import { AssetPerformanceComponent } from "./app-work-orders/asset-performance/asset-performance.component";
import { FinancialActualBudgetCategoryComponent } from "./app-work-orders/financial-actual-budget-category/financial-actual-budget-category.component";
import { FinancialActualBudgetFuelNonFuelStateComponent } from "./app-work-orders/financial-actual-budget-fuel-non-fuel-state/financial-actual-budget-fuel-non-fuel-state.component";
import { FinancialActualBudgetStoreTypeStateComponent } from "./app-work-orders/financial-actual-budget-store-type-state/financial-actual-budget-store-type-state.component";
import { FinancialAssetCostComponent } from "./app-work-orders/financial-asset-cost/financial-asset-cost.component";
import { FinancialAssetCountComponent } from "./app-work-orders/financial-asset-count/financial-asset-count.component";
import { FinancialBudgetActualForecastMonthComponent } from "./app-work-orders/financial-budget-actual-forecast-month/financial-budget-actual-forecast-month.component";
import { FinancialBudgetActualForecastStateComponent } from "./app-work-orders/financial-budget-actual-forecast-state/financial-budget-actual-forecast-state.component";
import { FinancialForecastBudgetStoreGlComponent } from "./app-work-orders/financial-forecast-budget-store-gl/financial-forecast-budget-store-gl.component";
import { FinancialForecastBudgetStoreComponent } from "./app-work-orders/financial-forecast-budget-store/financial-forecast-budget-store.component";
import { FinancialSummaryComponent } from "./app-work-orders/financial-summary/financial-summary.component";
import { FinancialWOComponent } from "./app-work-orders/financial-wo/financial-wo.component";
import { FinancialComponent } from "./app-work-orders/financial/financial.component";
import { VendorPerformanceKpiReportComponent } from "./app-work-orders/vendor-performance-kpi-report/vendor-performance-kpi-report.component";
import { VendorPerformanceStatusMonthComponent } from "./app-work-orders/vendor-performance-status-month/vendor-performance-status-month.component";
import { VendorPerformanceStatusStateComponent } from "./app-work-orders/vendor-performance-status-state/vendor-performance-status-state.component";
import { VendorPerformanceStatusSummarySghComponent } from "./app-work-orders/vendor-performance-status-summary/vendor-performance-status-summary-sgh.component";
import { VendorPerformanceStatusSummaryComponent } from "./app-work-orders/vendor-performance-status-summary/vendor-performance-status-summary.component";
import { VendorPerformanceStatusVendorPComponent } from "./app-work-orders/vendor-performance-status-vendor-p/vendor-performance-status-vendor-p.component";
import { VendorPerformanceStatusVendorRIComponent } from "./app-work-orders/vendor-performance-status-vendor-ri/vendor-performance-status-vendor-ri.component";
import { VendorPerformanceStatusComponent } from "./app-work-orders/vendor-performance-status/vendor-performance-status.component";
import { WeeklyStatusAssetComponent } from "./app-work-orders/weekly-status-asset/weekly-status-asset.component";
import { WeeklyStatusDailyVolumeComponent } from "./app-work-orders/weekly-status-daily-volume/weekly-status-daily-volume.component";
import { WeeklyStatusKPIPerformanceComponent } from "./app-work-orders/weekly-status-kpi-performance/weekly-status-kpi-performance.component";
import { WeeklyStatusTopComponent } from "./app-work-orders/weekly-status-top/weekly-status-top.component";
import { WeeklyStatusVolumePriorityComponent } from "./app-work-orders/weekly-status-volume-priority/weekly-status-volume-priority.component";
import { WeeklyStatusComponent } from "./app-work-orders/weekly-status/weekly-status.component";
import { WidgetConfigComponent } from "./app-work-orders/widget-config/widget-config.component";
import { WorkOrdersCompletedAssetComponent } from "./app-work-orders/work-orders-completed-asset/work-orders-completed-asset.component";
import { WorkOrdersCompletedMonthComponent } from "./app-work-orders/work-orders-completed-month/work-orders-completed-month.component";
import { WorkOrdersCompletedPriorityComponent } from "./app-work-orders/work-orders-completed-priority/work-orders-completed-priority.component";
import { WorkOrdersCompletedResolveComponent } from "./app-work-orders/work-orders-completed-resolve/work-orders-completed-resolve.component";
import { WorkOrdersCompletedRespondComponent } from "./app-work-orders/work-orders-completed-respond/work-orders-completed-respond.component";
import { WorkOrdersCompletedStateComponent } from "./app-work-orders/work-orders-completed-state/work-orders-completed-state.component";
import { WorkOrdersCompletedStoreComponent } from "./app-work-orders/work-orders-completed-store/work-orders-completed-store.component";
import { WorkOrdersCompletedSummaryComponent } from "./app-work-orders/work-orders-completed-summary/work-orders-completed-summary.component";
import { WorkOrdersCompletedVendorComponent } from "./app-work-orders/work-orders-completed-vendor/work-orders-completed-vendor.component";
import { WorkOrdersCompletedComponent } from "./app-work-orders/work-orders-completed/work-orders-completed.component";
import { WorkOrdersFiltersComponent } from "./app-work-orders/work-orders-filters/work-orders-filters.component";
import { WorkOrdersNavComponent } from "./app-work-orders/work-orders-nav/work-orders-nav.component";
import { WorkOrdersOpenAssetComponent } from "./app-work-orders/work-orders-open-asset/work-orders-open-asset.component";
import { WorkOrdersOpenOverdueDateComponent } from "./app-work-orders/work-orders-open-overdue-date/work-orders-open-overdue-date.component";
import { WorkOrdersOpenOverdueComponent } from "./app-work-orders/work-orders-open-overdue/work-orders-open-overdue.component";
import { WorkOrdersOpenPMComponent } from "./app-work-orders/work-orders-open-pm/work-orders-open-pm.component";
import { WorkOrdersOpenPriorityComponent } from "./app-work-orders/work-orders-open-priority/work-orders-open-priority.component";
import { WorkOrdersOpenRMComponent } from "./app-work-orders/work-orders-open-rm/work-orders-open-rm.component";
import { WorkOrdersOpenStatePMComponent } from "./app-work-orders/work-orders-open-state-pm/work-orders-open-state-pm.component";
import { WorkOrdersOpenStateRMComponent } from "./app-work-orders/work-orders-open-state-rm/work-orders-open-state-rm.component";
import { WorkOrdersOpenStateComponent } from "./app-work-orders/work-orders-open-state/work-orders-open-state.component";
import { WorkOrdersOpenStoreComponent } from "./app-work-orders/work-orders-open-store/work-orders-open-store.component";
import { WorkOrdersOpenSummaryComponent } from "./app-work-orders/work-orders-open-summary/work-orders-open-summary.component";
import { WorkOrdersOpenVendorComponent } from "./app-work-orders/work-orders-open-vendor/work-orders-open-vendor.component";
import { WorkOrdersOpenComponent } from "./app-work-orders/work-orders-open/work-orders-open.component";
import { AppComponent } from "./app.component";
import { AzureAuthCallbackComponent } from "./azure-auth-callback/azure-auth-callback.component";
import { HeaderComponent } from "./header/header.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { SamlAuthCallbackComponent } from "./saml-auth-callback/saml-auth-callback.component";

export function app_Init(settingsHttpService: SettingsHttpService) {
	return () => settingsHttpService.initializeApp();
}

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		AssetConditionComponent,
		HeaderComponent,
		StoreDashboardComponent,
		LoginComponent,
		AzureAuthCallbackComponent,
		SamlAuthCallbackComponent,
		StoreInfoComponent,
		AssetsComponent,
		AssetConditionRatingComponent,
		LifeCycleForecastComponent,
		AssetConditionByStoreComponent,
		AssetConditionByDescriptionComponent,
		DecimalPipeTwoDigits,
		DecimalPipeZeroDigits,
		DecimalPipeCostZeroDigits,
		DecimalPipeCostTwoDigits,
		DatePipeFull,
		WorkOrdersFiltersComponent,
		WorkOrdersNavComponent,
		WorkOrdersOpenComponent,
		WorkOrdersOpenSummaryComponent,
		WorkOrdersOpenOverdueComponent,
		WorkOrdersOpenOverdueDateComponent,
		WorkOrdersOpenPriorityComponent,
		WorkOrdersOpenStateComponent,
		WorkOrdersOpenStateRMComponent,
		WorkOrdersOpenStatePMComponent,
		WorkOrdersOpenVendorComponent,
		WorkOrdersOpenAssetComponent,
		WorkOrdersOpenStoreComponent,
		WorkOrdersOpenRMComponent,
		WorkOrdersOpenPMComponent,
		WorkOrdersCompletedComponent,
		WorkOrdersCompletedSummaryComponent,
		WorkOrdersCompletedPriorityComponent,
		WorkOrdersCompletedStateComponent,
		WorkOrdersCompletedMonthComponent,
		WorkOrdersCompletedResolveComponent,
		WorkOrdersCompletedRespondComponent,
		WorkOrdersCompletedVendorComponent,
		WorkOrdersCompletedAssetComponent,
		WorkOrdersCompletedStoreComponent,
		WeeklyStatusComponent,
		WeeklyStatusVolumePriorityComponent,
		WeeklyStatusAssetComponent,
		WeeklyStatusDailyVolumeComponent,
		WeeklyStatusTopComponent,
		WeeklyStatusKPIPerformanceComponent,
		VendorPerformanceStatusComponent,
		VendorPerformanceStatusSummaryComponent,
		VendorPerformanceStatusSummarySghComponent,
		VendorPerformanceStatusVendorRIComponent,
		VendorPerformanceStatusVendorPComponent,
		VendorPerformanceStatusMonthComponent,
		VendorPerformanceStatusStateComponent,
		VendorPerformanceKpiReportComponent,
		WidgetConfigComponent,
		FinancialComponent,
		FinancialSummaryComponent,
		FinancialBudgetActualForecastMonthComponent,
		FinancialBudgetActualForecastStateComponent,
		FinancialAssetCountComponent,
		FinancialAssetCostComponent,
		FinancialActualBudgetCategoryComponent,
		FinancialActualBudgetStoreTypeStateComponent,
		FinancialActualBudgetFuelNonFuelStateComponent,
		FinancialForecastBudgetStoreComponent,
		FinancialForecastBudgetStoreGlComponent,
		FinancialWOComponent,
		AssetAvailabilityComponent,
		AssetAvailabilitySummaryComponent,
		AssetAvailabilityCriticalOfflineComponent,
		AssetAvailabilityChartComponent,
		AssetAvailabilityTableComponent,
		AssetAffectingSTTableComponent,
		AssetPerformanceComponent,
		AssetPerformanceAgeComponent,
		AssetPerformanceMonthComponent,
		AssetPerformanceSummaryAssetComponent,
		AssetPerformanceSeriesComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		NgSelectModule,
		ChartModule,
		NgxDatatableModule,
		NgbModule,
		NgbNavModule,
		FontAwesomeModule,
		MyDateRangePickerModule,
		DpDatePickerModule,
		BrowserAnimationsModule,
		// NgJsonEditorModule,
		AngJsoneditorModule,
		DragDropModule,
		GoogleMapsModule,
		SimpleNotificationsModule.forRoot({
			timeOut: 5000,
			showProgressBar: true,
			pauseOnHover: true,
			clickToClose: true,
			position: ["top", "right"],
		}),
		NgxColorsModule,
		FormsModule,
		RouterModule.forRoot(
			[
				{
					path: "",
					component: HomeComponent,
					pathMatch: "full",
					canActivate: [AuthGuard],
				},
				{
					path: "asset-condition",
					component: AssetConditionComponent,
					canActivate: [AuthGuard],
				},
				{
					path: "login",
					component: LoginComponent,
				},
				{
					path: "work-orders-open",
					component: WorkOrdersOpenComponent,
					canActivate: [AuthGuard],
				},
				{
					path: "work-orders-completed",
					component: WorkOrdersCompletedComponent,
					canActivate: [AuthGuard],
				},
				{
					path: "weekly-status",
					component: WeeklyStatusComponent,
					canActivate: [AuthGuard],
				},
				{
					path: "vendor-performance-status",
					component: VendorPerformanceStatusComponent,
					canActivate: [AuthGuard],
				},
				{
					path: "financial",
					component: FinancialComponent,
					canActivate: [AuthGuard],
				},
				{
					path: "asset-availability",
					component: AssetAvailabilityComponent,
					canActivate: [AuthGuard],
				},
				{
					path: "asset-performance",
					component: AssetPerformanceComponent,
					canActivate: [AuthGuard],
				},
				{
					path: "7eleven/ssologin",
					component: AzureAuthCallbackComponent,
				},
				{
					path: "racv/ssologin",
					component: AzureAuthCallbackComponent,
				},
				{
					path: "visionaustralia/ssologin",
					component: SamlAuthCallbackComponent,
				},
			],
			{
				scrollPositionRestoration: "enabled",
			}
		),
	],
	providers: [
		MessageService,
		AuthService,
		AuthGuard,
		SettingsService,
		StoreService,
		StoreFiltersService,
		EntitiesService,
		StateService,
		WhiteLabelService,
		AssetConditionsStatsService,
		AssetConditionsRatingsService,
		LifeCycleForecastService,
		AssetConditionsInfoByStoreService,
		AssetConditionsInfoByDescriptionService,
		FilesService,
		WorkOrderReportService,
		VendorKpiService,
		FinancialService,
		DashboardService,
		WOFiltersService,
		AssetService,
		DateRangeUtilService,
		AdalService,
		{
			provide: APP_INITIALIZER,
			useFactory: app_Init,
			deps: [SettingsHttpService],
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AdalOptionalInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
