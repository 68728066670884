import {
	Component,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import {
	StateService
} from '../../services/state.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	faArrowAltCircleLeft,
	faTable
} from '@fortawesome/free-solid-svg-icons';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	QueryOptionsPagingModel
} from '../models/queryOptionsPagingModel';
import {
	DatatableComponent
} from '@swimlane/ngx-datatable';
import { AuthService } from '../../services/auth.service';
import { Observable, Subscription } from 'rxjs';

@Component({
	selector: 'app-work-orders-open-asset',
	templateUrl: './work-orders-open-asset.component.html'
})
export class WorkOrdersOpenAssetComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loadedByAsset: boolean;
	tableDataByAsset = {
		rows: [],
		columns: [{
			prop: 'name',
			name: 'Asset Category'
		}, {
			prop: 'Incident',
			name: 'Incident'
		}, {
			prop: 'Planned',
			name: 'Planned'
		}, {
			prop: 'Reactive',
			name: 'Reactive'
		}, {
			prop: 'Total',
			name: 'Total'
		}],
		total: [],
		onNameClick: (row) => {
			for (var i = 0; i < this.woFiltersService.assetNames.length; i++) {
				if (this.woFiltersService.assetNames[i] === row.assetName) {
					this.woFiltersService.isCollapsed = false;
					this.woFiltersService.selectedAssetName = [this.woFiltersService.assetNames[i]];
					break;
				}
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.openWOAssetTable.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		}
	};
	@ViewChild('openWOAssetTable') openWOAssetTable: DatatableComponent;

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByAsset = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(this.stateService.getCurrentDateRange(), value);
		}));
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ['Open'];
		body = this.woFiltersService.fillBodyEndDateThisMonth(body);
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByAsset = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		this.subs.add(this.workOrderReportService.getRequestSummaryAsset(body)
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					let headers = ["Incident", "Planned", "Reactive", "Total"];
					let newData: {
						[id: string]: any;
					} = {};
					let newTotal = [];
					headers.forEach(element => {
						newTotal.push(0);
					});
					data.forEach(element => {
						let rowId = `${element.name} - ${element.category}`;
						if (typeof newData[rowId] === "undefined") {
							let newRow = {
								name: rowId,
								assetName: element.name,
								assetCategory: element.category,
								count: 0
							};
							headers.forEach(element => {
								newRow[element] = 0;
							});
							newData[rowId] = newRow;
						}
						newData[rowId][element.requestType] += element.count;
						newData[rowId]["Total"] += element.count;
						let index = headers.indexOf(element.requestType);
						newTotal[index] += element.count;
						let indexTotal = headers.indexOf("Total");
						newTotal[indexTotal] += element.count;
					});

					let newDataArray = [];
					for (let key in newData) {
						newDataArray.push(newData[key]);
					}

					this.tableDataByAsset.total = newTotal;
					this.tableDataByAsset.rows = newDataArray;
					this.loadedByAsset = true;
					this.ref.detectChanges();
				}
			}));
	}

	// Icons
	faTable = faTable;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}