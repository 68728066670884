<div class="card full-height-lg has-invisible-content" [ngClass]="{'has-back': hasBack()}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> Open Work Orders by {{facilityTypeMode ? "Facility Type" : "State"}}
		</h2>
		<div class="back-btn" *ngIf="hasBack()">
			<button type="button" class="btn btn-secondary" (click)="this.facilityTypeMode && woFiltersService.drilldownBackByStoreType() || !this.facilityTypeMode && woFiltersService.drilldownBackByState()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loadedByState}" class="chart-container long-height-sm long-height-lg">
			<chart class="chart-container long-height-sm long-height-lg" #stateChart [type]="chartConfigByState.type" [data]="chartConfigByState.data" [options]="chartConfigByState.options"></chart>
		</div>

		<div *ngIf="!loadedByState"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
