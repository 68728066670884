import {
	Component,
	OnInit,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import {
	StateService
} from '../../services/state.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	faTable
} from '@fortawesome/free-solid-svg-icons';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	WhiteLabelService
} from '../../services/white-label.service';
import {
	DecimalPipe
} from '@angular/common';
import {
	MessageService
} from '../../services/message.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-weekly-status-top',
	templateUrl: './weekly-status-top.component.html'
})
export class WeeklyStatusTopComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedTop5Vendor: boolean;
	tableDataTop5Vendor = {
		rows: [],
		columns: [{
			prop: 'name',
			name: 'Vendor Name',
			headerClass: 'col-5',
			cellClass: 'col-5',
			resizeable: false,
			draggable: false
		}, {
			prop: 'count',
			name: 'Count',
			headerClass: 'col text-right',
			cellClass: 'col text-right',
			resizeable: false,
			draggable: false,
			pipe: new DecimalPipe("en-US")
		}]
	};

	loadedTop5Store: boolean;
	tableDataTop5Store = {
		rows: [],
		columns: [{
			prop: 'name',
			name: 'Store Name',
			headerClass: 'col-5',
			cellClass: 'col-5',
			resizeable: false,
			draggable: false
		}, {
			prop: 'count',
			name: 'Count',
			headerClass: 'col text-right',
			cellClass: 'col text-right',
			resizeable: false,
			draggable: false,
			pipe: new DecimalPipe("en-US")
		}]
	};

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private messageService: MessageService) {
	}

	ngOnInit() {
		this.loadedTop5Vendor = false;
		this.loadedTop5Store = false;
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(value);
		}));
	}

	update(updateId: string) {
		this.loadedTop5Vendor = false;
		this.loadedTop5Store = false;
		let body = this.woFiltersService.fillBodyWeeklyStatus(new QueryOptionsModel());
		this.subs.add(this.workOrderReportService.getRequestSummaryVendor(body)
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					let newData: {
						[id: string]: any;
					} = {};
					data.forEach(element => {
						var rowId = `${element.name}--${element.vendorNo}`;
						if (typeof newData[rowId] === "undefined") {
							let newRow = {
								name: element.name,
								count: 0
							};
							newData[rowId] = newRow;
						}
						newData[rowId].count += element.count;
					});

					let newDataArray = [];
					for (var key in newData) {
						newDataArray.push(newData[key]);
					}
					newDataArray.sort(function (a, b) {
						return b.count - a.count;
					});
					if (newDataArray.length > 5) {
						newDataArray.splice(5, newDataArray.length - 5);
					}

					this.tableDataTop5Vendor.rows = newDataArray;
					this.loadedTop5Vendor = true;
					this.ref.detectChanges();
				}
			}));

		this.loadedTop5Store = true;
		this.subs.add(this.workOrderReportService.getRequestSummaryEntity(body)
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					let newData: {
						[id: string]: any;
					} = {};
					let newTotal = [];
					data.forEach(element => {
						var rowId = `${element.name}--${element.storeNo}`;
						if (typeof newData[rowId] === "undefined") {
							let newRow = {
								name: element.name,
								count: 0
							};
							newData[rowId] = newRow;
						}
						newData[rowId][element.requestType] += element.count;
						newData[rowId].count += element.count;
					});

					let newDataArray = [];
					for (var key in newData) {
						newDataArray.push(newData[key]);
					}
					newDataArray.sort(function (a, b) {
						return b.count - a.count;
					});
					if (newDataArray.length > 5) {
						newDataArray.splice(5, newDataArray.length - 5);
					}

					this.tableDataTop5Store.rows = newDataArray;
					this.loadedTop5Store = true;
					this.ref.detectChanges();
				}
			}));
	}

	// Icons
	faTable = faTable;
}