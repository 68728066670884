import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFileExport,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/message.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { RequestSummaryEntityResponseModel } from "../models/requestSummaryEntityResponseModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-completed-store",
	templateUrl: "./work-orders-completed-store.component.html",
})
export class WorkOrdersCompletedStoreComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByStore: boolean;
	tableDataByStore = {
		rows: [],
		columns: [
			{
				prop: "storeNo",
				name: "Store No",
			},
			{
				prop: "name",
				name: "Name",
			},
			{
				prop: "state",
				name: "State",
			},
			{
				prop: "Work Orders",
				name: "Work Orders",
			},
			{
				prop: "Cost of RM",
				name: "Cost of RM",
			},
			{
				prop: "Cost of PM",
				name: "Cost of PM",
			},
			{
				prop: "Total",
				name: "Total",
			},
		],
		total: [],
		onNameClick: (row) => {
			for (var i = 0; i < this.woFiltersService.stores.length; i++) {
				if (this.woFiltersService.stores[i].id === row.id) {
					this.woFiltersService.isCollapsed = false;
					this.woFiltersService.selectedStore = [this.woFiltersService.stores[i]];
					break;
				}
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.tableByStore.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		},
	};
	@ViewChild("woStoreTable") tableByStore: DatatableComponent;

	public facilityTypeMode: boolean;
	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		public whiteLabelService: WhiteLabelService,
		private workOrderReportService: WorkOrderReportService,
		private messageService: MessageService
	) {
		this.facilityTypeMode = this.whiteLabelService.getFacilityTypeMode;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByStore = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Completed"];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByStore = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		//#region Table 3
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryEntity(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						let newData: {
							[id: string]: any;
						} = {};
						let newTotal = [
							{
								class: "col-1 text-end",
								value: 0,
							},
							{
								class: "col text-end",
								value: 0,
							},
							{
								class: "col text-end",
								value: 0,
							},
							{
								class: "col text-end",
								value: 0,
							},
						];
						data.forEach((element) => {
							let rowId = `${element.name}--${element.storeNo}`;
							if (typeof newData[rowId] === "undefined") {
								let newRow = {
									id: element.id,
									storeNo: element.storeNo,
									name: element.name,
									state: this.facilityTypeMode ? element.storeType : element.state,
									count: 0,
								};
								newRow["Work Orders"] = 0;
								newRow["Cost of RM"] = 0;
								newRow["Cost of PM"] = 0;
								newRow["Total"] = 0;
								newData[rowId] = newRow;
							}
							newData[rowId]["Work Orders"] += element.count;
							newTotal[0].value += element.count;
							if (
								element.requestType == "Reactive" ||
								element.requestType == "Incident"
							) {
								newData[rowId]["Cost of RM"] += element.cost;
								newTotal[1].value += element.cost;
								newData[rowId]["Total"] += element.cost;
								newTotal[3].value += element.cost;
							}
							if (element.requestType == "Planned") {
								newData[rowId]["Cost of PM"] += element.cost;
								newTotal[2].value += element.cost;
								newData[rowId]["Total"] += element.cost;
								newTotal[3].value += element.cost;
							}
						});

						let newDataArray = [];
						for (let key in newData) {
							newDataArray.push(newData[key]);
						}

						this.tableDataByStore.total = newTotal;
						this.tableDataByStore.rows = newDataArray;
						this.loadedByStore = true;
						this.ref.detectChanges();
					}
				})
		);
		//#endregion
	}

	//#region export CSV

	public inProgressExportCSV: boolean = false;
	private totalExport: number = -1;
	private dataExport: RequestSummaryEntityResponseModel[];
	exportCsv() {
		this.inProgressExportCSV = true;
		this.dataExport = [];
		this.totalExport = -1;
		let body = this.fillBody(
			new QueryOptionsPagingModel(),
			this.stateService.getCurrentDateRange()
		);
		//body.filter.overallStatuses = ["Completed"];

		this.exportCsvRequest(body);
	}

	exportCsvRequest(body: any) {
		const limit = 10000;
		body.paging = {
			limit: limit,
			currentPage:
				this.totalExport < 0 ? 1 : Math.round(this.dataExport.length / limit) + 1,
		};
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryEntity(body)
				.subscribe((data) => {
					if (data.length > 0) {
						this.totalExport = 1;
						this.dataExport.push(...data);
					}
					if (this.dataExport.length >= this.totalExport) {
						this.inProgressExportCSV = false;
						if (this.dataExport.length > 0) {
							let headers = Object.getOwnPropertyNames(data[0]);
							if (this.whiteLabelService.getSettings.filterStoreLabel != "store") {
								// replace store with whitelabel setting in all headers
								headers = headers.map((header) =>
									header.replace("store", this.whiteLabelService.getSettings.filterStoreLabel)
								);
								headers = headers.map((header) =>
									header.replace("Store", this.whiteLabelService.capitalizeFirstLetter(this.whiteLabelService.getSettings.filterStoreLabel))
								);
							}
							if (this.whiteLabelService.getSettings.filterVendorLabel != "vendor") {
								// replace vendor with whitelabel setting in all headers
								headers = headers.map((header) =>
									header.replace("vendor", this.whiteLabelService.getSettings.filterVendorLabel)
								);
								headers = headers.map((header) =>
									header.replace("Vendor", this.whiteLabelService.capitalizeFirstLetter(this.whiteLabelService.getSettings.filterVendorLabel))
								);
							}
							let options = {
								fieldSeparator: ",",
								quoteStrings: '"',
								decimalseparator: ".",
								showLabels: true,
								headers: headers,
								showTitle: false,
								title: "export",
								useBom: true,
							};
							new AngularCsv(this.dataExport, "export", options);
						} else {
							this.messageService.info("CSV Export", "No data");
						}
					} else {
						this.exportCsvRequest(body);
					}
				})
		);
	}

	//#endregion

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
	faFileExport = faFileExport;
}
