<div class="card full-height-xl">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon> Top 5 Vendors and Stores
		</h2>
	</div>
	<div class="card-body p-0">
		<table class="table table-striped mb-0" [ngClass]="{'table-empty': !tableDataTop5Vendor.rows.length}">
			<thead>
				<tr>
					<th [class.text-end]="column.prop == 'count'" *ngFor="let column of tableDataTop5Vendor.columns">{{column.name}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of tableDataTop5Vendor.rows">
					<td [class.text-end]="column.prop == 'count'" *ngFor="let column of tableDataTop5Vendor.columns; let i = index">{{i == 1 ? (row[column.prop] == undefined ? '' : row[column.prop] | number) : (row[column.prop] == undefined ? '' : row[column.prop])}}</td>
				</tr>
				<tr *ngIf="!tableDataTop5Vendor.rows.length">
					<td colspan="2">No data to display</td>
				</tr>
			</tbody>
		</table>

		<table class="table table-striped mb-0" [ngClass]="{'table-empty': !tableDataTop5Store.rows.length}">
			<thead>
				<tr>
					<th [class.text-end]="column.prop == 'count'" *ngFor="let column of tableDataTop5Store.columns">{{column.name}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of tableDataTop5Store.rows">
					<td [class.text-end]="column.prop == 'count'" *ngFor="let column of tableDataTop5Store.columns; let i = index">{{i == 1 ? (row[column.prop] == undefined ? '' : row[column.prop] | number) : (row[column.prop] == undefined ? '' : row[column.prop])}}</td>
				</tr>
				<tr *ngIf="!tableDataTop5Store.rows.length">
					<td colspan="2">No data to display</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
