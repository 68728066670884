import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SettingsService } from "./settings.service";
import { Settings } from "../models/settings";
import { Title } from "@angular/platform-browser";

@Injectable({ providedIn: 'root' })
export class SettingsHttpService {

    constructor(private http: HttpClient, private settingsService: SettingsService, private titleService: Title) {
    }

    initializeApp(): Promise<any> {

        return new Promise(
            (resolve) => {
                this.http.get('api/Settings/GetSettings')
                    .toPromise()
                    .then(response => {
                        this.settingsService.settings = <Settings>response;
                        this.titleService.setTitle(this.settingsService.settings.name);
                        //too slow, theme selected in theme.js
                        //this.loadScript(this.settingsService.settings.whiteLabel + '.js');
                        resolve(null);
                    }
                    )
            }
        );
    }

    loadScript(scriptUrl) {
        var script = document.createElement('script');
        script.src = scriptUrl;
        script.type = "text/javascript";
        document.body.appendChild(script);
    }
}
