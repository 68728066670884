<div class="card has-export has-invisible-content mb-3">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> {{title}}
		</h2>
		<div class="export-btns">
			<button *ngIf="loaded" type="button" class="btn btn-secondary" style="min-width: 0;" (click)="goFullscreenChart(true)">
				<fa-icon [icon]="faExpandArrowsAlt"></fa-icon>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div [ngClass]="{'invisible': !loaded, 'chart-wrapper': true, 'fullscreen': isChartFullscreen}" class="chart-wrapper">
			<h4 class="fullscreen-title">{{title}}</h4>
			<div class="chart-container chart-container-custome-legend legend-right-fullscreen my-4 mx-3">
				<chart class="chart-container" #apaChart [type]="chartConfig.type" [data]="chartConfig.data" [options]="chartConfig.options"></chart>
				<div *ngIf="legendArray.length" class="legend">
					<div *ngFor="let group of legendArray">
						<div>{{group[0]}}</div>
						<div>{{group[1]}}</div>
						<ul>
							<li *ngFor="let item of group[2]">
								<span [style.background-color]="item[1]"></span>
								{{item[0]}}
								<br/>
								{{item[2]}}
							</li>
						</ul>
					</div>
				</div>
			</div>
			<span class="fullscreen-close" (click)="goFullscreenChart(false)"></span>
		</div>
		<div *ngIf="!loaded"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>

<div class="card has-export has-invisible-content mb-3">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon> {{title}}
		</h2>
		<div class="export-btns">
			<button *ngIf="loaded" type="button" class="btn btn-secondary" (click)="exportCsv()">
				<fa-icon [icon]="faFileExport"></fa-icon><span class="d-none d-md-inline ms-1"> CSV</span>
			</button>
		</div>
	</div>
	<div class="card-body p-0" style="min-height: 200px;">
		<div class="table-responsive set-max-height">
			<table class="table table-striped table-bordered table-head-bordered table-layout-fixed mb-0" [ngClass]="{'invisible': !loaded, 'table-empty': !tableData.rows.length}">
				<thead>
					<tr>
						<th *ngFor="let cell of tableData.headersGroup; let i = index" [attr.colspan]="cell[1]" class="text-end" [style.width]="i === 0 ? '60px' : ''">{{cell[0]}}</th>
					</tr>
					<tr>
						<th [class.text-end]="column !== 'Age'" *ngFor="let column of tableData.columns">{{column}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let row of tableData.rows">
						<td *ngFor="let column of tableData.columns; let i = index" [class]="row[i][1]">{{row[i][0]}}</td>
					</tr>
					<tr *ngIf="!tableData.rows.length">
						<td colspan="2">No data to display</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div *ngIf="!loaded"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
