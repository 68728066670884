<div class="card has-invisible-content">
	<div class="card-body">
		<div class="form-group-wrapper">
			<div class="form-group color-field">
				<label>Color</label>
				<div>
					<ngx-colors ngx-colors-trigger [(ngModel)]="model.color" class="color-field-box"></ngx-colors>
					<span>{{model.color}}</span>
				</div>
			</div>
			<div class="form-group">
				<label class="half-strong d-block">Show In Graph</label>
				<div class="btn-group btn-group-contrast btn-group-toggle" role="group">
					<input [name]="model.filterType + 1" type="radio" [(ngModel)]="model.showInGraph" [value]="true" class="btn-check" [id]="model.filterType + 1" autocomplete="off" >
					<label class="btn-dark btn mb-0" [for]="model.filterType + 1" [ngClass]="{'active': model.showInGraph === true}">Yes</label>
				   	
					<input [name]="model.filterType + 1" type="radio" [(ngModel)]="model.showInGraph" [value]="false" class="btn-check" [id]="model.filterType + 2" autocomplete="off" >
					<label class="btn-dark btn mb-0" [for]="model.filterType + 2" [ngClass]="{'active': model.showInGraph === false}">No</label>
				</div>

				<!-- 
				<div class="btn-group btn-group-contrast btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="model.showInGraph">
					<label ngbButtonLabel class="btn-dark btn mb-0">
						<input ngbButton type="radio" [value]="true" name="radioBasic"> Yes
					</label>
					<label ngbButtonLabel class="btn-dark btn mb-0">
						<input ngbButton type="radio" [value]="false" name="radioBasic"> No
					</label>
				</div>
				-->
			</div>
		</div>

		<div class="form-group" *ngIf="model.filterType === 'assetNames'"> 
			<label class="half-strong d-block">Date Range</label>
			<div class="date-range-month-year">
				<dp-date-picker #monthPicker1 [(ngModel)]="month1" (onChange)="onMonth1Changed($event)" [config]="datePicker1Config" [mode]="'month'" [theme]="'dp-material'"></dp-date-picker>
				<span>&#8212;</span>
				<dp-date-picker #monthPicker2 [(ngModel)]="month2" (onChange)="onMonth2Changed($event)" [config]="datePicker2Config" [mode]="'month'" [theme]="'dp-material'" opens="left"></dp-date-picker>
			</div>
		</div>

		<div class="form-group" *ngIf="model.filterType !== 'assetNames' && model.filterType !== 'stores'">
			<label class="half-strong">Select filter type</label>
			<ng-select [clearable]="false" [appendTo]="'body'" [(ngModel)]="model.filterType" (change)="onChangeFilterType()" class="dropdown-select">
				<ng-option *ngIf="!whiteLabelService.getSettings.assetPerformanceBenchmarkHideRetailBusinessManager" [value]="'retailBusinessManagers'">{{whiteLabelService.getSettings.filterRetailBusinessManagerLabel.toUpperCase()}}</ng-option>
				<ng-option *ngIf="!whiteLabelService.getSettings.assetPerformanceBenchmarkHideStoreProfile" [value]="'storeProfiles'">STORE PROFILE</ng-option>
				<ng-option *ngIf="!whiteLabelService.getSettings.assetPerformanceBenchmarkHideStoreType" [value]="'storeTypes'">{{whiteLabelService.getSettings.filterStoreTypeLabel.toUpperCase()}}</ng-option>
				<ng-option *ngIf="!whiteLabelService.getSettings.assetPerformanceBenchmarkHideCategory" [value]="'categories'">CATEGORY</ng-option>
			 </ng-select>
		</div>

		<!-- Series 1 -->
		<div class="form-group mb-0" *ngIf="model.filterType === 'assetNames'">
			<label class="half-strong">Select asset name</label>
			<ng-select [dropdownPosition]="'bottom'" [appendTo]="'body'" [items]="woFiltersService.keyAssetNames" [(ngModel)]="singleValue" (change)="onChangeSingleValue()" placeholder="Select asset name" class="dropdown-select">
			</ng-select>
		</div>
		<!-- Series 2 -->
		<!-- squad -->
		<div class="form-group mb-0" *ngIf="model.filterType === 'retailBusinessManagers'">
			<label class="half-strong">Select {{whiteLabelService.getSettings.filterRetailBusinessManagerLabel}}</label>
			<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [appendTo]="'body'" [items]="woFiltersService.retailBusinessManagers" [(ngModel)]="model.filterValues" placeholder="Select {{whiteLabelService.getSettings.filterRetailBusinessManagerLabel}}" class="dropdown-select">
			</ng-select>
		</div>
		<!-- fuel/non fuel -->
		<div class="form-group mb-0" *ngIf="model.filterType === 'storeProfiles'">
			<label class="half-strong">Select store profile</label>
			<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [appendTo]="'body'" [items]="woFiltersService.storeProfile" [(ngModel)]="model.filterValues" placeholder="Select store profile" class="dropdown-select">
			</ng-select>
		</div>
		<!-- storeTypes -->
		<div class="form-group mb-0" *ngIf="model.filterType === 'storeTypes'">
			<label class="half-strong">Select {{whiteLabelService.getSettings.filterStoreTypeLabel}}</label>
			<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [appendTo]="'body'" [items]="whiteLabelService.getSettings.filterStoreTypeLabel === 'facility type' ? woFiltersService.storeType : woFiltersService.storeCluster" [(ngModel)]="model.filterValues" placeholder="Select {{whiteLabelService.getSettings.filterStoreTypeLabel}}" class="dropdown-select">
			</ng-select>
		</div>
		<!-- categories -->
		<div class="form-group mb-0" *ngIf="model.filterType === 'categories'">
			<label class="half-strong">Select category</label>
			<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [appendTo]="'body'" [items]="woFiltersService.categories" [(ngModel)]="model.filterValues" placeholder="Select category" class="dropdown-select">
			</ng-select>
		</div>
		<!-- Series 3 -->
		<div class="form-group mb-0" *ngIf="model.filterType === 'stores'">
			<label class="half-strong">Select {{whiteLabelService.getSettings.filterStoreLabel}}</label>
			<ng-select [dropdownPosition]="'bottom'" [appendTo]="'body'" [items]="woFiltersService.stores" bindLabel="name" [(ngModel)]="singleValue" (change)="onChangeSingleValue()" placeholder="Select {{whiteLabelService.getSettings.filterStoreLabel}}" class="dropdown-select">
			</ng-select>
		</div>
	</div>
</div>
