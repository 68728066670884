import { Injectable, IterableDiffer, IterableDiffers } from "@angular/core";
import { Guid } from "guid-typescript";
import * as moment from "moment";
import {
	DateRangeUtilService,
	IMyDate,
	IMyDateRangeModel,
} from "mydaterangepicker";
import { BehaviorSubject, Observable } from "rxjs";
import { DropdownItem } from "../../models/dropdownItem";
import { AuthService } from "../../services/auth.service";
import { HelperService } from "../../services/helper.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { Filter } from "../models/filter";
import { NameModel } from "../models/nameModel";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WorkOrderReportService } from "./work-order-report.service";

@Injectable({
	providedIn: "root",
})
export class WOFiltersService {
	public isCollapsed = true;

	private dateRangeEst = new BehaviorSubject<IMyDateRangeModel>(null);
	private isDateRangeEstChanged = false;
	get getDateRangeEst(): Observable<IMyDateRangeModel> {
		return this.dateRangeEst.asObservable();
	}
	getCurrentDateRangeEst(): IMyDateRangeModel {
		return this.dateRangeEst.value;
	}
	public setDateRangeEst(value: IMyDateRangeModel) {
		this.dateRangeEst.next(value);
		this.isDateRangeEstChanged = true;
	}

	private dateRangeEstUpdateId = new BehaviorSubject<string>("");
	public dateRangeEstUpdateOperation: string = "";
	public dateRangeEstUpdateBeginDate: Date | null = null;
	public dateRangeEstUpdateEndDate: Date | null = null;
	get getDateRangeEstUpdateId(): Observable<string> {
		return this.dateRangeEstUpdateId.asObservable();
	}
	updateDateRangeEst(
		operation: string,
		beginDate: Date | null,
		endDate: Date | null,
		setDateRange: boolean = false
	) {
		if (setDateRange) {
			if (operation === "null") {
				this.setDateRangeEst(null);
			} else {
				let drm = this.getDateRangeModel(
					{
						year: beginDate.getFullYear(),
						month: beginDate.getMonth() + 1,
						day: beginDate.getDate(),
					},
					{
						year: endDate.getFullYear(),
						month: endDate.getMonth() + 1,
						day: endDate.getDate(),
					}
				);
				this.setDateRangeEst(drm);
			}
		}
		this.dateRangeEstUpdateOperation = operation;
		this.dateRangeEstUpdateBeginDate = beginDate;
		this.dateRangeEstUpdateEndDate = endDate;
		let guid = Guid.create().toString();
		this.dateRangeEstUpdateId.next(guid);
	}
	getDateRangeModel(beginDate: IMyDate, endDate: IMyDate): IMyDateRangeModel {
		// Creates a date range model object from the given parameters
		let bEpoc: number = this.drus.getTimeInMilliseconds(beginDate) / 1000.0;
		let eEpoc: number = this.drus.getTimeInMilliseconds(endDate) / 1000.0;
		return {
			beginDate: beginDate,
			beginJsDate: this.getDate(beginDate),
			endDate: endDate,
			endJsDate: this.getDate(endDate),
			formatted: "",
			beginEpoc: bEpoc,
			endEpoc: eEpoc,
		};
	}
	getDate(date: IMyDate): Date {
		return new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0);
	}

	iterableDifferVendor: IterableDiffer<any>;
	vendors: DropdownItem[];
	selectedVendor: DropdownItem[];
	getVendors(reload?: boolean): void {
		if (reload || !this.vendors || this.vendors.length === 0) {
			this.workOrderReportService.getVendors().subscribe((data) => {
				this.vendors = data;
			});
		}
	}

	iterableDifferVendorNames: IterableDiffer<any>;
	vendorNames: NameModel[];
	selectedVendorNames: NameModel[];
	getVendorNames(reload?: boolean): void {
		if (reload || !this.vendorNames || this.vendorNames.length === 0) {
			this.workOrderReportService.getVendorNames().subscribe((data) => {
				this.vendorNames = data;
			});
		}
	}

	iterableDifferState: IterableDiffer<any>;
	iterableDifferStateFilter: IterableDiffer<any>;
	states: string[];
	selectedState: string[];
	getStates(reload?: boolean): void {
		if (reload || !this.states || this.states.length === 0) {
			this.workOrderReportService.getStates().subscribe((data) => {
				this.states = data;
			});
		}
	}

	iterableDifferStore: IterableDiffer<any>;
	stores: DropdownItem[];
	selectedStore: DropdownItem[];
	getStores(reload?: boolean): void {
		if (reload || !this.stores || this.stores.length === 0) {
			this.workOrderReportService
				.getStores(
					this.selectedState,
					this.selectedBrand,
					this.selectedRetailBusinessManager
				)
				.subscribe((data) => {
					this.stores = data;
				});
		}
	}

	iterableDifferAssetName: IterableDiffer<any>;
	assetNames: string[];
	selectedAssetName: string[];
	getAssetNames(reload?: boolean): void {
		if (reload || !this.assetNames || this.assetNames.length === 0) {
			this.workOrderReportService.getAssetNames().subscribe((data) => {
				this.assetNames = data;
			});
		}
	}
	assetNamesEnabled: boolean = true;
	assetNamesEnable(value: boolean) {
		this.assetNamesEnabled = value;
		this.selectedAssetName = [];
	}

	iterableDifferBrand: IterableDiffer<any>;
	iterableDifferBrandFilter: IterableDiffer<any>;
	brands: string[];
	selectedBrand: string[];
	getBrands(reload?: boolean): void {
		if (!this.whiteLabelService.getSettings.enableFilterBrand) return;
		if (reload || !this.brands || this.brands.length === 0) {
			this.workOrderReportService.getBrands().subscribe((data) => {
				this.brands = data;
			});
		}
	}
	brandsEnabled: boolean = true;
	brandsEnable(value: boolean) {
		this.brandsEnabled = value;
		this.selectedBrand = [];
	}

	iterableDifferDistrict: IterableDiffer<any>;
	districts: string[];
	selectedDistrict: string[];
	getDistricts(reload?: boolean): void {
		if (!this.whiteLabelService.getSettings.enableFilterDistrict) return;
		if (reload || !this.districts || this.districts.length === 0) {
			this.workOrderReportService.getDistricts().subscribe((data) => {
				this.districts = data;
			});
		}
	}
	districtsEnabled: boolean = true;
	districtsEnable(value: boolean) {
		this.districtsEnabled = value;
		this.selectedDistrict = [];
	}

	iterableDifferCategory: IterableDiffer<any>;
	categories: string[];
	selectedCategory: string[];
	getCategories(reload?: boolean): void {
		if (!this.whiteLabelService.getSettings.enableFilterCategory) return;
		if (reload || !this.categories || this.categories.length === 0) {
			this.workOrderReportService.getCategories().subscribe((data) => {
				this.categories = data;
			});
		}
	}
	categoriesEnabled: boolean = true;
	categoriesEnable(value: boolean) {
		this.categoriesEnabled = value;
		this.selectedCategory = [];
	}

	iterableDifferCategoryManager: IterableDiffer<any>;
	categoryManagers: string[];
	selectedCategoryManager: string[];
	getCategoryManagers(reload?: boolean): void {
		if (!this.whiteLabelService.getSettings.enableFilterCategoryManager) return;
		if (reload || !this.categoryManagers || this.categoryManagers.length === 0) {
			this.workOrderReportService.getCategoryManagers().subscribe((data) => {
				this.categoryManagers = data;
			});
		}
	}
	categoryManagersEnabled: boolean = true;
	categoryManagersEnable(value: boolean) {
		this.categoryManagersEnabled = value;
		this.selectedCategoryManager = [];
	}

	iterableDifferKeyAssetName: IterableDiffer<any>;
	keyAssetNames: string[];
	selectedKeyAssetName: string[];
	getKeyAssetNames(reload?: boolean): void {
		if (reload || !this.keyAssetNames || this.keyAssetNames.length === 0) {
			this.workOrderReportService.getKeyAssetNames().subscribe((data) => {
				this.keyAssetNames = data;
			});
		}
	}

	iterableDifferRequestType: IterableDiffer<any>;
	requestTypes: string[];
	selectedRequestType: string[];
	getRequestType(reload?: boolean): void {
		if (reload || !this.requestTypes || this.requestTypes.length === 0) {
			this.requestTypes = this.workOrderReportService.getRequestTypes();
		}
	}

	iterableDifferPriority: IterableDiffer<any>;
	priorities: string[];
	selectedPriority: string[];
	getPriority(reload?: boolean): void {
		if (reload || !this.priorities || this.priorities.length === 0) {
			this.workOrderReportService.getPriorities().subscribe((data) => {
				this.priorities = data;
			});
		}
	}

	iterableDifferGLCostTypes: IterableDiffer<any>;
	glCostTypes: string[];
	selectedGLCostTypes: string[];
	getGLCostTypes(reload?: boolean): void {
		if (reload || !this.glCostTypes || this.glCostTypes.length === 0) {
			this.workOrderReportService.getGLCostTypes().subscribe((data) => {
				this.glCostTypes = data;
			});
		}
	}

	iterableDifferGLDescriptions: IterableDiffer<any>;
	glDescriptions: string[] = [];
	selectedGLDescriptions: string[];
	getGLDescriptions(reload?: boolean): void {
		if (!this.whiteLabelService.getSettings.enableFilterGLDescription) return;
		if (reload || !this.glDescriptions || this.glDescriptions.length === 0) {
			this.workOrderReportService.getGLDescriptions().subscribe((data) => {
				this.glDescriptions = data;
				// select FM by default
				if (this.glDescriptions.indexOf("FM") > -1) {
					this.selectedGLDescriptions = ["FM"];
				}
			});
		}
	}

	iterableDifferGLStatuses: IterableDiffer<any>;
	glStatuses: string[];
	selectedGLStatuses: string[];
	getGLStatuses(reload?: boolean): void {
		if (reload || !this.glStatuses || this.glStatuses.length === 0) {
			this.glStatuses = this.workOrderReportService.getGLStatuses();
		}
	}

	iterableDifferRegionalManagers: IterableDiffer<any>;
	regionalManagers: string[] = [];
	selectedRegionalManagers: string[];
	getRegionalManagers(reload?: boolean): void {
		if (reload || !this.regionalManagers || this.regionalManagers.length === 0) {
			this.workOrderReportService.getRegionalManagers().subscribe((data) => {
				this.regionalManagers = data;
			});
		}
	}

	iterableDifferRetailBusinessManager: IterableDiffer<any>;
	iterableDifferRetailBusinessManagerFilter: IterableDiffer<any>;
	retailBusinessManagers: string[] = [];
	selectedRetailBusinessManager: string[];
	getRetailBusinessManagers(reload?: boolean): void {
		if (
			reload ||
			!this.retailBusinessManagers ||
			this.retailBusinessManagers.length === 0
		) {
			this.workOrderReportService
				.getRetailBusinessManagers(this.selectedState, this.selectedBrand)
				.subscribe((data) => {
					this.retailBusinessManagers = data;
				});
		}
	}

	iterableDifferDistrictManagers: IterableDiffer<any>;
	districtManagers: string[] = [];
	selectedDistrictManagers: string[];
	getDistrictManagers(reload?: boolean): void {
		if (reload || !this.districtManagers || this.districtManagers.length === 0) {
			this.workOrderReportService.getDMs().subscribe((data) => {
				this.districtManagers = data;
			});
		}
	}

	iterableDifferStoreType: IterableDiffer<any>;
	storeType: string[] = [];
	selectedStoreType: string[];
	getStoreType(reload?: boolean): void {
		if (reload || !this.storeType || this.storeType.length === 0) {
			this.workOrderReportService.getStoreType().subscribe((data) => {
				this.storeType = data;
			});
		}
	}

	iterableDifferStoreProfile: IterableDiffer<any>;
	storeProfile: string[] = [];
	selectedStoreProfile: string[];
	getStoreProfile(reload?: boolean): void {
		if (reload || !this.storeProfile || this.storeProfile.length === 0) {
			this.workOrderReportService.getStoreProfile().subscribe((data) => {
				this.storeProfile = data;
			});
		}
	}

	iterableDifferStoreCluster: IterableDiffer<any>;
	storeCluster: string[] = [];
	selectedStoreCluster: string[];
	getStoreCluster(reload?: boolean): void {
		if (reload || !this.storeCluster || this.storeCluster.length === 0) {
			this.workOrderReportService.getStoreCluster().subscribe((data) => {
				this.storeCluster = data;
			});
		}
	}

	iterableDifferServiceDeliveryManager: IterableDiffer<any>;
	serviceDeliveryManager: string[] = [];
	selectedServiceDeliveryManager: string[];
	getServiceDeliveryManager(reload?: boolean): void {
		if (
			reload ||
			!this.serviceDeliveryManager ||
			this.serviceDeliveryManager.length === 0
		) {
			this.workOrderReportService
				.getServiceDeliveryManagers()
				.subscribe((data) => {
					this.serviceDeliveryManager = data;
				});
		}
	}

	iterableDifferDeliveryIndicators: IterableDiffer<any>;
	deliveryIndicators: string[] = [];
	selectedDeliveryIndicators: string[];
	getDeliveryIndicators(reload?: boolean): void {
		if (
			reload ||
			!this.deliveryIndicators ||
			this.deliveryIndicators.length === 0
		) {
			this.deliveryIndicators =
				this.workOrderReportService.getDeliveryIndicators();
		}
	}

	iterableDifferStatuses: IterableDiffer<any>;
	statuses: string[] = [];
	selectedStatuses: string[];
	getStatuses(reload?: boolean): void {
		if (reload || !this.statuses || this.statuses.length === 0) {
			this.workOrderReportService.getStatuses().subscribe((data) => {
				this.statuses = data;
			});
		}
	}

	years: string[] = [];
	selectedYear: string;
	oldYear: string;
	getYears(reload?: boolean) {
		let weekFilterOffsetMonthes =
			this.whiteLabelService.getWeekFilterOffsetMonthes();
		let m = moment.utc();
		let years: string[] = [];
		let currentYear = m.year();
		let currentMonth = m.month();
		if (currentMonth <= weekFilterOffsetMonthes) {
			currentYear -= 1;
		}
		const isFY = this.whiteLabelService.isStarbucks();
		for (let i = 2018; i <= currentYear; i++) {
			years.push(isFY ? this.stateService.yearToFY(i) : i.toString());
		}
		this.years = years;
		this.selectedYear = years[years.length - 1];

		this.getWeeks();
	}
	onChangeSelectedYear($event) {
		this.getWeeks();
	}

	twoDigitsToString(n) {
		return n > 9 ? "" + n : "0" + n;
	}
	weeks: string[] = [];
	selectedWeek: string;
	oldWeek: string;
	fyWeekToWeekMap: any;
	fyWeekToWeek(fy: number, fyWeek: number): string {
		let temp = fy + "-" + this.twoDigitsToString(fyWeek);
		let week = this.fyWeekToWeekMap[temp];
		if (week) {
			return week;
		} else {
			return temp;
		}
	}
	public weekToFYWeek(year: number, week: number): string {
		let weekFilterOffsetMonthes =
			this.whiteLabelService.getWeekFilterOffsetMonthes();
		if (weekFilterOffsetMonthes === 0) {
			return year + "-" + this.twoDigitsToString(week);
		}
		let thisWeekEnd = moment
			.utc(year + "-01-01")
			.week(week)
			.isoWeekday(7);
		let month = thisWeekEnd.year() > year ? 12 : thisWeekEnd.month() + 1; // js month is 0 based
		let fy = year;
		if (month <= weekFilterOffsetMonthes) {
			// previous FY
			fy -= 1;
		}
		let currentDayofYear = moment
			.utc(fy + "-01-01")
			.add(weekFilterOffsetMonthes, "months")
			.isoWeekday(7);
		let lastDayOfYear = moment
			.utc(fy + "-12-31")
			.add(weekFilterOffsetMonthes, "months");
		let index = 1;
		let resultWeek = 0;
		while (lastDayOfYear >= currentDayofYear && thisWeekEnd >= currentDayofYear) {
			resultWeek = index;
			currentDayofYear = currentDayofYear.add(1, "weeks");
			index++;
		}
		if (resultWeek > 0) {
			return fy + "-" + this.twoDigitsToString(resultWeek);
		} else {
			return year + "-" + this.twoDigitsToString(week);
		}
	}
	getWeeks(reload?: boolean) {
		let weekFilterOffsetMonthes =
			this.whiteLabelService.getWeekFilterOffsetMonthes();
		let year = parseInt(this.selectedYear.substr(0, 4));
		let thisWeekEnd = moment.utc().isoWeekday(7);
		let currentDayofYear = moment
			.utc(year + "-01-01")
			.add(weekFilterOffsetMonthes, "months")
			.isoWeekday(7);
		let lastDayOfYear = moment
			.utc(year + "-12-31")
			.add(weekFilterOffsetMonthes, "months");
		let index = 1;
		this.fyWeekToWeekMap = {};
		let weeks: string[] = [];
		while (lastDayOfYear >= currentDayofYear && thisWeekEnd >= currentDayofYear) {
			weeks.push(index.toString());
			this.fyWeekToWeekMap[year + "-" + this.twoDigitsToString(index)] =
				currentDayofYear.year().toString() +
				"-" +
				this.twoDigitsToString(currentDayofYear.isoWeek());
			currentDayofYear = currentDayofYear.add(1, "weeks");
			index++;
		}
		weeks.reverse();
		this.weeks = weeks;
		if (this.selectedWeek && weeks.indexOf(this.selectedWeek) >= 0) {
			// keep same week
		} else {
			this.selectedWeek = weeks[0];
		}
	}

	loadData(reload?: boolean): void {
		this.getVendors(reload);
		this.getVendorNames(reload);
		this.getStates(reload);
		this.getStores(reload);
		this.getAssetNames(reload);
		this.getBrands(reload);
		this.getDistricts(reload);
		this.getCategories(reload);
		this.getCategoryManagers(reload);
		this.getKeyAssetNames(reload);
		this.getRequestType(reload);
		this.getPriority(reload);
		this.getGLCostTypes(reload);
		this.getGLDescriptions(reload);
		this.getGLStatuses(reload);
		this.getRetailBusinessManagers(reload);
		this.getDistrictManagers(reload);
		this.getRegionalManagers(reload);
		this.getStoreType(reload);
		this.getStoreProfile(reload);
		this.getStoreCluster(reload);
		this.getServiceDeliveryManager(reload);
		this.getDeliveryIndicators(reload);
		this.getYears(reload);
		this.getStatuses(reload);
	}

	shouldOpenFilters(): boolean {
		let shouldOpenFilters = false;
		if (this.selectedVendor && this.selectedVendor.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedVendorNames && this.selectedVendorNames.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedState && this.selectedState.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedStore && this.selectedStore.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedAssetName && this.selectedAssetName.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedBrand && this.selectedBrand.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedDistrict && this.selectedDistrict.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedCategory && this.selectedCategory.length > 0) {
			shouldOpenFilters = true;
		} else if (
			this.selectedCategoryManager &&
			this.selectedCategoryManager.length > 0
		) {
			shouldOpenFilters = true;
		} else if (
			this.selectedKeyAssetName &&
			this.selectedKeyAssetName.length > 0
		) {
			shouldOpenFilters = true;
		} else if (this.selectedRequestType && this.selectedRequestType.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedPriority && this.selectedPriority.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedGLCostTypes && this.selectedGLCostTypes.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedGLDescriptions && this.selectedGLDescriptions.length > 0) {
			shouldOpenFilters = true;
		} else if (this.selectedGLStatuses && this.selectedGLStatuses.length > 0) {
			shouldOpenFilters = true;
		} else if (
			this.selectedRetailBusinessManager &&
			this.selectedRetailBusinessManager.length > 0
		) {
			shouldOpenFilters = true;
		} else if (
			this.selectedDistrictManagers &&
			this.selectedDistrictManagers.length > 0
		) {
			shouldOpenFilters = true;
		} else if (this.selectedCategory && this.selectedCategory.length > 0) {
			shouldOpenFilters = true;
		} else if (
			this.selectedCategoryManager &&
			this.selectedCategoryManager.length > 0
		) {
			shouldOpenFilters = true;
		} else if (
			this.selectedRegionalManagers &&
			this.selectedRegionalManagers.length > 0
		) {
			shouldOpenFilters = true;
		} else if (this.selectedStoreType && this.selectedStoreType.length > 0) {
			shouldOpenFilters = true;
		} else if (
			this.selectedStoreProfile &&
			this.selectedStoreProfile.length > 0
		) {
			shouldOpenFilters = true;
		} else if (
			this.selectedStoreCluster &&
			this.selectedStoreCluster.length > 0
		) {
			shouldOpenFilters = true;
		} else if (
			this.selectedServiceDeliveryManager &&
			this.selectedServiceDeliveryManager.length > 0
		) {
			shouldOpenFilters = true;
		} else if (
			this.selectedDeliveryIndicators &&
			this.selectedDeliveryIndicators.length > 0
		) {
			shouldOpenFilters = true;
		} else if (this.selectedStatuses && this.selectedStatuses.length > 0) {
			shouldOpenFilters = true;
		} else {
			let current = this.getCurrentDateRangeEst();
			if (
				current !== null &&
				current.beginDate != null &&
				current.endDate != null &&
				current.beginDate.year > 0
			) {
				shouldOpenFilters = true;
			}
		}
		return shouldOpenFilters;
	}

	fillBodyEndDateThisMonth(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		estCompletedDt: boolean = false
	): QueryOptionsModel | QueryOptionsPagingModel {
		if (
			(!estCompletedDt && !body.filter.toDt) ||
			(estCompletedDt && !body.filter.toEstCompletedDt)
		) {
			const lastDay = this.helperService.lastDayInMonth;
			const month = this.helperService.numberToTwoDigitString(
				lastDay.getMonth() + 1
			);
			const day = this.helperService.numberToTwoDigitString(lastDay.getDate());
			const dateStr = `${lastDay.getFullYear()}-${month}-${day}T00:00:00.000Z`;
			if (estCompletedDt) {
				body.filter.fromEstCompletedDt = null;
				body.filter.toEstCompletedDt = dateStr;
			} else {
				body.filter.fromDt = null;
				body.filter.toDt = dateStr;
			}
		}
		return body;
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body.filter = new Filter();
		if (
			dateRange !== null &&
			dateRange.beginDate != null &&
			dateRange.endDate != null &&
			dateRange.beginDate.year > 0
		) {
			let month1 = this.helperService.numberToTwoDigitString(
				dateRange.beginDate.month
			);
			let month2 = this.helperService.numberToTwoDigitString(
				dateRange.endDate.month
			);
			let day1 = this.helperService.numberToTwoDigitString(
				dateRange.beginDate.day
			);
			let day2 = this.helperService.numberToTwoDigitString(dateRange.endDate.day);
			body.filter.fromDt = `${dateRange.beginDate.year}-${month1}-${day1}T00:00:00.000Z`;
			body.filter.toDt = `${dateRange.endDate.year}-${month2}-${day2}T00:00:00.000Z`;
		}
		let dateRangeEst = this.getCurrentDateRangeEst();
		if (
			dateRangeEst !== null &&
			dateRangeEst.beginDate != null &&
			dateRangeEst.endDate != null &&
			dateRangeEst.beginDate.year > 0
		) {
			let month1 = this.helperService.numberToTwoDigitString(
				dateRangeEst.beginDate.month
			);
			let month2 = this.helperService.numberToTwoDigitString(
				dateRangeEst.endDate.month
			);
			let day1 = this.helperService.numberToTwoDigitString(
				dateRangeEst.beginDate.day
			);
			let day2 = this.helperService.numberToTwoDigitString(
				dateRangeEst.endDate.day
			);
			body.filter.fromEstCompletedDt = `${dateRangeEst.beginDate.year}-${month1}-${day1}T00:00:00.000Z`;
			body.filter.toEstCompletedDt = `${dateRangeEst.endDate.year}-${month2}-${day2}T00:00:00.000Z`;
		}
		if (this.selectedVendor && this.selectedVendor.length > 0) {
			body.filter.vendorIds = this.selectedVendor.map((item) => item.id);
		}
		if (this.selectedVendorNames && this.selectedVendorNames.length > 0) {
			body.filter.vendorNames = this.selectedVendorNames.map((item) => item.name);
		}
		if (this.selectedState && this.selectedState.length > 0) {
			body.filter.states = this.selectedState;
		}
		if (this.selectedStore && this.selectedStore.length > 0) {
			body.filter.storeIds = this.selectedStore.map((item) => item.id);
		}
		if (this.selectedAssetName && this.selectedAssetName.length > 0) {
			body.filter.assetNames = this.selectedAssetName;
		}
		if (this.selectedBrand && this.selectedBrand.length > 0) {
			body.filter.brands = this.selectedBrand;
		}
		if (this.selectedDistrict && this.selectedDistrict.length > 0) {
			body.filter.districts = this.selectedDistrict;
		}
		if (this.selectedCategory && this.selectedCategory.length > 0) {
			body.filter.categories = this.selectedCategory;
		}
		if (this.selectedCategoryManager && this.selectedCategoryManager.length > 0) {
			body.filter.categoryManagers = this.selectedCategoryManager;
		}
		if (this.selectedKeyAssetName && this.selectedKeyAssetName.length > 0) {
			body.filter.assetNames = this.selectedKeyAssetName;
		}
		if (this.selectedRequestType && this.selectedRequestType.length > 0) {
			body.filter.requestTypes = this.selectedRequestType;
		}
		if (this.selectedPriority && this.selectedPriority.length > 0) {
			body.filter.priorities = this.selectedPriority;
		}
		if (this.selectedGLCostTypes && this.selectedGLCostTypes.length > 0) {
			body.filter.glCostTypes = this.selectedGLCostTypes;
		}
		if (this.selectedGLDescriptions && this.selectedGLDescriptions.length > 0) {
			body.filter.glDescriptions = this.selectedGLDescriptions;
		}
		if (this.selectedGLStatuses && this.selectedGLStatuses.length > 0) {
			body.filter.glStatuses = this.selectedGLStatuses;
		}
		if (
			this.selectedRetailBusinessManager &&
			this.selectedRetailBusinessManager.length > 0
		) {
			body.filter.retailBusinessManagers = this.selectedRetailBusinessManager;
		}
		if (
			this.selectedDistrictManagers &&
			this.selectedDistrictManagers.length > 0
		) {
			body.filter.districtManagers = this.selectedDistrictManagers;
		}
		if (
			this.selectedRegionalManagers &&
			this.selectedRegionalManagers.length > 0
		) {
			body.filter.regionalManagers = this.selectedRegionalManagers;
		}
		if (this.selectedStoreType && this.selectedStoreType.length > 0) {
			body.filter.storeTypes = this.selectedStoreType;
		}
		if (this.selectedStoreProfile && this.selectedStoreProfile.length > 0) {
			body.filter.storeProfiles = this.selectedStoreProfile;
		}
		if (this.selectedStoreCluster && this.selectedStoreCluster.length > 0) {
			body.filter.storeClusters = this.selectedStoreCluster;
		}
		if (
			this.selectedServiceDeliveryManager &&
			this.selectedServiceDeliveryManager.length > 0
		) {
			body.filter.serviceDeliveryManagers = this.selectedServiceDeliveryManager;
		}
		if (
			this.selectedDeliveryIndicators &&
			this.selectedDeliveryIndicators.length > 0
		) {
			body.filter.deliveryIndicators = this.selectedDeliveryIndicators;
		}
		if (this.selectedStatuses && this.selectedStatuses.length > 0) {
			body.filter.statuses = this.selectedStatuses;
		}
		return body;
	}

	getDateFromWeeklyStatus(): moment.Moment {
		const yearTmp = parseInt(this.selectedYear.substr(0, 4));
		const weekTmp = parseInt(this.selectedWeek);
		if (yearTmp && weekTmp) {
			let actualYearWeek = this.fyWeekToWeek(yearTmp, weekTmp);
			let temp = actualYearWeek.split("-");
			let year = parseInt(temp[0]);
			let week = parseInt(temp[1]);
			let m = moment
				.utc()
				.isoWeekYear(year)
				.isoWeekday(1)
				.isoWeek(week)
				.hours(0)
				.minutes(0)
				.seconds(0)
				.milliseconds(0);
			return m;
		} else {
			return moment("");
		}
	}

	fillBodyWeeklyStatus(body: QueryOptionsModel): QueryOptionsModel {
		body.filter = new Filter();
		body.filter.overallStatuses = ["Open", "Completed"];
		body.filter.requestTypes = ["Reactive", "Incident"];
		if (this.selectedYear && this.selectedWeek) {
			var m = this.getDateFromWeeklyStatus();
			if (m.isValid()) {
				body.filter.fromDt = m.toDate().toISOString();
				body.filter.toDt = m
					.add(7, "days")
					.subtract(1, "days")
					.toDate()
					.toISOString();
			}
		}
		if (this.selectedState && this.selectedState.length > 0) {
			body.filter.states = this.selectedState;
		}
		return body;
	}

	private isChangedEnabled: boolean = true;

	resetFilters() {
		this.isChangedEnabled = false;

		this.selectedVendor = [];
		this.selectedVendorNames = [];
		this.selectedState = [];
		this.selectedStore = [];
		this.selectedAssetName = [];
		this.selectedBrand = [];
		this.selectedDistrict = [];
		this.selectedCategory = [];
		this.selectedCategoryManager = [];
		this.selectedKeyAssetName = [];
		this.selectedRequestType = [];
		this.selectedPriority = [];
		this.selectedGLCostTypes = [];
		this.selectedGLDescriptions = [];
		this.selectedGLStatuses = [];
		this.selectedRetailBusinessManager = [];
		this.selectedDistrictManagers = [];
		this.selectedRegionalManagers = [];
		this.selectedStoreType = [];
		this.selectedStoreProfile = [];
		this.selectedStoreCluster = [];
		this.selectedServiceDeliveryManager = [];
		this.selectedDeliveryIndicators = [];
		this.selectedStatuses = [];
		this.setDateRangeEst(null);

		setTimeout(() => {
			this.isChangedEnabled = true;
		});
	}

	clearDataAndFilters() {
		this.vendors = [];
		this.vendorNames = [];
		this.states = [];
		this.stores = [];
		this.assetNames = [];
		this.brands = [];
		this.districts = [];
		this.categories = [];
		this.categoryManagers = [];
		this.keyAssetNames = [];
		this.requestTypes = [];
		this.priorities = [];
		this.retailBusinessManagers = [];
		this.districtManagers = [];
		this.regionalManagers = [];
		this.storeType = [];
		this.storeProfile = [];
		this.storeCluster = [];
		this.selectedServiceDeliveryManager = [];
		this.deliveryIndicators = [];
		this.statuses = [];
		this.resetFilters();
	}

	isFilterChanged(): boolean {
		let changes = this.iterableDifferStateFilter.diff(this.selectedState);
		changes = this.iterableDifferBrandFilter.diff(this.selectedBrand) || changes;
		changes =
			this.iterableDifferRetailBusinessManagerFilter.diff(
				this.selectedRetailBusinessManager
			) || changes;
		return changes !== null;
	}

	isChanged(): boolean {
		//console.log("isChanged");
		let changes: any = this.iterableDifferVendor.diff(this.selectedVendor);
		changes =
			this.iterableDifferVendorNames.diff(this.selectedVendorNames) || changes;
		changes = this.iterableDifferState.diff(this.selectedState) || changes;
		changes = this.iterableDifferStore.diff(this.selectedStore) || changes;
		changes =
			this.iterableDifferAssetName.diff(this.selectedAssetName) || changes;
		changes = this.iterableDifferBrand.diff(this.selectedBrand) || changes;
		changes = this.iterableDifferDistrict.diff(this.selectedDistrict) || changes;
		changes = this.iterableDifferCategory.diff(this.selectedCategory) || changes;
		changes =
			this.iterableDifferCategoryManager.diff(this.selectedCategoryManager) ||
			changes;
		changes =
			this.iterableDifferKeyAssetName.diff(this.selectedKeyAssetName) || changes;
		changes =
			this.iterableDifferRequestType.diff(this.selectedRequestType) || changes;
		changes = this.iterableDifferPriority.diff(this.selectedPriority) || changes;
		changes =
			this.iterableDifferGLCostTypes.diff(this.selectedGLCostTypes) || changes;
		changes =
			this.iterableDifferGLDescriptions.diff(this.selectedGLDescriptions) || changes;
		changes =
			this.iterableDifferGLStatuses.diff(this.selectedGLStatuses) || changes;
		changes =
			this.iterableDifferRetailBusinessManager.diff(
				this.selectedRetailBusinessManager
			) || changes;
		changes =
			this.iterableDifferDistrictManagers.diff(this.selectedDistrictManagers) ||
			changes;
		changes =
			this.iterableDifferRegionalManagers.diff(this.selectedRegionalManagers) ||
			changes;
		changes =
			this.iterableDifferStoreType.diff(this.selectedStoreType) || changes;
		changes =
			this.iterableDifferStoreProfile.diff(this.selectedStoreProfile) || changes;
		changes =
			this.iterableDifferStoreCluster.diff(this.selectedStoreCluster) || changes;
		changes =
			this.iterableDifferServiceDeliveryManager.diff(
				this.selectedServiceDeliveryManager
			) || changes;
		changes =
			this.iterableDifferDeliveryIndicators.diff(
				this.selectedDeliveryIndicators
			) || changes;
		changes =
			this.iterableDifferStatuses.diff(this.selectedStatuses) || changes;
		if (this.oldYear !== this.selectedYear) {
			changes = true;
			this.oldYear = this.selectedYear;
		}
		if (this.oldWeek !== this.selectedWeek) {
			changes = true;
			this.oldWeek = this.selectedWeek;
		}
		if (this.isDateRangeEstChanged) {
			this.isDateRangeEstChanged = false;
			changes = true;
		}

		if (!this.isChangedEnabled) {
			return false;
		}

		if (changes) {
			return true;
		} else {
			return false;
		}
	}

	private updateId = new BehaviorSubject<string>("");
	public get getUpdateId(): Observable<string> {
		return this.updateId.asObservable();
	}
	public getCurrentUpdateId(): string {
		return this.updateId.value;
	}
	public setUpdateId(value: string) {
		this.updateId.next(value);
	}

	public isDrilldownBackByVendorAvailable() {
		return (
			(this.selectedVendor && this.selectedVendor.length > 0) ||
			(this.selectedVendorNames && this.selectedVendorNames.length > 0)
		);
	}

	public drilldownBackByVendor() {
		if (this.selectedVendor && this.selectedVendor.length > 0) {
			this.selectedVendor = [];
		}
		if (this.selectedVendorNames && this.selectedVendorNames.length > 0) {
			this.selectedVendorNames = [];
		}
	}

	public drilldownBackByState() {
		if (this.selectedState && this.selectedState.length > 0) {
			this.selectedState = [];
		}
	}

	public drilldownBackByStore() {
		if (this.selectedStore && this.selectedStore.length > 0) {
			this.selectedStore = [];
		}
	}

	public drilldownBackByStoreType() {
		if (this.selectedStoreType && this.selectedStoreType.length > 0) {
			this.selectedStoreType = [];
		}
	}

	public drilldownBackByAssetName() {
		if (this.selectedAssetName && this.selectedAssetName.length > 0) {
			this.selectedAssetName = [];
		}
	}

	public drilldownBackByBrand() {
		if (this.selectedBrand && this.selectedBrand.length > 0) {
			this.selectedBrand = [];
		}
	}

	public drilldownBackByDistrict() {
		if (this.selectedDistrict && this.selectedDistrict.length > 0) {
			this.selectedDistrict = [];
		}
	}

	public drilldownBackByCategory() {
		if (this.selectedCategory && this.selectedCategory.length > 0) {
			this.selectedCategory = [];
		}
	}

	public drilldownBackByCategoryManager() {
		if (this.selectedCategoryManager && this.selectedCategoryManager.length > 0) {
			this.selectedCategoryManager = [];
		}
	}

	public drilldownBackByPriority() {
		if (this.selectedPriority && this.selectedPriority.length > 0) {
			this.selectedPriority = [];
		}
	}

	public drilldownBackRM() {
		if (this.selectedPriority && this.selectedPriority.length > 0) {
			this.selectedPriority = [];
		} else if (this.selectedState && this.selectedState.length > 0) {
			this.selectedState = [];
		}
	}

	public drilldownBackRMByStoreType() {
		if (this.selectedPriority && this.selectedPriority.length > 0) {
			this.selectedPriority = [];
		} else if (this.selectedStoreType && this.selectedStoreType.length > 0) {
			this.selectedStoreType = [];
		}
	}

	public isDrilldownBackByDateEstAvailable() {
		let dateRange = this.getCurrentDateRangeEst();
		return (
			dateRange !== null &&
			dateRange.beginDate != null &&
			dateRange.endDate != null &&
			dateRange.beginDate.year > 0
		);
	}

	public drilldownBackByDateEst() {
		this.updateDateRangeEst("null", null, null, true);
	}

	constructor(
		private workOrderReportService: WorkOrderReportService,
		private authService: AuthService,
		private iterableDiffers: IterableDiffers,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		private drus: DateRangeUtilService,
		private helperService: HelperService
	) {
		this.iterableDifferVendor = this.iterableDiffers.find([]).create(null);
		this.iterableDifferVendorNames = this.iterableDiffers.find([]).create(null);
		this.iterableDifferState = this.iterableDiffers.find([]).create(null);
		this.iterableDifferStateFilter = this.iterableDiffers.find([]).create(null);
		this.iterableDifferStore = this.iterableDiffers.find([]).create(null);
		this.iterableDifferAssetName = this.iterableDiffers.find([]).create(null);
		this.iterableDifferBrand = this.iterableDiffers.find([]).create(null);
		this.iterableDifferBrandFilter = this.iterableDiffers.find([]).create(null);
		this.iterableDifferDistrict = this.iterableDiffers.find([]).create(null);
		this.iterableDifferCategory = this.iterableDiffers.find([]).create(null);
		this.iterableDifferCategoryManager = this.iterableDiffers
			.find([])
			.create(null);
		this.iterableDifferKeyAssetName = this.iterableDiffers.find([]).create(null);
		this.iterableDifferRequestType = this.iterableDiffers.find([]).create(null);
		this.iterableDifferPriority = this.iterableDiffers.find([]).create(null);
		this.iterableDifferGLCostTypes = this.iterableDiffers.find([]).create(null);
		this.iterableDifferGLDescriptions = this.iterableDiffers.find([]).create(null);
		this.iterableDifferGLStatuses = this.iterableDiffers.find([]).create(null);
		this.iterableDifferRetailBusinessManager = this.iterableDiffers
			.find([])
			.create(null);
		this.iterableDifferRetailBusinessManagerFilter = this.iterableDiffers
			.find([])
			.create(null);
		this.iterableDifferDistrictManagers = this.iterableDiffers
			.find([])
			.create(null);
		this.iterableDifferRegionalManagers = this.iterableDiffers
			.find([])
			.create(null);
		this.iterableDifferStoreType = this.iterableDiffers.find([]).create(null);
		this.iterableDifferStoreProfile = this.iterableDiffers.find([]).create(null);
		this.iterableDifferStoreCluster = this.iterableDiffers.find([]).create(null);
		this.iterableDifferServiceDeliveryManager = this.iterableDiffers
			.find([])
			.create(null);
		this.iterableDifferDeliveryIndicators = this.iterableDiffers
			.find([])
			.create(null);
		this.iterableDifferStatuses = this.iterableDiffers.find([]).create(null);
		this.oldYear = this.selectedYear;
		this.oldWeek = this.selectedWeek;
		this.authService.isLoggedIn.subscribe((data) => {
			if (!data) {
				this.clearDataAndFilters();
			}
		});
	}
}
