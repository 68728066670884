import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Entity } from '../models/entity';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../services/message.service';
import { AuthService } from '../../services/auth.service';
import { StateService } from '../../services/state.service';
import { WhiteLabelService } from '../../services/white-label.service';

@Injectable()
export class EntitiesService {
  private apiUrl = this.whiteLabelService.getSettings.apiUrl + 'api/Entities';

  private currentEntity = new BehaviorSubject<Entity>(undefined);

  get getCurrentEntity(): Observable<Entity> {
    return this.currentEntity.asObservable();
  }

  constructor(private http: HttpClient,
    private messages: MessageService,
    private authService: AuthService,
    private stateService: StateService,
    private whiteLabelService: WhiteLabelService) {

    this.stateService.getCurrentStore.subscribe(value => {
      if (value) {
        this.getEntity(value.id)
          .subscribe(entity => {
            this.currentEntity.next(entity);
          });
      } else {
        this.currentEntity.next(null);
      }
    });
  }

  getEntity(id: number): Observable<Entity> {
    return this.http.get<Entity>(`${this.apiUrl}/${id}`, this.authService.getHttpOptions()).pipe(
      catchError(this.messages.handleError(this.authService, 'getEntity(id)', null))
    );
  }
}
