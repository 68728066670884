import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faHourglassEnd,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { IMyDateRangeModel } from "mydaterangepicker";
import { forkJoin, Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { FinancialService } from "../services/financial.service";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-financial-summary",
	templateUrl: "./financial-summary.component.html",
})
export class FinancialSummaryComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loaded: boolean;

	budget: number = 0;
	totalSpend: number = 0;
	totalSpendVBudget: number = 0;
	fullYearForecastVBudget: number = 0;
	woCount: number = 0;

	showFullYearForecastVBudget: boolean = true;

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private financialService: FinancialService
	) {}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loaded = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.glStatuses = ["Open", "Completed", "WIP", "PO Issued"];
		return body;
	}

	twoDigitsToString(n) {
		return n > 9 ? "" + n : "0" + n;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;

		//let currentFY = this.stateService.yearToFY(this.stateService.getCurrentFY());
		this.showFullYearForecastVBudget = this.stateService.isCurrentFY(); //this.stateService.selectedYear && this.stateService.selectedYear === currentFY;

		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		let query1 = this.workOrderReportService.getRequestSummaryMonth(body);
		let query2 = this.financialService.getBudgetSummaryMonth(body);

		this.subs.add(
			forkJoin([query1, query2]).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					let today = moment.utc();
					let thisMonth = moment.utc(
						`${today.year()}-${this.twoDigitsToString(today.month() + 1)}-01`
					);

					let budget = 0;
					let totalSpend = 0;
					let totalSpendVBudget = 0;
					let fullYearForecastVBudget = 0;
					let woCount = 0;

					let actualYTD = 0; // sum of Actual (by month) = cost from RequestSummaryMonth
					let budgetFuture = 0; // amount from BudgetSummaryMonth where year.month > currentYear.currentMonth
					data[0].forEach((element) => {
						actualYTD += element.cost + element.costEstNet;
						woCount += element.count;
					});
					data[1].forEach((element) => {
						if (element.entityMatch) {
							budget += element.amount;
							let date = moment.utc(
								`${element.year}-${this.twoDigitsToString(element.month)}-01`
							);
							if (date > thisMonth) {
								budgetFuture += element.amount;
							}
						}
					});

					totalSpend = actualYTD;
					totalSpendVBudget = budget - totalSpend;
					fullYearForecastVBudget = budget - totalSpend - budgetFuture;

					this.budget = budget;
					this.totalSpend = totalSpend;
					this.totalSpendVBudget = totalSpendVBudget;
					this.fullYearForecastVBudget = fullYearForecastVBudget;
					this.woCount = woCount;

					this.loaded = true;
					this.ref.detectChanges();
				}
			})
		);
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faHourglassEnd = faHourglassEnd;
}
