<div class="card info-card has-invisible-content critical-assets-offline" [ngClass]="{'has-back': woFiltersService.selectedAssetName && woFiltersService.selectedAssetName.length > 0}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faInfo"></fa-icon> Critical Assets Offline
		</h2>
		<div class="back-btn" *ngIf="woFiltersService.selectedAssetName && woFiltersService.selectedAssetName.length > 0">
			<button type="button" class="btn btn-secondary" (click)="woFiltersService.drilldownBackByAssetName()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loaded}" class="container">
			<div class="row align-items-start align-items-sm-stretch">
				<div *ngFor="let item of data" class="col-sm-6 col-md-4 col-lg-4 col-xl-3 d-flex" [ngClass]="{'selected': woFiltersService.selectedAssetName && woFiltersService.selectedAssetName.length && woFiltersService.selectedAssetName.includes(item.name)}">
					<div class="card mt-2 mb-2 text-center cursor-pointer w-100" (click)="onClick(item)">
						<img [src]="item.icon" class="card-img-top mx-auto mt-4" alt="" />
						<div class="card-body d-flex flex-column">
							<h5 class="card-title text-uppercase">{{item.name}}</h5>
							<p class="card-text">{{item.count | number}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="!loaded"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
