<div class="card has-invisible-content full-height-xl">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> {{model.title}}
		</h2>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loadedKPIPerformance}" class="chart-container long-height-sm long-height-lg">
			<chart class="chart-container long-height-sm long-height-lg" #kpiPerformanceChart [type]="chartConfigKPIPerformance.type" [data]="chartConfigKPIPerformance.data" [options]="chartConfigKPIPerformance.options"></chart>
		</div>

		<div *ngIf="!loadedKPIPerformance"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
