import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faChartColumn,
} from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-open-state-pm",
	templateUrl: "./work-orders-open-state-pm.component.html",
})
export class WorkOrdersOpenStatePMComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loadedByState: boolean;

	chartConfigByStatePM = {
		type: "bar",
		data: {
			labels: [],
			datasets: [],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: true,
			},
			scales: {
				xAxes: [
					{
						stacked: true,
						ticks: {
							autoSkip: false,
						},
					},
				],
				yAxes: [
					{
						stacked: true,
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "left",
						id: "y-axis-1",
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `${label.toLocaleString()}`;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data["datasets"][tooltipItem[0]["datasetIndex"]].label;
						return title === null ? "null" : title;
					},
					label: function (tooltipItem, data) {
						let datasetIndex = tooltipItem["datasetIndex"];
						let datasetIndex2;
						if (datasetIndex === 0 || datasetIndex === 2) {
							datasetIndex2 = datasetIndex + 1;
						} else {
							datasetIndex2 = datasetIndex - 1;
						}
						let value = data["datasets"][datasetIndex]["data"][tooltipItem["index"]];
						let value2 =
							data["datasets"][datasetIndex2]["data"][tooltipItem["index"]];
						let total = value + value2;
						let percent = total == 0 ? 0 : (100 * value) / total;
						return `${value.toLocaleString()} (${percent.toLocaleString(undefined, {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						})}%)`;
					},
				},
			},
			onClick: (event) => {
				var activePoints = this.chartByStatePM.chart.getElementsAtEvent(event);
				if (
					activePoints &&
					activePoints.length > 0 &&
					activePoints[0]._model &&
					activePoints[0]._model.label
				) {
					this.woFiltersService.isCollapsed = false;
					var value = activePoints[0]._model.label;
					if (this.facilityTypeMode) {
						this.woFiltersService.selectedStoreType = [value];
					} else {
						this.woFiltersService.selectedState = [value];
					}
				}
			},
		},
	};
	@ViewChild("statePMChart") chartByStatePM: ChartComponent;

	public hasBack(): boolean {
		return (
			(this.facilityTypeMode &&
				this.woFiltersService.selectedStoreType &&
				this.woFiltersService.selectedStoreType.length > 0) ||
			(!this.facilityTypeMode &&
				this.woFiltersService.selectedState &&
				this.woFiltersService.selectedState.length > 0)
		);
	}

	backgroundColor = [];
	hoverBackgroundColor = [];
	public facilityTypeMode: boolean;
	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;
		this.facilityTypeMode = this.whiteLabelService.getFacilityTypeMode;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByState = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Open"];
		body = this.woFiltersService.fillBodyEndDateThisMonth(body);
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByState = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		let datasourcesPM = ["Resolve KPI Fail", "Resolve KPI Success"];

		if (this.facilityTypeMode) {
			this.subs.add(
				this.workOrderReportService
					.getRequestSummaryStoreType(body)
					.subscribe((data) => {
						if (this.woFiltersService.getCurrentUpdateId() === updateId) {
							this.loadedByState = true;
							let newLabels = [];
							let newDataPM = [];
							let states = [];
							let colors = this.whiteLabelService.getKPIFailSuccessColors();
							datasourcesPM.forEach((element) => {
								newDataPM.push({
									label: element,
									backgroundColor: colors[element].backgroundColor,
									hoverBackgroundColor: colors[element].hoverBackgroundColor,
									yAxisID: "y-axis-1",
									data: [],
									type: "bar",
									stack: "stack1",
								});
							});
							data.forEach((element) => {
								if (!states.includes(element.storeType)) {
									states.push(element.storeType);
									newDataPM.forEach((element) => {
										element.data.push(0);
									});
								}
								var indexState = states.indexOf(element.storeType);
								if (element.requestType == "Planned") {
									newDataPM[0].data[indexState] += element.resolveKPIFail;
									newDataPM[1].data[indexState] +=
										element.count - element.resolveKPIFail;
								}
							});
							newLabels = states;
							var array = [];
							states.forEach((element2) => {
								array.push(0);
							});

							this.chartConfigByStatePM.data.labels = newLabels;
							this.chartConfigByStatePM.data.datasets = newDataPM;
							if (this.chartByStatePM) {
								this.chartByStatePM.chart.update();
							}

							this.ref.detectChanges();
						}
					})
			);
		} else {
			this.subs.add(
				this.workOrderReportService
					.getRequestSummaryState(body)
					.subscribe((data) => {
						if (this.woFiltersService.getCurrentUpdateId() === updateId) {
							this.loadedByState = true;
							let newLabels = [];
							let newDataPM = [];
							let states = [];
							let colors = this.whiteLabelService.getKPIFailSuccessColors();
							datasourcesPM.forEach((element) => {
								newDataPM.push({
									label: element,
									backgroundColor: colors[element].backgroundColor,
									hoverBackgroundColor: colors[element].hoverBackgroundColor,
									yAxisID: "y-axis-1",
									data: [],
									type: "bar",
									stack: "stack1",
								});
							});
							data.forEach((element) => {
								if (!states.includes(element.state)) {
									states.push(element.state);
									newDataPM.forEach((element) => {
										element.data.push(0);
									});
								}
								var indexState = states.indexOf(element.state);
								if (element.requestType == "Planned") {
									newDataPM[0].data[indexState] += element.resolveKPIFail;
									newDataPM[1].data[indexState] +=
										element.count - element.resolveKPIFail;
								}
							});
							newLabels = states;
							var array = [];
							states.forEach((element2) => {
								array.push(0);
							});

							this.chartConfigByStatePM.data.labels = newLabels;
							this.chartConfigByStatePM.data.datasets = newDataPM;
							if (this.chartByStatePM) {
								this.chartByStatePM.chart.update();
							}

							this.ref.detectChanges();
						}
					})
			);
		}
	}

	// Icons
	faChartColumn = faChartColumn;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
