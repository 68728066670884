import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AssetConditionsStats } from '../models/assetConditionsStats';
import { ApplyFilters } from '../models/applyFilters';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../services/message.service';
import { AuthService } from '../../services/auth.service';
import { WhiteLabelService } from '../../services/white-label.service';

@Injectable()
export class AssetConditionsStatsService {
  private apiUrl = this.whiteLabelService.getSettings.apiUrl + 'api/AssetConditionsStats';

  constructor(
    private http: HttpClient,
    private messages: MessageService,
    private authService: AuthService,
    private whiteLabelService: WhiteLabelService) { }

  getAssetConditionsStats(storeNumber: string | null, filters: ApplyFilters | null): Observable<AssetConditionsStats> {
    let url = storeNumber == null ? this.apiUrl : `${this.apiUrl}/${storeNumber}`;
    return this.http.post<AssetConditionsStats>(url, filters, this.authService.getHttpOptions()).pipe(
      catchError(this.messages.handleError(this.authService, 'getAssetConditionsStats(storeNumber, filters)', null))
    );
  }
}
