import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-vendor-performance-status-summary",
	templateUrl: "./vendor-performance-status-summary.component.html",
})
export class VendorPerformanceStatusSummaryComponent
	implements OnInit, OnDestroy
{
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedSummary: boolean;
	openIncident: number = 0;
	openPlanned: number = 0;
	openReactive: number = 0;
	openTotalCost: number = 0;
	completedIncident: number = 0;
	completedPlanned: number = 0;
	completedReactive: number = 0;
	completedTotalCost: number = 0;

	loadedSummaryOpenRMQuotes: boolean;
	openRMQuotes: number = 0;

	loadedSummaryRejectedRMQuotes: boolean;
	rejectedRMQuotes: number = 0;

	loadedSummaryFMUnderReview: boolean;
	fmUnderReview: number = 0;

	loadedSummaryFMRejected: boolean;
	fmRejected: number = 0;

	loadedSummaryMissedKPI: boolean;
	missedP1KPI: number = 0;
	missedP2KPI: number = 0;

	loadedSummaryCompletedNotClaimed: boolean;
	completedNotClaimed: number = 0;

	loadedSummaryInformUsaged: boolean;
	informUsage: number = 0;

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		public whiteLabelService: WhiteLabelService
	) {}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.stateService.setHeaderTitle(this.whiteLabelService.capitalizeFirstLetter(this.whiteLabelService.getSettings.filterVendorLabel) + " Performance Status");
		this.loadedSummary = false;
		this.loadedSummaryOpenRMQuotes = false;
		this.loadedSummaryRejectedRMQuotes = false;
		this.loadedSummaryFMUnderReview = false;
		this.loadedSummaryFMRejected = false;
		this.loadedSummaryMissedKPI = false;
		this.loadedSummaryCompletedNotClaimed = false;
		this.loadedSummaryInformUsaged = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.roles$.subscribe((data) => {
				if (
					data.length > 0 &&
					data.indexOf("store") < 0 &&
					data.indexOf("rm") < 0
				) {
					this.subs.add(
						this.woFiltersService.getUpdateId.subscribe((value) => {
							this.update(this.stateService.getCurrentDateRange(), value);
						})
					);
				}
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		return this.woFiltersService.fillBody(body, dateRange);
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedSummary = false;
		this.loadedSummaryOpenRMQuotes = false;
		this.loadedSummaryRejectedRMQuotes = false;
		this.loadedSummaryFMUnderReview = false;
		this.loadedSummaryFMRejected = false;
		this.loadedSummaryMissedKPI = false;
		this.loadedSummaryCompletedNotClaimed = false;
		this.loadedSummaryInformUsaged = false;

		let body = this.fillBody(new QueryOptionsModel(), dateRange);
		body.filter.overallStatuses = ["Open", "Completed"];
		//#region Item 1-8
		this.subs.add(
			this.workOrderReportService.getRequestSummary(body).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.openIncident = 0;
					this.openPlanned = 0;
					this.openReactive = 0;
					this.openTotalCost = 0;

					this.completedIncident = 0;
					this.completedPlanned = 0;
					this.completedReactive = 0;
					this.completedTotalCost = 0;

					data.forEach((element) => {
						if (element.overallStatus === "Open") {
							if (element.requestType === "Planned") {
								this.openPlanned += element.count;
							} else if (element.requestType === "Reactive") {
								this.openReactive += element.count;
							} else if (element.requestType === "Incident") {
								this.openIncident += element.count;
							}
							this.openTotalCost += element.cost;
						} else {
							if (element.requestType === "Planned") {
								this.completedPlanned += element.count;
							} else if (element.requestType === "Reactive") {
								this.completedReactive += element.count;
							} else if (element.requestType === "Incident") {
								this.completedIncident += element.count;
							}
							this.completedTotalCost += element.cost;
						}
					});
					this.loadedSummary = true;
					this.ref.detectChanges();
				}
			})
		);
		//#endregion

		//#region Item 9
		let bodyItem9 = this.fillBody(new QueryOptionsModel(), dateRange);
		bodyItem9.filter.overallStatuses = ["Open"];
		bodyItem9.filter.requestTypes = ["Reactive"];
		bodyItem9.filter.statusIds = [15];
		this.subs.add(
			this.workOrderReportService
				.getRequestSummary(bodyItem9)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.openRMQuotes = 0;

						data.forEach((element) => {
							this.openRMQuotes += element.count;
						});
						this.loadedSummaryOpenRMQuotes = true;
						this.ref.detectChanges();
					}
				})
		);
		//#endregion

		//#region Item 10
		let bodyItem10 = this.fillBody(new QueryOptionsModel(), dateRange);
		bodyItem10.filter.requestTypes = ["Reactive"];
		bodyItem10.filter.statusIds = [16];
		this.subs.add(
			this.workOrderReportService
				.getRequestSummary(bodyItem10)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.rejectedRMQuotes = 0;

						data.forEach((element) => {
							this.rejectedRMQuotes += element.count;
						});
						this.loadedSummaryRejectedRMQuotes = true;
						this.ref.detectChanges();
					}
				})
		);
		//#endregion

		//#region Item 11
		let bodyItem11 = this.fillBody(new QueryOptionsModel(), dateRange);
		bodyItem11.filter.requestTypes = ["Reactive"];
		bodyItem11.filter.statusIds = [21];
		this.subs.add(
			this.workOrderReportService
				.getRequestSummary(bodyItem11)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.fmUnderReview = 0;

						data.forEach((element) => {
							this.fmUnderReview += element.count;
						});
						this.loadedSummaryFMUnderReview = true;
						this.ref.detectChanges();
					}
				})
		);
		//#endregion

		//#region Item 12
		let bodyItem12 = this.fillBody(new QueryOptionsModel(), dateRange);
		bodyItem12.filter.requestTypes = ["Reactive"];
		bodyItem12.filter.statusIds = [22];
		this.subs.add(
			this.workOrderReportService
				.getRequestSummary(bodyItem12)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.fmRejected = 0;

						data.forEach((element) => {
							this.fmRejected += element.count;
						});
						this.loadedSummaryFMRejected = true;
						this.ref.detectChanges();
					}
				})
		);
		//#endregion

		//#region Item 13, 14
		let bodyItem13and14 = this.fillBody(new QueryOptionsModel(), dateRange);
		bodyItem13and14.filter.overallStatuses = ["Open", "Completed"];
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryPriority(bodyItem13and14)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.missedP1KPI = 0;
						this.missedP2KPI = 0;

						data.forEach((element) => {
							if (element.priority) {
								if (element.priority.startsWith("P1")) {
									this.missedP1KPI += element.respondKPIFail;
								} else if (element.priority.startsWith("P2")) {
									this.missedP2KPI += element.resolveKPIFail;
								}
							}
						});
						this.loadedSummaryMissedKPI = true;
						this.ref.detectChanges();
					}
				})
		);
		//#endregion

		//#region Item 15
		let bodyItem15 = this.fillBody(new QueryOptionsModel(), null);
		bodyItem15.filter.statusIds = [4];
		this.subs.add(
			this.workOrderReportService
				.getRequestSummary(bodyItem15)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.completedNotClaimed = 0;

						data.forEach((element) => {
							this.completedNotClaimed += element.count;
						});
						this.loadedSummaryCompletedNotClaimed = true;
						this.ref.detectChanges();
					}
				})
		);
		//#endregion

		//#region Item 16
		let bodyItem16 = this.fillBody(new QueryOptionsModel(), dateRange);
		bodyItem16.filter.overallStatuses = ["Completed"];
		this.subs.add(
			this.workOrderReportService
				.getRequestSummary(bodyItem16)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.informUsage = 0;

						let informUseByVendor = 0;
						let count = 0;

						data.forEach((element) => {
							informUseByVendor += element.informUseByVendor;
							count += element.count;
						});
						this.informUsage = count === 0 ? 0 : (100 * informUseByVendor) / count;
						this.loadedSummaryInformUsaged = true;
						this.ref.detectChanges();
					}
				})
		);
		//#endregion
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
