import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-completed-respond",
	templateUrl: "./work-orders-completed-respond.component.html",
})
export class WorkOrdersCompletedRespondComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByPriority: boolean;
	priorityLabel: string = "";
	chartDataByRespond = [[], []];
	chartConfigByRespond = {
		type: "bar",
		data: {
			labels: [],
			datasets: [],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: false,
			},
			scales: {
				xAxes: [
					{
						stacked: true,
						ticks: {
							autoSkip: false,
						},
					},
				],
				yAxes: [
					{
						stacked: true,
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "left",
						id: "y-axis-1",
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `${(label * 100).toLocaleString()}\%`;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data.datasets[tooltipItem[0].datasetIndex].label;
						return title === null ? "null" : title;
					},
					label: (tooltipItem, data) => {
						var original =
							this.chartDataByRespond[tooltipItem.datasetIndex][tooltipItem["index"]];
						return `${original.toLocaleString()} (${(
							data.datasets[tooltipItem.datasetIndex].data[tooltipItem["index"]] * 100
						).toLocaleString(undefined, {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						})}%)`;
					},
				},
			},
		},
	};
	@ViewChild("respondChart") chartByRespond: ChartComponent;

	backgroundColor = [];
	hoverBackgroundColor = [];
	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByPriority = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Completed"];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByPriority = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		//#region Chart 5
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryPriority(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.loadedByPriority = true;
						let acceptablePrioritiesStr =
							this.whiteLabelService.getSettings.respondKPIPriorities;
						let acceptablePriorities = acceptablePrioritiesStr.split(",");
						let newLabelsRespond = [];
						data.forEach((element) => {
							let indexResolve = newLabelsRespond.indexOf(element.priority);
							if (indexResolve < 0) {
								let isAcceptable = false;
								acceptablePriorities.forEach((priority) => {
									if (element.priority.startsWith(priority)) {
										isAcceptable = true;
									}
								});
								if (isAcceptable) {
									newLabelsRespond.push(element.priority);
								}
							}
						});
						newLabelsRespond.sort();
						let valuesTemplate = [];
						newLabelsRespond.forEach((element) => {
							valuesTemplate.push(0);
						});
						let newDataRespond = [];
						let newDataRespondeOriginal = [
							valuesTemplate.slice(),
							valuesTemplate.slice(),
						];
						let i = 0;
						let datasources = ["Fail", "Pass"];
						let colors = this.whiteLabelService.getKPIFailPassColors();
						datasources.forEach((element) => {
							newDataRespond.push({
								label: element,
								backgroundColor: colors.colorsRespond[i],
								hoverBackgroundColor: colors.colorsHoverRespond[i],
								yAxisID: "y-axis-1",
								data: valuesTemplate.slice(),
							});
							i++;
						});
						data.forEach((element) => {
							let indexRespond = newLabelsRespond.indexOf(element.priority);
							if (indexRespond > -1) {
								newDataRespond[0].data[indexRespond] +=
									element.respondKPIFail / element.count;
								newDataRespond[1].data[indexRespond] +=
									(element.count - element.respondKPIFail) / element.count;
								newDataRespondeOriginal[0][indexRespond] += element.respondKPIFail;
								newDataRespondeOriginal[1][indexRespond] +=
									element.count - element.respondKPIFail;
							}
						});

						var priorityLabel = newLabelsRespond.join(", ");
						if (priorityLabel) {
							this.priorityLabel = priorityLabel;
						} else {
							this.priorityLabel = "";
						}

						this.chartDataByRespond = newDataRespondeOriginal;

						this.chartConfigByRespond.data.labels = newLabelsRespond;
						this.chartConfigByRespond.data.datasets = newDataRespond;
						if (this.chartByRespond) {
							this.chartByRespond.chart.update();
						}
						this.ref.detectChanges();
					}
				})
		);
		//#endregion
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
