import {
	Component,
	OnInit,
	OnDestroy
} from '@angular/core';
import {
	StateService
} from '../../services/state.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import { AuthService } from '../../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { Guid } from "guid-typescript";
import { WhiteLabelService } from '../../services/white-label.service';

@Component({
	selector: 'app-asset-availability',
	templateUrl: './asset-availability.component.html'
})
export class AssetAvailabilityComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();

	resetFilters() {
		this.woFiltersService.resetFilters();
		this.stateService.updateDateRange("null", null, null);
		this.stateService.setDateRange(null); // call update automatically
	}

	constructor(private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		public whiteLabelService: WhiteLabelService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.stateService.setHeaderTitle('Asset Availability');

		this.roles$ = this.authService.getCurrentUserRoles;

		this.woFiltersService.loadData();
		this.subs.add(this.stateService.getDateRange.subscribe((value: IMyDateRangeModel) => {
			this.update();
		}));
	}

	ngDoCheck() {
		if (this.woFiltersService.isChanged()) {
			this.update();
		}
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	update() {
		let currentUpdateId = Guid.create().toString();
		this.woFiltersService.setUpdateId(currentUpdateId);
	}
}