<ng-template #availabilityTipContent>
	% of assets that are currently operational
</ng-template>
<div class="card full-height-xl" [ngClass]="{'has-back': woFiltersService.selectedAssetName && woFiltersService.selectedAssetName.length > 0}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon> Key Asset Offline by Asset Name
		</h2>
		<div class="back-btn" *ngIf="woFiltersService.selectedAssetName && woFiltersService.selectedAssetName.length > 0">
			<button type="button" class="btn btn-secondary" (click)="woFiltersService.drilldownBackByAssetName()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div class="datatable-responsive data-responsive-sm">
			<ngx-datatable #assetAvailabilityTable class="bootstrap overflow-header-name" [rows]="tableData.rows" [columns]="tableData.columns" [headerHeight]="50" [scrollbarV]="true" [rowHeight]="38" [footerHeight]="50" [columnMode]="'flex'" [sorts]="[{prop: 'offlineCount', dir: 'desc'}]" [loadingIndicator]="!loaded">
				<!-- Row Detail Template -->
				<ngx-datatable-row-detail [rowHeight]="100" (toggle)="tableData.onDetailToggle($event)">
					<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
						<div class="row-detail-inner d-flex flex-row flex-wrap">
							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Total Asset Count: </span>{{row.count | number}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Offline Asset Count: </span>{{row.offlineCount | number}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Availability (%): </span>{{row.available | number}}%
							</div>
						</div>
					</ng-template>
				</ngx-datatable-row-detail>
				<!-- Column Templates -->
				<ngx-datatable-column prop="id" name="Asset Name" headerClass="col-5 has-expand-icon" cellClass="col-5 has-expand-icon" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-row="row" let-value="value" let-expanded="expanded" ngx-datatable-cell-template>
						<span title="{{value}}">
							<span class="position-relative d-block">
								<a
									href="javascript:void(0)"
									[class.datatable-icon-right]="!expanded"
									[class.datatable-icon-down]="expanded"
									class="datatable-icon-action"
									title="Expand/Collapse Row"
									(click)="tableData.toggleExpandRow(row)">
								</a>
								<span class="cursor-pointer text-truncate-custom" (click)="tableData.onNameClick(row)">{{value}}</span>
							</span>
						</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="count" name="Total Asset Count" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="offlineCount" name="Offline Asset Count" [comparator]="offlineCountComparator" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="available" name="Availability (%)" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper">
							<span class="datatable-header-cell-label" (click)="sort()">
								<span [ngbTooltip]="availabilityTipContent" placement="bottom" container="body" tooltipClass="summary-tooltip">{{column.name}}</span>
							</span>
						</span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number : '1.0-2'}}%
					</ng-template>
				</ngx-datatable-column>
			</ngx-datatable>
		</div>
	</div>
</div>
