import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { DropdownItem } from "../../models/dropdownItem";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/message.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { NameModel } from "../models/nameModel";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { ReportWeeklyStatusModel } from "../models/reportWeeklyStatusModel";
import { RequestListResponseModel } from "../models/requestListResponseModel";
import { RequestSummaryAssetResponseModel } from "../models/requestSummaryAssetResponseModel";
import { RequestSummaryAssetStateResponseModel } from "../models/requestSummaryAssetStateResponseModel";
import { RequestSummaryAssetStoreProfileResponseModel } from "../models/requestSummaryAssetStoreProfileResponseModel";
import { RequestSummaryAssetStoreTypeResponseModel } from "../models/requestSummaryAssetStoreTypeResponseModel";
import { RequestSummaryDayResolveResponseModel } from "../models/requestSummaryDayResolveResponseModel";
import { RequestSummaryDayResponseModel } from "../models/requestSummaryDayResponseModel";
import { RequestSummaryEntityGLPivotResponseModel } from "../models/requestSummaryEntityGLPivotResponseModel";
import { RequestSummaryEntityResponseModel } from "../models/requestSummaryEntityResponseModel";
import { RequestSummaryGLCategoryResponseModel } from "../models/requestSummaryGLCategoryResponseModel";
import { RequestSummaryGLCodePivotResponseModel } from "../models/requestSummaryGLCodePivotResponseModel";
import { RequestSummaryMonthResponseModel } from "../models/requestSummaryMonthResponseModel";
import { RequestSummaryPriorityResponseModel } from "../models/requestSummaryPriorityResponseModel";
import { RequestSummaryResponseModel } from "../models/requestSummaryResponseModel";
import { RequestSummaryStateMonthResponseModel } from "../models/requestSummaryStateMonthResponseModel";
import { RequestSummaryStateResponseModel } from "../models/requestSummaryStateResponseModel";
import { RequestSummaryStoreProfileMonthResponseModel } from "../models/requestSummaryStoreProfileMonthResponseModel";
import { RequestSummaryStoreProfileResponseModel } from "../models/requestSummaryStoreProfileResponseModel";
import { RequestSummaryStoreTypeMonthResponseModel } from "../models/requestSummaryStoreTypeMonthResponseModel";
import { RequestSummaryStoreTypeResponseModel } from "../models/requestSummaryStoreTypeResponseModel";
import { RequestSummaryVendorPivotResponseModel } from "../models/requestSummaryVendorPivotResponseModel";
import { RequestSummaryVendorResponseModel } from "../models/requestSummaryVendorResponseModel";
import { RequestSummaryWeekPriorityResponseModel } from "../models/requestSummaryWeekPriorityResponseModel";

@Injectable()
export class WorkOrderReportService {
	private apiUrl = this.whiteLabelService.getSettings.informApiUrl + "api/";
	private pdfUrl =
		this.whiteLabelService.getSettings.apiUrl + "ReportWeeklyStatus/PdfPost";

	constructor(
		private http: HttpClient,
		private messages: MessageService,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService
	) {}

	capitalizeFirstLetter(value: string): string {
		return value[0].toUpperCase() + value.slice(1);
	}

	getRequestSummary(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummary";
		return this.http
			.post<RequestSummaryResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getRequestSummary(model)", [])
				)
			);
	}

	getRequestSummaryPriority(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryPriorityResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryPriority";
		return this.http
			.post<RequestSummaryPriorityResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryPriority(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryState(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryStateResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryState";
		return this.http
			.post<RequestSummaryStateResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryState(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryVendor(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryVendorResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryVendor";
		return this.http
			.post<RequestSummaryVendorResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryVendor(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryEntity(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryEntityResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryEntity";
		return this.http
			.post<RequestSummaryEntityResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryEntity(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryAsset(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryAssetResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryAsset";
		return this.http
			.post<RequestSummaryAssetResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryAsset(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryAssetState(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryAssetStateResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryAssetState";
		return this.http
			.post<RequestSummaryAssetStateResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryAssetState(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryAssetStoreType(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryAssetStoreTypeResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryAssetStoreType";
		return this.http
			.post<RequestSummaryAssetStoreTypeResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryAssetStoreType(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryAssetStoreProfile(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryAssetStoreProfileResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryAssetStoreProfile";
		return this.http
			.post<RequestSummaryAssetStoreProfileResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryAssetStoreProfile(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryMonth(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryMonthResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryMonth";
		return this.http
			.post<RequestSummaryMonthResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryMonth(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryStateMonth(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryStateMonthResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryStateMonth";
		return this.http
			.post<RequestSummaryStateMonthResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryStateMonth(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryStoreType(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryStoreTypeResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryStoreType";
		return this.http
			.post<RequestSummaryStoreTypeResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryStoreType(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryStoreTypeMonth(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryStoreTypeMonthResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryStoreTypeMonth";
		return this.http
			.post<RequestSummaryStoreTypeMonthResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryStoreTypeMonth(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryStoreProfile(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryStoreProfileResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryStoreProfile";
		return this.http
			.post<RequestSummaryStoreProfileResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryStoreProfile(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryStoreProfileMonth(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryStoreProfileMonthResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryStoreProfileMonth";
		return this.http
			.post<RequestSummaryStoreProfileMonthResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryStoreProfileMonth(model)",
						[]
					)
				)
			);
	}

	getStates(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/States";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(this.messages.handleError(this.authService, "getStates()", []))
			);
	}

	getVendors(): Observable<DropdownItem[]> {
		let url = this.apiUrl + "WorkOrders/Vendors";
		return this.http
			.get<DropdownItem[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(this.messages.handleError(this.authService, "getVendors()", []))
			);
	}

	getVendorParents(model: QueryOptionsModel | null): Observable<DropdownItem[]> {
		let url = this.apiUrl + "WorkOrders/VendorParents";
		return this.http
			.post<DropdownItem[]>(url, model, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getVendorParents(model)", [])
				)
			);
	}

	getVendorNames(): Observable<NameModel[]> {
		let url = this.apiUrl + "WorkOrders/VendorNames";
		return this.http
			.get<NameModel[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getVendorNames()", [])
				)
			);
	}

	getStores(
		states: string[],
		brands: string[],
		regions: string[]
	): Observable<DropdownItem[]> {
		let url = this.apiUrl + "WorkOrders/Stores?";
		if (states) {
			states.forEach((state) => {
				url += "states=" + state + "&";
			});
		}
		if (brands) {
			brands.forEach((brand) => {
				url += "brands=" + brand + "&";
			});
		}
		if (regions) {
			regions.forEach((region) => {
				url += "rbms=" + region + "&";
			});
		}
		return this.http
			.get<DropdownItem[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(this.messages.handleError(this.authService, "getStores()", []))
			);
	}

	getAssetCategories(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/AssetCategories";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getAssetCategories()", [])
				)
			);
	}

	getAssetNames(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/AssetNames";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getAssetNames()", [])
				)
			);
	}

	getBrands(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/Brands";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(this.messages.handleError(this.authService, "getBrands()", []))
			);
	}

	getDistricts(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/Districts";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getDistricts()", [])
				)
			);
	}

	getCategories(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/Categories";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getCategories()", [])
				)
			);
	}

	getCategoryManagers(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/CategoryManagers";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getCategoryManagers()", [])
				)
			);
	}

	getKeyAssetNames(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/KeyAssetNames";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleErrorSilent(this.authService, "getKeyAssetNames()", [])
				)
			);
	}

	getPriorities(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/Priorities";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getPriorities()", [])
				)
			);
	}

	getGLCostTypes(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/GLCostTypes";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getGLCostTypes()", [])
				)
			);
	}

	getGLDescriptions(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/GLDescriptions";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getGLDescriptions()", [])
				)
			);
	}

	getGLStatuses(): string[] {
		return ["Open", "Completed", "WIP", "PO Issued"];
	}

	getRequestTypes(): string[] {
		return ["Incident", "Planned", "Reactive"];
	}

	getServiceDeliveryManagers(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/ServiceDeliveryManagers";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getServiceDeliveryManagers()",
						[]
					)
				)
			);
	}

	getRetailBusinessManagers(
		states: string[],
		brands: string[]
	): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/RetailBusinessManagers?";
		if (states) {
			states.forEach((state) => {
				url += "states=" + state + "&";
			});
		}
		if (brands) {
			brands.forEach((brand) => {
				url += "brands=" + brand + "&";
			});
		}
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRetailBusinessManagers()",
						[]
					)
				)
			);
	}

	getDMs(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/DMs";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(this.messages.handleError(this.authService, "getDMs()", []))
			);
	}

	getRegionalManagers(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/RegionalManagers";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getRegionalManagers()", [])
				)
			);
	}

	getStoreProfile(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/StoreProfile";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getStoreProfile()", [])
				)
			);
	}

	getDeliveryIndicators(): string[] {
		return [
			"Response Pending",
			"Response Overdue",
			"Resolve Pending",
			"Resolve Overdue",
		];
	}

	getStatuses(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/Statuses";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getStatuses()", [])
				)
			);
	}

	getStoreType(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/StoreType";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getStoreType()", [])
				)
			);
	}

	getStoreCluster(): Observable<string[]> {
		let url = this.apiUrl + "WorkOrders/StoreCluster";
		return this.http
			.get<string[]>(url, this.authService.getHttpOptions())
			.pipe(
				catchError(
					this.messages.handleErrorSilent(this.authService, "StoreCluster()", [])
				)
			);
	}

	getRequestList(
		model: QueryOptionsModel | QueryOptionsPagingModel | null
	): Observable<RequestListResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestList";
		return this.http
			.post<RequestListResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getRequestList()", [])
				)
			);
	}

	getRequestSummaryWeekPriority(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryWeekPriorityResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryWeekPriority";
		return this.http
			.post<RequestSummaryWeekPriorityResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryWeekPriority(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryDay(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryDayResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryDay";
		return this.http
			.post<RequestSummaryDayResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryDay(model)",
						[]
					)
				)
			);
	}

	getWeeklyStatusPdf(
		model: ReportWeeklyStatusModel
	): Observable<HttpResponse<Blob>> {
		let token = this.authService.getToken();
		return this.http
			.post<Blob>(`${this.pdfUrl}`, model, {
				headers: new HttpHeaders({
					Authorization: token,
				}),
				observe: "response",
				responseType: "blob" as "json",
			})
			.pipe(
				catchError(
					this.messages.handleError(this.authService, "getWeeklyStatusPdf()", null)
				)
			);
	}

	getRequestSummaryVendorPivot(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryVendorPivotResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryVendorPivot";
		return this.http
			.post<RequestSummaryVendorPivotResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryVendorPivot(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryEntityGLPivot(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryEntityGLPivotResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryEntityGLPivot";
		return this.http
			.post<RequestSummaryEntityGLPivotResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryEntityGLPivot(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryGLCodePivot(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryGLCodePivotResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryGLCodePivot";
		return this.http
			.post<RequestSummaryGLCodePivotResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryGLCodePivot(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryGLCategory(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryGLCategoryResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryGLCategory";
		return this.http
			.post<RequestSummaryGLCategoryResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryGLCategory(model)",
						[]
					)
				)
			);
	}

	getRequestSummaryDayResolve(
		model: QueryOptionsModel | null
	): Observable<RequestSummaryDayResolveResponseModel[]> {
		let url = this.apiUrl + "WorkOrderReport/RequestSummaryDayResolve";
		return this.http
			.post<RequestSummaryDayResolveResponseModel[]>(
				url,
				model,
				this.authService.getHttpOptions()
			)
			.pipe(
				catchError(
					this.messages.handleError(
						this.authService,
						"getRequestSummaryDayResolve(model)",
						[]
					)
				)
			);
	}
}
