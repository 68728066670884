<div class="card full-height-xl" [ngClass]="{'has-back': woFiltersService.selectedPriority && woFiltersService.selectedPriority.length > 0}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon><span>Work Orders by Asset<span *ngIf="woFiltersService.selectedPriority && woFiltersService.selectedPriority.length > 0"> - {{woFiltersService.selectedPriority[0]}}</span></span>
		</h2>
		<div class="back-btn" *ngIf="woFiltersService.selectedPriority && woFiltersService.selectedPriority.length > 0">
			<button type="button" class="btn btn-secondary" (click)="woFiltersService.drilldownBackByPriority()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div class="w-100">
			<ngx-datatable #woAssetTable class="bootstrap" [rows]="tableDataByAsset.rows" [columns]="tableDataByAsset.columns" [headerHeight]="50" [scrollbarV]="true" [rowHeight]="38" [footerHeight]="50" [columnMode]="'flex'" [sorts]="[{prop: 'count', dir: 'desc'}]" [loadingIndicator]="!loadedByAsset">
				<!-- Footer Template -->
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template>
						<div class="datatable-footer-row">
							<div class="datatable-footer-cell col-9 col-xl-10">Total</div>
							<div *ngFor="let column of tableDataByAsset.total" class="datatable-footer-cell col text-end">
								{{column | number}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
	</div>
</div>
