<div class="card has-export" [ngClass]="{'has-back': woFiltersService.selectedStore && woFiltersService.selectedStore.length > 0}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon> Completed Work Orders by {{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}}
		</h2>
		<div class="back-btn" *ngIf="woFiltersService.selectedStore && woFiltersService.selectedStore.length > 0">
			<button type="button" class="btn btn-secondary" (click)="woFiltersService.drilldownBackByStore()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
		<div class="export-btns" *ngIf="!woFiltersService.selectedStore || woFiltersService.selectedStore.length == 0">
			<button type="button" class="btn btn-secondary" (click)="exportCsv()" [disabled]="inProgressExportCSV">
				<fa-icon [icon]="faFileExport"></fa-icon><span class="d-none d-md-inline ms-1"> CSV</span>
				<span class="loader loader-small loader-reverse"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div class="datatable-responsive data-responsive-lg">
			<ngx-datatable #woStoreTable class="bootstrap overflow-header-name" [rows]="tableDataByStore.rows" [columns]="tableDataByStore.columns" [headerHeight]="50" [scrollbarV]="true" [rowHeight]="38" [footerHeight]="50" [columnMode]="'flex'" [sorts]="[{prop: 'storeNo', dir: 'asc'}]" [loadingIndicator]="!loadedByStore">
				<!-- Row Detail Template -->
				<ngx-datatable-row-detail [rowHeight]="150" (toggle)="tableDataByStore.onDetailToggle($event)">
					<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
						<div class="row-detail-inner d-flex flex-row flex-wrap">
							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Work Orders: </span>{{row['Work Orders'] | number : '1.0-0'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Cost of RM: </span>${{row['Cost of RM'] | number : '1.0-0'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Cost of PM: </span>${{row['Cost of PM'] | number : '1.0-0'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Total: </span>${{row.Total | number : '1.0-0'}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-row-detail>
				<!-- Column Templates -->
				<ngx-datatable-column prop="storeNo" name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterStoreLabel)}} No" headerClass="col-2 has-expand-icon" cellClass="col-2 has-expand-icon" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-row="row" let-value="value" let-expanded="expanded" ngx-datatable-cell-template>
						<span title="{{value}}">
							<span class="position-relative d-block">
								<a
									href="javascript:void(0)"
									[class.datatable-icon-right]="!expanded"
									[class.datatable-icon-down]="expanded"
									class="datatable-icon-action"
									title="Expand/Collapse Row"
									(click)="tableDataByStore.toggleExpandRow(row)">
								</a>
								<span class="cursor-pointer text-truncate-custom" (click)="tableDataByStore.onNameClick(row)">{{value}}</span>
						</span>
						</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="name" name="Name" headerClass="col-2" cellClass="col-2" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
						<span title="{{value}}" class="cursor-pointer text-truncate-custom" (click)="tableDataByStore.onNameClick(row)">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="state" name="{{facilityTypeMode ? 'Facility Type' : 'State'}}" headerClass="col-2" cellClass="col-2" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Work Orders" name="Work Orders" headerClass="col-1 text-end" cellClass="col-1 text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Cost of RM" name="Cost of RM" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						${{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Cost of PM" name="Cost of PM" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						${{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Total" name="Total" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						${{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<!-- Footer Template -->
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template>
						<div class="datatable-footer-row">
							<div class="datatable-footer-cell col-6">Total</div>
							<div *ngFor="let column of tableDataByStore.total; let i = index" [ngClass]="column.class" class="datatable-footer-cell">
								{{i > 0 ? '$' : ''}}{{column.value | number : '1.0-0'}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
	</div>
</div>
