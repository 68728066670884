<div class="card has-invisible-content" [ngClass]="{'has-back': woFiltersService.isDrilldownBackByDateEstAvailable()}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon><span>Open Work Orders by Expected Completion Date</span>
		</h2>
		<div class="back-btn" *ngIf="woFiltersService.isDrilldownBackByDateEstAvailable()">
			<button type="button" class="btn btn-secondary" (click)="woFiltersService.drilldownBackByDateEst()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loaded}" class="chart-container long-height">
			<chart class="chart-container long-height" #overdueChart [type]="chartConfig.type" [data]="chartConfig.data" [options]="chartConfig.options"></chart>
		</div>

		<div *ngIf="!loaded"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span
			class="right"></span></span></span></div>
	</div>
</div>
