<div class="card has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> <input class="form-control" [(ngModel)]="model.title" >
		</h2>
	</div>
	<div class="card-body">
		<json-editor [options]="editorOptions" [data]="filterEditable" (change)="changeFilter($event)"></json-editor>
	</div>
</div>
