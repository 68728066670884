import { Component, OnInit } from '@angular/core';
import { StateService } from '../../services/state.service';
import { Store } from '../models/store';
import { ApplyFilters } from '../models/applyFilters';
import { AssetConditionsInfoByDescriptionService } from '../services/ac-by-description.service';
import { WhiteLabelService } from '../../services/white-label.service';

@Component({
  selector: 'app-asset-condition-by-description',
  templateUrl: './asset-condition-by-description.component.html'
})
export class AssetConditionByDescriptionComponent implements OnInit {
  tableByDescription = {
    rows: [],
    columns: [],
    conditionTotal: 0,
    year1Total: 0,
    year2Total: 0,
    year3Total: 0,
    year4Total: 0,
    year5Total: 0,
    year6Total: 0,
    year7Total: 0,
    year8Total: 0,
    year9Total: 0,
    year10Total: 0
  }

  assetConditionsInfoByDescriptionLoaded: boolean;

  constructor(private stateService: StateService,
    private assetConditionsInfoByDescriptionService: AssetConditionsInfoByDescriptionService,
    public whiteLabelService: WhiteLabelService) {
    if (this.whiteLabelService.isWhittlesea()) {
      this.tableByDescription.columns = [
        { prop: 'description', name: 'Description' },
        { prop: 'condition', name: 'Condition' },
        { prop: 'year1', name: 'Year 1' },
        { prop: 'year2', name: 'Year 2' },
        { prop: 'year3', name: 'Year 3' },
        { prop: 'year4', name: 'Year 4' },
        { prop: 'year5', name: 'Year 5' },
        { prop: 'year6', name: 'Year 6' },
        { prop: 'year7', name: 'Year 7' },
        { prop: 'year8', name: 'Year 8' },
        { prop: 'year9', name: 'Year 9' },
        { prop: 'year10', name: 'Year 10' }
      ];
    } else {
      this.tableByDescription.columns = [
        { prop: 'description', name: 'Description' },
        { prop: 'condition', name: 'Condition' },
        { prop: 'year1', name: 'Year 1' },
        { prop: 'year2', name: 'Year 2' },
        { prop: 'year3', name: 'Year 3' },
        { prop: 'year4', name: 'Year 4' },
        { prop: 'year5', name: 'Year 5' }
      ];
    }
  }

  ngOnInit() {
    this.assetConditionsInfoByDescriptionLoaded = false;
    this.stateService.getStoreAndFilter.subscribe((value: [Store, ApplyFilters]) => {
      this.updateAsset(value[0] ? value[0].storeNo : null, value[1]);
    });
  }

  updateAsset(storeNo: string, filters: ApplyFilters) {
    //this.assetConditionsInfoByDescriptionLoaded = false;
    this.tableByDescription.rows = [];
    this.tableByDescription.conditionTotal = 0;
    this.tableByDescription.year1Total = 0;
    this.tableByDescription.year2Total = 0;
    this.tableByDescription.year3Total = 0;
    this.tableByDescription.year4Total = 0;
    this.tableByDescription.year5Total = 0;
    this.tableByDescription.year6Total = 0;
    this.tableByDescription.year7Total = 0;
    this.tableByDescription.year8Total = 0;
    this.tableByDescription.year9Total = 0;
    this.tableByDescription.year10Total = 0;
    this.assetConditionsInfoByDescriptionService.getAssetConditionsInfoByDescription(storeNo, filters)
      .subscribe(assetConditionsInfoByDescription => {
        let conditionTotal = 0;
        let year1Total = 0;
        let year2Total = 0;
        let year3Total = 0;
        let year4Total = 0;
        let year5Total = 0;
        let year6Total = 0;
        let year7Total = 0;
        let year8Total = 0;
        let year9Total = 0;
        let year10Total = 0;
        assetConditionsInfoByDescription.forEach(element => {
          conditionTotal += element.condition;
          year1Total += element.year1;
          year2Total += element.year2;
          year3Total += element.year3;
          year4Total += element.year4;
          year5Total += element.year5;
          year6Total += element.year6;
          year7Total += element.year7;
          year8Total += element.year8;
          year9Total += element.year9;
          year10Total += element.year10;
        });
        conditionTotal /= assetConditionsInfoByDescription.length;
        this.tableByDescription.conditionTotal = conditionTotal;
        this.tableByDescription.year1Total = year1Total;
        this.tableByDescription.year2Total = year2Total;
        this.tableByDescription.year3Total = year3Total;
        this.tableByDescription.year4Total = year4Total;
        this.tableByDescription.year5Total = year5Total;
        this.tableByDescription.year6Total = year6Total;
        this.tableByDescription.year7Total = year7Total;
        this.tableByDescription.year8Total = year8Total;
        this.tableByDescription.year9Total = year9Total;
        this.tableByDescription.year10Total = year10Total;
        this.assetConditionsInfoByDescriptionLoaded = true;
        this.tableByDescription.rows = assetConditionsInfoByDescription;
      });
  }
}
