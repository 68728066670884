import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AssetConditionsRating } from '../models/assetConditionsRating';
import { ApplyFilters } from '../models/applyFilters';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../services/message.service';
import { AuthService } from '../../services/auth.service';
import { WhiteLabelService } from '../../services/white-label.service';

@Injectable()
export class AssetConditionsRatingsService {
  private apiUrl = this.whiteLabelService.getSettings.apiUrl + 'api/AssetConditionsRating';

  constructor(
    private http: HttpClient,
    private messages: MessageService,
    private authService: AuthService,
    private whiteLabelService: WhiteLabelService) { }

  getAssetConditionsRating(storeNumber: string | null, filters: ApplyFilters | null): Observable<AssetConditionsRating[]> {
    let url = storeNumber == null ? this.apiUrl : `${this.apiUrl}/${storeNumber}`;
    return this.http.post<AssetConditionsRating[]>(url, filters, this.authService.getHttpOptions()).pipe(
      catchError(this.messages.handleError(this.authService, 'getAssetConditionsRating(storeNumber, filters)', []))
    );
  }
}
