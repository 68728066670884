import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../services/message.service';
import { AuthService } from '../../services/auth.service';
import { Uploads } from '../models/uploads';
import { WhiteLabelService } from '../../services/white-label.service';

@Injectable()
export class FilesService {
  private apiUrl = this.whiteLabelService.getSettings.apiUrl + 'api/Files';

  constructor(private http: HttpClient,
    private messages: MessageService,
    private authService: AuthService,
    private whiteLabelService: WhiteLabelService) { }

  getFileName(storeNumber: string, blobName: string): string {
    return `${this.apiUrl}/${storeNumber}/${blobName}?instance=Platinum FM`;
  }

  getFiles(storeNumber: string): Observable<Uploads[]> {
    return this.http.get<Uploads[]>(`${this.apiUrl}/${storeNumber}`, this.authService.getHttpOptions()).pipe(
      catchError(this.messages.handleError(this.authService, 'getFiles(storeNumber)', []))
    );
  }

  getFile(storeNumber: string, blobName: string): Observable<HttpResponse<Blob>> {
    let token = this.authService.getToken();
    return this.http.get<Blob>(`${this.apiUrl}/${storeNumber}/${blobName}`, {
      headers: new HttpHeaders({
        "Authorization": token
      }),
      observe: 'response',
      responseType: 'blob' as 'json'
    }).pipe(
      catchError(this.messages.handleErrorSilent(this.authService, 'getFile(id)', null))
    );
  }

  uploadFile(formData: FormData, storeNumber: string): Observable<Uploads> {
    let token = this.authService.getToken();
    return this.http.post<Uploads>(`${this.apiUrl}/${storeNumber}`, formData, {
      headers: new HttpHeaders({
        "Authorization": token
      })
    });
  }

  deleteFile(id: number) {
    return this.http.delete<number>(this.apiUrl + `/${id}`, this.authService.getHttpOptions()).pipe(
      catchError(this.messages.handleError(this.authService, 'deleteFile(id)'))
    );
  }
}
