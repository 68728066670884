import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'azure-auth-callback',
    templateUrl: './azure-auth-callback.component.html'
})
export class AzureAuthCallbackComponent implements OnInit {

    constructor(private router: Router,
        private adal: AdalService,
        private auth: AuthService) { }

    ngOnInit() {
        this.adal.handleWindowCallback();
        this.auth.loginAzure();
    }
}
