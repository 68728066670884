import { Component, OnInit } from "@angular/core";
import {
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { ApplyFilters } from "../models/applyFilters";
import { Entity } from "../models/entity";
import { Filters } from "../models/filters";
import { Store } from "../models/store";
import { EntitiesService } from "../services/entities.service";
import { StoreFiltersService } from "../services/store-filters.service";
import { StoreService } from "../services/store.service";

@Component({
	selector: "app-asset-condition",
	templateUrl: "./asset-condition.component.html",
})
export class AssetConditionComponent implements OnInit {
	public isCollapsed = true;
	public filters: Filters;
	stores: Store[];
	storesAll: Store[];
	selectedStore: Store;
	selectedRetailBusinessMgr: string;
	selectedServiceDeliveryManager: string;
	selectedStoreState: string;
	selectedDescription: string;
	selectedDm: string;
	selectedFunctionalRepresentative: string;
	selectedFacilityType: string;
	entity: Entity;
	preventReload = false;
	roles$: Observable<string[]>;

	constructor(
		private entitiesService: EntitiesService,
		private authService: AuthService,
		private storeService: StoreService,
		public stateService: StateService,
		private storeFiltersService: StoreFiltersService,
		public whiteLabelService: WhiteLabelService
	) {}

	ngOnInit() {
		this.stateService.setHeaderTitle(
			this.whiteLabelService.getSettings.assetConditionName
		);
		this.roles$ = this.authService.getCurrentUserRoles;
		if (this.whiteLabelService.getSettings.assetConditionEnable) {
			this.roles$.subscribe((data) => {
				if (data.indexOf("admin") > -1 || data.indexOf("fm") > -1) {
					this.stateService.resetStoreAndFilters();
					this.getStores();
					this.filters = null;
					this.stateService.getStoreAndFilter.subscribe(
						(value: [Store, ApplyFilters]) => {
							if (!this.preventReload) {
								this.selectedStore = value[0];
								let filters = value[1];
								this.selectedRetailBusinessMgr = filters.retailBusinessMgr;
								this.selectedServiceDeliveryManager = filters.serviceDeliveryManager;
								this.selectedStoreState = filters.storeState;
								this.selectedDescription = filters.assetDescription;
							}
							this.preventReload = false;
						}
					);
					this.storeFiltersService.getFilters().subscribe((value) => {
						this.filters = value;
					});
					this.entitiesService.getCurrentEntity.subscribe((value) => {
						this.entity = value;
					});
				}
			});
		}
	}

	getStores(): void {
		this.storeService.getStores().subscribe((stores) => {
			this.storesAll = stores;
			this.stores = stores;
		});
	}

	onChangeStore(store) {
		this.preventReload = true;
		this.stateService.selectStore(store);
	}

	resetStoreAndFilters() {
		this.preventReload = true;
		this.selectedStore = null;
		this.stateService.resetStoreAndFilters();
	}

	onChange(item) {
		let newValue = new ApplyFilters();
		newValue.retailBusinessMgr = this.selectedRetailBusinessMgr;
		newValue.serviceDeliveryManager = this.selectedServiceDeliveryManager;
		newValue.storeState = this.selectedStoreState;
		newValue.assetDescription = this.selectedDescription;
		newValue.dm = this.selectedDm;
		newValue.functionalRepresentative = this.selectedFunctionalRepresentative;
		newValue.facilityType = this.selectedFacilityType;
		this.stateService.selectFilters(newValue);

		let arrays = [];
		if (
			typeof this.selectedDescription !== "undefined" &&
			this.selectedDescription != null
		) {
			arrays.push(this.filters.assetFilters[this.selectedDescription]);
		}
		if (
			typeof this.selectedRetailBusinessMgr !== "undefined" &&
			this.selectedRetailBusinessMgr != null
		) {
			arrays.push(
				this.filters.retailBusinessMgrFilters[this.selectedRetailBusinessMgr]
			);
		}
		if (
			typeof this.selectedServiceDeliveryManager !== "undefined" &&
			this.selectedServiceDeliveryManager != null
		) {
			arrays.push(
				this.filters.serviceDeliveryManagerFilters[
					this.selectedServiceDeliveryManager
				]
			);
		}
		if (
			typeof this.selectedStoreState !== "undefined" &&
			this.selectedStoreState != null
		) {
			arrays.push(this.filters.storeStateFilters[this.selectedStoreState]);
		}
		if (typeof this.selectedDm !== "undefined" && this.selectedDm != null) {
			arrays.push(this.filters.dmFilters[this.selectedDm]);
		}
		if (
			typeof this.selectedFunctionalRepresentative !== "undefined" &&
			this.selectedFunctionalRepresentative != null
		) {
			arrays.push(
				this.filters.functionalRepresentativeFilters[
					this.selectedFunctionalRepresentative
				]
			);
		}
		if (
			typeof this.selectedFacilityType !== "undefined" &&
			this.selectedFacilityType != null
		) {
			arrays.push(this.filters.facilityTypeFilters[this.selectedFacilityType]);
		}
		if (arrays.length > 0) {
			let storeNumbers = arrays.pop();
			if (arrays.length > 0) {
				arrays.forEach((array) => {
					storeNumbers = storeNumbers.filter((storeNumber) =>
						array.includes(storeNumber)
					);
				});
			}
			storeNumbers = Array.from(new Set(storeNumbers));
			this.stores = this.storesAll.filter(function (store) {
				return storeNumbers.includes(store.storeNo);
			});
		} else {
			this.stores = this.storesAll;
		}
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
}
