import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import { IMyDateRangeModel } from "mydaterangepicker";
import { forkJoin, Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { FinancialService } from "../services/financial.service";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-financial-actual-budget-category",
	templateUrl: "./financial-actual-budget-category.component.html",
})
export class FinancialActualBudgetCategoryComponent
	implements OnInit, OnDestroy
{
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loaded: boolean;
	countTooltip: any = {};
	chartConfig = {
		type: "bar",
		data: {
			labels: [],
			datasets: [],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: true,
			},
			scales: {
				xAxes: [
					{
						stacked: true,
						ticks: {
							autoSkip: false,
						},
					},
				],
				yAxes: [
					{
						stacked: true,
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "left",
						id: "y-axis-1",
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `\$${label.toLocaleString()}`;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data["datasets"][tooltipItem[0]["datasetIndex"]].label;
						return title === null ? "null" : title;
					},
					label: (tooltipItem, data) => {
						let type = data.datasets[tooltipItem.datasetIndex].label;
						let result = `$${tooltipItem.yLabel.toLocaleString()}`;
						if (type !== "Budget" && this.countTooltip[tooltipItem.xLabel]) {
							let count = this.countTooltip[tooltipItem.xLabel][type];
							result += ` / Count ${count}`;
						}
						return result;
					},
				},
			},
		},
	};
	@ViewChild("chartFABCategory") chart: ChartComponent;

	backgroundColor = [];
	hoverBackgroundColor = [];
	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private financialService: FinancialService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loaded = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Open", "Completed"];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		let query1 = this.workOrderReportService.getRequestSummaryGLCategory(body);
		let query2 = this.financialService.getBudgetSummaryGLCategory(body);

		this.subs.add(
			forkJoin([query1, query2]).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.countTooltip = {};
					let newTooltip = {};
					this.loaded = true;
					let newLabels = [];
					let newData = [];
					let types = ["Budget", "Actuals", "Forecast"];
					let glCategories = [];
					data[0].forEach((element) => {
						if (element.glCategory && !glCategories.includes(element.glCategory)) {
							glCategories.push(element.glCategory);
						}
					});
					data[1].forEach((element) => {
						if (element.glCategory && !glCategories.includes(element.glCategory)) {
							glCategories.push(element.glCategory);
						}
					});
					glCategories.sort();
					newLabels = glCategories;
					let array = [];
					glCategories.forEach((element) => {
						array.push(0);
						newTooltip[element] = {};
						newTooltip[element]["Actuals"] = 0;
						newTooltip[element]["Forecast"] = 0;
					});
					newData.push({
						label: "Budget",
						backgroundColor: this.backgroundColor[1],
						hoverBackgroundColor: this.hoverBackgroundColor[1],
						borderColor: this.backgroundColor[1],
						yAxisID: "y-axis-1",
						data: array.slice(),
						type: "bar",
						stack: "Stack 0",
					});
					newData.push({
						label: "Actuals",
						backgroundColor: this.backgroundColor[0],
						hoverBackgroundColor: this.hoverBackgroundColor[0],
						borderColor: this.backgroundColor[0],
						yAxisID: "y-axis-1",
						data: array.slice(),
						type: "bar",
						stack: "Stack 1",
					});
					newData.push({
						label: "Forecast",
						backgroundColor: this.backgroundColor[7],
						hoverBackgroundColor: this.hoverBackgroundColor[7],
						borderColor: this.backgroundColor[7],
						yAxisID: "y-axis-1",
						data: array.slice(),
						type: "bar",
						stack: "Stack 1",
					});
					data[0].forEach((element) => {
						var indexCategory = glCategories.indexOf(element.glCategory);
						if (indexCategory >= 0) {
							var indexActuals = types.indexOf("Actuals");
							var indexForecast = types.indexOf("Forecast");
							newData[indexActuals].data[indexCategory] += element.cost;
							newData[indexForecast].data[indexCategory] += element.costEstNet;

							newTooltip[element.glCategory]["Actuals"] +=
								element.count - element.costEstNetCount;
							newTooltip[element.glCategory]["Forecast"] += element.costEstNetCount;
						}
					});
					data[1].forEach((element) => {
						if (element.entityMatch) {
							let indexCategory = glCategories.indexOf(element.glCategory);
							if (indexCategory >= 0) {
								let indexBudget = types.indexOf("Budget");
								newData[indexBudget].data[indexCategory] += element.amount;
							}
						}
					});
					this.chartConfig.data.labels = newLabels;
					this.chartConfig.data.datasets = newData;
					this.countTooltip = newTooltip;
					if (this.chart) {
						this.chart.chart.update();
					}
					this.ref.detectChanges();
				}
			})
		);
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
