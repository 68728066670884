<main>
	<div *ngIf="!whiteLabelService.getSettings.enableAssetPerformanceTab" class="container">
		<div class="alert alert-warning font-weight-bold mt-3" role="alert">
			Not available.
		</div>
	</div>
	<div *ngIf="whiteLabelService.getSettings.enableAssetPerformanceTab" class="container">
		<div class="row mb-3">
			<nav aria-label="breadcrumb" class="w-100 half-strong">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">Home</li>
					<li class="breadcrumb-item"><span (click)="resetFilters()">Asset Performance Benchmark</span></li>
				</ol>
			</nav>
		</div>

		<div class="row">
			<div class="col-12 mb-4">
				<app-work-orders-nav></app-work-orders-nav>
			</div>
		</div>

		<div class="card has-invisible-content has-export mb-3">
			<div class="card-header">
				<h2 class="mb-0 h5">
					<fa-icon [icon]="faFilter"></fa-icon> Filters
				</h2>
				<div class="right-btns">
					<button *ngIf="isDashboardReady && config.dataSets.length < 4" [disabled]="inProgressSaveDashboard"
						type="button" class="btn btn-primary btn-filter" (click)="addDataSet()">
						<fa-icon [icon]="faPlusSquare"></fa-icon><span class="d-none d-md-inline ms-1"> Data Set</span>
					</button>
					<button *ngIf="isDashboardReady" [disabled]="inProgressSaveDashboard" type="button"
						class="btn btn-alert btn-filter" (click)="cancelEditDashboard()">
						<fa-icon [icon]="faUndo"></fa-icon><span class="d-none d-md-inline ms-1"> Cancel</span>
					</button>
					<button *ngIf="isDashboardReady" [disabled]="inProgressSaveDashboard" type="button"
						class="btn btn-info btn-filter" (click)="saveDashboard()">
						<fa-icon [icon]="faSave"></fa-icon><span class="d-none d-md-inline ms-1"> Save/Update</span>
					</button>
				</div>
			</div>
			<div class="card-body">
				<div *ngIf="isDashboardReady && config && config.dataSets" class="asset-performance-filters">
					<div *ngFor="let dataSet of config.dataSets; let j = index" class="row-edit no-drug">
						<div class="row-edit--head">
							<span class="row-edit--title">Data Set {{j + 1}}</span>
							<button type="button" class="btn btn-danger" (click)="deleteDataSet(j)">
								<fa-icon [icon]="faTrashAlt"></fa-icon>
							</button>
							<button *ngIf="j < 2 && dataSet.series.length < 3 || dataSet.series.length < 2"
								type="button" class="btn btn-primary" (click)="addSeries(j)">
								<fa-icon [icon]="faPlusSquare"></fa-icon><span class="ms-2">Series</span>
							</button>
						</div>
						<div class="row-edit--body">
							<div *ngFor="let series of dataSet.series; let i = index" class="widget-edit--wrapper">
								<div class="widget-edit">
									<div class="widget-edit--head">
										<span class="widget-edit--title">Series {{i + 1}}</span>
										<button *ngIf="i === (dataSet.series.length - 1)" type="button"
											class="btn btn-danger" (click)="deleteSeries(j, series)">
											<fa-icon [icon]="faTrashAlt"></fa-icon>
										</button>
									</div>
									<app-asset-performance-series *ngIf="i < 2 || config.dataSets.length < 3"
										[(model)]="dataSet.series[i]"></app-asset-performance-series>
									<p class="ml-3 mt-3" *ngIf="i >= 2 && config.dataSets.length >= 3">Disabled</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="!isDashboardReady"><span class="loader"><span class="loader-inner"><span
								class="left">Loading...</span><span class="right"></span></span></span></div>
			</div>
		</div>

		<div class="row" *ngIf="isDashboardReady">
			<div class="col-12 mb-4">
				<ul class="nav nav-tabs work-orders-tabs">
					<li class="nav-item">
						<a class="nav-link" [ngClass]="{'active': selectedTab === '1'}" (click)="selectTab('1')"
							data="link">Annualised Average Maintenance Cost</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" [ngClass]="{'active': selectedTab === '2'}" (click)="selectTab('2')"
							data="link">Annualised Average Maintenance Count</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" [ngClass]="{'active': selectedTab === '3'}" (click)="selectTab('3')"
							data="link">Mean Time Between Failures (MTBF)</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" [ngClass]="{'active': selectedTab === '4'}" (click)="selectTab('4')"
							data="link">Average Maintenance Cost by Calendar Month</a>
					</li>
				</ul>
			</div>

			<div class="col-12 mb-3">
				<app-asset-performance-age *ngIf="selectedTab === '1'" [selectedDisplayType]="'1'"
					[model]="config"></app-asset-performance-age>
				<app-asset-performance-age *ngIf="selectedTab === '2'" [selectedDisplayType]="'2'"
					[model]="config"></app-asset-performance-age>
				<app-asset-performance-age *ngIf="selectedTab === '3'" [selectedDisplayType]="'3'"
					[model]="config"></app-asset-performance-age>
				<app-asset-performance-summary-asset *ngIf="selectedTab !== '4'"
					[model]="config"></app-asset-performance-summary-asset>
				<app-asset-performance-month *ngIf="selectedTab === '4'" [selectedDisplayType]="'1'"
					[model]="config"></app-asset-performance-month>
			</div>
		</div>
	</div>
</main>