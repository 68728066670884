import {
	Injectable
} from '@angular/core';
import {
	HttpClient
} from '@angular/common/http';
import {
	Observable
} from 'rxjs';
import {
	catchError
} from 'rxjs/operators';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	MessageService
} from '../../services/message.service';
import {
	AuthService
} from '../../services/auth.service';
import { WhiteLabelService } from '../../services/white-label.service';
import { BudgetSummaryResponseModel } from '../models/budgetSummaryResponseModel';
import { BudgetSummaryMonthResponseModel } from '../models/budgetSummaryMonthResponseModel';
import { BudgetSummaryStateResponseModel } from '../models/budgetSummaryStateResponseModel';
import { BudgetSummaryEntityResponseModel } from '../models/budgetSummaryEntityResponseModel';
import { BudgetSummaryGLCodeResponseModel } from '../models/budgetSummaryGLCodeResponseModel';
import { BudgetSummaryGLCategoryResponseModel } from '../models/budgetSummaryGLCategoryResponseModel';
import { BudgetSummaryStoreTypeResponseModel } from '../models/budgetSummaryStoreTypeResponseModel';
import { BudgetSummaryStoreProfileResponseModel } from '../models/budgetSummaryStoreProfileResponseModel';

@Injectable()
export class FinancialService {
	private apiUrl = this.whiteLabelService.getSettings.informApiUrl + 'api/';

	constructor(
		private http: HttpClient,
		private messages: MessageService,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService) { }

	getBudgetSummary(model: QueryOptionsModel | null): Observable<BudgetSummaryResponseModel[]> {
		let url = this.apiUrl + 'Finance/BudgetSummary';
		return this.http.post<BudgetSummaryResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getBudgetSummary(model)', []))
		);
	}

	getBudgetSummaryMonth(model: QueryOptionsModel | null): Observable<BudgetSummaryMonthResponseModel[]> {
		let url = this.apiUrl + 'Finance/BudgetSummaryMonth';
		return this.http.post<BudgetSummaryMonthResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getBudgetSummaryMonth(model)', []))
		);
	}

	getBudgetSummaryState(model: QueryOptionsModel | null): Observable<BudgetSummaryStateResponseModel[]> {
		let url = this.apiUrl + 'Finance/BudgetSummaryState';
		return this.http.post<BudgetSummaryStateResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getBudgetSummaryState(model)', []))
		);
	}

	getBudgetSummaryStoreType(model: QueryOptionsModel | null): Observable<BudgetSummaryStoreTypeResponseModel[]> {
		let url = this.apiUrl + 'Finance/BudgetSummaryStoreType';
		return this.http.post<BudgetSummaryStoreTypeResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getBudgetSummaryStoreType(model)', []))
		);
	}

	getBudgetSummaryStoreProfile(model: QueryOptionsModel | null): Observable<BudgetSummaryStoreProfileResponseModel[]> {
		let url = this.apiUrl + 'Finance/BudgetSummaryStoreProfile';
		return this.http.post<BudgetSummaryStoreProfileResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getBudgetSummaryStoreProfile(model)', []))
		);
	}

	getBudgetSummaryEntity(model: QueryOptionsModel | null): Observable<BudgetSummaryEntityResponseModel[]> {
		let url = this.apiUrl + 'Finance/BudgetSummaryEntity';
		return this.http.post<BudgetSummaryEntityResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getBudgetSummaryEntity(model)', []))
		);
	}

	getBudgetSummaryGLCode(model: QueryOptionsModel | null): Observable<BudgetSummaryGLCodeResponseModel[]> {
		let url = this.apiUrl + 'Finance/BudgetSummaryGLCode';
		return this.http.post<BudgetSummaryGLCodeResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getBudgetSummaryGLCode(model)', []))
		);
	}

	getBudgetSummaryGLCategory(model: QueryOptionsModel | null): Observable<BudgetSummaryGLCategoryResponseModel[]> {
		let url = this.apiUrl + 'Finance/BudgetSummaryGLCategory';
		return this.http.post<BudgetSummaryGLCategoryResponseModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getBudgetSummaryGLCategory(model)', []))
		);
	}
}