import {
	Component,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import {
	StateService
} from '../../services/state.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	faArrowAltCircleLeft,
	faTable
} from '@fortawesome/free-solid-svg-icons';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	DecimalPipe
} from '@angular/common';
import {
	QueryOptionsPagingModel
} from '../models/queryOptionsPagingModel';
import {
	DatatableComponent
} from '@swimlane/ngx-datatable';
import { AuthService } from '../../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { WhiteLabelService } from '../../services/white-label.service';

@Component({
	selector: 'app-work-orders-open-store',
	templateUrl: './work-orders-open-store.component.html'
})
export class WorkOrdersOpenStoreComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loadedByStore: boolean;
	tableDataByStore = {
		rows: [],
		columns: [{
			prop: 'storeNo',
			name: 'Store No',
			headerClass: 'col-2',
			cellClass: 'col-2 cursor-pointer',
			resizeable: false,
			draggable: false
		}, {
			prop: 'name',
			name: 'Name',
			headerClass: 'col-3',
			cellClass: 'col-3 cursor-pointer',
			resizeable: false,
			draggable: false
		}, {
			prop: 'state',
			name: 'State',
			headerClass: 'col-2',
			cellClass: 'col-2 cursor-pointer',
			resizeable: false,
			draggable: false
		}, {
			prop: 'Incident',
			name: 'Incident',
			headerClass: 'text-right',
			cellClass: 'text-right cursor-pointer',
			resizeable: false,
			draggable: false,
			pipe: new DecimalPipe("en-US")
		}, {
			prop: 'Planned',
			name: 'Planned',
			headerClass: 'col text-right',
			cellClass: 'col text-right cursor-pointer',
			resizeable: false,
			draggable: false,
			pipe: new DecimalPipe("en-US")
		}, {
			prop: 'Reactive',
			name: 'Reactive',
			headerClass: 'col text-right',
			cellClass: 'col text-right cursor-pointer',
			resizeable: false,
			draggable: false,
			pipe: new DecimalPipe("en-US")
		}, {
			prop: 'Total',
			name: 'Total',
			headerClass: 'col text-right',
			cellClass: 'col text-right cursor-pointer',
			resizeable: false,
			draggable: false,
			pipe: new DecimalPipe("en-US")
		}],
		total: [],
		onNameClick: (row) => {
			for (var i = 0; i < this.woFiltersService.stores.length; i++) {
				if (this.woFiltersService.stores[i].id === row.id) {
					this.woFiltersService.isCollapsed = false;
					this.woFiltersService.selectedStore = [this.woFiltersService.stores[i]];
					break;
				}
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.tableByStore.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		}
	};
	@ViewChild('openWOStoreTable') tableByStore: DatatableComponent;

	public facilityTypeMode: boolean;
	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public whiteLabelService: WhiteLabelService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService) {
		this.facilityTypeMode = this.whiteLabelService.getFacilityTypeMode;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByStore = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(this.stateService.getCurrentDateRange(), value);
		}));
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ['Open'];
		body = this.woFiltersService.fillBodyEndDateThisMonth(body);
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByStore = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		//#region Table 3
		this.subs.add(this.workOrderReportService.getRequestSummaryEntity(body)
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					let headers = ["Incident", "Planned", "Reactive", "Total"];
					let newData: {
						[id: string]: any;
					} = {};
					let newTotal = [];
					headers.forEach(element => {
						newTotal.push(0);
					});
					data.forEach(element => {
						let rowId = `${element.name}--${element.storeNo}`;
						if (typeof newData[rowId] === "undefined") {
							let newRow = {
								id: element.id,
								storeNo: element.storeNo,
								name: element.name,
								state: this.facilityTypeMode ? element.storeType : element.state,
								count: 0
							};
							headers.forEach(element => {
								newRow[element] = 0;
							});
							newData[rowId] = newRow;
						}
						newData[rowId][element.requestType] += element.count;
						newData[rowId]["Total"] += element.count;
						let index = headers.indexOf(element.requestType);
						newTotal[index] += element.count;
						let indexTotal = headers.indexOf("Total");
						newTotal[indexTotal] += element.count;
					});

					let newDataArray = [];
					for (let key in newData) {
						newDataArray.push(newData[key]);
					}

					this.tableDataByStore.total = newTotal;
					this.tableDataByStore.rows = newDataArray;
					this.loadedByStore = true;
					this.ref.detectChanges();
				}
			}));
		//#endregion
	}

	// Icons
	faTable = faTable;
	faArrowAltCircleLeft = faArrowAltCircleLeft;

}