import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AdalService } from "adal-angular4";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { UserProfileModel } from "../models/userProfileModel";
import { StateService } from "../services/state.service";
import { MessageService } from "./message.service";
import { WhiteLabelService } from "./white-label.service";

@Injectable()
export class AuthService {
	private apiUrl =
		this.whiteLabelService.getSettings.informApiUrl + "api/Users/Login";

	private loginInProgress: BehaviorSubject<boolean>;
	private loggedIn: BehaviorSubject<boolean>;
	private invalidLogin: BehaviorSubject<boolean>;
	private currentUsername: BehaviorSubject<string>;
	private roles: BehaviorSubject<string[]>;
	private userProfile: BehaviorSubject<UserProfileModel>;

	get isLoginInProgress() {
		return this.loginInProgress.asObservable();
	}

	get isLoggedIn() {
		return this.loggedIn.asObservable();
	}

	get isInvalidLogin() {
		return this.invalidLogin.asObservable();
	}

	get getCurrentUsername() {
		return this.currentUsername.asObservable();
	}

	get getCurrentUserRoles() {
		return this.roles.asObservable();
	}

	get getCurrentUserProfile() {
		return this.userProfile.asObservable();
	}

	constructor(
		private http: HttpClient,
		private router: Router,
		private messages: MessageService,
		private stateService: StateService,
		private adal: AdalService,
		private _zone: NgZone,
		private whiteLabelService: WhiteLabelService
	) {
		let adalConfig = environment.adalConfig[whiteLabelService.getWhiteLabel];
		if (adalConfig) {
			this.adal.init(adalConfig);
		}
		this.loginInProgress = new BehaviorSubject<boolean>(false);
		let isTokenValid = this.isTokenValid();
		this.loggedIn = new BehaviorSubject<boolean>(isTokenValid);
		this.invalidLogin = new BehaviorSubject<boolean>(false);
		this.currentUsername = new BehaviorSubject<string>(
			localStorage.getItem("username")
		);
		this.roles = new BehaviorSubject<string[]>([]);
		this.userProfile = new BehaviorSubject<UserProfileModel>(null);
		if (isTokenValid) {
			this.loadRoles();
			this.loadUserProfile();
		}
	}

	isTokenValid() {
		let azureAuth =
			this.adal && this.adal.userInfo && this.adal.userInfo.authenticated;
		if (azureAuth) {
			return true;
		}
		var expiredOn = localStorage.getItem("expiredOn");
		//console.log("expired on: " + expiredOn + " || " + new Date(expiredOn));
		if (expiredOn && new Date(expiredOn) > new Date()) {
			return true;
		}
		return false;
	}

	getRoles(): Observable<string[]> {
		let url = this.whiteLabelService.getSettings.informApiUrl + "api/Users/Roles";
		return this.http
			.get<string[]>(url, this.getHttpOptions())
			.pipe(catchError(this.messages.handleError(this, "getRoles()", [])));
	}

	loadRoles() {
		this.getRoles().subscribe(
			(data) => {
				this.roles.next(data);
			},
			(error) => {
				console.error(error);
				this.messages.error("Error", `Can't load user roles: ${error.message}`);
			}
		);
	}

	getUserProfile(): Observable<UserProfileModel> {
		let url =
			this.whiteLabelService.getSettings.informApiUrl + "api/Users/GetUserProfile";
		return this.http
			.get<UserProfileModel>(url, this.getHttpOptions())
			.pipe(catchError(this.messages.handleError(this, "getUserProfile()", null)));
	}

	loadUserProfile() {
		this.getUserProfile().subscribe(
			(data) => {
				this.userProfile.next(data);
			},
			(error) => {
				console.error(error);
				this.messages.error("Error", `Can't load user profile: ${error.message}`);
			}
		);
	}

	login(user: any) {
		if (user.username === "" || user.password === "") {
			console.error("Empty username or password");
			this.messages.error("Error", "Please enter username and password");
			this.invalidLogin.next(true);
		} else {
			let credentials = JSON.stringify(user);
			this.loginInProgress.next(true);
			this.invalidLogin.next(false);
			this.http
				.post(this.apiUrl, credentials, {
					headers: new HttpHeaders({
						"Content-Type": "application/json",
					}),
				})
				.subscribe(
					(response) => {
						let token = (<any>response).token;
						let expiredOn = (<any>response).expiredOn;
						localStorage.setItem("username", user.username);
						localStorage.setItem("token", token);
						localStorage.setItem("expiredOn", expiredOn);
						this.invalidLogin.next(false);
						this.loggedIn.next(true);
						this.loginInProgress.next(false);
						this.currentUsername.next(user.username);
						this.loadRoles();
						this.loadUserProfile();
						this.stateService.selectStore(null);
						this.router.navigate(["/"]);
					},
					(error) => {
						console.error(error);
						if (error.status === 401) {
							this.messages.error("Error", "Invalid username or password");
						} else {
							this.messages.error("Error", `login failed: ${error.message}`);
						}
						this.invalidLogin.next(true);
						this.loginInProgress.next(false);
					}
				);
		}
	}

	loginAzure() {
		if (this.adal && this.adal.userInfo && this.adal.userInfo.authenticated) {
			localStorage.setItem("username", this.adal.userInfo.userName);
			localStorage.setItem("jwt-ad-token", this.adal.userInfo.token);
			this.invalidLogin.next(false);
			this.loggedIn.next(true);
			this.loginInProgress.next(false);
			this.currentUsername.next(this.adal.userInfo.userName);
			this.loadRoles();
			this.loadUserProfile();
			this.stateService.selectStore(null);
			setTimeout(() => {
				this._zone.run(() => this.router.navigate(["/"]));
			}, 200);
		}
	}

	loginSamlStep1() {
		// redirect to api -> to saml provider(okta)
		let samlConfig = environment.samlConfig[this.whiteLabelService.getWhiteLabel];
		window.open(samlConfig.samlSsoUrl, "_self");
	}

	loginSamlStep2() {
		var jwt = window.location.hash.slice(1);
		//console.log(jwt);
		if (jwt) {
			// Save jwt to browser storage
			localStorage.setItem("jwt-saml-token", jwt);

			// // Decode the token
			const helper = new JwtHelperService();
			const decodedToken = helper.decodeToken(jwt);
			console.log(decodedToken);

			localStorage.setItem("username", decodedToken.sub);
			const expiredOn = decodedToken.exp * 1000;
			localStorage.setItem("expiredOn", expiredOn.toString());
			this.invalidLogin.next(false);
			this.loggedIn.next(true);
			this.loginInProgress.next(false);
			this.currentUsername.next(decodedToken.sub);
			this.loadRoles();
			this.loadUserProfile();
			this.stateService.selectStore(null);
			setTimeout(() => {
				this._zone.run(() => this.router.navigate(["/"]));
			}, 200);
		}
	}

	logout() {
		//localStorage.removeItem("username");
		//localStorage.removeItem("token");
		//localStorage.removeItem("jwt-ad-token");
		const isSaml = !!localStorage.getItem("jwt-saml-token");
		localStorage.removeItem("jwt-saml-token");
		//localStorage.removeItem("expiredOn");
		this.loggedIn.next(false);
		this.invalidLogin.next(false);
		this.roles.next([]);
		this.userProfile.next(null);
		if (this.adal && this.adal.userInfo && this.adal.userInfo.authenticated) {
			this.adal.logOut();
		}
		if (isSaml) {
			// redirect to api -> to saml provider(okta)
			let samlConfig =
				environment.samlConfig[this.whiteLabelService.getWhiteLabel];
			window.open(samlConfig.samlSloUrl, "_self");
		} else {
			setTimeout(() => {
				this._zone.run(() => this.router.navigate(["/login"]));
			}, 200);
		}
	}

	getToken(): string {
		let pfmToken = localStorage.getItem("token");
		if (pfmToken) {
			return "Token " + pfmToken;
		} 
		
		pfmToken = localStorage.getItem("jwt-ad-token");
		if (pfmToken) {
			return "Bearer " + pfmToken;
		}
		
		pfmToken = localStorage.getItem("jwt-saml-token");
		if (pfmToken) {
			return "Bearer " + pfmToken;
		}

		return undefined;
	}

	getHttpOptions() {
		let httpOptions = {
			headers: new HttpHeaders({
				Authorization: this.getToken(),
				"Content-Type": "application/json",
			}),
			params: new HttpParams(),
		};
		return httpOptions;
	}
}
