export class Filter {
	authUserId: number;
	fromDt: Date | string | null;
	toDt: Date | string | null;
	fromEstCompletedDt: Date | string | null;
	toEstCompletedDt: Date | string | null;
	overallStatuses: string[];
	states: string[];
	vendorIds: number[];
	vendorNames: string[];
	vendorExclusions: string[];
	storeIds: number[];
	assetCategories: string[];
	assetNames: string[];
	brands: string[];
	districts: string[];
	statuses: string[];
	statusIds: number[];
	requestTypes: string[];
	priorities: string[];
	glCostTypes: string[];
	glDescriptions: string[];
	glStatuses: string[];
	serviceDeliveryManagers: string[];
	retailBusinessManagers: string[];
	regionalManagers: string[];
	categories: string[];
	categoryManagers: string[];
	storeTypes: string[];
	storeProfiles: string[];
	storeClusters: string[];
	districtManagers: string[];
	deliveryIndicators: string[];
	startDate: Date | string | null;
	endDate: Date | string | null;
	isActive: boolean | null;
	excludeExistingReports: boolean | null;
	reportTypes: string[];
	assetTypeTags: string[];
}
