import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import * as moment from "moment";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-vendor-performance-status-month",
	templateUrl: "./vendor-performance-status-month.component.html",
})
export class VendorPerformanceStatusMonthComponent
	implements OnInit, OnDestroy
{
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByMonth: boolean;
	chartConfigByMonth = {
		type: "bar",
		data: {
			labels: [],
			datasets: [],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: true,
			},
			scales: {
				xAxes: [
					{
						stacked: true,
						ticks: {
							autoSkip: false,
						},
					},
				],
				yAxes: [
					{
						stacked: true,
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "left",
						id: "y-axis-1",
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `${label.toLocaleString()}`;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data["datasets"][tooltipItem[0]["datasetIndex"]].label;
						return title === null ? "null" : title;
					},
					label: function (tooltipItem, data) {
						return `${tooltipItem.yLabel.toLocaleString()}`;
					},
				},
			},
			onClick: (event) => {
				var activePoints = this.chartByMonth.chart.getElementsAtEvent(event);
				if (
					activePoints &&
					activePoints.length > 0 &&
					activePoints[0]._model &&
					activePoints[0]._model.label
				) {
					this.woFiltersService.isCollapsed = false;
					let value = activePoints[0]._model.label;
					let m = moment(value + "/01", "YYYY/MM/DD");
					let beginDate = m.toDate();
					let endDate = m.add(1, "months").date(0).toDate();
					this.stateService.updateDateRange("set", beginDate, endDate);
				}
			},
		},
	};
	@ViewChild("monthChart") chartByMonth: ChartComponent;

	backgroundColor = [];
	hoverBackgroundColor = [];
	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByMonth = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.roles$.subscribe((data) => {
				if (
					data.length > 0 &&
					data.indexOf("store") < 0 &&
					data.indexOf("rm") < 0
				) {
					this.subs.add(
						this.woFiltersService.getUpdateId.subscribe((value) => {
							this.update(this.stateService.getCurrentDateRange(), value);
						})
					);
				}
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		return this.woFiltersService.fillBody(body, dateRange);
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByMonth = false;

		//#region Chart 1
		let bodyChart1 = this.fillBody(new QueryOptionsModel(), dateRange);
		bodyChart1.filter.overallStatuses = ["Open", "Completed"];
		bodyChart1.filter.requestTypes = ["Planned"];
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryMonth(bodyChart1)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.loadedByMonth = true;
						let newLabels = [];
						let newData = [];
						let types = [];
						let monthes = [];
						data.forEach((element) => {
							let month =
								element.year +
								"/" +
								element.month.toLocaleString("en-US", {
									minimumIntegerDigits: 2,
									useGrouping: false,
								});
							if (!monthes.includes(month)) {
								monthes.push(month);
							}
							if (!types.includes(element.overallStatus)) {
								types.push(element.overallStatus);
							}
						});
						monthes.sort();
						newLabels = monthes;
						var array = [];
						monthes.forEach((element2) => {
							array.push(0);
						});
						types.sort();
						let i = 0;
						types.forEach((element) => {
							newData.push({
								label: element + " Count",
								backgroundColor: this.backgroundColor[i],
								hoverBackgroundColor: this.hoverBackgroundColor[i],
								yAxisID: "y-axis-1",
								data: array.slice(),
								type: "bar",
							});
							i++;
						});
						data.forEach((element) => {
							let month =
								element.year +
								"/" +
								element.month.toLocaleString("en-US", {
									minimumIntegerDigits: 2,
									useGrouping: false,
								});
							var indexMonth = monthes.indexOf(month);
							var indexTypeCount = types.indexOf(element.overallStatus);
							newData[indexTypeCount].data[indexMonth] += element.count;
						});
						this.chartConfigByMonth.data.labels = newLabels;
						this.chartConfigByMonth.data.datasets = newData;
						if (this.chartByMonth) {
							this.chartByMonth.chart.update();
						}
						this.ref.detectChanges();
					}
				})
		);
		//#endregion
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
