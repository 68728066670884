<main class="pt-0">
  <div
    *ngIf="
      !whiteLabelService.getSettings.assetConditionEnable ||
      ((roles$ | async)?.length > 0 &&
        (roles$ | async)?.indexOf('admin') < 0 &&
        (roles$ | async)?.indexOf('fm') < 0)
    "
    class="container"
  >
    <div class="alert alert-warning font-weight-bold mt-3" role="alert">
      Not available for current user role.
    </div>
  </div>
  <div
    *ngIf="
      whiteLabelService.getSettings.assetConditionEnable &&
      ((roles$ | async)?.indexOf('admin') > -1 ||
        (roles$ | async)?.indexOf('fm') > -1)
    "
    class="container"
  >
    <div class="row mb-3">
      <nav aria-label="breadcrumb" class="w-100 half-strong">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Home</li>
          <li class="breadcrumb-item">
            <span (click)="resetStoreAndFilters()">{{
              whiteLabelService.getSettings.assetConditionName
            }}</span>
          </li>
          <li
            class="breadcrumb-item active"
            aria-current="page"
            *ngIf="selectedStore"
          >
            {{ selectedStore.longName }}
          </li>
        </ol>
      </nav>
    </div>
    <div
      class="row"
      *ngIf="
        whiteLabelService.getSettings.assetConditionEnable &&
        whiteLabelService.getSettings.assetConditionShowInTopNav
      "
    >
      <div class="col-12 mb-4">
        <app-work-orders-nav></app-work-orders-nav>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3 asser-conditional-filters">
        <div class="store-filters d-flex align-items-end">
          <div>
            <ng-select
              [dropdownPosition]="'bottom'"
              [items]="stores"
              bindLabel="longName"
              [(ngModel)]="selectedStore"
              (change)="onChangeStore($event)"
              placeholder="Select {{
                whiteLabelService.getSettings.assetConditionStoreText
              }}"
              class="dropdown-select"
            >
            </ng-select>
          </div>
          <button
            type="button"
            class="btn btn-primary btn-filter ms-3"
            (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseFilters"
          >
            <fa-icon [icon]="faFilter"></fa-icon
            ><span class="d-none d-sm-inline ms-2">Filters</span>
            <fa-icon
              [icon]="faCaretDown"
              class="ms-2 d-none d-md-inline"
            ></fa-icon>
          </button>
        </div>
        <div id="collapseFilters" [ngbCollapse]="isCollapsed">
          <div class="dropdown-filters">
            <div class="dropdown-filters-inner">
              <div *ngIf="filters" class="filters-wrapper">
                <div class="mb-3" *ngIf="whiteLabelService.is7Eleven()">
                  <label class="half-strong" [class.disabled]="selectedStore"
                    >Retail Business Manager</label
                  >
                  <ng-select
                    [disabled]="selectedStore"
                    (change)="onChange($event)"
                    [(ngModel)]="selectedRetailBusinessMgr"
                    [dropdownPosition]="'bottom'"
                    placeholder="Retail Business Manager"
                    class="dropdown-select"
                  >
                    <ng-option
                      *ngFor="
                        let item of filters.retailBusinessMgrFilters | keyvalue
                      "
                      [value]="item.key"
                      >{{ item.key == "" ? "Empty" : item.key }}</ng-option
                    >
                  </ng-select>
                </div>

                <div class="mb-3" *ngIf="whiteLabelService.is7Eleven()">
                  <label class="half-strong" [class.disabled]="selectedStore"
                    >Service Delivery Manager</label
                  >
                  <ng-select
                    [disabled]="selectedStore"
                    (change)="onChange($event)"
                    [(ngModel)]="selectedServiceDeliveryManager"
                    [dropdownPosition]="'bottom'"
                    placeholder="Service Delivery Manager"
                    class="dropdown-select"
                  >
                    <ng-option
                      *ngFor="
                        let item of filters.serviceDeliveryManagerFilters
                          | keyvalue
                      "
                      [value]="item.key"
                      >{{ item.key == "" ? "Empty" : item.key }}</ng-option
                    >
                  </ng-select>
                </div>

                <div class="mb-3" *ngIf="!whiteLabelService.isWhittlesea()">
                  <label class="half-strong" [class.disabled]="selectedStore"
                    >State</label
                  >
                  <ng-select
                    [disabled]="selectedStore"
                    (change)="onChange($event)"
                    [(ngModel)]="selectedStoreState"
                    [dropdownPosition]="'bottom'"
                    placeholder="State"
                    class="dropdown-select"
                  >
                    <ng-option
                      *ngFor="let item of filters.storeStateFilters | keyvalue"
                      [value]="item.key"
                      >{{ item.key == "" ? "Empty" : item.key }}</ng-option
                    >
                  </ng-select>
                </div>

                <div class="mb-3">
                  <label class="half-strong">Asset Description</label>
                  <ng-select
                    (change)="onChange($event)"
                    [(ngModel)]="selectedDescription"
                    [dropdownPosition]="'bottom'"
                    placeholder="Asset Description"
                    class="dropdown-select"
                  >
                    <ng-option
                      *ngFor="let item of filters.assetFilters | keyvalue"
                      [value]="item.key"
                      >{{ item.key == "" ? "Empty" : item.key }}</ng-option
                    >
                  </ng-select>
                </div>

                <div class="mb-3" *ngIf="whiteLabelService.isStarbucks()">
                  <label class="half-strong" [class.disabled]="selectedStore"
                    >District Manager</label
                  >
                  <ng-select
                    [disabled]="selectedStore"
                    (change)="onChange($event)"
                    [(ngModel)]="selectedDm"
                    [dropdownPosition]="'bottom'"
                    placeholder="District Manager"
                    class="dropdown-select"
                  >
                    <ng-option
                      *ngFor="let item of filters.dmFilters | keyvalue"
                      [value]="item.key"
                      >{{ item.key == "" ? "Empty" : item.key }}</ng-option
                    >
                  </ng-select>
                </div>

                <div class="mb-3" *ngIf="whiteLabelService.isWhittlesea()">
                  <label class="half-strong" [class.disabled]="selectedStore"
                    >Functional Site Representative</label
                  >
                  <ng-select
                    [disabled]="selectedStore"
                    (change)="onChange($event)"
                    [(ngModel)]="selectedFunctionalRepresentative"
                    [dropdownPosition]="'bottom'"
                    placeholder="Functional Site Representative"
                    class="dropdown-select"
                  >
                    <ng-option
                      *ngFor="
                        let item of filters.functionalRepresentativeFilters
                          | keyvalue
                      "
                      [value]="item.key"
                      >{{ item.key == "" ? "Empty" : item.key }}</ng-option
                    >
                  </ng-select>
                </div>

                <div class="mb-3" *ngIf="whiteLabelService.isWhittlesea()">
                  <label class="half-strong" [class.disabled]="selectedStore"
                    >Facility Type</label
                  >
                  <ng-select
                    [disabled]="selectedStore"
                    (change)="onChange($event)"
                    [(ngModel)]="selectedFacilityType"
                    [dropdownPosition]="'bottom'"
                    placeholder="Facility Type"
                    class="dropdown-select"
                  >
                    <ng-option
                      *ngFor="
                        let item of filters.facilityTypeFilters | keyvalue
                      "
                      [value]="item.key"
                      >{{ item.key == "" ? "Empty" : item.key }}</ng-option
                    >
                  </ng-select>
                </div>
              </div>
              <div
                *ngIf="!filters"
                class="loader-row d-flex justify-content-center align-items-center"
              >
                <span class="loader"
                  ><span class="loader-inner"
                    ><span class="left">Loading...</span
                    ><span class="right"></span></span
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="entity">
      <div class="col-12 col-lg-4 mb-3">
        <div class="card full-height-lg">
          <div class="card-header">
            <h2 class="mb-0 h5">
              <fa-icon [icon]="faClipboardList"></fa-icon>
              {{ whiteLabelService.getSettings.assetConditionStoreText }} Info
            </h2>
          </div>
          <div class="card-body">
            <app-store-info></app-store-info>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 mb-3">
        <div class="card full-height-lg">
          <div class="card-header">
            <h2 class="mb-0 h5">
              <fa-icon [icon]="faCalendarAlt"></fa-icon>
              {{ whiteLabelService.getSettings.assetConditionStoreText }}
              Dashboard
            </h2>
          </div>
          <div class="card-body p-0">
            <app-store-dashboard></app-store-dashboard>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-3">
        <div class="card info-card">
          <div class="card-header">
            <h2 class="mb-0 h5"><fa-icon [icon]="faInfo"></fa-icon> Asset</h2>
          </div>
          <div class="card-body">
            <app-assets></app-assets>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="card full-height-lg">
          <div class="card-header">
            <h2 class="mb-0 h5">
              <fa-icon [icon]="faChartColumn"></fa-icon> Asset Condition Rating
            </h2>
          </div>
          <div class="card-body">
            <app-asset-condition-rating></app-asset-condition-rating>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="card full-height-lg">
          <div class="card-header">
            <h2 class="mb-0 h5">
              <fa-icon [icon]="faChartColumn"></fa-icon> Life Cycle Forecast
            </h2>
          </div>
          <div class="card-body">
            <app-life-cycle-forecast></app-life-cycle-forecast>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-3" *ngIf="!selectedStore">
        <div class="card">
          <div class="card-header">
            <h2 class="mb-0 h5">
              <fa-icon [icon]="faTable"></fa-icon> Asset Condition by
              {{ whiteLabelService.getSettings.assetConditionStoreText }}
            </h2>
          </div>
          <div class="card-body p-0">
            <app-asset-condition-by-store></app-asset-condition-by-store>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h2 class="mb-0 h5">
              <fa-icon [icon]="faTable"></fa-icon> Asset Condition by
              Description
            </h2>
          </div>
          <div class="card-body p-0">
            <app-asset-condition-by-description></app-asset-condition-by-description>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
