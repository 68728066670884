<div class="card full-height-xl has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> Work Orders Volume by Priority
		</h2>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loadedByPriority}" class="chart-container long-height-xl">
			<chart class="chart-container long-height-xl" #priorityChart [type]="chartConfigByPriority.type" [data]="chartConfigByPriority.data" [options]="chartConfigByPriority.options"></chart>
		</div>

		<div *ngIf="!loadedByPriority"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
