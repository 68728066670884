import {
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/message.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { DashboardWidgetModel } from "../models/dashboardWidgetModel";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { RequestSummaryWeekPriorityResponseModel } from "../models/requestSummaryWeekPriorityResponseModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-weekly-status-kpi-performance",
	templateUrl: "./weekly-status-kpi-performance.component.html",
})
export class WeeklyStatusKPIPerformanceComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	@Input() model: DashboardWidgetModel;

	loadedKPIPerformance: boolean;
	chartConfigKPIPerformance = {
		type: "bar",
		data: {
			labels: [],
			datasets: [],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: true,
			},
			scales: {
				xAxes: [
					{
						ticks: {
							autoSkip: false,
						},
					},
				],
				yAxes: [
					{
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "right",
						id: "y-axis-1",
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `${label.toLocaleString()}%`;
							},
						},
					},
					{
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "left",
						id: "y-axis-2",
						// grid line settings
						gridLines: {
							drawOnChartArea: false, // only want the grid lines for one axis to show up
						},
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `${label.toLocaleString()}`;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data["datasets"][tooltipItem[0]["datasetIndex"]].label;
						return title === null ? "null" : title;
					},
					label: (tooltipItem, data) => {
						var value =
							data["datasets"][tooltipItem.datasetIndex]["data"][tooltipItem.index];
						if (tooltipItem.datasetIndex > 0) {
							return `${value.toLocaleString(undefined, {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							})}%`;
						} else {
							return `${value.toLocaleString(undefined, {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							})}`;
						}
					},
				},
			},
		},
	};
	@ViewChild("kpiPerformanceChart") chartKPIPerformance: ChartComponent;

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private messageService: MessageService
	) {}

	ngOnInit() {
		this.loadedKPIPerformance = false;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(value);
			})
		);
	}

	update(updateId: string) {
		this.loadedKPIPerformance = false;
		let body = this.woFiltersService.fillBodyWeeklyStatus(
			new QueryOptionsModel()
		);
		var dateFrom2 = this.woFiltersService
			.getDateFromWeeklyStatus()
			.subtract(4, "weeks");
		if (dateFrom2.isValid()) {
			body.filter.fromDt = dateFrom2.toISOString();
		}
		if (this.model && this.model.filter) {
			if (this.model.filter.overallStatuses) {
				body.filter.overallStatuses = this.model.filter.overallStatuses; // specified in fillBodyWeeklyStatus
			}
			if (this.model.filter.states) {
				body.filter.states = this.model.filter.states; // specified in fillBodyWeeklyStatus
			}
			body.filter.vendorIds = this.model.filter.vendorIds;
			body.filter.storeIds = this.model.filter.storeIds;
			body.filter.assetNames = this.model.filter.assetNames;
			body.filter.assetCategories = this.model.filter.assetCategories;
			body.filter.statuses = this.model.filter.statuses;
			body.filter.statusIds = this.model.filter.statusIds;
			if (this.model.filter.requestTypes) {
				body.filter.requestTypes = this.model.filter.requestTypes; // specified in fillBodyWeeklyStatus
			}
			body.filter.priorities = this.model.filter.priorities;
			body.filter.serviceDeliveryManagers =
				this.model.filter.serviceDeliveryManagers;
			body.filter.retailBusinessManagers =
				this.model.filter.retailBusinessManagers;
			body.filter.regionalManagers = this.model.filter.regionalManagers;
			body.filter.storeTypes = this.model.filter.storeTypes;
			body.filter.storeProfiles = this.model.filter.storeProfiles;
			body.filter.districtManagers = this.model.filter.districtManagers;
		}
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryWeekPriority(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.loadedKPIPerformance = true;
						this.getRequestSummaryWeekPriorityEvent(
							data,
							this.chartConfigKPIPerformance,
							this.chartKPIPerformance
						);
					}
				})
		);
	}

	getRequestSummaryWeekPriorityEvent(
		data: RequestSummaryWeekPriorityResponseModel[],
		chartConfig: any,
		chart: ChartComponent
	) {
		let newLabels = [];
		let newData = [];
		let newTotals = [];
		let weeks = [];
		data.forEach((element) => {
			var key = this.woFiltersService.weekToFYWeek(element.year, element.week);
			if (!weeks.includes(key)) {
				weeks.push(key);
			}
		});
		weeks.sort();
		var array = [];
		weeks.forEach((element2) => {
			array.push(0);
		});
		var colors = this.whiteLabelService.getResponseRectificationColors();
		newData.push({
			label: "Count",
			backgroundColor: colors.colorsCount[0],
			hoverBackgroundColor: colors.colorsCount[1],
			yAxisID: "y-axis-2",
			data: array.slice(),
			type: "line",
			fill: false,
			borderColor: colors.colorsCount[0],
			borderWidth: 2,
		});
		newData.push({
			label: "Respond",
			backgroundColor: colors.colorsResponse[0],
			hoverBackgroundColor: colors.colorsResponse[1],
			yAxisID: "y-axis-1",
			data: array.slice(),
		});
		newData.push({
			label: "Resolve",
			backgroundColor: colors.colorsRectification[0],
			hoverBackgroundColor: colors.colorsRectification[1],
			yAxisID: "y-axis-1",
			data: array.slice(),
		});
		newTotals.push(array.slice());
		newTotals.push(array.slice());
		data.forEach((element) => {
			var key = this.woFiltersService.weekToFYWeek(element.year, element.week);
			var indexWeek = weeks.indexOf(key);
			newData[2].data[indexWeek] += element.count - element.resolveKPIFail;
			newTotals[1][indexWeek] += element.count;
			newData[0].data[indexWeek] += element.count;
			if (element.priority === "P1") {
				newData[1].data[indexWeek] += element.count - element.respondKPIFail;
				newTotals[0][indexWeek] += element.count;
			}
		});
		if (weeks.length > 0) {
			for (var i = 0; i < weeks.length; i++) {
				newData[1].data[i] =
					newTotals[0][i] == 0 ? 0 : (100 * newData[1].data[i]) / newTotals[0][i];
				newData[2].data[i] =
					newTotals[1][i] == 0 ? 0 : (100 * newData[2].data[i]) / newTotals[1][i];
			}
			var value0 =
				newData[0].data.reduce(function (a, b) {
					return a + b;
				}, 0) / newData[0].data.length;
			var value1 =
				newData[1].data.reduce(function (a, b) {
					return a + b;
				}, 0) / newData[1].data.length;
			var value2 =
				newData[2].data.reduce(function (a, b) {
					return a + b;
				}, 0) / newData[2].data.length;
			newData[0].data.push(value0);
			newData[1].data.push(value1);
			newData[2].data.push(value2);
			weeks.push("AVG");
		}
		newLabels = weeks;
		chartConfig.data.labels = newLabels;
		chartConfig.data.datasets = newData;
		if (chart) {
			chart.chart.update();
		}

		this.ref.detectChanges();
	}

	// Icons
	faChartColumn = faChartColumn;
}
