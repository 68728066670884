import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/message.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-weekly-status-daily-volume",
	templateUrl: "./weekly-status-daily-volume.component.html",
})
export class WeeklyStatusDailyVolumeComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByVolume: boolean;
	chartConfigByVolume = {
		type: "bar",
		data: {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [],
					hoverBackgroundColor: [],
				},
			],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: false,
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data["labels"][tooltipItem[0]["index"]];
						return title === null ? "null" : title;
					},
					label: function (tooltipItem, data) {
						var total = data["datasets"][0]["data"].reduce((a, b) => a + b, 0);
						//var total = this._chart.getDatasetMeta(0).total;
						var value = data["datasets"][0]["data"][tooltipItem["index"]];
						var percent = total == 0 ? 0 : (100 * value) / total;
						return `${value.toLocaleString()} (${percent.toLocaleString(undefined, {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						})}%)`;
					},
				},
			},
		},
	};
	@ViewChild("volumeChart") chartByVolume: ChartComponent;

	backgroundColor = [];
	hoverBackgroundColor = [];

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private messageService: MessageService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;

		this.chartConfigByVolume.data.datasets[0].backgroundColor =
			this.backgroundColor;
		this.chartConfigByVolume.data.datasets[0].hoverBackgroundColor =
			this.hoverBackgroundColor;
	}

	ngOnInit() {
		this.loadedByVolume = false;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(value);
			})
		);
	}

	update(updateId: string) {
		this.loadedByVolume = false;
		let body = this.woFiltersService.fillBodyWeeklyStatus(
			new QueryOptionsModel()
		);
		this.subs.add(
			this.workOrderReportService.getRequestSummaryDay(body).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.loadedByVolume = true;
					let dates = [];
					let newLabels = [];
					let newData = [];
					data.forEach((element) => {
						var key = `${element.year}-${element.month.toLocaleString(undefined, {
							minimumIntegerDigits: 2,
						})}-${element.day.toLocaleString(undefined, {
							minimumIntegerDigits: 2,
						})}`;
						if (dates.indexOf(key) < 0) {
							dates.push(key);
							newData.push(0);
						}
					});
					dates.sort();
					data.forEach((element) => {
						var key = `${element.year}-${element.month.toLocaleString(undefined, {
							minimumIntegerDigits: 2,
						})}-${element.day.toLocaleString(undefined, {
							minimumIntegerDigits: 2,
						})}`;
						var index = dates.indexOf(key);
						newData[index] += element.count;
					});
					dates.forEach((element) => {
						var m = moment.utc(element);
						newLabels.push(m.format("dddd"));
					});
					this.chartConfigByVolume.data.labels = newLabels;
					this.chartConfigByVolume.data.datasets[0].data = newData;
					if (this.chartByVolume) {
						this.chartByVolume.chart.update();
					}
					this.ref.detectChanges();
				}
			})
		);
	}

	// Icons
	faChartColumn = faChartColumn;
}
