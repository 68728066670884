import {
	Component,
	OnInit
} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Observable } from 'rxjs';
import { WhiteLabelService } from '../../services/white-label.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-work-orders-nav',
	templateUrl: './work-orders-nav.component.html'
})
export class WorkOrdersNavComponent implements OnInit {
	constructor(public router: Router,
		private authService: AuthService,
		public whiteLabelService: WhiteLabelService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.roles$ = this.authService.getCurrentUserRoles;
	}
}