<main>
	<div *ngIf="(roles$ | async)?.length > 0 && (roles$ | async)?.indexOf('store') > -1 || (roles$ | async)?.indexOf('rm') > -1"
		class="container">
		<div class="alert alert-warning font-weight-bold mt-3" role="alert">
			Not available for current user role.
		</div>
	</div>
	<div *ngIf="!((roles$ | async)?.indexOf('store') > -1 || (roles$ | async)?.indexOf('rm') > -1)" class="container">
		<div class="row mb-3">
			<nav aria-label="breadcrumb" class="w-100 half-strong">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">Home</li>
					<li class="breadcrumb-item"><span
							(click)="resetFilters()">{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}}
							Performance Status</span></li>
				</ol>
			</nav>
		</div>

		<div class="row">
			<div class="col-12 mb-4">
				<app-work-orders-nav></app-work-orders-nav>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-filters></app-work-orders-filters>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3" *ngIf="!whiteLabelService.isSgh()">
				<app-vendor-performance-status-summary></app-vendor-performance-status-summary>
			</div>
			<div class="col-12 mb-3" *ngIf="whiteLabelService.isSgh()">
				<app-vendor-performance-status-summary-sgh></app-vendor-performance-status-summary-sgh>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-vendor-performance-status-vendor-ri></app-vendor-performance-status-vendor-ri>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-vendor-performance-status-vendor-p></app-vendor-performance-status-vendor-p>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-vendor-performance-status-month></app-vendor-performance-status-month>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-open-state-rm></app-work-orders-open-state-rm>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-open-rm></app-work-orders-open-rm>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-open-state-pm></app-work-orders-open-state-pm>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-open-pm></app-work-orders-open-pm>
			</div>
		</div>

		<div class="row"
			*ngIf="!whiteLabelService.getSettings.hideWidgetVendorPerformanceStatusCompletedWorkOrdersByState">
			<div class="col-12 mb-3">
				<app-vendor-performance-status-state></app-vendor-performance-status-state>
			</div>
		</div>

		<div class="row" *ngIf="!whiteLabelService.getSettings.hideWidgetVendorPerformanceStatusVendorKPIReports">
			<div class="col-12">
				<app-vendor-performance-kpi-report></app-vendor-performance-kpi-report>
			</div>
		</div>

	</div>
</main>