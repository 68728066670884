import {
	Component,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import {
	StateService
} from '../../services/state.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	faArrowAltCircleLeft,
	faTable
} from '@fortawesome/free-solid-svg-icons';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	QueryOptionsPagingModel
} from '../models/queryOptionsPagingModel';
import {
	DatatableComponent
} from '@swimlane/ngx-datatable';
import { AuthService } from '../../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { WhiteLabelService } from '../../services/white-label.service';
import { AssetService } from '../services/asset.service';

@Component({
	selector: 'app-asset-availability-table',
	templateUrl: './asset-availability-table.component.html'
})
export class AssetAvailabilityTableComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loaded: boolean;

	offlineCountComparator(valueA, valueB, rowA, rowB, sortDirection) {
		if (valueA < valueB) {
			return -1;
		}
		if (valueA > valueB) {
			return 1;
		}
		if (rowA.count < rowB.count) {
			return -1;
		}
		if (rowA.count > rowB.count) {
			return 1;
		}
		return 0;
	}

	tableData = {
		rows: [],
		columns: [{
			prop: 'id',
			name: 'Asset Name'
		}, {
			prop: 'count',
			name: 'Total Asset Count'
		}, {
			prop: 'offlineCount',
			name: 'Offline Asset Count',
			comparator: this.offlineCountComparator.bind(this)
		}, {
			prop: 'available',
			name: 'Availability (%)'
		}],
		total: [],
		onNameClick: (row) => {
			for (var i = 0; i < this.woFiltersService.assetNames.length; i++) {
				if (this.woFiltersService.assetNames[i] === row.name) {
					this.woFiltersService.isCollapsed = false;
					this.woFiltersService.selectedAssetName = [this.woFiltersService.assetNames[i]];
					break;
				}
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.openWOVendorTable.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		}
	};
	@ViewChild('assetAvailabilityTable') openWOVendorTable: DatatableComponent;

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private assetService: AssetService,
		private whiteLabelService: WhiteLabelService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loaded = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(this.stateService.getCurrentDateRange(), value);
		}));
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.assetTypeTags = ['Key'];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		this.subs.add(this.assetService.getAssetOfflineSummary(body)
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					let newData: {
						[id: string]: any;
					} = {};
					data.forEach(element => {
						let rowId = element.name;
						if (typeof newData[rowId] === "undefined") {
							let newRow = {
								id: rowId,
								name: element.name,
								count: 0,
								offlineCount: 0,
								available: 0
							};
							newData[rowId] = newRow;
						}
						newData[rowId].count += element.count;
						newData[rowId].offlineCount += element.offlineCount;
					});

					let newDataArray = [];
					for (let key in newData) {
						newData[key].available = 100 * (newData[key].count ? ((newData[key].count - newData[key].offlineCount) / newData[key].count) : 0)
						newDataArray.push(newData[key]);
					}

					this.tableData.rows = newDataArray;
					this.loaded = true;
					this.ref.detectChanges();
				}
			}));
	}

	// Icons
	faTable = faTable;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}