import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faChartColumn,
} from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-open-priority",
	templateUrl: "./work-orders-open-priority.component.html",
})
export class WorkOrdersOpenPriorityComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loadedByPriority: boolean;
	totalByPriority: number;
	chartConfigByPriority = {
		type: "pie",
		data: {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [],
					hoverBackgroundColor: [],
				},
			],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: true,
				onClick: (e, legendItem) => {
					var ci = this.chartByPriority.chart;
					var meta = ci.getDatasetMeta(0);
					var index = legendItem.index;
					meta.data[index].hidden = !meta.data[index].hidden;
					ci.update();

					var total = meta.total;
					this.totalByPriority = total;
				},
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data["labels"][tooltipItem[0]["index"]];
						return title === null ? "null" : title;
					},
					label: function (tooltipItem, data) {
						//var total = data['datasets'][0]['data'].reduce((a, b) => a + b, 0);
						var total = this._chart.getDatasetMeta(0).total;
						var value = data["datasets"][0]["data"][tooltipItem["index"]];
						var percent = total == 0 ? 0 : (100 * value) / total;
						return `${value.toLocaleString()} (${percent.toLocaleString(undefined, {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						})}%)`;
					},
				},
			},
			onClick: (event) => {
				var activePoints = this.chartByPriority.chart.getElementsAtEvent(event);
				if (
					activePoints &&
					activePoints.length > 0 &&
					activePoints[0]._model &&
					activePoints[0]._model.label
				) {
					if (
						this.chartConfigByPriority.additional.lastClickTime &&
						this.chartConfigByPriority.additional.lastClickElement ===
							activePoints[0] &&
						new Date().getTime() -
							this.chartConfigByPriority.additional.lastClickTime <
							500
					) {
						this.chartConfigByPriority.additional.lastClickTime = null;
						this.chartConfigByPriority.additional.lastClickElement = null;

						this.woFiltersService.isCollapsed = false;
						var value = activePoints[0]._model.label;
						if (value !== "null") {
							this.woFiltersService.selectedPriority = [value];
						}
					} else {
						this.chartConfigByPriority.additional.lastClickTime =
							new Date().getTime();
						this.chartConfigByPriority.additional.lastClickElement = activePoints[0];
					}
				}
			},
		},
		additional: {
			lastClickTime: null,
			lastClickElement: null,
		},
	};
	@ViewChild("priorityChart") chartByPriority: ChartComponent;

	backgroundColor = [];
	hoverBackgroundColor = [];
	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;

		this.chartConfigByPriority.data.datasets[0].backgroundColor =
			this.backgroundColor;
		this.chartConfigByPriority.data.datasets[0].hoverBackgroundColor =
			this.hoverBackgroundColor;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByPriority = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Open"];
		body = this.woFiltersService.fillBodyEndDateThisMonth(body);
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByPriority = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryPriority(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						// Chart 1
						// User count grouped by priority
						this.totalByPriority = 0;
						this.loadedByPriority = true;
						let newLabels = [];
						let newData = [];
						data.forEach((element) => {
							newLabels.push(element.priority);
							newData.push(element.count);
							this.totalByPriority += element.count;
						});
						this.chartConfigByPriority.data.labels = newLabels;
						this.chartConfigByPriority.data.datasets[0].data = newData;
						if (this.chartByPriority) {
							this.chartByPriority.chart.update();
						}
						this.ref.detectChanges();
					}
				})
		);
	}

	// Icons
	faChartColumn = faChartColumn;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
