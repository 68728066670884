<div class="card full-height-lg has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> Resolve KPI % <span class="header-additional-info" *ngIf="priorityLabel">({{priorityLabel}})</span>
		</h2>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loadedByPriority}" class="chart-container">
			<chart class="chart-container" #resolveChart [type]="chartConfigByResolve.type" [data]="chartConfigByResolve.data" [options]="chartConfigByResolve.options"></chart>
		</div>

		<div *ngIf="!loadedByPriority"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
