<div class="card full-height-xl has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> Work Order Daily Volume
		</h2>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loadedByVolume}" class="chart-container long-height-xl">
			<chart class="chart-container long-height-xl" #volumeChart [type]="chartConfigByVolume.type" [data]="chartConfigByVolume.data" [options]="chartConfigByVolume.options"></chart>
		</div>

		<div *ngIf="!loadedByVolume"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span
			class="right"></span></span></span></div>
	</div>
</div>
