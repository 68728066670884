<div class="card has-button-right">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon>
			{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}} KPI Reports
		</h2>
		<div class="right-btns">
			<button type="button" class="btn btn-primary" (click)="showNewDialog(addReportContent)"
				[disabled]="generationInProgress">
				<fa-icon [icon]="faPlusSquare"></fa-icon><span class="d-none d-md-inline ms-1"> Adhoc Report</span>
				<span class="loader loader-small loader-reverse"><span class="loader-inner"><span
							class="left">Loading...</span><span class="right"></span></span></span>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div class="datatable-responsive data-responsive-sm">
			<ngx-datatable #woInformTable class="bootstrap overflow-header-name" [rows]="tableData.rows"
				[columns]="tableData.columns" [headerHeight]="50" [scrollbarV]="true" [rowHeight]="38"
				[footerHeight]="50" [columnMode]="'flex'" [sorts]="[{prop: 'createdDate', dir: 'desc'}]"
				[loadingIndicator]="!loaded">
				<!-- Row Detail Template -->
				<ngx-datatable-row-detail [rowHeight]="100" (toggle)="tableData.onDetailToggle($event)">
					<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
						<div class="row-detail-inner d-flex flex-row flex-wrap">
							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Type: </span>{{row.createType}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Version: </span>{{row.reportTypeDescription}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Start Date: </span>{{row.startDate |
								date:'yyyy-MM-dd'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">End Date: </span>{{row.endDate |
								date:'yyyy-MM-dd'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Generated: </span>{{row.createdDate |
								date:'yyyy-MM-dd HH:mm:ss'}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-row-detail>
				<!-- Column Templates -->
				<ngx-datatable-column prop="vendorName"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}}"
					headerClass="col-5 col-xl-4 has-expand-icon" cellClass="col-5 col-xl-4 has-expand-icon"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-row="row" let-value="value" let-expanded="expanded" ngx-datatable-cell-template>
						<span>
							<span class="position-relative d-block">
								<a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
									[class.datatable-icon-down]="expanded" class="datatable-icon-action"
									title="Expand/Collapse Row" (click)="tableData.toggleExpandRow(row)">
								</a>

								<span class="me-3 manage-report-btn" title="Download Report"><a class="btn btn-link"
										href="{{getLink(row)}}" target="_blank"><fa-icon
											[icon]="faCloudDownloadAlt"></fa-icon></a></span>
								<span class="me-3 manage-report-btn" title="Regenerate Report"><button
										class="btn btn-link" [disabled]="!isRegenerateAllowed(row)"
										(click)="regenerateReport(row)" target="_blank"><fa-icon
											[icon]="faSyncAlt"></fa-icon></button></span>

								<span title="{{value}}">{{value}}</span>
							</span>
						</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="createType" name="Type" headerClass="col text-start"
					cellClass="col text-start" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="reportTypeDescription" name="Version" headerClass="col text-start"
					cellClass="col text-start" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="startDate" name="Start Date" headerClass="col text-start"
					cellClass="col text-start" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | date:'yyyy-MM-dd'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="endDate" name="End Date" headerClass="col text-start"
					cellClass="col text-start" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | date:'yyyy-MM-dd'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="createdDate" name="Generated" headerClass="col text-start"
					cellClass="col text-start" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | date:'yyyy-MM-dd HH:mm:ss'}}
					</ng-template>
				</ngx-datatable-column>
				<!-- Footer Template -->
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template>
						<div class="datatable-footer-row">
							<div class="datatable-footer-cell col">
								{{tableData.rows.length | number}} total
							</div>
						</div>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
	</div>

	<ng-template #addReportContent let-modal>
		<div class="modal-header">
			<h4 class="modal-title">New Adhoc Report</h4>
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="filters-wrapper">
				<div class="mb-3">
					<label class="half-strong">Vendor</label>
					<ng-select [disabled]="!loadedVendors" [multiple]="false" [dropdownPosition]="'bottom'"
						[items]="vendors" bindLabel="name" [(ngModel)]="selectedVendor"
						(change)="onVendorChange($event)"
						placeholder="Select {{whiteLabelService.getSettings.filterVendorLabel}}"
						class="dropdown-select">
					</ng-select>
				</div>
				<div class="mb-3">
					<label class="half-strong">Date Range</label>
					<my-date-range-picker [disabled]="!selectedVendor" #mydrpNewReport name="mydaterange"
						placeholder="Select date range" id="date-range-input" class="data-range width-full"
						[options]="myDateRangePickerOptions" (dateRangeChanged)="onDateRangeChanged($event)"
						(inputFocusBlur)="onInputFocusBlur($event)" [(ngModel)]="model" required></my-date-range-picker>
				</div>
				<div class="mb-3">
					<label class="half-strong">Version</label>
					<ng-select [disabled]="!canSelectType()" [multiple]="false" [dropdownPosition]="'bottom'"
						[items]="types" bindLabel="name" [(ngModel)]="selectedType" placeholder="Select version"
						class="dropdown-select">
					</ng-select>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-primary" (click)="modal.close('Generate click')"
				[disabled]="!isReadyToGenerate()">Generate Report</button>
		</div>
	</ng-template>

	<ng-template #popupDownload let-url="url">
		<div class="download-report">
			<div class="sn-title">Report</div>
			<div class="sn-content">Generation complete</div>
			<div class="icon generated-report">
				<fa-icon [icon]="faCloudDownloadAlt"></fa-icon>
			</div>
		</div>

		<div class="close-notification">
			<fa-icon class="close-notification-icon" [icon]="faTimes"></fa-icon>
		</div>
	</ng-template>
</div>