<div class="card full-height-xl" [ngClass]="{'has-back': woFiltersService.isDrilldownBackByVendorAvailable()}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon> Completed Work Orders by
			{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}}
		</h2>
		<div class="back-btn" *ngIf="woFiltersService.isDrilldownBackByVendorAvailable()">
			<button type="button" class="btn btn-secondary" (click)="woFiltersService.drilldownBackByVendor()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div class="datatable-responsive data-responsive-md">
			<ngx-datatable #woVendorTable class="bootstrap overflow-header-name" [rows]="tableDataByVendor.rows"
				[columns]="tableDataByVendor.columns" [headerHeight]="50" [scrollbarV]="true" [rowHeight]="38"
				[footerHeight]="50" [columnMode]="'flex'" [sorts]="[{prop: 'name', dir: 'asc'}]"
				[loadingIndicator]="!loadedByVendor">
				<!-- Row Detail Template -->
				<ngx-datatable-row-detail [rowHeight]="150" (toggle)="tableDataByVendor.onDetailToggle($event)">
					<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
						<div class="row-detail-inner d-flex flex-row flex-wrap">
							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Work Orders: </span>{{row['Work Orders'] |
								number : '1.0-0'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Cost of RM: </span>${{row['Cost of RM'] |
								number : '1.0-0'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Cost of PM: </span>${{row['Cost of PM'] |
								number : '1.0-0'}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Total: </span>${{row.Total | number : '1.0-0'}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-row-detail>
				<!-- Column Templates -->
				<ngx-datatable-column prop="name"
					name="{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}} Name"
					headerClass="col-5 col-xl-4 has-expand-icon" cellClass="col-5 col-xl-4 has-expand-icon"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-row="row" let-value="value" let-expanded="expanded" ngx-datatable-cell-template>
						<span title="{{value}}">
							<span class="position-relative d-block">
								<a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
									[class.datatable-icon-down]="expanded" class="datatable-icon-action"
									title="Expand/Collapse Row" (click)="tableDataByVendor.toggleExpandRow(row)">
								</a>
								<span class="cursor-pointer text-truncate-custom"
									(click)="tableDataByVendor.onNameClick(row)">{{value}}</span>
							</span>
						</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Work Orders" name="Work Orders" headerClass="col col-xl-2 text-end"
					cellClass="col col-xl-2 text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Cost of RM" name="Cost of RM" headerClass="col text-end"
					cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						${{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Cost of PM" name="Cost of PM" headerClass="col text-end"
					cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						${{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Total" name="Total" headerClass="col text-end" cellClass="col text-end"
					[resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label"
								(click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						${{value | number : '1.0-0'}}
					</ng-template>
				</ngx-datatable-column>
				<!-- Footer Template -->
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template>
						<div class="datatable-footer-row">
							<div class="datatable-footer-cell col-5 col-xl-4">Total</div>
							<div *ngFor="let column of tableDataByVendor.total; let i = index" [ngClass]="column.class"
								class="datatable-footer-cell">
								{{i > 0 ? '$' : ''}}{{column.value | number : '1.0-0'}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
	</div>
</div>