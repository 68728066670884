import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	faArrowAltCircleLeft,
	faFileExport,
	faTable
} from '@fortawesome/free-solid-svg-icons';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { MessageService } from "../../services/message.service";
import {
	StateService
} from '../../services/state.service';
import { WhiteLabelService } from '../../services/white-label.service';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import { QueryOptionsPagingModel } from '../models/queryOptionsPagingModel';
import { RequestSummaryVendorPivotResponseModel } from '../models/requestSummaryVendorPivotResponseModel';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';

@Component({
	selector: 'app-vendor-performance-status-vendor-p',
	templateUrl: './vendor-performance-status-vendor-p.component.html'
})
export class VendorPerformanceStatusVendorPComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByVendorPlanned: boolean;
	tableDataByVendorPlanned = {
		rows: [],
		columns: [{
			prop: 'name',
			name: 'Vendor'
		}, {
			prop: 'plannedCount',
			name: 'Planned Count'
		}, {
			prop: 'plannedRespondKPIFail',
			name: 'Planned Respond KPI Fail'
		}, {
			prop: 'plannedResolveKPIFail',
			name: 'Planned Resolve KPI Fail'
		}, {
			prop: 'plannedResolveFailPercentage',
			name: 'Planned Resolve Fail %'
		}],
		total: [],
		onNameClick: (row) => {
			for (var i = 0; i < this.woFiltersService.vendors.length; i++) {
				if (this.whiteLabelService.getSettings.enableNewVendorFilter) {
					if (this.woFiltersService.vendorNames[i].name === row.name) {
						this.woFiltersService.isCollapsed = false;
						this.woFiltersService.selectedVendorNames = [this.woFiltersService.vendorNames[i]];
						break;
					}
				} else {
					if (this.woFiltersService.vendors[i].id === row.id) {
						this.woFiltersService.isCollapsed = false;
						this.woFiltersService.selectedVendor = [this.woFiltersService.vendors[i]];
						break;
					}
				}
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.tableByVendorPlanned.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		}
	};
	@ViewChild('woVendorTablePlanned') tableByVendorPlanned: DatatableComponent;

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		public whiteLabelService: WhiteLabelService,
		private messageService: MessageService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.stateService.setHeaderTitle(this.whiteLabelService.capitalizeFirstLetter(this.whiteLabelService.getSettings.filterVendorLabel) + ' Performance Status');
		this.loadedByVendorPlanned = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.roles$.subscribe(data => {
			if (data.length > 0 && data.indexOf('store') < 0 && data.indexOf('rm') < 0) {
				this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
					this.update(this.stateService.getCurrentDateRange(), value);
				}));
			}
		}));
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		return this.woFiltersService.fillBody(body, dateRange);
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByVendorPlanned = false;

		//#region Table 2
		let bodyTable2 = this.fillBody(new QueryOptionsModel(), dateRange);
		bodyTable2.filter.overallStatuses = ['Open'];
		bodyTable2.filter.requestTypes = ['Planned'];
		bodyTable2 = this.woFiltersService.fillBodyEndDateThisMonth(bodyTable2);
		this.subs.add(this.workOrderReportService.getRequestSummaryVendorPivot(bodyTable2)
			.subscribe(data => {
				this.dataExport = data;
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					let newData: {
						[id: string]: any;
					} = {};
					let newTotal = [{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: null
					}];
					data.forEach(element => {
						let rowId = `${element.name}--${element.vendorNo}`;
						if (typeof newData[rowId] === "undefined") {
							let newRow = {
								id: element.id,
								name: element.subGroupName,
								plannedCount: 0,
								plannedRespondKPIFail: 0,
								plannedResolveKPIFail: 0,
								plannedResolveFailPercentage: 0
							};
							newData[rowId] = newRow;
						}
						newData[rowId].plannedCount += element.plannedCount;
						newTotal[0].value += element.plannedCount;
						newData[rowId].plannedRespondKPIFail += element.plannedRespondKPIFail;
						newTotal[1].value += element.plannedRespondKPIFail;
						newData[rowId].plannedResolveKPIFail += element.plannedResolveKPIFail;
						newTotal[2].value += element.plannedResolveKPIFail;
						newData[rowId].plannedResolveFailPercentage = (newData[rowId].plannedResolveKPIFail / newData[rowId].plannedCount) * 100;
					});

					let newDataArray = [];
					for (let key in newData) {
						newDataArray.push(newData[key]);
					}
					this.tableDataByVendorPlanned.total = newTotal;
					this.tableDataByVendorPlanned.rows = newDataArray;
					this.loadedByVendorPlanned = true;
					this.ref.detectChanges();
				}
			}));
		//#endregion
	}

	//#region export CSV

	public inProgressExportCSV: boolean = false;
	private dataExport: RequestSummaryVendorPivotResponseModel[];
	exportCsv() {
		this.inProgressExportCSV = true;
		let headers = Object.getOwnPropertyNames(this.dataExport[0]);
		headers = headers.filter(item => !item.startsWith("cost"));
		headers = headers.filter(item => !item.startsWith("reactive"));
		headers = headers.filter(item => !item.startsWith("incident"));
		headers = headers.filter(item => !item.startsWith("count"));
		if (this.whiteLabelService.getSettings.filterStoreLabel != "store") {
			// replace store with whitelabel setting in all headers
			headers = headers.map((header) =>
				header.replace("store", this.whiteLabelService.getSettings.filterStoreLabel)
			);
			headers = headers.map((header) =>
				header.replace("Store", this.whiteLabelService.capitalizeFirstLetter(this.whiteLabelService.getSettings.filterStoreLabel))
			);
		}
		if (this.whiteLabelService.getSettings.filterVendorLabel != "vendor") {
			// replace vendor with whitelabel setting in all headers
			headers = headers.map((header) =>
				header.replace("vendor", this.whiteLabelService.getSettings.filterVendorLabel)
			);
			headers = headers.map((header) =>
				header.replace("Vendor", this.whiteLabelService.capitalizeFirstLetter(this.whiteLabelService.getSettings.filterVendorLabel))
			);
		}
		let options = {
			fieldSeparator: ",",
			quoteStrings: '"',
			decimalseparator: ".",
			showLabels: true,
			headers: headers,
			useHeader: true,
			showTitle: false,
			title: "export",
			useBom: true,
		};		
		new AngularCsv(this.dataExport, "export", options);
		this.inProgressExportCSV = false;
	}

	//#endregion

	// Icons
	faTable = faTable;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
	faFileExport = faFileExport;
}