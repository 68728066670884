import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Component({
	selector: "saml-auth-callback",
	templateUrl: "./saml-auth-callback.component.html",
})
export class SamlAuthCallbackComponent implements OnInit {
	constructor(private router: Router, private auth: AuthService) {}

	ngOnInit() {
		this.auth.loginSamlStep2();
	}
}
