import {
	CdkDragDrop,
	moveItemInArray,
	transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
	Component,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from "@angular/core";
import {
	faArrowDown,
	faArrowsAlt,
	faArrowUp,
	faCaretDown,
	faEdit,
	faFileDownload,
	faFilePdf,
	faPlusSquare,
	faSave,
	faTrashAlt,
	faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { Guid } from "guid-typescript";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/message.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { DashboardRowModel } from "../models/dashboardRowModel";
import { DashboardWidgetModel } from "../models/dashboardWidgetModel";
import { FilterLight } from "../models/filterLight";
import { ReportWeeklyStatusModel } from "../models/reportWeeklyStatusModel";
import { DashboardService } from "../services/dashboard.service";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";
import { WeeklyStatusAssetComponent } from "../weekly-status-asset/weekly-status-asset.component";
import { WeeklyStatusDailyVolumeComponent } from "../weekly-status-daily-volume/weekly-status-daily-volume.component";
import { WeeklyStatusKPIPerformanceComponent } from "../weekly-status-kpi-performance/weekly-status-kpi-performance.component";
import { WeeklyStatusTopComponent } from "../weekly-status-top/weekly-status-top.component";
import { WeeklyStatusVolumePriorityComponent } from "../weekly-status-volume-priority/weekly-status-volume-priority.component";

@Component({
	selector: "app-weekly-status",
	templateUrl: "./weekly-status.component.html",
})
export class WeeklyStatusComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	public kpiRows: DashboardRowModel[];

	public isCollapsed = true;

	public inProgressPdf = false;

	public comment = "";

	@ViewChild("WeeklyStatusVolumePriority")
	weeklyStatusVolumePriorityComponent: WeeklyStatusVolumePriorityComponent;
	@ViewChild("WeeklyStatusAsset")
	weeklyStatusAssetComponent: WeeklyStatusAssetComponent;
	@ViewChild("WeeklyStatusDailyVolume")
	weeklyStatusDailyVolumeComponent: WeeklyStatusDailyVolumeComponent;
	@ViewChild("WeeklyStatusTop")
	weeklyStatusTopComponent: WeeklyStatusTopComponent;
	@ViewChildren("WeeklyStatusKPIPerformance")
	kpiPerformanceComponents: QueryList<WeeklyStatusKPIPerformanceComponent>;

	generatePdf() {
		this.inProgressPdf = true;
		var model = new ReportWeeklyStatusModel();
		model.BodyClass = this.whiteLabelService.getWhiteLabel;
		model.Comment = this.comment;
		model.Year = this.woFiltersService.selectedYear;
		model.Week = this.woFiltersService.selectedWeek;
		model.State = this.woFiltersService.selectedState;
		model.Priority =
			this.woFiltersService.selectedPriority &&
			this.woFiltersService.selectedPriority.length > 0
				? this.woFiltersService.selectedPriority[0]
				: "";
		model.ChartConfigByPriorityLabels =
			this.weeklyStatusVolumePriorityComponent.chartConfigByPriority.data.labels;
		model.ChartConfigByPriorityValues =
			this.weeklyStatusVolumePriorityComponent.chartConfigByPriority.data.datasets[0].data;
		model.TableAssetData = this.weeklyStatusAssetComponent.tableDataByAsset.rows;
		model.ChartsConfigKPIPerformance = [];
		this.kpiPerformanceComponents.forEach((child) => {
			model.ChartsConfigKPIPerformance.push({
				Title: child.model.title,
				Labels: child.chartConfigKPIPerformance.data.labels,
				Values: [
					child.chartConfigKPIPerformance.data.datasets[0].data,
					child.chartConfigKPIPerformance.data.datasets[1].data,
					child.chartConfigKPIPerformance.data.datasets[2].data,
				],
			});
		});
		model.ChartConfigByVolumeLabels =
			this.weeklyStatusDailyVolumeComponent.chartConfigByVolume.data.labels;
		model.ChartConfigByVolumeValues =
			this.weeklyStatusDailyVolumeComponent.chartConfigByVolume.data.datasets[0].data;
		model.TableVendorsData =
			this.weeklyStatusTopComponent.tableDataTop5Vendor.rows;
		model.TableStoresData = this.weeklyStatusTopComponent.tableDataTop5Store.rows;

		this.subs.add(
			this.workOrderReportService.getWeeklyStatusPdf(model).subscribe((res) => {
				this.inProgressPdf = false;
				if (res && res.ok) {
					const blob = new Blob([res.body], {
						type: res.headers.get("Content-Type"),
					});
					const contentDispositionHeader = res.headers.get("Content-Disposition");
					if (contentDispositionHeader !== null) {
						const contentDispositionHeaderResult = contentDispositionHeader
							.split(";")[1]
							.trim()
							.split("=")[1];
						const contentDispositionFileName = contentDispositionHeaderResult.replace(
							/"/g,
							""
						);
						if ((window.navigator as any).msSaveOrOpenBlob) {
							(window.navigator as any).msSaveBlob(blob, contentDispositionFileName);
						} else {
							const downloadlink = document.createElement("a");
							downloadlink.href = window.URL.createObjectURL(blob);
							downloadlink.download = contentDispositionFileName;
							downloadlink.click();
						}
					}
				} else {
					this.messageService.error("Error", "Can't generate pdf");
				}
			})
		);
	}

	isDashboardReady: boolean = false;

	constructor(
		private authService: AuthService,
		public whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private messageService: MessageService,
		private dashboardService: DashboardService
	) {}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.stateService.setHeaderTitle("Weekly Status");
		this.roles$ = this.authService.getCurrentUserRoles;
		this.woFiltersService.loadData();
		if (
			this.woFiltersService.selectedPriority &&
			this.woFiltersService.selectedPriority.length > 1
		) {
			this.woFiltersService.selectedPriority = [];
		}

		// default dashboard layout
		// this.kpiRows = JSON.parse('[{"widgets":[{"title":"KPI Performance - 5 week trend","filter":{}}]},{"widgets":[{"title":"Coffee KPI performance - 5 week trend","filter":{"assetNames":["Coffee Machine","Coffee Machine - Under Warranty","Coffee Machine Milk Refrigerator"]}},{"title":"Fuel KPI Performance - 5 week trend","filter":{"assetCategories":["Fuel Systems"]}}]},{"widgets":[{"title":"HVAC KPI Performance - 5 week trend","filter":{"assetCategories":["Air Conditioning"]}},{"title":"Slurpee KPI performance - 5 week trend","filter":{"assetNames":["Slurpee Machine"]}}]}]');
		this.dashboardService.getDashboard().subscribe((data) => {
			if (data) {
				this.kpiRows = data;
			} else {
				this.kpiRows = [];
			}
			this.isDashboardReady = true;
		});

		this.subs.add(
			this.stateService.getDateRange.subscribe((value) => {
				this.update();
			})
		);
	}

	ngDoCheck() {
		if (this.woFiltersService.isChanged()) {
			this.update();
		}
	}

	update() {
		let currentUpdateId = Guid.create().toString();
		this.woFiltersService.setUpdateId(currentUpdateId);
	}

	isEdit: boolean = false;
	dashboardBackup: string = "";

	editDashboard() {
		this.dashboardBackup = JSON.stringify(this.kpiRows);
		this.isEdit = true;
	}

	cancelEditDashboard() {
		this.kpiRows = JSON.parse(this.dashboardBackup);
		this.isEdit = false;
	}

	inProgressSaveDashboard: boolean = false;

	saveDashboard() {
		this.inProgressSaveDashboard = true;
		this.dashboardService.saveDashboard(this.kpiRows).subscribe((value) => {
			this.messageService.success("Dashboard", "Changes saved.");
			this.isEdit = false;
			this.inProgressSaveDashboard = false;
		});
	}

	moveElement(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		}
	}

	moveRowUp(row: DashboardRowModel) {
		let fromIndex = this.kpiRows.indexOf(row);
		if (fromIndex > 0) {
			let toIndex = fromIndex - 1;
			var element = this.kpiRows[fromIndex];
			this.kpiRows.splice(fromIndex, 1);
			this.kpiRows.splice(toIndex, 0, element);
		}
	}

	moveRowDown(row: DashboardRowModel) {
		let fromIndex = this.kpiRows.indexOf(row);
		if (fromIndex < this.kpiRows.length - 1) {
			let toIndex = fromIndex + 1;
			var element = this.kpiRows[fromIndex];
			this.kpiRows.splice(fromIndex, 1);
			this.kpiRows.splice(toIndex, 0, element);
		}
	}

	addRow() {
		let row = new DashboardRowModel();
		row.widgets = [];
		let widget = new DashboardWidgetModel();
		widget.title = "New Widget";
		widget.filter = new FilterLight();
		row.widgets.push(widget);
		this.kpiRows.push(row);
	}

	deleteRow(row: DashboardRowModel) {
		let fromIndex = this.kpiRows.indexOf(row);
		if (fromIndex >= 0) {
			this.kpiRows.splice(fromIndex, 1);
		}
	}

	addWidget(row: DashboardRowModel) {
		let widget = new DashboardWidgetModel();
		widget.title = "New Widget";
		widget.filter = new FilterLight();
		row.widgets.push(widget);
	}

	deleteWidget(row: DashboardRowModel, widget: DashboardWidgetModel) {
		let fromIndex = row.widgets.indexOf(widget);
		if (fromIndex >= 0) {
			row.widgets.splice(fromIndex, 1);
		}
	}

	// Icons
	faCaretDown = faCaretDown;
	faFilePdf = faFilePdf;
	faFileDownload = faFileDownload;
	faEdit = faEdit;
	faSave = faSave;
	faArrowsAlt = faArrowsAlt;
	faArrowUp = faArrowUp;
	faArrowDown = faArrowDown;
	faTrashAlt = faTrashAlt;
	faPlusSquare = faPlusSquare;
	faUndo = faUndo;
}
