import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-completed-resolve",
	templateUrl: "./work-orders-completed-resolve.component.html",
})
export class WorkOrdersCompletedResolveComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByPriority: boolean;
	priorityLabel: string = "";
	chartDataByResolve = [[], []];
	chartConfigByResolve = {
		type: "bar",
		data: {
			labels: [],
			datasets: [],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: false,
			},
			scales: {
				xAxes: [
					{
						stacked: true,
						ticks: {
							autoSkip: false,
						},
					},
				],
				yAxes: [
					{
						stacked: true,
						type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: true,
						position: "left",
						id: "y-axis-1",
						ticks: {
							beginAtZero: true,
							callback: function (label, index, labels) {
								return `${(label * 100).toLocaleString()}\%`;
							},
						},
					},
				],
			},
			tooltips: {
				callbacks: {
					title: function (tooltipItem, data) {
						var title = data.datasets[tooltipItem[0].datasetIndex].label;
						return title === null ? "null" : title;
					},
					label: (tooltipItem, data) => {
						var original =
							this.chartDataByResolve[tooltipItem.datasetIndex][tooltipItem["index"]];
						return `${original.toLocaleString()} (${(
							data.datasets[tooltipItem.datasetIndex].data[tooltipItem["index"]] * 100
						).toLocaleString(undefined, {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						})}%)`;
					},
				},
			},
		},
	};
	@ViewChild("resolveChart") chartByResolve: ChartComponent;

	backgroundColor = [];
	hoverBackgroundColor = [];
	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByPriority = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Completed"];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByPriority = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		//#region Chart 4
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryPriority(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						this.loadedByPriority = true;
						let acceptablePrioritiesStr =
							this.whiteLabelService.getSettings.resolveKPIPriorities;
						let acceptablePriorities = acceptablePrioritiesStr.split(",");
						let newLabelsResolve = [];
						data.forEach((element) => {
							let indexResolve = newLabelsResolve.indexOf(element.priority);
							if (indexResolve < 0) {
								let isAcceptable = false;
								acceptablePriorities.forEach((priority) => {
									if (element.priority.startsWith(priority)) {
										isAcceptable = true;
									}
								});
								if (isAcceptable) {
									newLabelsResolve.push(element.priority);
								}
							}
						});
						newLabelsResolve.sort();
						let valuesTemplate = [];
						newLabelsResolve.forEach((element) => {
							valuesTemplate.push(0);
						});
						let newDataResolve = [];
						let newDataResolveOriginal = [
							valuesTemplate.slice(),
							valuesTemplate.slice(),
						];
						let i = 0;
						let datasources = ["Fail", "Pass"];
						let colors = this.whiteLabelService.getKPIFailPassColors();
						datasources.forEach((element) => {
							newDataResolve.push({
								label: element,
								backgroundColor: colors.colorsResolve[i],
								hoverBackgroundColor: colors.colorsHoverResolve[i],
								yAxisID: "y-axis-1",
								data: valuesTemplate.slice(),
							});
							i++;
						});
						data.forEach((element) => {
							let indexResolve = newLabelsResolve.indexOf(element.priority);
							if (indexResolve > -1) {
								newDataResolve[0].data[indexResolve] +=
									element.resolveKPIFail / element.count;
								newDataResolve[1].data[indexResolve] +=
									(element.count - element.resolveKPIFail) / element.count;
								newDataResolveOriginal[0][indexResolve] += element.resolveKPIFail;
								newDataResolveOriginal[1][indexResolve] +=
									element.count - element.resolveKPIFail;
							}
						});

						let priorityNumbers = [];
						acceptablePriorities.forEach((element) => {
							priorityNumbers.push(parseInt(element.replace(/\D/g, "")));
						});
						priorityNumbers.sort();
						let solidPriorities = priorityNumbers.length > 1;
						if (solidPriorities) {
							let previousValue = priorityNumbers[0];
							for (let i = 1; i < priorityNumbers.length; i++) {
								if (
									previousValue + 1 !== priorityNumbers[i] &&
									previousValue !== priorityNumbers[i]
								) {
									solidPriorities = false;
									break;
								}
								previousValue = priorityNumbers[i];
							}
						}
						if (solidPriorities) {
							let priorityLabel = `${acceptablePriorities[0]}-${
								acceptablePriorities[acceptablePriorities.length - 1]
							}`;
							this.priorityLabel = priorityLabel;
						} else {
							let priorityLabel = newLabelsResolve.join(", ");
							if (priorityLabel) {
								this.priorityLabel = priorityLabel;
							} else {
								this.priorityLabel = "";
							}
						}

						this.chartConfigByResolve.data.labels = newLabelsResolve;
						this.chartConfigByResolve.data.datasets = newDataResolve;
						this.chartDataByResolve = newDataResolveOriginal;
						if (this.chartByResolve) {
							this.chartByResolve.chart.update();
						}
						this.ref.detectChanges();
					}
				})
		);
		//#endregion
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
