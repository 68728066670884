import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import {
	faCloudDownloadAlt,
	faPlusSquare,
	faSyncAlt,
	faTable,
	faTimes
} from '@fortawesome/free-solid-svg-icons';
import {
	NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NotificationType } from 'angular2-notifications';
import {
	IMyDateRangeModel, IMyDrpOptions,
	IMyInputFocusBlur,
	MyDateRangePicker
} from 'mydaterangepicker';
import { Observable, Subscription } from 'rxjs';
import { UserProfileModel } from '../..//models/userProfileModel';
import { DropdownItem } from '../../models/dropdownItem';
import { AuthService } from '../../services/auth.service';
import { MessageService } from '../../services/message.service';
import {
	StateService
} from '../../services/state.service';
import { WhiteLabelService } from '../../services/white-label.service';
import { Filter } from '../models/filter';
import { IsReportReadyQueryOptionsModel } from '../models/isReportReadyQueryOptionsModel';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import { QueryOptionsPagingModel } from '../models/queryOptionsPagingModel';
import { ReportTypeVendorDropdownModel } from '../models/reportTypeVendorDropdownModel';
import { ReportTypeVendorHistoryResponseModel } from '../models/reportTypeVendorHistoryResponseModel';
import { ReportTypeVendorResponseModel } from '../models/reportTypeVendorResponseModel';
import { VendorKpiService } from '../services/vendor-kpi.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import { WorkOrderReportService } from '../services/work-order-report.service';

@Component({
	selector: 'app-vendor-performance-kpi-report',
	templateUrl: './vendor-performance-kpi-report.component.html'
})
export class VendorPerformanceKpiReportComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loaded: boolean;
	tableData = {
		rows: [],
		columns: [{
			prop: 'vendorName',
			name: 'Vendor'
		}, {
			prop: 'createType',
			name: 'Trigger'
		}, {
			prop: 'reportTypeCd',
			name: 'Type'
		}, {
			prop: 'startDate',
			name: 'Start Date'
		}, {
			prop: 'endDate',
			name: 'End Date'
		}, {
			prop: 'createdDate',
			name: 'Generated'
		}],
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.tableByInformByState.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		}
	};
	@ViewChild('woInformTable') tableByInformByState: DatatableComponent;

	userProfile$: Observable<UserProfileModel>;
	userProfile: UserProfileModel;

	constructor(private ref: ChangeDetectorRef,
		private modalService: NgbModal,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private vendorKpiService: VendorKpiService,
		private messageService: MessageService,
		private workOrderReportService: WorkOrderReportService,
		public whiteLabelService: WhiteLabelService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loaded = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.roles$.subscribe(data => {
			if (data.length > 0 && data.indexOf('store') < 0 && data.indexOf('rm') < 0) {
				this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
					this.update(this.stateService.getCurrentDateRange(), value);
				}));
			}
		}));
		this.userProfile$ = this.authService.getCurrentUserProfile;
		this.userProfile$.subscribe(data => {
			this.userProfile = data;
		});
		this.loadVendors();
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		return this.woFiltersService.fillBody(body, dateRange);
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;

		let body = this.fillBody(new QueryOptionsModel(), dateRange);
		this.subs.add(this.vendorKpiService.getReportTypeVendorHistory(body)
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.tableData.rows = data;
					this.loaded = true;
					this.ref.detectChanges();
				}
			}));
	}

	vendors: DropdownItem[] = [];
	selectedVendor: DropdownItem;
	loadedVendors: boolean = false;
	types: ReportTypeVendorDropdownModel[] = [];
	selectedType: ReportTypeVendorDropdownModel;
	loadedTypes: boolean = true;

	myDateRangePickerOptions: IMyDrpOptions = {
		dateFormat: 'dd mmm yyyy',
		showClearBtn: false,
		showApplyBtn: false,
		selectorHeight: '282px',
		selectorWidth: '304px',
		height: '38px',
		selectionTxtFontSize: '16px'
	};
	// For example initialize to specific date (09.10.2018 - 19.10.2018). It is also possible
	// to set initial date range value using the selDateRange attribute.
	public model: any = {
		beginDate: undefined,
		endDate: undefined
	};
	@ViewChild('mydrpNewReport') mydrp: MyDateRangePicker;
	onDateRangeChanged(event: IMyDateRangeModel) {
		//this.stateService.setDateRange(event);
		//console.log(event);
		this.loadTypes(this.selectedVendor, event);
	}
	onInputFocusBlur(event: IMyInputFocusBlur): void {
		if (event.reason === 1) {
			this.mydrp.openBtnClicked();
		}
	}

	loadVendors() {
		this.loadedVendors = false;
		this.vendors = [];
		this.selectedVendor = null;
		let body = new QueryOptionsModel();
		body.filter = new Filter();
		body.filter.vendorExclusions = ["NonKpiReporting"];
		this.subs.add(this.workOrderReportService.getVendorParents(body)
			.subscribe(data => {
				this.vendors = data;
				this.loadedVendors = true;
				this.ref.detectChanges();
			}));
	}

	onVendorChange(event) {
		this.loadTypes(event, this.model);
	}

	updateIdTypes: string = "";

	loadTypes(vendor: DropdownItem | null, dateRange: IMyDateRangeModel) {
		this.types = [];
		this.selectedType = null;
		if (vendor && vendor.id && dateRange !== null && dateRange.beginDate != null && dateRange.endDate != null && dateRange.beginDate.year > 0) {
			this.loadedTypes = false;
			let updateId = `${vendor.id}-${dateRange.beginEpoc}-${dateRange.endEpoc}`;
			this.updateIdTypes = updateId;
			let body = new QueryOptionsModel();
			body.filter = new Filter();
			let month1 = dateRange.beginDate.month.toLocaleString('en-US', {
				minimumIntegerDigits: 2,
				useGrouping: false
			});
			let month2 = dateRange.endDate.month.toLocaleString('en-US', {
				minimumIntegerDigits: 2,
				useGrouping: false
			});
			let day1 = dateRange.beginDate.day.toLocaleString('en-US', {
				minimumIntegerDigits: 2,
				useGrouping: false
			});
			let day2 = dateRange.endDate.day.toLocaleString('en-US', {
				minimumIntegerDigits: 2,
				useGrouping: false
			});
			body.filter.vendorIds = [vendor.id];
			body.filter.startDate = `${dateRange.beginDate.year}-${month1}-${day1}T00:00:00.000Z`;
			body.filter.endDate = `${dateRange.endDate.year}-${month2}-${day2}T00:00:00.000Z`;
			body.filter.isActive = true;
			body.filter.excludeExistingReports = false;

			this.subs.add(this.vendorKpiService.getReportTypeVendors(body)
				.subscribe(data => {
					if (this.updateIdTypes === updateId) {
						let uniqueTypes: string[] = [];
						let types: ReportTypeVendorDropdownModel[] = [];
						data.forEach(element => {
							if (uniqueTypes.indexOf(element.reportTypeCd) < 0) {
								uniqueTypes.push(element.reportTypeCd);
								types.push({
									id: element.reportTypeCd,
									path: element.reportTypePath,
									name: element.reportTypeDescription,
									reportSettings: element.reportSettings
								});
							}
						});
						this.types = types;
						this.loadedTypes = true;
						this.ref.detectChanges();
					}
				}));
		}
	}

	canSelectType() {
		var vendor = this.selectedVendor;
		var dateRange = this.model;
		return vendor && vendor.id && dateRange !== null && dateRange.beginDate != null && dateRange.endDate != null && dateRange.beginDate.year > 0;
	}

	isReadyToGenerate() {
		var vendor = this.selectedVendor;
		var dateRange = this.model;
		return this.selectedType && vendor && vendor.id && dateRange !== null && dateRange.beginDate != null && dateRange.endDate != null && dateRange.beginDate.year > 0;
	}

	generationInProgress: boolean = false;

	showNewDialog(content) {
		this.modalService.open(content, {}).result.then((result) => {
			// Generate
			var vendor = this.selectedVendor;
			var dateRange = this.model;
			var type = this.selectedType;
			if (type && vendor && vendor.id && dateRange !== null && dateRange.beginDate != null && dateRange.endDate != null && dateRange.beginDate.year > 0) {
				this.loadedTypes = false;

				let month1 = dateRange.beginDate.month.toLocaleString('en-US', {
					minimumIntegerDigits: 2,
					useGrouping: false
				});
				let month2 = dateRange.endDate.month.toLocaleString('en-US', {
					minimumIntegerDigits: 2,
					useGrouping: false
				});
				let day1 = dateRange.beginDate.day.toLocaleString('en-US', {
					minimumIntegerDigits: 2,
					useGrouping: false
				});
				let day2 = dateRange.endDate.day.toLocaleString('en-US', {
					minimumIntegerDigits: 2,
					useGrouping: false
				});
				let startDate = `${dateRange.beginDate.year}-${month1}-${day1}T00:00:00.000Z`;
				let endDate = `${dateRange.endDate.year}-${month2}-${day2}T00:00:00.000Z`;

				let body: ReportTypeVendorResponseModel = {
					reportTypeCd: type.id,
					reportTypeStartDate: startDate,
					reportTypeEndDate: endDate,
					reportTypePath: type.path,
					reportTypeDescription: type.name,
					vendorId: vendor.id,
					reportEmail: this.userProfile && this.userProfile.email ? this.userProfile.email : null,
					reportSettings: type.reportSettings,
					trigger: "adhoc"
				};
				this.generateReport(body);
			}
		}, (reason) => {
			// Dismissed
		});
	}

	generateReport(body: ReportTypeVendorResponseModel) {
		this.generationInProgress = true;
		this.messageService.info("Report", "Generation in progress");
		let requestTime = new Date();
		this.subs.add(this.vendorKpiService.scheduleReport(body)
			.subscribe(data => {
				let query = new QueryOptionsModel();
				query.filter = new Filter();
				query.filter.fromDt = body.reportTypeStartDate;
				query.filter.toDt = body.reportTypeEndDate;
				query.filter.vendorIds = [body.vendorId];
				let isReadyBody: IsReportReadyQueryOptionsModel = {
					model: query,
					requestTime: requestTime
				};
				this.waitForReportGeneration(isReadyBody);
			}));
	}

	regenerateReport(row) {
		if (this.generationInProgress) {
			this.messageService.warn("Report", "Please wait for the previous report generation");
		} else {
			this.generationInProgress = true;
			let body = new QueryOptionsModel();
			body.filter = new Filter();
			body.filter.vendorIds = [row.vendorId];
			body.filter.startDate = row.startDate;
			body.filter.endDate = row.endDate;
			body.filter.isActive = true;
			body.filter.excludeExistingReports = false;
			this.subs.add(this.vendorKpiService.getReportTypeVendors(body)
				.subscribe(data => {
					let uniqueTypes: string[] = [];
					let paths: any = {};
					data.forEach(element => {
						if (uniqueTypes.indexOf(element.reportTypeCd) < 0) {
							uniqueTypes.push(element.reportTypeCd);
							paths[element.reportTypeCd] = {
								path: element.reportTypePath,
								description: element.reportTypeDescription,
								reportSettings: element.reportSettings
							};
						}
					});
					let body2: ReportTypeVendorResponseModel = {
						reportTypeCd: row.reportTypeCd,
						reportTypeStartDate: row.startDate,
						reportTypeEndDate: row.endDate,
						reportTypePath: paths[row.reportTypeCd].path,
						reportTypeDescription: paths[row.reportTypeCd].description,
						vendorId: row.vendorId,
						reportEmail: this.userProfile && this.userProfile.email ? this.userProfile.email : null,
						reportSettings: paths[row.reportTypeCd].reportSettings,
						trigger: "regenerate"
					};
					this.generateReport(body2);
				}));
		}
	}

	@ViewChild('popupDownload') popupDownload: TemplateRef<any>;

	waitForReportGeneration(body: IsReportReadyQueryOptionsModel) {
		setTimeout(() => {
			this.vendorKpiService.isReportReady(body).subscribe(data => {
				if (data) {
					this.generationInProgress = false;
					this.update(this.stateService.getCurrentDateRange(), this.woFiltersService.getCurrentUpdateId());
					let link = this.getLink(data);
					let context: any = { url: link };
					this.messageService.html(
						this.popupDownload,
						NotificationType.Success,
						{
							timeOut: 0,
							showProgressBar: false,
							clickToClose: false
						},
						null,
						context,
						(event, notification) => {
							if (!this.hasSomeParentTheClass(event.toElement ? event.toElement : event.srcElement, "close-notification")) {
								// download
								const downloadlink = document.createElement('a');
								downloadlink.href = link;
								downloadlink.click();
							}
							// close
							this.messageService.removeNotification(notification);
						});
				} else {
					this.waitForReportGeneration(body);
				}
			});
		}, 5000);
	}

	hasSomeParentTheClass(element, className) {
		if (!element) {
			return false;
		}
		do {
			if (element.classList && element.classList.contains(className)) {
				return true;
			}
			element = element.parentNode;
		} while (element);
		return false;
	}

	getLink(report: ReportTypeVendorHistoryResponseModel) {
		return this.vendorKpiService.getReportLink(report);
	}

	isRegenerateAllowed(row): boolean {
		return row.createType === "scheduled";
	}

	// Icons
	faTable = faTable;
	faPlusSquare = faPlusSquare;
	faCloudDownloadAlt = faCloudDownloadAlt;
	faSyncAlt = faSyncAlt;
	faTimes = faTimes;
}