<div class="card info-card has-invisible-content">
	<div class="card-header tabs-display-none">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faInfo"></fa-icon> Summary
		</h2>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !(loadedOpenSummary && loadedKPISummary)}">
			<div class="info-wrapper place-row-md">
				<div class="display-1">{{openRM | number}}<small>Open RM</small></div>
				<div class="display-1">{{openPM | number}}<small>Open PM</small></div>
				<div class="display-1 text-danger pb-sm-1 pb-md-0">{{p1KPI | number}}<small class="text-body">P1 missed KPI</small></div>
				<div class="display-1 text-danger pb-sm-1 pb-md-0">{{p2KPI | number}}<small class="text-body">P2 missed KPI</small></div>
			</div>
		</div>

		<div *ngIf="!loadedOpenSummary || !loadedKPISummary"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
