<div class="card has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> Open Work Orders by Priority
		</h2>
	</div>
	<div class="card-body p-0 card-body-owo-priority">
		<div class="table-responsive sticky-first-column-regular-table">
			<table *ngIf="tableData.rows.length && loaded" class="table table-striped table-owo-priority">
				<thead>
					<tr>
						<th [class.text-right]="column.prop != 'Type'" *ngFor="let column of tableData.columns">{{column.name}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let row of tableData.rows">
						<td [class.text-right]="column.prop != 'Type'" *ngFor="let column of tableData.columns; let i = index">{{i == 0 ? row[column.prop] : (row[column.prop] | number)}}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<p *ngIf="!tableData.rows.length && loaded" class="card-padding">No data to display</p>

		<div *ngIf="!loaded"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
