import {
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from "@angular/core";
import {
	faChartColumn,
	faExpandArrowsAlt,
	faFileExport,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AngularCsv } from "angular-csv-ext";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import ConverterHelper from "../../converterHelper";
import { DropdownItem } from "../../models/dropdownItem";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { AssetBenchmarkByAgeResponseModel } from "../models/assetBenchmarkByAgeResponseModel";
import { AssetBenchmarkByAssetResponseModel } from "../models/assetBenchmarkByAssetResponseModel";
import { AssetBenchmarkListExtendedModel } from "../models/assetBenchmarkListExtendedModel";
import { AssetBenchmarkListResponseModel } from "../models/assetBenchmarkListResponseModel";
import { DashboardAssetPerformanceDataSetModel } from "../models/dashboardAssetPerformanceDataSetModel";
import { DashboardAssetPerformanceModel } from "../models/dashboardAssetPerformanceModel";
import { DashboardAssetPerformanceSeriesModel } from "../models/dashboardAssetPerformanceSeriesModel";
import { Filter } from "../models/filter";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { AssetService } from "../services/asset.service";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-asset-performance-summary-asset",
	templateUrl: "./asset-performance-summary-asset.component.html",
})
export class AssetPerformanceSummaryAssetComponent
	implements OnInit, OnDestroy
{
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loaded: boolean;
	title: string;
	// data, series, dataSet, datasetIndex, seriesIndex
	dataByAge: [
		AssetBenchmarkByAgeResponseModel[],
		DashboardAssetPerformanceSeriesModel,
		DashboardAssetPerformanceDataSetModel,
		number,
		number
	][] = [];
	// data, series, dataSet, datasetIndex, seriesIndex
	dataByAsset: [
		AssetBenchmarkByAssetResponseModel[],
		DashboardAssetPerformanceSeriesModel,
		DashboardAssetPerformanceDataSetModel,
		number,
		number
	][] = [];
	// data, series, dataSet, datasetIndex, seriesIndex
	dataBenchmarkList: [
		AssetBenchmarkListResponseModel[],
		DashboardAssetPerformanceSeriesModel,
		DashboardAssetPerformanceDataSetModel,
		number,
		number
	][] = [];

	tableData = {
		rows: [],
		columns: [
			"",
			"Asset Number",
			"Barcode",
			"Installation Date",
			"Decommission Date",
			"Age",
			"Annualised ($)",
			"Benchmark (against All Assets)",
			"% Against Benchmark",
			"Annualised (Counts)",
			"Benchmark (against All Assets)",
			"% Against Benchmark",
			"Annualised (Hrs)",
			"Benchmark (against All Assets)",
			"% Against Benchmark",
		],
		headersGroup: [
			["", 6],
			["Maintenance Cost", 3],
			["Maintenance Counts", 3],
			["MTBF", 3],
		],
		rowsForExport: [],
		headersForExport: [
			"",
			"Asset Number",
			"Barcode",
			"Installation Date",
			"Decommission Date",
			"Age",
			"Maintenance Cost - Annualised ($)",
			"Maintenance Cost - Benchmark (against All Assets)",
			"Maintenance Cost - % Against Benchmark",
			"Maintenance Counts - Annualised (Counts)",
			"Maintenance Counts - Benchmark (against All Assets)",
			"Maintenance Counts - % Against Benchmark",
			"MTBF - Annualised (Hrs)",
			"MTBF - Benchmark (against All Assets)",
			"MTBF - % Against Benchmark",
		],
	};

	tableDataBenchmarkList = {
		rows: [],
		columns: [
			{
				prop: "description",
				name: "Asset Description",
			},
			{
				prop: "assetId",
				name: "Asset Number",
			},
			{
				prop: "barcode",
				name: "Barcode",
			},
			{
				prop: "installationDate",
				name: "Installation Date",
			},
			{
				prop: "decommissionDate",
				name: "Decommission Date",
			},
			{
				prop: "ageInYears",
				name: "Age",
			},
		],
	};

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private assetService: AssetService,
		private modalService: NgbModal
	) {}

	@Input() model: DashboardAssetPerformanceModel | null = null;
	roles$: Observable<string[]>;
	ngOnInit() {
		this.loaded = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel,
		dataSet: DashboardAssetPerformanceDataSetModel,
		series: DashboardAssetPerformanceSeriesModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body.filter = new Filter();
		body.filter.assetTypeTags = ["Key"];
		switch (series.filterType) {
			case "assetNames": {
				body.filter.assetNames = series.filterValues as string[];
				break;
			}
			case "retailBusinessManagers": {
				// squad
				body.filter.retailBusinessManagers = series.filterValues as string[];
				body.filter.assetNames = dataSet.series[0].filterValues as string[];
				break;
			}
			case "storeProfiles": {
				// fuel/non fuel
				body.filter.storeProfiles = series.filterValues as string[];
				body.filter.assetNames = dataSet.series[0].filterValues as string[];
				break;
			}
			case "storeTypes": {
				if (
					this.whiteLabelService.getSettings.filterStoreTypeLabel === "facility type"
				) {
					body.filter.storeTypes = series.filterValues as string[];
				} else {
					body.filter.storeClusters = series.filterValues as string[];
				}
				body.filter.assetNames = dataSet.series[0].filterValues as string[];
				break;
			}
			case "stores": {
				body.filter.storeIds = (series.filterValues as DropdownItem[]).map(
					(item) => item.id
				);
				body.filter.assetNames = dataSet.series[0].filterValues as string[];
				break;
			}
		}
		if (dataSet.series[0].fromDt && dataSet.series[0].toDt) {
			body.filter.fromDt = dataSet.series[0].fromDt + "T00:00:00.000Z";
			body.filter.toDt = dataSet.series[0].toDt + "T00:00:00.000Z";
		}
		return body;
	}

	updateCounter = 0;
	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;
		this.tableData.rows = [];
		this.tableData.rowsForExport = [];
		this.dataByAge = [];
		this.dataByAsset = [];
		this.dataBenchmarkList = [];
		let updateCounter = 0;
		this.model.dataSets.forEach((dataSet) => {
			updateCounter += dataSet.series.length > 2 ? 1 : 0;
		});
		const requestsCount = 3;
		this.updateCounter = updateCounter * requestsCount;
		let i = 1;
		const disableSeries3 = this.model.dataSets.length > 2;
		this.model.dataSets.forEach((dataSet) => {
			if (dataSet.series.length > 2) {
				if (!disableSeries3) {
					this.sendRequestSeries1(
						i,
						0,
						dataSet,
						dataSet.series[0],
						this.fillBody(
							new QueryOptionsModel(),
							dateRange,
							dataSet,
							dataSet.series[0]
						),
						updateId
					);
					this.sendRequestSeries3(
						i,
						2,
						dataSet,
						dataSet.series[2],
						this.fillBody(
							new QueryOptionsModel(),
							dateRange,
							dataSet,
							dataSet.series[2]
						),
						updateId
					);
				} else {
					this.updateCounter -= requestsCount;
				}
			}
			i++;
		});

		if (!this.updateCounter) {
			this.loaded = true;
		}
	}

	sendRequestSeries1(
		dataSetIndex: number,
		seriesIndex: number,
		dataSet: DashboardAssetPerformanceDataSetModel,
		series: DashboardAssetPerformanceSeriesModel,
		body: QueryOptionsModel | QueryOptionsPagingModel,
		updateId: string
	) {
		this.subs.add(
			this.assetService.getAssetBenchmarkByAge(body).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.updateCounter--;
					this.dataByAge.push([data, series, dataSet, dataSetIndex, seriesIndex]);
					this.fillTable();
				}
			})
		);
	}

	sendRequestSeries3(
		dataSetIndex: number,
		seriesIndex: number,
		dataSet: DashboardAssetPerformanceDataSetModel,
		series: DashboardAssetPerformanceSeriesModel,
		body: QueryOptionsModel | QueryOptionsPagingModel,
		updateId: string
	) {
		this.subs.add(
			this.assetService.getAssetBenchmarkByAge(body).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.updateCounter--;
					this.dataByAge.push([data, series, dataSet, dataSetIndex, seriesIndex]);
					this.fillTable();
				}
			})
		);
		this.subs.add(
			this.assetService.getAssetBenchmarkByAsset(body).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.updateCounter--;
					this.dataByAsset.push([data, series, dataSet, dataSetIndex, seriesIndex]);
					this.fillTable();
				}
			})
		);
		this.subs.add(
			this.assetService.getAssetBenchmarkList(body).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.updateCounter--;
					this.dataBenchmarkList.push([
						data,
						series,
						dataSet,
						dataSetIndex,
						seriesIndex,
					]);
					this.fillTable();
				}
			})
		);
	}

	fillTable() {
		if (this.updateCounter <= 0) {
			// fill table from this.dataByAge, this.dataByAsset, this.dataBenchmarkList
			interface GroupedSummaryDataSets {
				group: string;
				values: string[][];
			}
			const groupedData: GroupedSummaryDataSets[] = [];
			const disableSeries3 = this.model.dataSets.length > 2;
			for (let i = 0; i < this.model.dataSets.length; i++) {
				const currentDataSet = this.model.dataSets[i];
				if (currentDataSet.series.length) {
					const timeStr =
						currentDataSet.series[0].fromDt && currentDataSet.series[0].toDt
							? `${currentDataSet.series[0].fromDt} - ${currentDataSet.series[0].toDt}`
							: "";
					let j = 0;
					this.model.dataSets[i].series.forEach((series) => {
						if (!disableSeries3 || j < 2) {
							const filterStr =
								j < 2
									? (series.filterValues as string[]).join(",")
									: (series.filterValues as DropdownItem[]).map((x) => x.name).join(",");
							const gd = {
								group: `Data Set ${i + 1}<br/>${`Series ${
									j + 1
								}`}<br/>${filterStr}<br/>${timeStr}`,
								values: [],
							};
							groupedData.push(gd);
						}
						j++;
					});
				}
			}

			for (let i = 0; i < this.dataByAsset.length; i++) {
				const currentDataByAsset = this.dataByAsset[i][0];
				//const currentSeries = this.dataByAsset[i][1];
				//const currentDataSet = this.dataByAsset[i][2];
				const currentDataSetIndex = this.dataByAsset[i][3] - 1;
				const currentSeriesIndex = this.dataByAsset[i][4];
				let currentDataByAge: AssetBenchmarkByAgeResponseModel[] = [];
				for (let j = 0; j < this.dataByAge.length; j++) {
					if (
						this.dataByAge[j][3] === this.dataByAsset[i][3] &&
						this.dataByAge[j][4] === 0
					) {
						currentDataByAge = this.dataByAge[j][0];
						break;
					}
				}
				let currentDataBenchmarkList: AssetBenchmarkListResponseModel[] = [];
				for (let j = 0; j < this.dataBenchmarkList.length; j++) {
					if (
						this.dataBenchmarkList[j][3] === this.dataByAsset[i][3] &&
						this.dataBenchmarkList[j][4] === this.dataByAsset[i][4]
					) {
						currentDataBenchmarkList = this.dataBenchmarkList[j][0];
						break;
					}
				}
				let gdIndex = 0;
				for (let dsI = 0; dsI < this.model.dataSets.length; dsI++) {
					let stop = false;
					for (let sI = 0; sI < this.model.dataSets[dsI].series.length; sI++) {
						if (dsI === currentDataSetIndex && currentSeriesIndex === sI) {
							stop = true;
							break;
						}
						gdIndex++;
					}
					if (stop) {
						break;
					}
				}
				currentDataByAsset.forEach((item) => {
					let itemByAge: AssetBenchmarkByAgeResponseModel | null = null;
					for (let g = 0; g < currentDataByAge.length; g++) {
						if (currentDataByAge[g].ageInYears === item.ageInYears) {
							itemByAge = currentDataByAge[g];
							break;
						}
					}
					let itemsBenchmarkList: AssetBenchmarkListExtendedModel[] = [];
					for (let g = 0; g < currentDataBenchmarkList.length; g++) {
						if (
							currentDataBenchmarkList[g].groupingAssetTypeMapId ===
							item.groupingAssetTypeMapId
						) {
							itemsBenchmarkList.push({
								age: item.ageInYears,

								assetId: currentDataBenchmarkList[g].assetId,
								assetTypeMapId: currentDataBenchmarkList[g].assetTypeMapId,
								groupingAssetTypeMapId:
									currentDataBenchmarkList[g].groupingAssetTypeMapId,
								groupByEntity: currentDataBenchmarkList[g].groupByEntity,
								system: currentDataBenchmarkList[g].system,
								category: currentDataBenchmarkList[g].category,
								name: currentDataBenchmarkList[g].name,
								description: currentDataBenchmarkList[g].description,

								installationDate: currentDataBenchmarkList[g].installationDate,
								decommissionDate: currentDataBenchmarkList[g].decommissionDate,
								barcode: currentDataBenchmarkList[g].barcode,
							});
						}
					}
					let row = [];
					const firstCell = item.groupByEntity ? itemsBenchmarkList : item.assetId;
					row.push([
						firstCell,
						firstCell,
						Array.isArray(firstCell) ? "text-center" : "text-end",
					]);
					const barcode = item.groupByEntity ? "" : item.barcode;
					row.push([barcode, barcode, "text-end"]);
					const installationDate = item.groupByEntity
						? ""
						: this.formatDateForTable(item.installationDate as string);
					row.push([installationDate, installationDate, "text-end"]);
					const decommissionDate = item.groupByEntity
						? ""
						: this.formatDateForTable(item.decommissionDate as string);
					row.push([decommissionDate, decommissionDate, "text-end"]);
					const age = item.groupByEntity ? "" : item.ageInYears;
					row.push([age, age, "text-end"]);
					row.push([
						item.annualisedMaintCost.toFixed(2),
						`$${ConverterHelper.floatToString(item.annualisedMaintCost)}`,
						"text-end",
					]);
					const benchmarkMaintCost = itemByAge ? itemByAge.benchmarkMaintCost : 0;
					row.push([
						itemByAge ? benchmarkMaintCost.toFixed(2) : "N/A",
						itemByAge
							? `$${ConverterHelper.floatToString(benchmarkMaintCost)}`
							: "N/A",
						"text-end",
					]);
					const againstBenchamrk1 =
						itemByAge && itemByAge.benchmarkMaintCost
							? (100 * item.annualisedMaintCost) / itemByAge.benchmarkMaintCost
							: 0;
					row.push([
						itemByAge ? againstBenchamrk1.toFixed(2) : "N/A",
						itemByAge
							? `${ConverterHelper.floatToString(againstBenchamrk1)}%`
							: "N/A",
						`text-end ${
							againstBenchamrk1 > 150
								? "bg-danger text-white"
								: againstBenchamrk1 > 125
								? "bg-warning text-white"
								: ""
						}`,
					]);
					row.push([
						item.annualisedMaintCount,
						ConverterHelper.floatToString(item.annualisedMaintCount),
						"text-end",
					]);
					const benchmarkMaintCount = itemByAge ? itemByAge.benchmarkMaintCount : 0;
					row.push([
						itemByAge ? benchmarkMaintCount.toFixed(2) : "N/A",
						itemByAge
							? `${ConverterHelper.floatToString(benchmarkMaintCount)}`
							: "N/A",
						"text-end",
					]);
					const againstBenchamrk2 =
						itemByAge && itemByAge.benchmarkMaintCount
							? (100 * item.annualisedMaintCount) / itemByAge.benchmarkMaintCount
							: 0;
					row.push([
						itemByAge ? againstBenchamrk2.toFixed(2) : "N/A",
						itemByAge
							? `${ConverterHelper.floatToString(againstBenchamrk2)}%`
							: "N/A",
						`text-end ${
							againstBenchamrk2 > 150
								? "bg-danger text-white"
								: againstBenchamrk2 > 125
								? "bg-warning text-white"
								: ""
						}`,
					]);
					row.push([
						item.annualisedTimeBetweenFailure.toString(),
						ConverterHelper.intToString(item.annualisedTimeBetweenFailure),
						"text-end",
					]);
					const timeBetweenFailure = itemByAge ? itemByAge.timeBetweenFailure : 0;
					row.push([
						itemByAge ? timeBetweenFailure.toString() : "N/A",
						itemByAge ? `${ConverterHelper.intToString(timeBetweenFailure)}` : "N/A",
						"text-end",
					]);
					const againstBenchamrk3 =
						itemByAge && itemByAge.timeBetweenFailure
							? (100 * item.annualisedTimeBetweenFailure) /
							  itemByAge.timeBetweenFailure
							: 0;
					row.push([
						itemByAge ? againstBenchamrk3.toFixed(2) : "N/A",
						itemByAge
							? `${ConverterHelper.floatToString(againstBenchamrk3)}%`
							: "N/A",
						`text-end ${
							againstBenchamrk3 < 50
								? "bg-danger text-white"
								: againstBenchamrk3 >= 50 && againstBenchamrk3 < 75
								? "bg-warning text-white"
								: ""
						}`,
					]);
					groupedData[gdIndex].values.push(row);
				});
			}

			const rows = [];
			const rowsForExport = [];
			groupedData.forEach((gd) => {
				let isFirst = true;
				gd.values.forEach((gRow) => {
					let row = [];
					if (isFirst) {
						row.push([gd.group, "dark-cell", gd.values.length]);
						isFirst = false;
					}
					let rowForExport = [gd.group.replace(/<br\/>/g, " - ")];
					let i = 0;
					gRow.forEach((cell) => {
						row.push([
							cell[1],
							cell[2],
							1,
							Array.isArray(cell[0]),
							i === 0 && cell[0] && !Array.isArray(cell[0])
								? `${this.whiteLabelService.getOldDashboardUrl()}/Asset/AssetDetails?id=${
										cell[0]
								  }`
								: "",
						]);
						if (Array.isArray(cell[0])) {
							rowForExport.push("");
						} else {
							rowForExport.push(cell[0]);
						}
						i++;
					});
					rows.push(row);
					rowsForExport.push(rowForExport);

					if (Array.isArray(gRow[0][0])) {
						const unboxedRows =
							gRow[0][0] as unknown as AssetBenchmarkListExtendedModel[];
						unboxedRows.forEach((internalRow) => {
							rowForExport = [];
							rowForExport.push(internalRow.description);
							rowForExport.push(internalRow.assetId.toString());
							rowForExport.push(internalRow.barcode);
							rowForExport.push(
								this.formatDateForTable(internalRow.installationDate as string)
							);
							rowForExport.push(
								this.formatDateForTable(internalRow.decommissionDate as string)
							);
							rowForExport.push(internalRow.age.toString());
							rowsForExport.push(rowForExport);
						});
					}
				});
			});

			this.tableData.rows = rows;
			this.tableData.rowsForExport = rowsForExport;

			// all done
			this.loaded = true;
			this.ref.detectChanges();
		}
	}

	public formatDateForTable(dateStr: string): string {
		if (dateStr) {
			const tIndex = dateStr.indexOf("T");
			if (tIndex > -1) {
				return dateStr.substr(0, tIndex);
			} else {
				return dateStr;
			}
		} else {
			return "";
		}
	}

	isChartFullscreen = false;
	public goFullscreenChart(value: boolean) {
		this.isChartFullscreen = value;
	}

	public exportCsv() {
		const options = {
			fieldSeparator: ",",
			quoteStrings: '"',
			decimalseparator: ".",
			showLabels: true,
			headers: this.tableData.headersForExport.map((x) => {
				return `\"${x}\"`;
			}),
			showTitle: false,
			title: "export",
			useBom: true,
		};
		return new AngularCsv(this.tableData.rowsForExport, "export", options);
	}

	public open(content, list) {
		this.tableDataBenchmarkList.rows = list;
		this.modalService.open(content, { size: "lg" });
	}

	// Icons
	faChartColumn = faChartColumn;
	faFileExport = faFileExport;
	faExpandArrowsAlt = faExpandArrowsAlt;
	faTable = faTable;
}
