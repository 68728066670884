import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-completed-summary",
	templateUrl: "./work-orders-completed-summary.component.html",
})
export class WorkOrdersCompletedSummaryComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedSummary: boolean;
	countRM: number = 0;
	countPM: number = 0;
	costRM: number = 0;
	costPM: number = 0;

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedSummary = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.woFiltersService.loadData();
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Completed"];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedSummary = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		//#region Item 1, 2, 3, 4
		this.subs.add(
			this.workOrderReportService.getRequestSummary(body).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.countRM = 0;
					this.countPM = 0;
					this.costRM = 0;
					this.costPM = 0;
					data.forEach((element) => {
						if (element.requestType == "Planned") {
							this.countPM += element.count;
							this.costPM += element.cost;
						}
						if (
							element.requestType == "Reactive" ||
							element.requestType == "Incident"
						) {
							this.countRM += element.count;
							this.costRM += element.cost;
						}
					});
					this.loadedSummary = true;
					this.ref.detectChanges();
				}
			})
		);
		//#endregion
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
