import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	faFileExport,
	faTable
} from '@fortawesome/free-solid-svg-icons';
import {
	DatatableComponent
} from '@swimlane/ngx-datatable';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import * as moment from 'moment';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { MessageService } from '../../services/message.service';
import {
	StateService
} from '../../services/state.service';
import {
	WhiteLabelService
} from '../../services/white-label.service';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import {
	QueryOptionsPagingModel
} from '../models/queryOptionsPagingModel';
import { RequestListResponseModel } from '../models/requestListResponseModel';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';

@Component({
	selector: 'app-financial-wo',
	templateUrl: './financial-wo.component.html'
})
export class FinancialWOComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		public whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private messageService: MessageService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(value);
		}));
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ['Open', 'Completed'];
		return body;
	}

	private currentUpdateId: string = '';
	update(updateId: string) {
		this.currentUpdateId = updateId;
		setTimeout(() => {
			this.loadOpenRMWorkOrders(true);
		});
	}

	//#region table RM Work Orders
	@ViewChild('financialWorkOrders') tableOpenRMWorkOrders: DatatableComponent;
	loadingOpenRMWorkOrders: boolean = false;
	tableDataOpenRMWorkOrders = {
		page: {
			size: 20,
			totalElements: 0
		},
		sorts: [{
			prop: 'requestNumber',
			dir: 'asc'
		}],
		rows: [],
		columns: [{
			prop: 'id',
			name: 'Id'
		}, {
			prop: 'storeNo',
			name: 'Store No'
		}, {
			prop: 'storeName',
			name: 'Store Name'
		}, {
			prop: 'vendorName',
			name: 'Vendor Name'
		}, {
			prop: 'requestDate',
			name: 'Request Date'
		}, {
			prop: 'statusName',
			name: 'Status'
		}, {
			prop: 'code',
			name: 'GL Code'
		}, {
			prop: 'status',
			name: 'GL Status'
		}, {
			prop: 'assetName',
			name: 'AssetName – AssetCategory'
		}],
		onSort: (event) => {
			this.loadOpenRMWorkOrders(true);
		},
		onScroll: (event) => {
			if (event && event.currentTarget) {
				const offsetY = event.currentTarget.scrollTop;
				// total height of all rows in the viewport
				const viewHeight = event.currentTarget.clientHeight;

				// check if we scrolled to the end of the viewport
				if (!this.loadingOpenRMWorkOrders && offsetY + viewHeight >= (this.tableDataOpenRMWorkOrders.rows.length - 4) * 38/*rowHeight*/) {
					this.loadOpenRMWorkOrders(false);
				}
			}
		},
		onNameClick: (row) => {
			if (event.type == 'click') {
				window.open(this.whiteLabelService.getOldDashboardUrl() + '/FM/RequestDetails?requestid=' + row.id, '_blank');
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.tableOpenRMWorkOrders.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		}
	};
	loadOpenRMWorkOrders(cleanup: boolean) {
		if (!cleanup && this.tableDataOpenRMWorkOrders.rows.length >= this.tableDataOpenRMWorkOrders.page.totalElements) {
			return;
		}
		this.loadingOpenRMWorkOrders = true;
		let body = new QueryOptionsPagingModel();
		body.paging = {
			limit: this.tableDataOpenRMWorkOrders.page.size,
			currentPage: cleanup ? 1 : Math.round(this.tableDataOpenRMWorkOrders.rows.length / this.tableDataOpenRMWorkOrders.page.size) + 1
		};
		let sorts = [];
		this.tableOpenRMWorkOrders.sorts.forEach(element => {
			sorts.push({
				field: element.prop,
				order: this.workOrderReportService.capitalizeFirstLetter(element.dir)
			});
		});
		body.sortBy = sorts;
		let body2 = this.fillBody(body, this.stateService.getCurrentDateRange());
		this.subs.add(this.workOrderReportService.getRequestList(body2)
			.subscribe(data => this.subscribeTableDataOpenRMWorkOrders(data, cleanup)));
	}

	subscribeTableDataOpenRMWorkOrders(data: RequestListResponseModel[], cleanup: boolean) {
		if (this.woFiltersService.getCurrentUpdateId() === this.currentUpdateId) {
			let newData = [];
			data.forEach(element => {
				let d = moment(element.requestDate);
				let newRow = {
					id: element.id,
					storeNo: element.storeNo,
					storeName: element.storeName,
					vendorName: element.vendorName,
					requestDate: d.format('DD-MM-YYYY HH:mm'),
					glCode: element.glCode,
					assetName: `${element.assetName} - ${element.assetCategory}`,
					glStatus: element.glStatus,
					statusName: element.statusName,
					requestType: element.requestType,
					cost: element.cost,
					costEst: element.cost > 0 ? 0 : element.costEst,
					glCostType: element.glCostType,
					requestNumber: element.requestNumber
				};
				newData.push(newRow);
			});
			if (data.length > 0 && this.tableDataOpenRMWorkOrders.page.totalElements !== data[0].totalCount) {
				this.tableDataOpenRMWorkOrders.page.totalElements = data[0].totalCount;
			}
			let dataArray;
			if (cleanup) {
				dataArray = [];
				setTimeout(() => {
					this.tableOpenRMWorkOrders.offset = 0;
					this.tableOpenRMWorkOrders.bodyComponent.updateOffsetY(0);
				});
			} else {
				dataArray = this.tableDataOpenRMWorkOrders.rows;
			}
			dataArray = dataArray.concat(newData);
			this.tableDataOpenRMWorkOrders.rows = [...dataArray];
			this.loadingOpenRMWorkOrders = false;
			this.ref.detectChanges();
		}
	}

	//#endregion

	public inProgressExportCSV: boolean = false;
	private totalExport: number = -1;
	private dataExport: RequestListResponseModel[];
	exportCsv() {
		this.inProgressExportCSV = true;
		this.dataExport = [];
		this.totalExport = -1;
		let body = this.fillBody(new QueryOptionsPagingModel(), this.stateService.getCurrentDateRange());
		this.exportCsvRequest(body);
	}

	exportCsvRequest(body: any) {
		const limit = 10000;
		body.paging = {
			limit: limit,
			currentPage: this.totalExport < 0 ? 1 : Math.round(this.dataExport.length / limit) + 1
		};
		this.subs.add(this.workOrderReportService.getRequestList(body)
			.subscribe(data => {
				if (data.length > 0) {
					this.totalExport = data[0].totalCount;
					this.dataExport.push(...data);
				}
				if (this.dataExport.length >= this.totalExport) {
					this.inProgressExportCSV = false;
					if (this.dataExport.length > 0) {
						let headers = Object.getOwnPropertyNames(data[0]);
						if (this.whiteLabelService.getSettings.filterStoreLabel != "store") {
							// replace store with whitelabel setting in all headers
							headers = headers.map((header) =>
								header.replace("store", this.whiteLabelService.getSettings.filterStoreLabel)
							);
							headers = headers.map((header) =>
								header.replace("Store", this.whiteLabelService.capitalizeFirstLetter(this.whiteLabelService.getSettings.filterStoreLabel))
							);
						}
						if (this.whiteLabelService.getSettings.filterVendorLabel != "vendor") {
							// replace vendor with whitelabel setting in all headers
							headers = headers.map((header) =>
								header.replace("vendor", this.whiteLabelService.getSettings.filterVendorLabel)
							);
							headers = headers.map((header) =>
								header.replace("Vendor", this.whiteLabelService.capitalizeFirstLetter(this.whiteLabelService.getSettings.filterVendorLabel))
							);
						}
						let options = {
							fieldSeparator: ',',
							quoteStrings: '"',
							decimalseparator: '.',
							showLabels: true,
							headers: headers,
							showTitle: false,
							title: "export",
							useBom: true,
						};
						new AngularCsv(this.dataExport, 'export', options);
					} else {
						this.messageService.info("CSV Export", "No data");
					}
				} else {
					this.exportCsvRequest(body);
				}
			})
		);
	}

	// Icons
	faTable = faTable;
	faFileExport = faFileExport;

}