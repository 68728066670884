import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";
import { JsonEditorComponent, JsonEditorOptions } from "@maaxgr/ang-jsoneditor";
import { Subscription } from "rxjs";
import { DashboardWidgetModel } from "../models/dashboardWidgetModel";
import { FilterLight } from "../models/filterLight";

@Component({
	selector: "app-widget-config",
	templateUrl: "./widget-config.component.html",
})
export class WidgetConfigComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	@Input() model: DashboardWidgetModel;
	filterEditable: FilterLight;

	public editorOptions: JsonEditorOptions;
	public data: any;
	@ViewChild(JsonEditorComponent) editor: JsonEditorComponent;

	constructor() {
		this.editorOptions = new JsonEditorOptions();
		this.editorOptions.modes = ["code", "text", "tree", "view"];
		this.editorOptions.mode = "code";
	}

	ngOnInit() {
		this.filterEditable = this.model.filter;
	}

	changeFilter(event = null) {
		if (this.editor.isValidJson) {
			let temp: FilterLight = JSON.parse(this.editor.getText());
			this.model.filter = temp;
		}
	}

	// Icons
	faChartColumn = faChartColumn;
}
