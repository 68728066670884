import { DecimalPipe } from '@angular/common';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'decimalPipeTwoDigits'
})
export class DecimalPipeTwoDigits extends DecimalPipe {
    public transform(value): any {
      return super.transform(value, '1.2-2');
    }
  }