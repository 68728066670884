<main>
	<div *ngIf="!whiteLabelService.getSettings.enableWeeklyStatusTab" class="container">
		<div class="alert alert-warning font-weight-bold mt-3" role="alert">
			Not available.
		</div>
	</div>
	<div *ngIf="whiteLabelService.getSettings.enableWeeklyStatusTab" class="container">
		<div class="row mb-3">
			<nav aria-label="breadcrumb" class="w-100 half-strong">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">Home</li>
					<li class="breadcrumb-item"><span (click)="woFiltersService.resetFilters()">Weekly Status</span></li>
				</ol>
			</nav>
		</div>

		<div class="row">
			<div class="col-12 mb-4">
				<app-work-orders-nav></app-work-orders-nav>
			</div>
		</div>

		<div class="row justify-content-lg-start align-items-lg-top">
			<div class="col mb-3 mb-lg-0 filters-col">
				<div class="row">
					<div class="col-12 col-md-3 col-lg-4">
						<label class="half-strong">{{whiteLabelService.isStarbucks() ? 'Financial Year' : 'Year'}}</label>
						<ng-select [dropdownPosition]="'bottom'" [items]="woFiltersService.years" [(ngModel)]="woFiltersService.selectedYear" (change)="woFiltersService.onChangeSelectedYear($event)" placeholder="Select year" class="dropdown-select">
						</ng-select>
					</div>
					<div class="col-12 col-md-3 col-lg-4 mt-3 mt-md-0">
						<label class="half-strong">Week</label>
						<ng-select [dropdownPosition]="'bottom'" [items]="woFiltersService.weeks" [(ngModel)]="woFiltersService.selectedWeek" placeholder="Select week" class="dropdown-select">
						</ng-select>
					</div>
					<div class="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
						<label class="half-strong">State</label>
						<ng-select [multiple]="true" [dropdownPosition]="'bottom'" [items]="woFiltersService.states" [(ngModel)]="woFiltersService.selectedState" placeholder="Select state" class="dropdown-select">
						</ng-select>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-auto mt-lg-5 d-flex justify-content-between align-items-start print-col print-weekly-status">
				<button type="button" class="btn btn-primary btn-filter" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseFilters">
					<fa-icon [icon]="faFilePdf"></fa-icon><span class="ms-2">PDF</span>
					<fa-icon [icon]="faCaretDown" class="ms-2"></fa-icon>
				</button>


				<div *ngIf="(roles$ | async)?.indexOf('admin') > -1 && isDashboardReady" class="edit-mode-btns">
					<button *ngIf="!isEdit" type="button" class="btn btn-primary btn-filter" (click)="editDashboard()">
						<fa-icon [icon]="faEdit"></fa-icon><span class="ms-2">Edit</span>
					</button>
					<button *ngIf="isEdit" [disabled]="inProgressSaveDashboard" type="button" class="btn btn-secondary btn-filter" (click)="cancelEditDashboard()">
						<fa-icon [icon]="faUndo"></fa-icon><span class="ms-2">Cancel</span>
					</button>
					<button *ngIf="isEdit" [disabled]="inProgressSaveDashboard" type="button" class="btn btn-primary btn-filter" (click)="saveDashboard()">
						<fa-icon [icon]="faSave"></fa-icon><span class="ms-2">Save</span>
					</button>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3 print-weekly-status">
				<div id="collapseFilters" [ngbCollapse]="isCollapsed">
					<div class="dropdown-filters d-flex justify-content-end">
						<div class="dropdown-filters-inner">
							<div class="filters-wrapper">
								<div class="form-group">
									<label for="pdf-comment">PDF comment</label>
									<textarea class="form-control" rows="5" [(ngModel)]="comment"></textarea>
								</div>
								<div class="form-group text-end mb-0">
									<button type="button" class="btn btn-primary" (click)="generatePdf()" [disabled]="inProgressPdf">
										<fa-icon [icon]="faFileDownload"></fa-icon><span class="ms-2">Download</span><span class="loader loader-small loader-reverse"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-xl-7 mb-3">
				<app-weekly-status-volume-priority #WeeklyStatusVolumePriority></app-weekly-status-volume-priority>
			</div>

			<div class="col-12 col-xl-5 mb-3">
				<app-weekly-status-asset #WeeklyStatusAsset></app-weekly-status-asset>
			</div>
		</div>

		<div *ngIf="!isEdit">
			<div *ngFor="let kpiRow of kpiRows" class="row">
				<div *ngFor="let kpiWidget of kpiRow.widgets; let i = index" class="col-12 mb-3" [ngClass]="{'col-xl-6': kpiRow.widgets.length > 1 && (kpiRow.widgets.length % 2 === 0 || i < kpiRow.widgets.length - 1)}">
					<app-weekly-status-kpi-performance #WeeklyStatusKPIPerformance [model]="kpiWidget"></app-weekly-status-kpi-performance>
				</div>
			</div>
		</div>

		<div *ngIf="isEdit && isDashboardReady" class="mt-3" cdkDropListGroup>
			<div *ngFor="let kpiRow of kpiRows; let i = index" class="row-edit mb-3">
				<div class="row-edit--head">
					<span class="row-edit--title">Row</span>
					<button *ngIf="i > 0" type="button" class="btn btn-move" (click)="moveRowUp(kpiRow)">
						<fa-icon [icon]="faArrowUp"></fa-icon>
					</button>
					<button *ngIf="i < kpiRows.length - 1" type="button" class="btn btn-move" (click)="moveRowDown(kpiRow)">
						<fa-icon [icon]="faArrowDown"></fa-icon>
					</button>
					<button type="button" class="btn btn-danger" (click)="deleteRow(kpiRow)">
						<fa-icon [icon]="faTrashAlt"></fa-icon>
					</button>
					<button type="button" class="btn btn-primary" (click)="addWidget(kpiRow)">
						<fa-icon [icon]="faPlusSquare"></fa-icon><span class="ms-2">Widget</span>
					</button>
				</div>
				<div cdkDropList [cdkDropListData]="kpiRow.widgets" (cdkDropListDropped)="moveElement($event)" cdkDropListOrientation="horizontal" class="row-edit--body">
					<div cdkDrag *ngFor="let kpiWidget of kpiRow.widgets" class="widget-edit--wrapper">
						<div class="widget-edit">
							<div class="cdk-custom-placeholder widget-edit--placeholder" *cdkDragPlaceholder>
								<div class="widget-edit--placeholder-text">Drop here</div>
							</div>
							<div class="widget-edit--head" cdkDragHandle>
								<fa-icon class="widget-edit--move" [icon]="faArrowsAlt"></fa-icon> <span class="widget-edit--title">Widget</span>
								<button type="button" class="btn btn-danger" (click)="deleteWidget(kpiRow, kpiWidget)">
									<fa-icon [icon]="faTrashAlt"></fa-icon>
								</button>
							</div>
							<app-widget-config [model]="kpiWidget"></app-widget-config>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col mb-5 d-flex justify-content-center">
					<button type="button" class="btn btn-primary btn-lg" (click)="addRow()">
						<fa-icon [icon]="faPlusSquare"></fa-icon><span class="ms-2">Row</span>
					</button>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-xl-7 mb-3 mb-xl-0">
				<app-weekly-status-daily-volume #WeeklyStatusDailyVolume></app-weekly-status-daily-volume>
			</div>

			<div class="col-12 col-xl-5">
				<app-weekly-status-top #WeeklyStatusTop></app-weekly-status-top>
			</div>
		</div>
	</div>
</main>
