import {
	Component,
	OnInit,
	OnDestroy
} from '@angular/core';
import {
	StateService
} from '../../services/state.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import {
	MessageService
} from '../../services/message.service';

import {
	faFilter,
	faSave,
	faTrashAlt,
	faPlusSquare,
	faUndo
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { Guid } from "guid-typescript";
import { WhiteLabelService } from '../../services/white-label.service';
import { DashboardAssetPerformanceModel } from '../models/dashboardAssetPerformanceModel';
import * as moment from 'moment';
import { DashboardService } from '../services/dashboard.service';
import { DashboardAssetPerformanceDataSetModel } from '../models/dashboardAssetPerformanceDataSetModel';

@Component({
	selector: 'app-asset-performance',
	templateUrl: './asset-performance.component.html'
})
export class AssetPerformanceComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();

	resetFilters() {
		this.woFiltersService.resetFilters();
		this.stateService.updateDateRange("null", null, null);
		this.stateService.setDateRange(null); // call update automatically
	}

	constructor(private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		public whiteLabelService: WhiteLabelService,
		private messageService: MessageService,
		private dashboardService: DashboardService) {
	}

	roles$: Observable<string[]>;
	config: DashboardAssetPerformanceModel | null = null;
	isDashboardReady: boolean = false;
	ngOnInit() {
		this.stateService.setHeaderTitle('Asset Performance Benchmark');

		this.roles$ = this.authService.getCurrentUserRoles;

		this.woFiltersService.loadData();
		this.woFiltersService.assetNamesEnable(false);

		this.dashboardService.getDashboardAssetPerformance().subscribe(data => {
			if (data) {
				this.config = data;
			} else {
				this.config = { dataSets: [] };
			}
			this.dashboardBackup = JSON.stringify(this.config);
			this.isDashboardReady = true;
		});

		this.subs.add(this.stateService.getDateRange.subscribe((value: IMyDateRangeModel) => {
			this.update();
		}));
	}

	ngDoCheck() {
		if (this.woFiltersService.isChanged()) {
			this.update();
		}
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
		this.woFiltersService.assetNamesEnable(true);
	}

	update() {
		let currentUpdateId = Guid.create().toString();
		this.woFiltersService.setUpdateId(currentUpdateId);
	}


	selectedTab = "1";
	selectTab(value: string) {
		this.selectedTab = value;
	}

	dashboardBackup: string = "";

	cancelEditDashboard() {
		this.config = JSON.parse(this.dashboardBackup);
	}

	inProgressSaveDashboard: boolean = false;

	saveDashboard() {
		const today = moment();
		const maxDate = today.endOf('month').format('YYYY-MM-DD');
		let shortDateRange = false;
		let invalidDateRange = false;
		let bigDate = false;
		for (let i = 0; i < this.config.dataSets.length; i++) {
			const ds = this.config.dataSets[i];
			if (ds.series.length) {
				const s1 = ds.series[0];
				if (s1.fromDt && s1.toDt) {
					if (s1.toDt > maxDate) {
						bigDate = true;
						break;
					}
					const m1 = moment(s1.fromDt);
					const m2 = moment(s1.toDt);
					const days = m2.diff(m1, 'days') + 1;
					if (days < 365) {
						shortDateRange = true;
						break;
					}
				} else {
					invalidDateRange = true;
					break;
				}
			}
		}

		if (bigDate) {
			this.messageService.error("Error", `Please select Date Range below ${today.add(1, 'months').format('MMM YYYY')}`);
		} else if (invalidDateRange) {
			this.messageService.error("Error", "Please select Date Range");
		} else if (shortDateRange) {
			this.messageService.error("Error", "Please select Date Range over 12 months");
		} else {
			const dashboardBackup = JSON.stringify(this.config);
			this.inProgressSaveDashboard = true;
			this.dashboardService.saveDashboardAssetPerformance(this.config).subscribe(value => {
				this.messageService.success("Dashboard", "Changes saved.");
				this.inProgressSaveDashboard = false;
				this.dashboardBackup = dashboardBackup;
				this.update();
			});
		}
	}

	addDataSet() {
		this.config.dataSets.push(new DashboardAssetPerformanceDataSetModel());
	}

	deleteDataSet(index: number) {
		this.config.dataSets.splice(index, 1);
	}

	addSeries(index: number) {
		const series = this.config.dataSets[index].series;
		if (series.length) {
			series.push({
				color: this.getRandomColor(),
				showInGraph: true,
				fromDt: null,
				toDt: null,
				filterType: 
					series.length === 1 ?
						(this.whiteLabelService.getSettings.assetPerformanceBenchmarkHideRetailBusinessManager ?
							"storeProfiles" : 
							"retailBusinessManagers") :
						"stores",
				filterValues: []
			});
		} else {
			// last 12 full months
			let m = moment();
			m = m.subtract(1, 'months').endOf('month');
			const toDt = m.format('YYYY-MM-DD');
			m = m.subtract(1, 'years').startOf('month');
			const fromDt = m.format('YYYY-MM-DD');
			series.push({
				color: this.getRandomColor(),
				showInGraph: true,
				fromDt: fromDt,
				toDt: toDt,
				filterType: "assetNames",
				filterValues: []
			});
		}
	}

	deleteSeries(index: number, series) {
		let fromIndex = this.config.dataSets[index].series.indexOf(series);
		if (fromIndex >= 0) {
			this.config.dataSets[index].series.splice(fromIndex, 1);
		}
	}

	getRandomColor() {
		return this.whiteLabelService.getRandomColor();
	}

	// Icons
	faFilter = faFilter;
	faSave = faSave;
	faTrashAlt = faTrashAlt;
	faPlusSquare = faPlusSquare;
	faUndo = faUndo;
}