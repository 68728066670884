import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faChartColumn,
} from "@fortawesome/free-solid-svg-icons";
import { ChartComponent } from "angular2-chartjs";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { AssetRequestListResponseModel } from "../models/assetRequestListResponseModel";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { AssetService } from "../services/asset.service";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-asset-availability-chart",
	templateUrl: "./asset-availability-chart.component.html",
})
export class AssetAvailabilityChartComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	loaded: boolean;
	total: number;
	chartConfig = {
		type: "horizontalBar",
		data: {
			labels: [],
			datasets: [],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				xAxes: [
					{
						stacked: true,
						ticks: {
							callback: function (label, index, labels) {
								return `${label.toLocaleString()}`;
							},
							beginAtZero: true,
						},
					},
				],
				yAxes: [
					{
						stacked: true,
						ticks: {
							autoSkip: false,
						},
					},
				],
			},
		},
		additional: {
			lastClickTime: null,
			lastClickElement: null,
		},
	};
	@ViewChild("keyAssetsChart") chartByPriority: ChartComponent;

	backgroundColor = [];
	hoverBackgroundColor = [];
	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		public whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		private assetService: AssetService
	) {
		let colors = whiteLabelService.getDefaultChartColors();
		this.backgroundColor = colors.backgroundColors;
		this.hoverBackgroundColor = colors.hoverBackgroundColors;
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loaded = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	selectedDisplayType = "1";
	onChangeDisplayType() {
		if (this.loaded) {
			this.loadPagedDataDone();
		}
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Open"];
		body.filter.assetTypeTags = ["Offline", "Key"];
		return body;
	}

	private totalExport: number = -1;
	private dataExport: AssetRequestListResponseModel[];

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;
		this.dataExport = [];
		this.totalExport = -1;
		let body = this.fillBody(new QueryOptionsPagingModel(), dateRange);
		this.loadPagedDataRequest(body, updateId);
	}

	loadPagedDataRequest(body: any, updateId) {
		const limit = 10000;
		body.paging = {
			limit: limit,
			currentPage:
				this.totalExport < 0 ? 1 : Math.round(this.dataExport.length / limit) + 1,
		};
		this.subs.add(
			this.assetService.getAssetRequestList(body).subscribe((data) => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					if (data.length > 0) {
						this.totalExport = data[0].totalCount;
						this.dataExport.push(...data);
					}
					if (this.dataExport.length >= this.totalExport) {
						this.loadPagedDataDone();
					} else {
						this.loadPagedDataRequest(body, updateId);
					}
				}
			})
		);
	}

	loadPagedDataDone() {
		this.total = this.dataExport.length;
		this.loaded = true;
		let newLabels = [];
		let newData = {};
		let priorities = [];
		this.dataExport.forEach((element) => {
			const label =
				this.selectedDisplayType === "1"
					? (this.whiteLabelService.getSettings.assetAvailabilityKeyAssetsOffline ===
					  "Squad" || this.whiteLabelService.getSettings.assetAvailabilityKeyAssetsOffline === "Territory")
						? element.retailBusinessManager
						: this.whiteLabelService.getSettings.assetAvailabilityKeyAssetsOffline ===
						  "Category"
						? element.category
						: element.storeType
					: element.storeState;
			if (newLabels.indexOf(label) < 0) {
				newLabels.push(label);
				newData[label] = {};
			}
			const priority = element.priority;
			if (!newData[label][priority]) {
				newData[label][priority] = 0;
			}
			newData[label][priority]++;
			if (priorities.indexOf(priority) < 0) {
				priorities.push(priority);
			}
		});
		newLabels.sort();
		priorities.sort();
		const newDatasets = [];
		for (let i = 0; i < priorities.length; i++) {
			let priority = priorities[i];
			let dataForDataset = [];
			newLabels.forEach((label) => {
				let current = newData[label][priority];
				if (current) {
					dataForDataset.push(current);
				} else {
					dataForDataset.push(0);
				}
			});
			newDatasets.push({
				label: priority,
				backgroundColor: this.backgroundColor[i],
				hoverBackgroundColor: this.hoverBackgroundColor[i],
				data: dataForDataset,
				type: "horizontalBar",
			});
		}
		this.chartConfig.data.labels = newLabels;
		this.chartConfig.data.datasets = newDatasets;

		this.loaded = true;
		if (this.chartByPriority) {
			this.chartByPriority.chart.update();
		}
		this.ref.detectChanges();
	}

	// Icons
	faChartColumn = faChartColumn;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
