<div class="card full-height-lg has-invisible-content" [ngClass]="{'has-back': woFiltersService.selectedPriority && woFiltersService.selectedPriority.length > 0}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> Open Work Orders by Priority
		</h2>
		<div class="back-btn" *ngIf="woFiltersService.selectedPriority && woFiltersService.selectedPriority.length > 0">
			<button type="button" class="btn btn-secondary" (click)="woFiltersService.drilldownBackByPriority()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loadedByPriority}" class="chart-container">
			<chart class="chart-container" #priorityChart [type]="chartConfigByPriority.type" [data]="chartConfigByPriority.data" [options]="chartConfigByPriority.options"></chart>
		</div>
		<div [ngClass]="{'invisible': !loadedByPriority}" class="total-data with-margin half-strong text-center text-sm-end text-md-end text-lg-center text-xl-end mt-3 mt-sm-0"><span class="visually-hide-selection">Total: </span><span>{{ totalByPriority | number }}</span></div>

		<div *ngIf="!loadedByPriority"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span
						class="right"></span></span></span></div>
	</div>
</div>
