import { Injectable } from "@angular/core";
import { Settings } from "../models/settings";
import { SettingsService } from "./settings.service";

@Injectable({
	providedIn: "root",
})
export class WhiteLabelService {
	get getWhiteLabel(): string {
		return this.settingsService.settings.whiteLabel;
	}

	get getSettings(): Settings {
		return this.settingsService.settings;
	}

	get getFacilityTypeMode(): boolean {
		return (
			!this.getSettings.enableFilterState &&
			this.getSettings.filterStoreTypeLabel === "facility type"
		);
	}

	constructor(private settingsService: SettingsService) {}

	capitalizeFirstLetter(value: string): string {
		return value.charAt(0).toUpperCase() + value.slice(1);
	}

	is7Eleven(): boolean {
		return this.settingsService.settings.whiteLabel === "7eleven";
	}

	isStarbucks(): boolean {
		return this.settingsService.settings.whiteLabel === "starbucks";
	}

	isWhittlesea(): boolean {
		return this.settingsService.settings.whiteLabel === "cityofwhittlesea";
	}

	isVisionAustralia(): boolean {
		return this.settingsService.settings.whiteLabel === "visionaustralia";
	}

	isRacv(): boolean {
		return this.settingsService.settings.whiteLabel === "racv";
	}

	isSgh(): boolean {
		return this.settingsService.settings.whiteLabel === "sgh";
	}

	isAzureADEnabled(): boolean {
		return this.is7Eleven() || this.isRacv();
	}

	isSamlEnabled(): boolean {
		return this.isVisionAustralia();
	}

	getEnableLogo(): boolean {
		return this.settingsService.settings.enableLogo;
	}

	getCompanyLogo(): string {
		//return '../../assets/img/company-logo/' + this.settingsService.settings.whiteLabel + '.png';
		return "api/Settings/GetLogo";
	}

	getOldDashboardUrl(): string {
		return this.settingsService.settings.informApplicationUrl;
	}

	getWeekFilterOffsetMonthes(): number {
		if (this.isStarbucks()) {
			return 6;
		} else {
			return 0;
		}
	}

	getRandomColor() {
		var letters = "0123456789ABCDEF";
		var color = "#";
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	getRatingChartColors(): any {
		return {
			backgroundColor: "#fe9666",
			hoverBackgroundColor: "#ed814f",
		};
	}

	getLifeChartColors(): any {
		return {
			backgroundColor: "#f2c80f",
			hoverBackgroundColor: "#ddb503",
		};
	}

	getDefaultChartColors(): any {
		return {
			backgroundColors: [
				"#06D6A0",
				"#CB48B7",
				"#0C7C59",
				"#54428E",
				"#84C318",
				"#5E7CE2",
				"#E83151",
				"#FFBA49",
				"#4EA699",
				"#FF773D",
				"#79B473",
				"#690500",
				"#58355E",
				"#065143",
				"#414073",
				"#EF5B5B",
			],
			hoverBackgroundColors: [
				"#05B083",
				"#A73B96",
				"#0A6649",
				"#453775",
				"#6DA014",
				"#4D66B9",
				"#BE2943",
				"#D1993C",
				"#40887E",
				"#D16232",
				"#64945F",
				"#430400",
				"#492C4D ",
				"#054337",
				"#36355F",
				"#C44B4B",
			],
		};
	}

	getIncidentPlannedReactiveColors(): any {
		return {
			Incident: {
				backgroundColor: "#f44336",
				hoverBackgroundColor: "#C8372D",
				backgroundColorCost: "#A01A7D",
				hoverBackgroundColorCost: "#831667",
			},
			Planned: {
				backgroundColor: "#6BCABA",
				hoverBackgroundColor: "#58A699",
				backgroundColorCost: "#056e5c",
				hoverBackgroundColorCost: "#055B4C",
			},
			Reactive: {
				backgroundColor: "#FFBA49",
				hoverBackgroundColor: "#D1993C",
				backgroundColorCost: "#ffdb5b",
				hoverBackgroundColorCost: "#D1B44B",
			},
		};
	}

	getKPIFailSuccessColors(): any {
		return {
			"Respond KPI Fail": {
				backgroundColor: "#E83151",
				hoverBackgroundColor: "#BE2943",
			},
			"Respond KPI Success": {
				backgroundColor: "#6BCABA",
				hoverBackgroundColor: "#58A699",
			},
			"Resolve KPI Fail": {
				backgroundColor: "#fab3ae",
				hoverBackgroundColor: "#CD938F",
			},
			"Resolve KPI Success": {
				backgroundColor: "#c3e9e3",
				hoverBackgroundColor: "#A0BFBA",
			},
		};
	}

	getKPIFailPassColors(): any {
		return {
			colorsResolve: ["#fab3ae", "#c3e9e3"],
			colorsHoverResolve: ["#CD938F", "#A0BFBA"],
			colorsRespond: ["#E83151", "#6BCABA"],
			colorsHoverRespond: ["#BE2943", "#58A699"],
		};
	}

	getLineChartColors(): any {
		return {
			backgroundColor: "#1B7EAC",
			hoverBackgroundColor: "#1B7EAC",
		};
	}

	getResponseRectificationColors(): any {
		return {
			colorsResponse: ["#6BCABA", "#58A699"],
			colorsRectification: ["#c3e9e3", "#A0BFBA"],
			colorsCount: ["#f2c80f", "#ddb503"],
		};
	}

	getOverdueChartColors(): any {
		return {
			colorsPast: ["#f44336", "#C8372D"],
			colorsToday: ["#ffc000", "#ffc000"],
			colorsFuture: ["#4ea699", "#4ea699"],
		};
	}
}
