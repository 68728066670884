<div class="card has-export has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon> Summary by Asset (Maintenance Cost, Count and MTBF)
		</h2>
		<div class="export-btns">
			<button *ngIf="loaded" type="button" class="btn btn-secondary" (click)="exportCsv()">
				<fa-icon [icon]="faFileExport"></fa-icon><span class="d-none d-md-inline ms-1"> CSV</span>
			</button>
		</div>
	</div>
	<div class="card-body p-0" style="min-height: 200px;">
		<div class="table-responsive set-max-height">
			<table class="table table-striped table-bordered table-head-bordered min-width-600 td-vertical-align-middle mb-0" [ngClass]="{'invisible': !loaded, 'table-empty': !tableData.rows.length}">
				<thead>
					<tr>
						<th *ngFor="let cell of tableData.headersGroup" [attr.colspan]="cell[1]" class="text-center">{{cell[0]}}</th>
					</tr>
					<tr>
						<th class="text-end" *ngFor="let column of tableData.columns; let i = index" [style.min-width]="i === 0 ? '120px' : ''">{{column}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let row of tableData.rows">
						<td *ngFor="let cell of row; let i = index" [class]="cell[1]" [attr.rowspan]="cell[2]">
							<button *ngIf="cell[3]" class="btn btn-secondary btn-sm" (click)="open(benchmarkListContent, cell[0])">View</button>
							<a *ngIf="!cell[3] && cell[4]" [href]="cell[4]" target="_blank" [innerHTML]="cell[0]" class="link-style link-style-underline"></a>
							<span *ngIf="!cell[3] && !cell[4]" [innerHTML]="cell[0]"></span>
						</td>
					</tr>
					<tr *ngIf="!tableData.rows.length">
						<td colspan="15">No data to display</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div *ngIf="!loaded"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>

<ng-template #benchmarkListContent let-modal>
	<div class="modal-header">
	  <h4 class="modal-title" id="modal-basic-title">Asset List</h4>
	  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	  </button>
	</div>
	<div class="modal-body">
		<div class="datatable-responsive data-responsive-sm">
			<ngx-datatable #tableBenchmarkList class="bootstrap wrap-header-name" [rows]="tableDataBenchmarkList.rows" [columns]="tableDataBenchmarkList.columns" [headerHeight]="76" [scrollbarV]="true" [rowHeight]="38" [footerHeight]="50" [columnMode]="'flex'" [loadingIndicator]="!loaded">
				<!-- Column Templates -->
				<ngx-datatable-column prop="description" name="Asset Description" headerClass="col-3" cellClass="col-3" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						<span title="{{value}}">{{value}}</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="assetId" name="Asset Number" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="barcode" name="Barcode" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="installationDate" name="Installation Date" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{formatDateForTable(value)}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="decommissionDate" name="Decommission Date" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{formatDateForTable(value)}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="age" name="Age" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value}}
					</ng-template>
				</ngx-datatable-column>
			</ngx-datatable>
		</div>
	</div>
  </ng-template>
