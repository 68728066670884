<main>
	<div *ngIf="(roles$ | async)?.length > 0 && (roles$ | async)?.indexOf('admin') < 0 && (roles$ | async)?.indexOf('fm')  < 0" class="container">
		<div class="alert alert-warning font-weight-bold mt-3" role="alert">
			Not available for current user role.
		</div>
	</div>
	<div *ngIf="(roles$ | async)?.indexOf('admin') > -1 || (roles$ | async)?.indexOf('fm') > -1" class="container">
		<div class="row mb-3">
			<nav aria-label="breadcrumb" class="w-100 half-strong">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">Home</li>
					<li class="breadcrumb-item"><span (click)="resetFilters()">Financial</span></li>
				</ol>
			</nav>
		</div>

		<div class="row">
			<div class="col-12 mb-4">
				<app-work-orders-nav></app-work-orders-nav>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-filters></app-work-orders-filters>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-financial-summary></app-financial-summary>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-lg-6 mb-3">
				<app-financial-budget-actual-forecast-month></app-financial-budget-actual-forecast-month>
			</div>
			<div class="col-12 col-lg-6 mb-3">
				<app-financial-budget-actual-forecast-state></app-financial-budget-actual-forecast-state>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-financial-asset-count></app-financial-asset-count>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-financial-asset-cost></app-financial-asset-cost>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-financial-actual-budget-category></app-financial-actual-budget-category>
			</div>
		</div>

		<div class="row" *ngIf="!whiteLabelService.getSettings.hideWidgetFinancialBudgetTotalSpendStoreTypeByState || !whiteLabelService.getSettings.hideWidgetFinancialBudgetTotalSpendFuelvsNonFuelByState">
			<div class="col-12 mb-3" *ngIf="!whiteLabelService.getSettings.hideWidgetFinancialBudgetTotalSpendStoreTypeByState" [ngClass]="{'col-lg-6': !whiteLabelService.getSettings.hideWidgetFinancialBudgetTotalSpendFuelvsNonFuelByState}">
				<app-financial-actual-budget-store-type-state></app-financial-actual-budget-store-type-state>
			</div>
			<div class="col-12 col-lg-6 mb-3" *ngIf="!whiteLabelService.getSettings.hideWidgetFinancialBudgetTotalSpendFuelvsNonFuelByState" [ngClass]="{'col-lg-6': !whiteLabelService.getSettings.hideWidgetFinancialBudgetTotalSpendStoreTypeByState}">
				<app-financial-actual-budget-fuel-non-fuel-state></app-financial-actual-budget-fuel-non-fuel-state>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-financial-forecast-budget-store></app-financial-forecast-budget-store>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-financial-forecast-budget-store-gl></app-financial-forecast-budget-store-gl>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-financial-wo></app-financial-wo>
			</div>
		</div>
	</div>
</main>
