import { Component, OnInit } from '@angular/core';
import { StateService } from '../../services/state.service';
import { AssetConditionsStats } from '../models/assetConditionsStats';
import { AssetConditionsStatsService } from '../services/ac-sats.service';
import { Store } from '../models/store';
import { ApplyFilters } from '../models/applyFilters';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html'
})
export class AssetsComponent implements OnInit {
  assetConditionsStats: AssetConditionsStats;
  assetConditionsStatsLoaded: boolean;

  constructor(private stateService: StateService,
    private acStatsService: AssetConditionsStatsService) { }

  ngOnInit() {
    this.assetConditionsStatsLoaded = false;
    this.stateService.getStoreAndFilter.subscribe((value: [Store, ApplyFilters]) => {
      this.updateAsset(value[0] ? value[0].storeNo : null, value[1]);
    });    
  }
  
  updateAsset(storeNo: string, filters: ApplyFilters) {
    this.assetConditionsStatsLoaded = false;
    this.acStatsService.getAssetConditionsStats(storeNo, filters)
      .subscribe(assetConditionsStats => {
        this.assetConditionsStats = assetConditionsStats;
        this.assetConditionsStatsLoaded = true;
      });
  }
}
