<main>
	<div *ngIf="!whiteLabelService.getSettings.enableAssetAvailabilityTab" class="container">
		<div class="alert alert-warning font-weight-bold mt-3" role="alert">
			Not available.
		</div>
	</div>
	<div *ngIf="whiteLabelService.getSettings.enableAssetAvailabilityTab" class="container">
		<div class="row mb-3">
			<nav aria-label="breadcrumb" class="w-100 half-strong">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">Home</li>
					<li class="breadcrumb-item"><span (click)="resetFilters()">Asset Availability</span></li>
				</ol>
			</nav>
		</div>

		<div class="row">
			<div class="col-12 mb-4">
				<app-work-orders-nav></app-work-orders-nav>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-filters></app-work-orders-filters>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-asset-availability-summary></app-asset-availability-summary>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-asset-availability-critical-offline></app-asset-availability-critical-offline>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-xl-5 mb-3">
				<app-asset-availability-chart></app-asset-availability-chart>
			</div>
			<div class="col-12 col-xl-7 mb-3">
				<app-asset-availability-table></app-asset-availability-table>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<app-asset-affecting-st-table></app-asset-affecting-st-table>
			</div>
		</div>
	</div>
</main>
