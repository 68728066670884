<div class="card info-card has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faInfo"></fa-icon> Summary
		</h2>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !(loadedSummary && loadedSummaryOpenRMQuotes && loadedSummaryRejectedRMQuotes && loadedSummaryFMUnderReview && loadedSummaryFMRejected && loadedSummaryMissedKPI && loadedSummaryCompletedNotClaimed && loadedSummaryInformUsaged)}">
			<div class="info-wrapper place-row-md multiple-data increase-fourth">
				<div class="display-1 long-data">{{openReactive | number}}<small>Open Reactive</small></div>
				<div class="display-1 long-data">{{openIncident | number}}<small>Open Incident</small></div>
				<div class="display-1 long-data">{{openPlanned | number}}<small>Open Planned</small></div>
				<div class="display-1 long-data">${{openTotalCost | number : '1.0-0'}}<small>Open Cost (Est.)</small></div>
				<div class="display-1 long-data">{{completedReactive | number}}<small>Completed Reactive</small></div>
				<div class="display-1 long-data">{{completedIncident | number}}<small>Completed Incident</small></div>
				<div class="display-1 long-data">{{completedPlanned | number}}<small>Completed Planned</small></div>
				<div class="display-1 long-data">${{completedTotalCost | number : '1.0-0'}}<small>Completed Cost</small></div>
				<div class="display-1 long-data">{{openRMQuotes | number}}<small>Open RM Quotes</small></div>
				<div class="display-1 long-data text-danger">{{rejectedRMQuotes | number}}<small class="text-body">Rejected RM Quotes</small></div>
				<div class="display-1 long-data text-danger">{{fmUnderReview | number}}<small class="text-body">FM Under Review</small></div>
				<div class="display-1 long-data text-danger">{{fmRejected | number}}<small class="text-body">FM Rejected</small></div>
				<div class="display-1 long-data text-danger">{{missedP1KPI | number}}<small class="text-body">Missed P1 Respond KPI</small></div>
				<div class="display-1 long-data text-danger">{{missedP2KPI | number}}<small class="text-body">Missed P2 Resolve KPI</small></div>
				<div class="display-1 long-data text-danger pb-sm-1 pb-md-0">{{completedNotClaimed | number}}<small class="text-body">Completed Not Claimed</small></div>
				<div class="display-1 long-data pb-sm-1 pb-md-0" [ngClass]="{'text-danger': informUsage < 70}">{{informUsage | number : '1.0-0'}}%<small class="text-body">Inform Usage</small></div>
			</div>
		</div>

		<div *ngIf="!(loadedSummary && loadedSummaryOpenRMQuotes && loadedSummaryRejectedRMQuotes && loadedSummaryFMUnderReview && loadedSummaryFMRejected && loadedSummaryMissedKPI && loadedSummaryCompletedNotClaimed && loadedSummaryInformUsaged)"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
