import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { QueryOptionsModel } from "../models/queryOptionsModel";
import { QueryOptionsPagingModel } from "../models/queryOptionsPagingModel";
import { WOFiltersService } from "../services/wo-filters.service";
import { WorkOrderReportService } from "../services/work-order-report.service";

@Component({
	selector: "app-work-orders-completed-vendor",
	templateUrl: "./work-orders-completed-vendor.component.html",
})
export class WorkOrdersCompletedVendorComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByVendor: boolean;
	tableDataByVendor = {
		rows: [],
		columns: [
			{
				prop: "name",
				name: "Vendor Name",
			},
			{
				prop: "Work Orders",
				name: "Work Orders",
			},
			{
				prop: "Cost of RM",
				name: "Cost of RM",
			},
			{
				prop: "Cost of PM",
				name: "Cost of PM",
			},
			{
				prop: "Total",
				name: "Total",
			},
		],
		total: [],
		onNameClick: (row) => {
			for (var i = 0; i < this.woFiltersService.vendors.length; i++) {
				if (this.whiteLabelService.getSettings.enableNewVendorFilter) {
					if (this.woFiltersService.vendorNames[i].name === row.name) {
						this.woFiltersService.isCollapsed = false;
						this.woFiltersService.selectedVendorNames = [
							this.woFiltersService.vendorNames[i],
						];
						break;
					}
				} else {
					if (this.woFiltersService.vendors[i].id === row.id) {
						this.woFiltersService.isCollapsed = false;
						this.woFiltersService.selectedVendor = [this.woFiltersService.vendors[i]];
						break;
					}
				}
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.tableByVendor.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		},
	};
	@ViewChild("woVendorTable") tableByVendor: DatatableComponent;

	constructor(
		private ref: ChangeDetectorRef,
		private authService: AuthService,
		public whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService
	) {}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByVendor = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(
			this.woFiltersService.getUpdateId.subscribe((value) => {
				this.update(this.stateService.getCurrentDateRange(), value);
			})
		);
	}

	fillBody(
		body: QueryOptionsModel | QueryOptionsPagingModel,
		dateRange: IMyDateRangeModel
	): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ["Completed"];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByVendor = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		//#region Table 1
		this.subs.add(
			this.workOrderReportService
				.getRequestSummaryVendor(body)
				.subscribe((data) => {
					if (this.woFiltersService.getCurrentUpdateId() === updateId) {
						let newData: {
							[id: string]: any;
						} = {};
						let newTotal = [
							{
								class: "col col-xl-2 text-right",
								value: 0,
							},
							{
								class: "col text-right",
								value: 0,
							},
							{
								class: "col text-right",
								value: 0,
							},
							{
								class: "col text-right",
								value: 0,
							},
						];
						data.forEach((element) => {
							let rowId = `${element.name}--${element.vendorNo}`;
							if (this.whiteLabelService.getSettings.ignoreVendorNoGrouping) {
								rowId = element.name;
							}
							if (typeof newData[rowId] === "undefined") {
								let newRow = {
									id: element.id,
									name: element.name,
									count: 0,
								};
								newRow["Work Orders"] = 0;
								newRow["Cost of RM"] = 0;
								newRow["Cost of PM"] = 0;
								newRow["Total"] = 0;
								newData[rowId] = newRow;
							}
							newData[rowId]["Work Orders"] += element.count;
							newTotal[0].value += element.count;
							if (
								element.requestType == "Reactive" ||
								element.requestType == "Incident"
							) {
								newData[rowId]["Cost of RM"] += element.cost;
								newTotal[1].value += element.cost;
								newData[rowId]["Total"] += element.cost;
								newTotal[3].value += element.cost;
							}
							if (element.requestType == "Planned") {
								newData[rowId]["Cost of PM"] += element.cost;
								newTotal[2].value += element.cost;
								newData[rowId]["Total"] += element.cost;
								newTotal[3].value += element.cost;
							}
						});

						let newDataArray = [];
						for (let key in newData) {
							newDataArray.push(newData[key]);
						}
						this.tableDataByVendor.total = newTotal;
						this.tableDataByVendor.rows = newDataArray;
						this.loadedByVendor = true;
						this.ref.detectChanges();
					}
				})
		);
		//#endregion
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
