import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	faArrowAltCircleLeft,
	faFileExport,
	faTable
} from '@fortawesome/free-solid-svg-icons';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { MessageService } from "../../services/message.service";
import {
	StateService
} from '../../services/state.service';
import { WhiteLabelService } from '../../services/white-label.service';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import { QueryOptionsPagingModel } from '../models/queryOptionsPagingModel';
import { RequestSummaryVendorPivotResponseModel } from '../models/requestSummaryVendorPivotResponseModel';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';

@Component({
	selector: 'app-vendor-performance-status-vendor-ri',
	templateUrl: './vendor-performance-status-vendor-ri.component.html'
})
export class VendorPerformanceStatusVendorRIComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByVendorReactiveIncident: boolean;
	tableDataByVendorReactiveIncident = {
		rows: [],
		columns: [{
			prop: 'name',
			name: 'Vendor'
		}, {
			prop: 'reactiveCount',
			name: 'Reactive Count'
		}, {
			prop: 'reactiveRespondKPIFail',
			name: 'Reactive Respond KPI Fail'
		}, {
			prop: 'reactiveResolveKPIFail',
			name: 'Reactive Resolve KPI Fail'
		}, {
			prop: 'incidentCount',
			name: 'Incident Count'
		}, {
			prop: 'incidentRespondKPIFail',
			name: 'Incident Respond KPI Fail'
		}, {
			prop: 'incidentResolveKPIFail',
			name: 'Incident Resolve KPI Fail'
		}, {
			prop: 'reactiveIncidentResolveFailPercentage',
			name: 'Resolve Fail %'
		}],
		total: [],
		onNameClick: (row) => {
			for (var i = 0; i < this.woFiltersService.vendors.length; i++) {
				if (this.whiteLabelService.getSettings.enableNewVendorFilter) {
					if (this.woFiltersService.vendorNames[i].name === row.name) {
						this.woFiltersService.isCollapsed = false;
						this.woFiltersService.selectedVendorNames = [this.woFiltersService.vendorNames[i]];
						break;
					}
				} else {
					if (this.woFiltersService.vendors[i].id === row.id) {
						this.woFiltersService.isCollapsed = false;
						this.woFiltersService.selectedVendor = [this.woFiltersService.vendors[i]];
						break;
					}
				}
			}
		},
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.tableByVendorReactiveIncident.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		}
	};
	@ViewChild('woVendorTableReactiveIncident') tableByVendorReactiveIncident: DatatableComponent;

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private workOrderReportService: WorkOrderReportService,
		public whiteLabelService: WhiteLabelService,
		private messageService: MessageService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByVendorReactiveIncident = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.roles$.subscribe(data => {
			if (data.length > 0 && data.indexOf('store') < 0 && data.indexOf('rm') < 0) {
				this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
					this.update(this.stateService.getCurrentDateRange(), value);
				}));
			}
		}));
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		return this.woFiltersService.fillBody(body, dateRange);
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByVendorReactiveIncident = false;

		//#region Table 1
		let bodyTable1 = this.fillBody(new QueryOptionsModel(), dateRange);
		bodyTable1.filter.overallStatuses = ['Open'];
		bodyTable1.filter.requestTypes = ['Reactive', 'Incident'];
		bodyTable1 = this.woFiltersService.fillBodyEndDateThisMonth(bodyTable1);
		this.subs.add(this.workOrderReportService.getRequestSummaryVendorPivot(bodyTable1)
			.subscribe(data => {
				this.dataExport = data;
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					let newData: {
						[id: string]: any;
					} = {};
					let newTotal = [{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: null
					}];
					data.forEach(element => {
						let rowId = `${element.name}--${element.vendorNo}`;
						if (typeof newData[rowId] === "undefined") {
							let newRow = {
								id: element.id,
								name: element.subGroupName,
								reactiveCount: 0,
								reactiveRespondKPIFail: 0,
								reactiveResolveKPIFail: 0,
								incidentCount: 0,
								incidentRespondKPIFail: 0,
								incidentResolveKPIFail: 0,
								resolveFailPercentage: 0
							};
							newData[rowId] = newRow;
						}
						newData[rowId].reactiveCount += element.reactiveCount;
						newTotal[0].value += element.reactiveCount;
						newData[rowId].reactiveRespondKPIFail += element.reactiveRespondKPIFail;
						newTotal[1].value += element.reactiveRespondKPIFail;
						newData[rowId].reactiveResolveKPIFail += element.reactiveResolveKPIFail;
						newTotal[2].value += element.reactiveResolveKPIFail;
						newData[rowId].incidentCount += element.incidentCount;
						newTotal[3].value += element.incidentCount;
						newData[rowId].incidentRespondKPIFail += element.incidentRespondKPIFail;
						newTotal[4].value += element.incidentRespondKPIFail;
						newData[rowId].incidentResolveKPIFail += element.incidentResolveKPIFail;
						newTotal[5].value += element.incidentResolveKPIFail;
						newData[rowId].resolveFailPercentage = (newData[rowId].reactiveResolveKPIFail + newData[rowId].incidentResolveKPIFail) / (newData[rowId].reactiveCount + newData[rowId].incidentCount) * 100;
					});

					let newDataArray = [];
					for (let key in newData) {
						newDataArray.push(newData[key]);
					}
					this.tableDataByVendorReactiveIncident.total = newTotal;
					this.tableDataByVendorReactiveIncident.rows = newDataArray;
					this.loadedByVendorReactiveIncident = true;
					this.ref.detectChanges();
				}
			}));
		//#endregion
	}

	//#region export CSV

	public inProgressExportCSV: boolean = false;
	private dataExport: RequestSummaryVendorPivotResponseModel[];
	exportCsv() {
		this.inProgressExportCSV = true;
		let headers = Object.getOwnPropertyNames(this.dataExport[0]);
		headers = headers.filter(item => !item.startsWith("cost"));
		headers = headers.filter(item => !item.startsWith("planned"));
		headers = headers.filter(item => !item.startsWith("count"));
		if (this.whiteLabelService.getSettings.filterVendorLabel != "vendor") {
			// replace vendor with whitelabel setting in all headers
			headers = headers.map((header) =>
				header.replace("vendor", this.whiteLabelService.getSettings.filterVendorLabel)
			);
		}
		let options = {
			fieldSeparator: ",",
			quoteStrings: '"',
			decimalseparator: ".",
			showLabels: true,
			headers: headers,
			useHeader: true,
			showTitle: false,
			title: "export",
			useBom: true,
		};		
		new AngularCsv(this.dataExport, "export", options);
		this.inProgressExportCSV = false;
	}

	//#endregion

	// Icons
	faTable = faTable;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
	faFileExport = faFileExport;
}