import { Component, OnDestroy, OnInit } from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { Guid } from "guid-typescript";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { WOFiltersService } from "../services/wo-filters.service";

@Component({
	selector: "app-work-orders-completed",
	templateUrl: "./work-orders-completed.component.html",
})
export class WorkOrdersCompletedComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	resetFilters() {
		this.woFiltersService.resetFilters();
		this.stateService.updateDateRange("null", null, null);
		this.stateService.setDateRange(null); // call update automatically
	}

	constructor(
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		public whiteLabelService: WhiteLabelService
	) {}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.stateService.setHeaderTitle("Completed Work Orders");
		this.roles$ = this.authService.getCurrentUserRoles;
		this.woFiltersService.loadData();
		this.subs.add(
			this.stateService.getDateRange.subscribe((value) => {
				this.update();
			})
		);
	}

	ngDoCheck() {
		if (this.woFiltersService.isChanged()) {
			this.update();
		}
	}

	update() {
		let currentUpdateId = Guid.create().toString();
		this.woFiltersService.setUpdateId(currentUpdateId);
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
}
