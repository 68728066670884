<div class="card full-height-lg has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> Budget v Total Spend - Fuel vs Non Fuel by State
		</h2>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loaded}" class="chart-container long-height">
			<chart class="chart-container long-height" #chartFABFuelState [type]="chartConfig.type" [data]="chartConfig.data" [options]="chartConfig.options"></chart>
		</div>

		<div *ngIf="!loaded"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
