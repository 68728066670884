import {
	Component,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import {
	StateService
} from '../../services/state.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	faTable
} from '@fortawesome/free-solid-svg-icons';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { QueryOptionsPagingModel } from '../models/queryOptionsPagingModel';
import { FinancialService } from '../services/financial.service';
import { WhiteLabelService } from '../../services/white-label.service';

@Component({
	selector: 'app-financial-forecast-budget-store-gl',
	templateUrl: './financial-forecast-budget-store-gl.component.html'
})
export class FinancialForecastBudgetStoreGlComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loadedByStore: boolean;
	tableDataByStore = {
		rows: [],
		columns: [{
			prop: 'code',
			name: 'GL Code'
		}, {
			prop: 'name',
			name: 'GL Name'
		}, {
			prop: 'open',
			name: 'Open'
		}, {
			prop: 'wip',
			name: 'WIP'
		}, {
			prop: 'completed',
			name: 'Completed'
		}, {
			prop: 'poRasised',
			name: 'PO Raised'
		}, {
			prop: 'total',
			name: 'Total'
		}, {
			prop: 'budget',
			name: 'Budget'
		}, {
			prop: 'varianceCost',
			name: '$ Variance'
		}, {
			prop: 'variancePercent',
			name: '% Variance'
		}],
		total: [],
		toggleExpandRow: (row) => {
			//console.log('Toggled Expand Row!', row);
			this.tableByStore.rowDetail.toggleExpandRow(row);
		},
		onDetailToggle: (event) => {
			//console.log('Detail Toggled', event);
		}
	};
	@ViewChild('woStoreTable') tableByStore: DatatableComponent;

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		public whiteLabelService: WhiteLabelService,
		private workOrderReportService: WorkOrderReportService,
		private financialService: FinancialService) {
	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loadedByStore = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(this.stateService.getCurrentDateRange(), value);
		}));
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.overallStatuses = ['Open', 'Completed'];
		return body;
	}

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loadedByStore = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		let query1 = this.workOrderReportService.getRequestSummaryGLCodePivot(body);
		let query2 = this.financialService.getBudgetSummaryGLCode(body);

		this.subs.add(forkJoin([query1, query2])
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					let newData: {
						[id: string]: any;
					} = {};
					let newTotal = [{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col text-end",
						value: 0
					},
					{
						class: "col-1 text-end",
						value: 0
					}
					];

					data[0].forEach(element => {
						let rowId = `${element.glCode}--${element.glName}`;
						if (typeof newData[rowId] === "undefined") {
							let newRow = {
								//id: element.id,
								code: element.glCode,
								name: element.glName,
								open: 0,
								wip: 0,
								completed: 0,
								poRasised: 0,
								total: 0,
								budget: 0,
								varianceCost: 0,
								variancePercent: 0
							};
							newData[rowId] = newRow;
						}
						newData[rowId].open += element.openCost + element.openCostEstNet;
						newData[rowId].wip += element.wipCost + element.wipCostEstNet;
						newData[rowId].completed += element.completedCost + element.completedCostEstNet;
						newData[rowId].poRasised += element.poRaisedCost + element.poRaisedCostEstNet;
						newData[rowId].total =
							newData[rowId].open +
							newData[rowId].wip +
							newData[rowId].completed +
							newData[rowId].poRasised;
					});
					data[1].forEach(element => {
						if (element.entityMatch) {
							let rowId = `${element.glCode}--${element.glName}`;
							if (typeof newData[rowId] === "undefined") {
								let newRow = {
									//id: element.id,
									code: element.glCode,
									name: element.glName,
									open: 0,
									wip: 0,
									completed: 0,
									poRasised: 0,
									total: 0,
									budget: 0,
									varianceCost: 0,
									variancePercent: 0
								};
								newData[rowId] = newRow;
							}
							newData[rowId].budget += element.amount;
						}
					});

					let newDataArray = [];
					for (let key in newData) {
						let element = newData[key];
						element.varianceCost += element.budget - element.total;
						if (element.budget !== 0) {
							element.variancePercent = 100 * element.varianceCost / element.budget;
						}
						newDataArray.push(element);
						newTotal[0].value += element.open;
						newTotal[1].value += element.wip;
						newTotal[2].value += element.completed;
						newTotal[3].value += element.poRasised;
						newTotal[4].value += element.total;
						newTotal[5].value += element.budget;
						newTotal[6].value += element.varianceCost;
					}
					if (newTotal[5].value !== 0) {
						newTotal[7].value = 100 * newTotal[6].value / newTotal[5].value;
					}

					this.tableDataByStore.total = newTotal;
					this.tableDataByStore.rows = newDataArray;
					this.loadedByStore = true;
					this.ref.detectChanges();
				}
			}));
	}

	// Icons
	faTable = faTable;
}