<div *ngIf="assetConditionsStatsLoaded">
  <div class="info-wrapper" *ngIf="assetConditionsStats">
      <div class="display-1">{{assetConditionsStats.count | number}}<small>Asset Count</small></div>
      <div *ngIf="assetConditionsStats.avgCondition" class="display-1">{{assetConditionsStats.avgCondition | number : '1.1-1'}}<small>Asset Condition Average</small></div>
  </div>

  <div *ngIf="!assetConditionsStats">
      No data to display
  </div>
</div>

<div *ngIf="!assetConditionsStatsLoaded"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>