<div class="card full-height-xl" [ngClass]="{'has-back': woFiltersService.selectedAssetName && woFiltersService.selectedAssetName.length > 0}">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faTable"></fa-icon> Open Work Orders by Asset Category
		</h2>
		<div class="back-btn" *ngIf="woFiltersService.selectedAssetName && woFiltersService.selectedAssetName.length > 0">
			<button type="button" class="btn btn-secondary" (click)="woFiltersService.drilldownBackByAssetName()">
				<fa-icon [icon]="faArrowAltCircleLeft"></fa-icon><span class="d-none d-md-inline ms-1"> Back</span>
			</button>
		</div>
	</div>
	<div class="card-body p-0">
		<div class="datatable-responsive data-responsive-sm">
			<ngx-datatable #openWOAssetTable class="bootstrap" [rows]="tableDataByAsset.rows" [columns]="tableDataByAsset.columns" [headerHeight]="50" [scrollbarV]="true" [rowHeight]="38" [footerHeight]="50" [columnMode]="'flex'" [sorts]="[{prop: 'name', dir: 'asc'}]" [loadingIndicator]="!loadedByAsset">
				<!-- Row Detail Template -->
				<ngx-datatable-row-detail [rowHeight]="100" (toggle)="tableDataByAsset.onDetailToggle($event)">
					<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
						<div class="row-detail-inner d-flex flex-row flex-wrap">
							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Incident: </span>{{row.Incident | number}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Planned: </span>{{row.Planned | number}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Reactive: </span>{{row.Reactive | number}}
							</div>

							<div class="mb-3 me-4">
								<span class="text-uppercase half-strong">Total: </span>{{row.Total | number}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-row-detail>
				<!-- Column Templates -->
				<ngx-datatable-column prop="name" name="Asset Category" headerClass="col-5 col-md-6 has-expand-icon" cellClass="col-5 col-md-6 has-expand-icon" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-row="row" let-value="value" let-expanded="expanded" ngx-datatable-cell-template>
						<span title="{{value}}">
							<span class="position-relative d-block">
								<a
									href="javascript:void(0)"
									[class.datatable-icon-right]="!expanded"
									[class.datatable-icon-down]="expanded"
									class="datatable-icon-action"
									title="Expand/Collapse Row"
									(click)="tableDataByAsset.toggleExpandRow(row)">
								</a>
								<span class="cursor-pointer text-truncate-custom" (click)="tableDataByAsset.onNameClick(row)">{{value}}</span>
						</span>
						</span>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Incident" name="Incident" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Planned" name="Planned" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Reactive" name="Reactive" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number}}
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-column prop="Total" name="Total" headerClass="col text-end" cellClass="col text-end" [resizeable]="false" [draggable]="false">
					<ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
						<span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">{{column.name}}</span></span>
					</ng-template>
					<ng-template let-value="value" ngx-datatable-cell-template>
						{{value | number}}
					</ng-template>
				</ngx-datatable-column>
				<!-- Footer Template -->
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template>
						<div class="datatable-footer-row">
							<div class="datatable-footer-cell col-5 col-md-6">Total</div>
							<div *ngFor="let column of tableDataByAsset.total" class="datatable-footer-cell col text-end">
								{{column | number}}
							</div>
						</div>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
	</div>
</div>
