import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '../../services/state.service';
import { AssetConditionsRatingsService } from '../services/ac-ratings.service';
import { Store } from '../models/store';
import { ApplyFilters } from '../models/applyFilters';
import { ChartComponent } from 'angular2-chartjs';
import { WhiteLabelService } from '../../services/white-label.service';

@Component({
  selector: 'app-asset-condition-rating',
  templateUrl: './asset-condition-rating.component.html'
})
export class AssetConditionRatingComponent implements OnInit {
  ratingChartConfig = {
    type: 'horizontalBar',
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: "#fe9666",
          hoverBackgroundColor: "#ed814f"
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              callback: function (label, index, labels) {
                return `${label.toLocaleString()}`;
              },
              beginAtZero: true
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return `${tooltipItem.xLabel.toLocaleString()}`;
          }
        }
      }
    }
  }
  @ViewChild('ratingChart') ratingChart: ChartComponent;
  assetConditionsRatingsLoaded: boolean;

  constructor(private stateService: StateService,
    private whiteLabelService: WhiteLabelService,
    private acRatingsService: AssetConditionsRatingsService, ) { }

  ngOnInit() {
    var colors = this.whiteLabelService.getRatingChartColors();
    this.ratingChartConfig.data.datasets[0].backgroundColor = colors.backgroundColor;
    this.ratingChartConfig.data.datasets[0].hoverBackgroundColor = colors.hoverBackgroundColor;
    this.assetConditionsRatingsLoaded = false;
    this.stateService.getStoreAndFilter.subscribe((value: [Store, ApplyFilters]) => {
      this.updateAsset(value[0] ? value[0].storeNo : null, value[1]);
    });
  }

  updateAsset(storeNo: string, filters: ApplyFilters) {
    //this.assetConditionsRatingsLoaded = false;
    this.acRatingsService.getAssetConditionsRating(storeNo, filters)
      .subscribe(assetConditionsRatings => {
        this.assetConditionsRatingsLoaded = true;
        let newLabels = [];
        let newData = [];
        assetConditionsRatings.forEach(element => {
          let condition = element.condition.toString();
          switch (element.condition) {
            case 0: condition = "Not Accessed"; break;
            case 1: condition = "Failed"; break;
            case 2: condition = "Poor"; break;
            case 3: condition = "Average"; break;
            case 4: condition = "Good"; break;
            case 5: condition = "Excellent"; break;
          }
          newLabels.push(condition);
          newData.push(element.count);
        });
        this.ratingChartConfig.data.labels = newLabels;
        this.ratingChartConfig.data.datasets[0].data = newData;
        if (this.ratingChart) {
          this.ratingChart.chart.update();
        }
      });
  }
}
