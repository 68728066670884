import {
	Injectable
} from '@angular/core';
import {
	HttpClient
} from '@angular/common/http';
import {
	Observable
} from 'rxjs';
import {
	catchError
} from 'rxjs/operators';
import {
	environment
} from '../../../environments/environment';
import {
	MessageService
} from '../../services/message.service';
import {
	AuthService
} from '../../services/auth.service';
import { DashboardRowModel } from '../models/dashboardRowModel';
import { WhiteLabelService } from '../../services/white-label.service';
import { DashboardAssetPerformanceModel } from '../models/dashboardAssetPerformanceModel';

@Injectable()
export class DashboardService {
	private apiUrl = this.whiteLabelService.getSettings.informApiUrl + 'api/';

	constructor(
		private http: HttpClient,
		private messages: MessageService,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService) { }

	getDashboard(): Observable<DashboardRowModel[]> {
		let url = this.apiUrl + 'Dashboard/Load';
		return this.http.get<DashboardRowModel[]>(url, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getDashboard()', []))
		);
	}

	saveDashboard(model: DashboardRowModel[]) {
		let url = this.apiUrl + 'Dashboard/Save';
		return this.http.put<DashboardRowModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'saveDashboard(model)', []))
		);
	}

	getDashboardAssetPerformance(): Observable<DashboardAssetPerformanceModel> {
		let url = this.apiUrl + 'Dashboard/LoadAssetPerformance';
		return this.http.get<DashboardAssetPerformanceModel>(url, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'getDashboardAssetPerformance()', null))
		);
	}

	saveDashboardAssetPerformance(model: DashboardAssetPerformanceModel) {
		let url = this.apiUrl + 'Dashboard/SaveAssetPerformance';
		return this.http.put<DashboardRowModel[]>(url, model, this.authService.getHttpOptions()).pipe(
			catchError(this.messages.handleError(this.authService, 'saveDashboardAssetPerformance(model)', []))
		);
	}
}