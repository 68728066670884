<ng-template #budgetTipContent>
	Full Financial Year budget
</ng-template>
<ng-template #totalSpendTipContent>
	Total Spend =<br>Actual Spend i.e. Completed RM/PM and PO Raised amounts<br>PLUS<br>Estimated Spend i.e. Open RM/PM WO and WIP amounts.
</ng-template>
<ng-template #fullYearForecastVBudgetTipContent>
	Budget v Projection =<br>Budget - Total Spend - Future Budget
</ng-template>
<ng-template #woCountTipContent>
	WO Count =<br>RM/PM Open and Completed work orders
</ng-template>
<div class="card info-card has-invisible-content">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faInfo"></fa-icon> Summary
		</h2>
	</div>
	<div class="card-body">
		<div [ngClass]="{'invisible': !loaded}">
			<div class="info-wrapper contain-long-data">
				<div [ngbTooltip]="budgetTipContent" placement="bottom" tooltipClass="summary-tooltip" class="display-1 long-data text-nowrap">${{budget | number : '1.0-0'}}<small>Budget</small></div>
				<div [ngbTooltip]="totalSpendTipContent" placement="bottom" tooltipClass="summary-tooltip" class="display-1 long-data text-nowrap">${{totalSpend | number : '1.0-0'}}<small>Total Spend</small></div>
				<div class="display-1 long-data text-nowrap pb-sm-1 pb-md-0">${{totalSpendVBudget | number : '1.0-0'}}<small class="text-body">Budget v Total Spend</small></div>
				<div [ngbTooltip]="fullYearForecastVBudgetTipContent" placement="bottom" tooltipClass="summary-tooltip" *ngIf="showFullYearForecastVBudget" class="display-1 long-data text-nowrap pb-sm-1 pb-md-0">${{fullYearForecastVBudget | number : '1.0-0'}}<small class="text-body">Budget v Projection</small></div>
				<div [ngbTooltip]="woCountTipContent" placement="bottom" tooltipClass="summary-tooltip" class="display-1 long-data text-nowrap pb-sm-1 pb-md-0">{{woCount | number : '1.0-0'}}<small class="text-body">WO Count</small></div>
			</div>
		</div>

		<div *ngIf="!loaded"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span></div>
	</div>
</div>
