<div *ngIf="entity">
    <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem>
            <a ngbNavLink><fa-icon [icon]="faMapMarkerAlt"></fa-icon><span class="d-none d-sm-inline ms-2"> Map</span></a>
            <ng-template ngbNavContent>
                <google-map [zoom]="zoom" [center]="center" class="gmap-container">
                    <map-marker [position]="center"></map-marker>
                </google-map>
                <!--<agm-map [zoom]="16" [latitude]="lat" [longitude]="lng" class="gmap-container">
                    <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                </agm-map>-->
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
            <a ngbNavLink><fa-icon [icon]="faFile"></fa-icon><span class="d-none d-sm-inline ms-2"> Files</span></a>
            <ng-template ngbNavContent>
                <div *ngIf="store">         
                    <div class="form-group form-group-upload mb-0">
                        <div class="upload-file-container">
                            <div class="custom-file upload-file-btn" title="{{isOk ? 'Upload Successful' : errorMessage ? errorMessage : ''}}" tabindex="1">
                                <input [disabled]="inProgress" #file type="file" (change)="upload(file.files)" class="custom-file-input" id="upload-file" />
    
                                <label class="upload-file-label" for="upload-file">
                                    <span class="file-name"><fa-icon [icon]="faFileUpload" class="me-2"></fa-icon>{{buttonText}}</span>
                                    <span *ngIf="inProgress" class="loader loader-small loader-reverse-adapt"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span>
                                    <span *ngIf="errorMessage" class="text-danger"><fa-icon [icon]="faExclamationTriangle" class="ms-2"></fa-icon></span>
                                    <span *ngIf="isOk" class="text-success"><fa-icon [icon]="faCheck" class="ms-2"></fa-icon></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="datatable-responsive data-responsive-sm">
                    <ngx-datatable
                        class="bootstrap overflow-header-name body-cell-valign-center flexible-scroll"
                        [rows]="tableData.rows"
                        [columns]="tableData.columns"
                        [headerHeight]="50"
                        [rowHeight]="'auto'"
                        [columnMode]="'flex'">
                        <ngx-datatable-column headerClass="col-6 col-lg-5 col-xl-6" cellClass="col-6 col-lg-5 col-xl-6" name="fileName" [sortable]="true">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">Name</span></span>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <a href="{{getLink(row)}}" target="_blank" class="d-flex file-name-link"><fa-icon [icon]="getIcon(value)" class="me-2"></fa-icon>{{value}}</a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column headerClass="col" cellClass="col" name="fileType" [sortable]="true">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" >
                                <span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">Type</span></span>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column headerClass="col" cellClass="col" name="uploadTime" [sortable]="true">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" >
                                <span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">Upload date</span></span>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{value | date:'yyyy-MM-dd HH:mm:ss'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column headerClass="col" cellClass="col" name="Actions" prop="id">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label">Delete</span></span>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <button (click)="deleteFile($event, value)" type="button" class="btn btn-danger btn-sm btn-trash"><fa-icon [icon]="faTrashAlt"></fa-icon></button>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </ng-template>
        </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<!-- <div *ngIf="entity">
    <ngb-tabset type="pills" id="store-dashdoard-tabs">
        <ngb-tab>
            <ng-template ngbTabTitle><fa-icon [icon]="faMapMarkerAlt"></fa-icon><span class="d-none d-sm-inline ml-2"> Map</span></ng-template>
            <ng-template ngbTabContent>
                <agm-map [zoom]="16" [latitude]="lat" [longitude]="lng" class="gmap-container">
                    <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                </agm-map>
            </ng-template>
        </ngb-tab>
        <ngb-tab>
            <ng-template ngbTabTitle><fa-icon [icon]="faFile"></fa-icon><span class="d-none d-sm-inline ml-2"> Files</span></ng-template>
            <ng-template ngbTabContent>
                <div *ngIf="store">         
                    <div class="form-group form-group-upload mb-0">
                        <div class="upload-file-container">
                            <div class="custom-file upload-file-btn" title="{{isOk ? 'Upload Successful' : errorMessage ? errorMessage : ''}}" tabindex="1">
                                <input [disabled]="inProgress" #file type="file" (change)="upload(file.files)" class="custom-file-input" id="upload-file" />

                                <label class="upload-file-label" for="upload-file">
                                    <span class="file-name"><fa-icon [icon]="faFileUpload" class="mr-2"></fa-icon>{{buttonText}}</span>
                                    <span *ngIf="inProgress" class="loader loader-small loader-reverse-adapt"><span class="loader-inner"><span class="left">Loading...</span><span class="right"></span></span></span>
                                    <span *ngIf="errorMessage" class="text-danger"><fa-icon [icon]="faExclamationTriangle" class="ml-2"></fa-icon></span>
                                    <span *ngIf="isOk" class="text-success"><fa-icon [icon]="faCheck" class="ml-2"></fa-icon></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="datatable-responsive data-responsive-sm">
                    <ngx-datatable
                        class="bootstrap overflow-header-name body-cell-valign-center flexible-scroll"
                        [rows]="tableData.rows"
                        [columns]="tableData.columns"
                        [headerHeight]="50"
                        [rowHeight]="'auto'"
                        [columnMode]="'flex'">
                        <ngx-datatable-column headerClass="col-6 col-lg-5 col-xl-6" cellClass="col-6 col-lg-5 col-xl-6" name="fileName" [sortable]="true">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">Name</span></span>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <a href="{{getLink(row)}}" target="_blank" class="d-flex file-name-link"><fa-icon [icon]="getIcon(value)" class="mr-2"></fa-icon>{{value}}</a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column headerClass="col" cellClass="col" name="fileType" [sortable]="true">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" >
                                <span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">Type</span></span>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column headerClass="col" cellClass="col" name="uploadTime" [sortable]="true">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" >
                                <span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label" (click)="sort()">Upload date</span></span>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{value | date:'yyyy-MM-dd HH:mm:ss'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column headerClass="col" cellClass="col" name="Actions" prop="id">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <span class="datatable-header-cell-wrapper"><span class="datatable-header-cell-label">Delete</span></span>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <button (click)="deleteFile($event, value)" type="button" class="btn btn-danger btn-sm btn-trash"><fa-icon [icon]="faTrashAlt"></fa-icon></button>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div> -->
<div *ngIf="!entity" class="card-padding">Please select store</div>