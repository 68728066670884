<main>
	<div class="container">
		<div class="row mb-3">
			<nav aria-label="breadcrumb" class="w-100 half-strong">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">Home</li>
					<li class="breadcrumb-item"><span (click)="resetFilters()">Completed Work Orders</span></li>
				</ol>
			</nav>
		</div>

		<div class="row">
			<div class="col-12 mb-4">
				<app-work-orders-nav></app-work-orders-nav>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-filters></app-work-orders-filters>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-completed-summary></app-work-orders-completed-summary>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-lg-4 mb-3">
				<app-work-orders-completed-priority></app-work-orders-completed-priority>
			</div>
			<div class="col-12 col-lg-8 mb-3">
				<app-work-orders-completed-state></app-work-orders-completed-state>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-xl-6 mb-3">
				<app-work-orders-completed-vendor></app-work-orders-completed-vendor>
			</div>

			<div class="col-12 col-xl-6 mb-3">
				<app-work-orders-completed-asset></app-work-orders-completed-asset>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-completed-store></app-work-orders-completed-store>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<app-work-orders-completed-month></app-work-orders-completed-month>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-lg-8 mb-3 mb-lg-0">
				<app-work-orders-completed-resolve></app-work-orders-completed-resolve>
			</div>

			<div class="col-12 col-lg-4 mb-0">
				<app-work-orders-completed-respond></app-work-orders-completed-respond>
			</div>
		</div>
	</div>
</main>
