import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '../../services/state.service';
import { LifeCycleForecastService } from '../services/life-cycle-forecast.service';
import { Store } from '../models/store';
import { ApplyFilters } from '../models/applyFilters';
import { ChartComponent } from 'angular2-chartjs';
import { WhiteLabelService } from '../../services/white-label.service';

@Component({
  selector: 'app-life-cycle-forecast',
  templateUrl: './life-cycle-forecast.component.html'
})
export class LifeCycleForecastComponent implements OnInit {
  lifeChartConfig = {
    type: 'horizontalBar',
    data: {
      labels: ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"],
      datasets: [
        {
          data: [],
          backgroundColor: "#f2c80f",
          hoverBackgroundColor: "#ddb503"
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              callback: function (label, index, labels) {
                return `$${label.toLocaleString()}`;
              },
              beginAtZero: true
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return `$${tooltipItem.xLabel.toLocaleString()}`;
          }
        }
      }
    }
  }
  @ViewChild('lifeChart') lifeChart: ChartComponent;
  lifeCycleForecastLoaded: boolean;

  constructor(private stateService: StateService,
    private whiteLabelService: WhiteLabelService,
    private lifeCycleForecastService: LifeCycleForecastService,) { }

  ngOnInit() {
    var colors = this.whiteLabelService.getLifeChartColors();
    this.lifeChartConfig.data.datasets[0].backgroundColor = colors.backgroundColor;
    this.lifeChartConfig.data.datasets[0].hoverBackgroundColor = colors.hoverBackgroundColor;
    this.lifeCycleForecastLoaded = false;
    this.stateService.getStoreAndFilter.subscribe((value: [Store, ApplyFilters]) => {
      this.updateAsset(value[0] ? value[0].storeNo : null, value[1]);
    });
  }

  updateAsset(storeNo: string, filters: ApplyFilters) {
    //this.lifeCycleForecastLoaded = false;
    this.lifeCycleForecastService.getLifeCycleForecast(storeNo, filters)
      .subscribe(lifeCycleForecast => {
        this.lifeCycleForecastLoaded = true;
        let newData = [];
        if (lifeCycleForecast) {
          newData.push(lifeCycleForecast.year1);
          newData.push(lifeCycleForecast.year2);
          newData.push(lifeCycleForecast.year3);
          newData.push(lifeCycleForecast.year4);
          newData.push(lifeCycleForecast.year5);
          if (this.whiteLabelService.isWhittlesea()) {
            newData.push(lifeCycleForecast.year6);
            newData.push(lifeCycleForecast.year7);
            newData.push(lifeCycleForecast.year8);
            newData.push(lifeCycleForecast.year9);
            newData.push(lifeCycleForecast.year10);
            this.lifeChartConfig.data.labels = ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5", "Year 6", "Year 7", "Year 8", "Year 9", "Year 10"];
          } else {
            this.lifeChartConfig.data.labels = ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"];
          }
        }
        this.lifeChartConfig.data.datasets[0].data = newData;
        if (this.lifeChart) {
          this.lifeChart.chart.update();
        }
      });
  }
}
