import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
	faArrowAltCircleLeft,
	faCalendarAlt,
	faCaretDown,
	faChartColumn,
	faClipboardList,
	faColumns,
	faFilter,
	faInfo,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import {
	IMyDateRangeModel,
	IMyDrpOptions,
	IMyInputFocusBlur,
	MyDateRangePicker,
} from "mydaterangepicker";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { StateService } from "../../services/state.service";
import { WhiteLabelService } from "../../services/white-label.service";
import { WOFiltersService } from "../services/wo-filters.service";

@Component({
	selector: "app-work-orders-filters",
	templateUrl: "./work-orders-filters.component.html",
})
export class WorkOrdersFiltersComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	myDateRangePickerOptions: IMyDrpOptions = {
		dateFormat: "dd mmm yyyy",
		showClearBtn: false,
		showApplyBtn: false,
		selectorHeight: "282px",
		selectorWidth: "304px",
		height: "38px",
		selectionTxtFontSize: "16px",
	};

	// For example initialize to specific date (09.10.2018 - 19.10.2018). It is also possible
	// to set initial date range value using the selDateRange attribute.
	public model: any = {
		beginDate: undefined,
		endDate: undefined,
	};

	@ViewChild("mydrp") mydrp: MyDateRangePicker;

	onDateRangeChanged(event: IMyDateRangeModel) {
		this.stateService.setDateRange(event);
	}

	onInputFocusBlur(event: IMyInputFocusBlur): void {
		if (event.reason === 1) {
			this.mydrp.openBtnClicked();
		}
	}

	myDateRangePickerEstOptions: IMyDrpOptions = {
		dateFormat: "dd mmm yyyy",
		showClearBtn: false,
		showApplyBtn: false,
		selectorHeight: "282px",
		selectorWidth: "304px",
		height: "38px",
		selectionTxtFontSize: "16px",
	};

	// For example initialize to specific date (09.10.2018 - 19.10.2018). It is also possible
	// to set initial date range value using the selDateRange attribute.
	public modelEst: any = {
		beginDate: undefined,
		endDate: undefined,
	};

	@ViewChild("mydrpEst") mydrpEst: MyDateRangePicker;

	onDateRangeEstChanged(event: IMyDateRangeModel) {
		this.woFiltersService.setDateRangeEst(event);
	}

	onInputFocusBlurEst(event: IMyInputFocusBlur): void {
		if (event.reason === 1) {
			this.mydrpEst.openBtnClicked();
		}
	}

	constructor(
		private authService: AuthService,
		public whiteLabelService: WhiteLabelService,
		public stateService: StateService,
		public woFiltersService: WOFiltersService
	) {
		var currentDateRange = this.stateService.getCurrentDateRange();
		if (
			currentDateRange != null &&
			currentDateRange.beginJsDate != null &&
			currentDateRange.endJsDate != null
		) {
			this.model = currentDateRange;
		}
		var currentDateRangeEst = this.woFiltersService.getCurrentDateRangeEst();
		if (
			currentDateRangeEst != null &&
			currentDateRangeEst.beginJsDate != null &&
			currentDateRangeEst.endJsDate != null
		) {
			this.modelEst = currentDateRangeEst;
		}
		let shouldOpenFilters = this.woFiltersService.shouldOpenFilters();
		this.woFiltersService.isCollapsed = !shouldOpenFilters;
		this.subs.add(
			this.stateService.getDateRangeUpdateId.subscribe((value) => {
				if (value) {
					switch (this.stateService.dateRangeUpdateOperation) {
						case "null": {
							this.model = null;
							break;
						}
						case "set": {
							let beginDate = this.stateService.dateRangeUpdateBeginDate;
							let endDate = this.stateService.dateRangeUpdateEndDate;
							if (this.mydrp) {
								this.mydrp.beginDate = {
									year: beginDate.getFullYear(),
									month: beginDate.getMonth() + 1,
									day: beginDate.getDate(),
								};
								this.mydrp.endDate = {
									year: endDate.getFullYear(),
									month: endDate.getMonth() + 1,
									day: endDate.getDate(),
								};
								this.mydrp.rangeSelected();
							} else {
								this.model.beginDate = {
									year: beginDate.getFullYear(),
									month: beginDate.getMonth() + 1,
									day: beginDate.getDate(),
								};
								this.model.endDate = {
									year: endDate.getFullYear(),
									month: endDate.getMonth() + 1,
									day: endDate.getDate(),
								};
							}
							break;
						}
					}
					this.stateService.dateRangeUpdateOperation = "";
				}
			})
		);
		this.subs.add(
			this.woFiltersService.getDateRangeEstUpdateId.subscribe((value) => {
				if (value) {
					switch (this.woFiltersService.dateRangeEstUpdateOperation) {
						case "null": {
							this.modelEst = null;
							break;
						}
						case "set": {
							let beginDate = this.woFiltersService.dateRangeEstUpdateBeginDate;
							let endDate = this.woFiltersService.dateRangeEstUpdateEndDate;
							if (this.mydrpEst) {
								this.mydrpEst.beginDate = {
									year: beginDate.getFullYear(),
									month: beginDate.getMonth() + 1,
									day: beginDate.getDate(),
								};
								this.mydrpEst.endDate = {
									year: endDate.getFullYear(),
									month: endDate.getMonth() + 1,
									day: endDate.getDate(),
								};
								this.mydrpEst.rangeSelected();
							} else {
								this.modelEst.beginDate = {
									year: beginDate.getFullYear(),
									month: beginDate.getMonth() + 1,
									day: beginDate.getDate(),
								};
								this.modelEst.endDate = {
									year: endDate.getFullYear(),
									month: endDate.getMonth() + 1,
									day: endDate.getDate(),
								};
							}
							break;
						}
					}
					this.woFiltersService.dateRangeEstUpdateOperation = "";
				}
			})
		);
	}

	ngOnInit() {
		this.woFiltersService.loadData();
	}

	ngDoCheck() {
		if (this.woFiltersService.isFilterChanged()) {
			this.woFiltersService.getStores(true);
			this.woFiltersService.getRetailBusinessManagers(true);
		}
	}

	// Icons
	faInfo = faInfo;
	faColumns = faColumns;
	faClipboardList = faClipboardList;
	faCalendarAlt = faCalendarAlt;
	faTable = faTable;
	faChartColumn = faChartColumn;
	faArrowAltCircleLeft = faArrowAltCircleLeft;
	faFilter = faFilter;
	faCaretDown = faCaretDown;
}
