import {
	Component,
	OnInit
} from '@angular/core';
import {
	NgbDropdownConfig
} from '@ng-bootstrap/ng-bootstrap';
import {
	faUser
} from '@fortawesome/free-solid-svg-icons';
import {
	Observable
} from 'rxjs';
import {
	AuthService
} from '../services/auth.service';
import {
	StateService
} from '../services/state.service';
import { WhiteLabelService } from '../services/white-label.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	providers: [NgbDropdownConfig] // add NgbDropdownConfig to the component providers
})
export class HeaderComponent implements OnInit {
	isLoggedIn$: Observable<boolean>;
	currentUsername$: Observable<string>;
	headerTitle$: Observable<string>;
	roles$: Observable<string[]>;
	public companyLogo = "";
	public enableLogo = true;

	// Icons
	faUser = faUser;

	constructor(config: NgbDropdownConfig,
		private authService: AuthService,
		private stateService: StateService,
		public whiteLabelService: WhiteLabelService) {
		this.companyLogo = whiteLabelService.getCompanyLogo();
		this.enableLogo = whiteLabelService.getEnableLogo();
		config.placement = 'bottom-right';
	}

	ngOnInit() {
		this.isLoggedIn$ = this.authService.isLoggedIn;
		this.currentUsername$ = this.authService.getCurrentUsername;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.headerTitle$ = this.stateService.getHeaderTitle;
	}

	logOut() {
		this.authService.logout();
	}

}