<ul class="nav nav-tabs work-orders-tabs">
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{ active: router.url === '/work-orders-open' }"
      routerLink="/work-orders-open">Open<span> Work Orders</span></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{ active: router.url === '/work-orders-completed' }"
      routerLink="/work-orders-completed">Completed<span> Work Orders</span></a>
  </li>
  <li *ngIf="whiteLabelService.getSettings.enableWeeklyStatusTab" class="nav-item">
    <a class="nav-link" [ngClass]="{ active: router.url === '/weekly-status' }" routerLink="/weekly-status">Weekly
      Status</a>
  </li>
  <li *ngIf="
      !(
        (roles$ | async)?.indexOf('store') > -1 ||
        (roles$ | async)?.indexOf('rm') > -1
      )
    " class="nav-item">
    <a class="nav-link" [ngClass]="{ active: router.url === '/vendor-performance-status' }"
      routerLink="/vendor-performance-status">{{whiteLabelService.capitalizeFirstLetter(whiteLabelService.getSettings.filterVendorLabel)}}
      Performance Status</a>
  </li>
  <li *ngIf="
      (roles$ | async)?.indexOf('admin') > -1 ||
      (roles$ | async)?.indexOf('fm') > -1
    " class="nav-item">
    <a class="nav-link" [ngClass]="{ active: router.url === '/financial' }" routerLink="/financial">Financial</a>
  </li>
  <li *ngIf="whiteLabelService.getSettings.enableAssetAvailabilityTab" class="nav-item">
    <a class="nav-link" [ngClass]="{ active: router.url === '/asset-availability' }"
      routerLink="/asset-availability">Asset Availability</a>
  </li>
  <li *ngIf="whiteLabelService.getSettings.enableAssetPerformanceTab" class="nav-item">
    <a class="nav-link" [ngClass]="{ active: router.url === '/asset-performance' }"
      routerLink="/asset-performance">Asset Performance</a>
  </li>
  <li *ngIf="
      whiteLabelService.getSettings.assetConditionEnable &&
      whiteLabelService.getSettings.assetConditionShowInTopNav &&
      ((roles$ | async).indexOf('admin') > -1 ||
        (roles$ | async).indexOf('fm') > -1)
    " class="nav-item">
    <a class="nav-link" [ngClass]="{ active: router.url === '/asset-condition' }" routerLink="/asset-condition">{{
      whiteLabelService.getSettings.assetConditionName }}</a>
  </li>
</ul>